import React, { useEffect, useState } from "react"

import { Link, useParams } from "react-router-dom"
import { MODEL, BASE_URL, INDEX_TITLE } from "./components/consts"
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable"
import FormLayout from "../../../components/Common/FormLayout"
import { useDispatch } from "react-redux"
import { setPageTitle } from "../../../store/pagetitle/actions"
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons"
import { MODULE } from "../consts"
import { INITIAL_STATE_FILTER } from "./components/consts"
import StockQuantCustomFilters from "./components/StockQuantCustomFilters"
import useQueryURL from "../../../hooks/useQueryURL"
import CustomLink from "../../../components/Common/CustomLink"

const Index = props => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [reserved, setReserved] = useState(null);
  const [available, setAvailable] = useState(null);
  const {filter, setFilter} = useQueryURL(INITIAL_STATE_FILTER);

  let { id } = useParams()

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE))
  }, [])

  const columns = [
    {
      name: "ProductName",
      label: "Producto",
      width: 30,
      type: "string"
    },
    {
      name: "LocationName",
      label: "Ubicación",
      width: 30,
      type: "string"
    },
    {
      name: "Quantity",
      label: "A mano",
      width: 15,
      sum: quantity,
      type: "number"
    },
    {
      name: "Reserved",
      label: "Reservado",
      width: 15,
      sum: reserved,
      type: "number"
    },
    {
      name: "Available",
      label: "Disponible",
      width: 15,
      sum: available,
      type: "number"
    },
    {
      name : "Value",
      label: "Valor",
      width: 15,
      sum: value,
      type: "number"
    },
  ]

  const rows = data => {
    const rows = []

    const r = data?.map((p, index) => {
      const fields = []

      fields.push(<span>{p.product.name}</span>)

      fields.push(
        <span>
          <CustomLink
            content={p.location?.name}
            to={`/${MODULE}/stocklocation/${p.location?.id}`}
          >
            {p.location?.name}
          </CustomLink>
        </span>
      )

      fields.push(<span>{p.quantity}</span>)
      fields.push(<span>
        <CustomLink
            content={`Reservas de ${p.product.name} en ${p.location.name}`}
            to={`/${MODULE}/stockpicking?fromStockLocation=${p.location?.id}&assigned=true&product=${p.product.id}`}
          >
            {p.reserved}
          </CustomLink>
        </span>)
      fields.push(<span>{p.available}</span>)
      fields.push(<span>$ {p.value}</span>)
      
      setQuantity(data?.reduce(function(prev, cur) {
        return prev + cur.quantity;
      }, 0));
    
      setReserved(data?.reduce(function(prev, cur) {
        return prev + cur.reserved;
      }, 0));

      setAvailable(data?.reduce(function(prev, cur) {
        return prev + cur.available;
      }, 0));

      setValue(data?.reduce(function(prev, cur) {
        return prev + cur.value;
      }, 0));

      rows.push({ fields: fields })
    })

    return rows
  }

  const actionButtons = <IndexActionButtons url={BASE_URL} />

  const customFilters = (
    <StockQuantCustomFilters filter={filter} setFilter={setFilter} columns={columns} />
  )

  return (
    <FormLayout actionButtons={null}>
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{orderCriteria: "ProductName", orderDirection: 0}}
      />
    </FormLayout>
  )
}

export default Index
