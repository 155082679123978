import React, { useState, useEffect, useReducer } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Modal, Row } from "reactstrap"
import apiCall from "../../api"
import { setApiError } from "../../store/actions"
import ErrorMessage from "./ErrorMessage"

const SwitchCompanies = props => {
  const { modal, setModal } = props
  const { user } = useSelector(state => state.FormActionStatus)
  const [companies, setCompanies] = useState(null)
  const dispatch = useDispatch()

  const { error } = useSelector(
    state => state.ApiStatus
  )

  useEffect( () => {
    if (modal) {
      const fetchCompanies = async company => {
        try {
          dispatch(setApiError(""))
    
          const { data } = await apiCall.get(`/company/getbyuser`)
    
          setCompanies(data)
        } catch (error) {
          dispatch(setApiError(error.message))
        }
      }

      fetchCompanies(user.id)
    }
  }, [modal])

  const switchCompany = async company => {

    let token = localStorage.getItem("token")
    let refreshToken = localStorage.getItem("refreshToken")

    try {
      const body = {
        token: token,
        refreshToken: refreshToken,
        company: company.id,
      }

      dispatch(setApiError(""))

      const { data } = await apiCall.post(`/user/switchcompany`, body)

      debugger;
      
      let tempUser = user
      tempUser.company = company

      localStorage.setItem("user",JSON.stringify(user))
      localStorage.setItem("token",data.data.token)
      localStorage.setItem("refreshToken",data.data.refreshToken)

      tog_large()
    } catch (error) {
      dispatch(setApiError(error.message))
    }
  }

  

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setModal(!modal)
    removeBodyCss()
  }

  return (
    <Modal
      size="sm"
      isOpen={modal}
      toggle={() => {
        tog_large()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Cambiar de compañia
        </h5>

        <button
          onClick={() => {
            setModal(false)
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <React.Fragment>
          {companies?.length > 0 ? (
            <div
              className="btn-group-vertical d-flex flex-column"
              role="group"
              aria-label="Vertical button group"
            >
              <ErrorMessage error={error} />
              {companies?.map((c, index) => {
                return (
                    <Button
                      color="secondary"
                      outline
                      active={c.id == user.company.id}
                      className="bd-highlight"
                      onClick={() => switchCompany(c)}
                    >
                      {c.name}
                    </Button>

                )
              })}
            </div>
          ) : null}
        </React.Fragment>
      </div>
    </Modal>
  )
}

export default React.memo(SwitchCompanies)
