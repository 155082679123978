import { useEffect } from "react";

import {  MODEL, BASE_URL, INDEX_TITLE } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import { INITIAL_STATE_FILTER } from "./components/consts";
import {MODULE } from "../consts";
import moment from "moment";
import useQueryURL from "../../../hooks/useQueryURL";
import { Badge } from "reactstrap";
import CustomLink from "../../../components/Common/CustomLink";
import AccountMoveCustomFilters from "./components/AccountMoveCustomFilters";

const Index = (props) => {

  const dispatch = useDispatch();
  const {filter, setFilter, queryURL} = useQueryURL(INITIAL_STATE_FILTER);
  
  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        name: "Name",
        label: "Número",
        width: 10,
        type: "string"
      },
      {
        name: "Date",
        label: "Fecha",
        width: 20,
        type: "date"
      },
      {
        name: "PartnerName",
        label: "Contacto",
        width: 20,
        type: "string"
      },
      {
        name: "Ref",
        label: "Referencia",
        width: 20,
        type: "string"
      },
      {
        name: "AccountJournalName",
        label: "Diario",
        width: 10,
        type: "string"
      },
      {
        name: "Amount",
        label: "Monto",
        width: 20,
        type: "number"
      },
      {
        name: "State",
        label: "Estado",
        width: 20,
      },
    ];

  const rows = (data) => {

    
    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
      
      fields.push(
        <CustomLink content={p.name} to={`${BASE_URL}/${p.id}${queryURL}`}>{p.name}</CustomLink>
      );
      
      fields.push(
        <span>
           {moment(p.date).format("DD/MM/YYYY")}</span>
        );

      fields.push(
        <span>
          <CustomLink 
          content={p.partnerName}
          to={`/${MODULE}/partner/${p.partnerID}`}>{p.partnerName}</CustomLink>
        </span>
      );
      
      fields.push(
        <span>{p.ref}</span>
        );

      fields.push(
        <span>
          <CustomLink 
          content={p.accountJournalName}
          to={`/accounting/accountjournal/${p.accountJournalID}`}>{p.accountJournalName}</CustomLink>

        </span>
      );

      fields.push(
      <span>$ {p.amount}</span>
      );
      
      switch (p.state) {
        case 0:
          fields.push(
            <Badge  className="me-1 rounded-pill bg-info font-size-11">Borrador</Badge>
          );
          
          break;
        case 1:
          fields.push(
            <Badge  className="me-1 rounded-pill bg-success font-size-11">Válido</Badge>
          );
          
          break;
        default:
          break;
      }


      rows.push({fields : fields});
    });

    return rows;
  };

  const customFilters = (
    <AccountMoveCustomFilters
      filter={filter}
      setFilter={setFilter}
      columns={columns}
    />
  );

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
      queryURL={queryURL}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{orderCriteria: "ID", orderDirection: 1}}
      />
    </FormLayout>
  );
};

export default Index;
