import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'
import MenuTable from '../Tables/MenuTable'

const MenuTab = (props) => {

    const { form, setFormState, disabled } = props;
    
    return(
        <TabPane tabId="2" className="p-3">
          <Row>
          <MenuTable
          form={form}
          setFormState={setFormState}
          disabled={disabled}
        />
          </Row>
        </TabPane>
    )
}

export default MenuTab