import React from "react"
import { useSelector } from "react-redux"
import SmartButton from "../../../../components/Common/SmartButton"
import ModuleWrapper from "../../../../components/Common/ModuleWrapper"
import useMenu from "../../../../hooks/useMenu"

export const SmartButtons = props => {
  const { form } = props
  const { action } = useSelector(state => state.FormActionStatus)

  const { GoTo } = useMenu()

  const btnStockAction = e => {
    GoTo(
      "Stock",
      `/inventory/stockquant?internal=true&product=${form.fields?.id}`
    )
  }

  const btnStockMoveLine = e => {
    GoTo(
      "Movimientos",
      `/inventory/stockmoveline?done=true&product=${form.fields?.id}`
    )
  }

  const btnSalesAction = e => {
    GoTo("Ventas", `/sales/saleorder?product=${form.fields?.id}`)
  }

  return action === "create" ? null : (
    <div className="d-flex flex-row-reverse">
      <ModuleWrapper module={4}>
        <SmartButton
          onClick={btnStockMoveLine}
          icon="bx-move-horizontal"
          label="Movimientos"
        />
        {form.fields?.stock ? (
          <SmartButton
            onClick={btnStockAction}
            icon="bxs-store-alt"
            label="Stock"
            value={form.fields?.stock}

          />
        ) : null}
      </ModuleWrapper>
      <ModuleWrapper
        module={2}
      >
      {form.fields?.salesCount ? (
        <SmartButton
          onClick={btnSalesAction}
          icon="bxs-dollar-circle"
          label="Ventas"
          value={form.fields?.salesCount}
        />
      ) : null}
      </ModuleWrapper>
      
    </div>
  )
}

export default SmartButtons
