import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import {  MODEL, BASE_URL, INDEX_TITLE } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import { MODULE } from "../consts";
import CustomLink from "../../../components/Common/CustomLink";

const Index = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        name: "Name",
        label: "Nombre",
        width: 30,
      },
      {
        label: "Almacén",
        width: 30,
      },

    ];

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
    
      fields.push(
        <Link to={`${BASE_URL}/${p.id}`}>{p.name}</Link>
      );
      
      fields.push(
        <span>
          <CustomLink
          content={p.stockWareHouse?.name}
          to={`/${MODULE}/stockwarehouse/${p.stockWareHouse?.id}`}>{p.stockWareHouse?.name}</CustomLink>
            
        </span>
      );

      rows.push({fields : fields});
    });

    return rows;
  };

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={{
          customFilters: [],
        }}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
      />
    </FormLayout>
  );
};

export default Index;
