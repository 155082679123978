import React from "react"
import { useSelector } from "react-redux"
import SmartButton from "../../../../components/Common/SmartButton"
import useMenu from "../../../../hooks/useMenu"

export const SmartButtons = props => {
  const { GoTo } = useMenu()

  const { form } = props
  const { action } = useSelector(state => state.FormActionStatus)

  const btnPurchasesAction = e => {
    GoTo("Compras", `/purchases/purchaseorder?partner=${form.fields?.id}`, true)
  }

  const btnSalesAction = e => {
    GoTo("Ventas", `/sales/saleorder?partner=${form.fields?.id}`, true)
  }

  const btnTotalInvoicedAction = e => {
    GoTo(
      "Facturas de cliente",
      `/invoices/accountinvoice?moveType=1&partner=${form.fields?.id}`,
      true
    )
  }

  return action === "create" ? null : (
    <div className="d-flex flex-row-reverse">
      {form.fields?.purchaseOrderCount ? (
        <SmartButton
          onClick={btnPurchasesAction}
          icon="bxs-cart-alt"
          label="Compras"
          value={form.fields?.purchaseOrderCount}
        />
      ) : null}
      {form.fields?.saleOrderCount ? (
        <SmartButton
          onClick={btnSalesAction}
          icon="bxs-store"
          label="Ventas"
          value={form.fields?.saleOrderCount}
        />
      ) : null}

      {form.fields?.totalInvoiced ? (
        <SmartButton
          onClick={btnTotalInvoicedAction}
          icon="bxs-file"
          label="Facturado"
          value={`$ ${form.fields?.totalInvoiced}`}
        />
      ) : null}
    </div>
  )
}

export default SmartButtons
