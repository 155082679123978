import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Container, CardTitle } from "reactstrap"
import FormLayout from "../../components/Common/FormLayout"
import useAPIFunctions from "../../hooks/useAPIFunctions"
import { setPageTitle } from "../../store/actions"
import LatestTransactions from "./LatestTransactions"
import StackedColumnChart from "../../components/Charts/StackedColumnChart"
import CustomLink from "../../components/Common/CustomLink"

const InvoicesDashboard = props => {
  const [results, setResults] = useState([])

  const { doGetAsync } = useAPIFunctions()
  const dispatch = useDispatch()

  const email = [
    // { title: "Mensual", linkto: "#", isActive: false },
    // { title: "Semanal", linkto: "#", isActive: false },
    { title: "Diario", linkto: "#", isActive: true },
  ]

  useEffect(() => {
    dispatch(setPageTitle("Facturación"))
    doFetch()
  }, [])

  const doFetch = async () => {
    try {
      const response = await doGetAsync(`/invoices/dashboard`)

      setResults(response)
    } catch (error) {
      console.log("doFetch", error.message)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Clientes</CardTitle>
                  <Row>
                    <Col sm="6">
                      <p className="text-muted">Facturación pendiente</p>
                      <CustomLink
                        content="Facturas de cliente"
                        to={
                          "/invoices/accountinvoice?moveType=1&internalType=1&open=true"
                        }
                      >
                        <h3 className="text-primary">{`$ ${results?.data?.data?.customerBalance}`}</h3>{" "}
                      </CustomLink>

                      <div className="mt-4">
                        <CustomLink
                          content="Crear factura"
                          to={
                            "/invoices/accountinvoice/create?moveType=1&internalType=1"
                          }
                          className="btn btn-primary waves-effect waves-light btn-sm"
                        >
                          Crear factura{" "}
                          <i className="mdi mdi-arrow-right ms-1"></i>
                        </CustomLink>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="mt-4 mt-sm-0"></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <LatestTransactions transactions={results?.data?.data?.sales} />
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Nivel de facturación</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        {email.map((mail, key) => (
                          <li className="nav-item" key={"_li_" + key}>
                            <Link
                              className={
                                mail.isActive ? "nav-link active" : "nav-link"
                              }
                              to={mail.linkto}
                            >
                              {mail.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart
                    data={results?.data?.data?.salesStackedColumnChart}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Proveedores</CardTitle>
                  <Row>
                    <Col sm="6">
                      <p className="text-muted">Facturación de proveedor</p>
                      <CustomLink
                        content="Facturas de cliente"
                        to={
                          "/invoices/accountinvoice?moveType=2&internalType=1&open=true"
                        }
                      >
                        <h3 className="text-primary">{`$ ${results?.data?.data?.supplierBalance}`}</h3>{" "}
                      </CustomLink>
                      <div className="mt-4">
                        <Link
                          to={
                            "/invoices/accountinvoice/create?moveType=2&internalType=1"
                          }
                          className="btn btn-primary waves-effect waves-light btn-sm"
                        >
                          Crear factura{" "}
                          <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="mt-4 mt-sm-0"></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <LatestTransactions
                transactions={results?.data?.data?.purchases}
              />

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Nivel de facturación</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        {email.map((mail, key) => (
                          <li className="nav-item" key={"_li_" + key}>
                            <Link
                              className={
                                mail.isActive ? "nav-link active" : "nav-link"
                              }
                              to={mail.linkto}
                            >
                              {mail.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart
                    data={results?.data?.data?.purchasesStackedColumnChart}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InvoicesDashboard
