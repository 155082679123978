import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const DropdownTable = (props) => {

  const { onRefresh,onExport, count } = props;
  const [menu, setMenu] = useState(false);

  const onClickRefresh = ((e) => {
    e.preventDefault();

    onRefresh();

  });

  const onClickExport = ((e) => {
    e.preventDefault();

    onExport();

  });

  return (
        <Dropdown className="" isOpen={menu} toggle={(() => setMenu(!menu))}>
          <DropdownToggle className="ms-1 btn btn-secondary rounded-circle">
            <i className="bx bx-dots-horizontal-rounded"></i> {count > 0 ? <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{count}</span> : null}
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem onClick={onClickRefresh}>
              <i className="mdi mdi-refresh font-size-16 text-success mr-2"></i>
              Actualizar
            </DropdownItem>
            <DropdownItem onClick={onClickExport}>
              <i className="mdi mdi-database-export font-size-16 text-warning mr-2"></i>
              Exportar
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem href="#">
              <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i>
              Eliminar
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
  );
};

export default DropdownTable;
