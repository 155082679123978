import { Row, TabPane } from 'reactstrap'
import ProductSupplierTable from '../Tables/ProductSupplierTable';

const PurchasesTab = (props) => {
    
    const { form, disabled, setFormState } = props;

    return(
        <TabPane tabId="3" className="p-3">
          <Row>
          <ProductSupplierTable
          form={form}
          setFormState={setFormState}
          disabled={disabled}
        />
          </Row>
        </TabPane>
    )
}

export default PurchasesTab