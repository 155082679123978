import React from "react";
import { Input, Label } from "reactstrap";

const CheckboxTable = (props) => {

  const { index , checked, onChange} = props;

  return (
    <div className="d-none d-sm-block custom-control custom-checkbox">
      <Input
        type="checkbox"
        checked={checked}
        className="custom-control-input"
        onChange={onChange}
        id={`customCheck${index}`}
      />
      <Label className="custom-control-label" htmlFor={`customCheck${index}`}>
        &nbsp;
      </Label>
    </div>
  );
};

export default CheckboxTable;
