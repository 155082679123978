import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'
import CustomSelect from '../../../../../components/Common/CustomSelect';
import UserTable from '../Tables/UserTable'

const UsersTab = (props) => {
    
    const { form, disabled, setFormState } = props;

    return(
      <TabPane tabId="1" className="p-3">
        <Row>
        <UserTable
          form={form}
          setFormState={setFormState}
          disabled={disabled}
        />
        </Row>
      </TabPane>
    )
}

export default UsersTab