import {
  BASE_GET_TRY,
  BASE_GET_ERROR,
  BASE_GET_OK,
} from "./actionTypes";

const initialState = {
  data: [],
  error: "",
  loading: false,
};

const result = (state = initialState, action) => {
  switch (action.type) {
    case BASE_GET_TRY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BASE_GET_OK:
      state = {
        ...state,
        loading: false,
        data: action.payload.data,
      };
      break;
    case BASE_GET_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default result;
