import {
  BASE_CREATE_TRY,
  BASE_CREATE_ERROR,
  BASE_CREATE_OK,
} from "./actionTypes";

export const createBase = (history,target,values) => {
  return {
    type: BASE_CREATE_TRY,
    payload: { history, target,values },
  };
};

export const createBaseOK = (data) => {
  return {
    type: BASE_CREATE_OK,
    payload: data,
  };
};

export const createBaseError = (error) => {
  return {
    type: BASE_CREATE_ERROR,
    payload: error,
  };
};
