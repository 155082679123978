import  { useEffect } from "react"

import {  useParams } from "react-router-dom"
import { MODEL, BASE_URL, INDEX_TITLE } from "./components/consts"
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable"
import FormLayout from "../../../components/Common/FormLayout"
import { useDispatch } from "react-redux"
import { setPageTitle } from "../../../store/pagetitle/actions"
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons"
import { INITIAL_STATE_FILTER } from "./components/consts"
import StockMoveLineCustomFilters from "./components/StockMoveLineCustomFilters"
import useQueryURL from "../../../hooks/useQueryURL"
import moment from "moment"
import { Badge } from "reactstrap"

const Index = props => {
  const dispatch = useDispatch()
  const {filter, setFilter} = useQueryURL(INITIAL_STATE_FILTER);

  let { id } = useParams()

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE))
  }, [])

  const columns = [
    {
      name: "Date",
      label: "Fecha",
      width: 10,
      type: "date"
    },
    {
      name: "Reference",
      label: "Referencia",
      width: 10,
      type: "string"
    },
    {
      name: "ProductName",
      label: "Producto",
      width: 20,
      type: "string"
    },
    {
      name: "FromLocationName",
      label: "Desde",
      width: 20,
      type: "string"
    },
    {
      name: "ToLocationName",
      label: "Hasta",
      width: 20,
      type: "string"
    },
    {
      name: "qtyDone",
      label: "Cantidad hecha",
      width: 10,
      type: "number"
    },
    {
      label: "Estado",
      width: 10,
    },
  ]

  const rows = data => {
    const rows = []

    const r = data?.map((p, index) => {
      const fields = []

      
      fields.push(<span>{moment(p.date).format("DD/MM/YYYY hh:mm")}</span>)

      fields.push(<span>{p.reference}</span>)

      fields.push(<span>{p.product.name}</span>)

      fields.push(<span>{p.fromLocation.name}</span>)

      fields.push(<span>{p.toLocation.name}</span>)

      fields.push(<span>{p.qtyDone}</span>)

      switch (p.state) {
        case 6:
          fields.push(
            <Badge className="me-1 rounded-pill bg-warning font-size-11">Preparado</Badge>
          );
          break;
        case 7:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">Hecho</Badge>
          );
          break;
        default:
          break;
      }

      rows.push({ fields: fields })
    })

    return rows
  }

  const actionButtons = <IndexActionButtons url={BASE_URL} />

  const customFilters = (
    <StockMoveLineCustomFilters filter={filter} setFilter={setFilter} columns={columns} />
  )

  return (
    <FormLayout actionButtons={null}>
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{orderCriteria: "Date", orderDirection: 1}}
      />
    </FormLayout>
  )
}

export default Index
