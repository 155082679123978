import React, { useEffect, useState } from "react";

import useForm from "../../../hooks/useForm";
import BaseForm from "./components/BaseForm";
import { MODEL, INITIAL_STATE, CREATE_TITLE, INITIAL_STATE_FILTER } from "./components/consts";
import { MODULE } from "../consts";
import DetailsPage from "../../../components/Actions/DetailsPage";
import { useDispatch, useSelector } from "react-redux";
import StatusBar from "./components/StatusBar";
import { setApiLoading } from "../../../store/actions";
import apiCall from "../../../api";
import AccountInvoiceAmounts from "./components/AccountInvoiceAmounts";
import AccountInvoiceActions from "./components/AccountInvoiceActions";
import AccountInvoiceDropdown from "./components/AccountInvoiceDropdown";
import useQueryURL from "../../../hooks/useQueryURL";
import moment from "moment";

const Details = () => {
  const [formState, setFormState] = useState([]);
  const { queryURL} = useQueryURL(INITIAL_STATE_FILTER);
  const form = useForm({ formState });
  const { action } = useSelector((state) => state.FormActionStatus);
  const dispatcher = useDispatch();
  
  const { loading } = useSelector(state => state.ApiStatus);

  useEffect(() => {
    if(action === 'edit' || action === 'create'){
      fetchPartner();
    }
  }, [form.fields.partner]);

  useEffect(() => {
    if(action === 'edit' || action === 'create'){
      if(form.fields.accountPaymentTerm?.due){
        var mydate = moment(form.fields.dateInvoice).format('YYYY-MM-DD');

        setFormState({
          ...form.fields,
          ["dueInvoice"]: moment(mydate).add(form.fields.accountPaymentTerm?.due,'days').format("YYYY-MM-DD")
        });

      }else{
        setFormState({
          ...form.fields,
          ["dueInvoice"]:form.fields.dateInvoice
        });
      }
      
    }
  }, [form.fields.accountPaymentTerm]);

  const fetchPartner = async () => {

    if(form.fields?.moveType == 2) return;
    
    if (form.fields?.partner == null) {
      setFormState({
        ...form.fields,
        ["accountPaymentTerm"]: { id: 1, name: "Pago inmediato" },
      });
      return;
    }

    dispatcher(setApiLoading(true));

    var request = {
      id: form.fields?.partner?.id,
    };

    try {
      const results = await apiCall.post(`/partner/fetchpartner`, request);
      
      dispatcher(setApiLoading(false));
      setFormState({
        ...form.fields,
        ["accountPaymentTerm"]: results.data?.data?.accountPaymentTerm,
        ["accountInvoiceType"]: results.data?.data?.accountInvoiceType,
      });
    } catch (error) {
      dispatcher(setApiLoading(false));
      console.log("fetchPartner", error.message);
    }
  };

  const statusBar = <StatusBar state={form.fields?.invoiceState} />;
  const dropdownSecondary= <AccountInvoiceDropdown id={form.fields?.id} />;

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      setFormState={setFormState}
      statusBar={statusBar}
      disableCreate={form.fields?.internalType === 3 || form.fields?.moveType === 2}
      dropdownSecondary={dropdownSecondary}
      defaultValues
      defaultValuesParams={queryURL}
    >
      <AccountInvoiceActions form={form} loading={loading} />

      <BaseForm
        form={form}
        setFormState={setFormState}
        disabled={action != "edit" && action != "create" ? true : false}
      />
      <hr />
      <AccountInvoiceAmounts form={form} setFormState={setFormState} />
    </DetailsPage>
  );
};

export default Details;
