import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'
import CustomDateTime from '../../../../../components/Common/CustomDateTime'
import CustomEnumSelect from '../../../../../components/Common/CustomEnumSelect'
import CustomSelect from '../../../../../components/Common/CustomSelect'
import { SALE_PICKINGPOLICY_ENUM } from '../consts'

const InformationTab = (props) => {

    const { form, disabled } = props;
    
    return(
        <TabPane tabId="2" className="p-3">
          <Row>
            <Col md="6">
            
            </Col>
          </Row>
        </TabPane>
    )
}

export default InformationTab