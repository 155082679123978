import ReactDOM from "react-dom/client"
import App from "./App"
import ThemeSelector from "./themes/ThemeSelector"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"

import store from "./store"

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <ThemeSelector>
        <App/>
      </ThemeSelector>
      {/* <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload()
          }

          return <App />
        }}
      </CacheBuster> */}
      {/* <App /> */}
    </BrowserRouter>
  </Provider>
)

// ReactDOM.render(app, document.getElementById("root"))
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(app);

serviceWorkerRegistration.register()
