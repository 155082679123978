import {
    BASE_EDIT_TRY,
    BASE_EDIT_ERROR,
    BASE_EDIT_OK,
  } from "./actionTypes";
  
  export const editBase = (history,target,values) => {
    return {
      type: BASE_EDIT_TRY,
      payload: { history, target,values },
    };
  };
  
  export const editBaseOK = (data) => {
    return {
      type: BASE_EDIT_OK,
      payload: data,
    };
  };
  
  export const editBaseError = (error) => {
    return {
      type: BASE_EDIT_ERROR,
      payload: error,
    };
  };
  