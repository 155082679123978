import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import useAccountInvoiceActions from "../Actions"
import AccountPaymentHelper from "../../AccountPayment/components/AccountPaymentHelper"
import { setFormSecondaryAction } from "../../../../store/actions"
import { useDispatch } from "react-redux"
import { PrintIFrame } from "../../../../components/Print/PrintIFrame"
import apiCall from "../../../../api"

const AccountInvoiceActions = props => {
  const { loading, form } = props

  const dispatch = useDispatch()

  const { doValidate, doPrint, doCreditNote, doDebitNote } =
    useAccountInvoiceActions(form.fields.id)

  const iFrameRef = useRef()

  const [settings, setSettings] = useState({
    printReport: false,
    printTicket: false,
  })

  useEffect(() => {
    fetchSettings()
      .then(result => setSettings(result.data))
      .catch(error => console.error("AccountInovice Settings", error.message))
      .finally(() => {})
  }, [])

  const fetchSettings = async () => {
    return await apiCall.get(`/saleorder/settings`)
  }
  
  return (
    <Row className="mb-0">
      <Col>
        {form.fields?.invoiceState === 0 ? (
          <Button
            color="primary"
            className="btn btn-primary btn-sm me-1"
            //  type="submit form="
            //  form="hook-form"
            type="submit"
            form="hook-form"
            onClick={() => {
              dispatch(setFormSecondaryAction("validate"))
            }}
            disabled={loading}
          >
            Validar
          </Button>
        ) : null}
        {form.fields?.id != 0 ? (
          <React.Fragment>
            {settings?.printReport && (
              <Button
                color="secondary"
                className="btn btn-secondary btn-sm me-1"
                onClick={e => {
                  e.preventDefault()
                  doPrint(form.fields.name)
                }}
                disabled={loading}
              >
                Imprimir
              </Button>
            )}

            {settings?.printTicket && (
              <Button
                color="secondary"
                className="btn btn-secondary btn-sm me-1"
                onClick={async e => {
                  e.preventDefault()

                  if (!iFrameRef.current) return

                  await iFrameRef.current.print(
                    `../print/accountinvoice/${form.fields?.id}`
                  )
                }}
                disabled={loading}
              >
                <i className={`bx bx-receipt font-size-16 align-middle`}></i>
              </Button>
            )}
            {form.fields?.internalType === 1 &&
            form.fields?.accountInvoiceRels?.length === 0 &&
            (form.fields?.invoiceState === 1 ||
              form.fields?.invoiceState === 2) ? (
              <React.Fragment>
                <Button
                  color="primary"
                  className="btn btn-secondary btn-sm me-1"
                  onClick={e => {
                    e.preventDefault()
                    doCreditNote()
                  }}
                  disabled={loading}
                >
                  Nota de crédito
                </Button>
              </React.Fragment>
            ) : null}
            {form.fields?.invoiceState === 1 ? (
              <AccountPaymentHelper
                form={form}
                loading={loading}
                model={"accountInvoice"}
                date={form.fields?.dateInvoice}
                partnerType={form.fields.moveType}
                paymentType={
                  form.fields.moveType === 1 && form.fields.internalType === 1
                    ? 1
                    : 2
                }
                forInvoice={true}
              />
            ) : null}
          </React.Fragment>
        ) : null}
      </Col>
      <PrintIFrame ref={iFrameRef} />
    </Row>
  )
}

export default AccountInvoiceActions
