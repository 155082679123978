import { useDispatch } from "react-redux";
import apiCall from "../../../api";
import useAPIFunctions from "../../../hooks/useAPIFunctions";
import { setFormAction, setFormRefresh, setApiLoading } from "../../../store/actions";

const useAccountPaymentActions = (id) => {
  const { doGetAsync } = useAPIFunctions();
  const dispatch = useDispatch();

  const doConfirm = async () => {
    try {
      const response = await doGetAsync(`/accountpayment/confirm/${id}`);

      // debugger;
      
      // await apiCall.getFile(
      //   `/accountpayment/report/${id}`,
      //   `${response.data.data.name}.pdf`
      // );

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
    } catch (error) {
      console.log("confirm", error.message);
    }
  };


  const doPrint = async (name) => {

    dispatch(setApiLoading(true))

    try {
      await apiCall.getFile(
        `/accountpayment/report/${id}`,
        `${name}.pdf`
      );

      dispatch(setApiLoading(false))
    } catch (error) {
      console.log("Report", error.message);
      dispatch(setApiLoading(false))
    }
  };

  const doSendMail = async () => {
    try {
      const results = await doGetAsync(`/accountpayment/sendmail/${id}`);

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
      
    } catch (error) {
      console.log("SendMail", error.message);
    }
  };

  return {
    doConfirm,
    doPrint,
    doSendMail,
  };
};

export default useAccountPaymentActions;
