import React from "react";
import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import CustomMultiSelect from "../../../../components/Common/CustomMultiSelect";
import CustomFileImage from "../../../../components/Common/CustomFileImage";

export const BaseForm = ({ form, handleSubmit, disabled }) => {

  
  return (
    <Row>
      <Col md="4">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />

        <CumstomField
          {...form?.getInput("email")}
          label="Email"
          type="email"
          disabled={disabled}
          required
        />

        <CumstomField
          {...form?.getInput("password")}
          label="Password"
          type="password"
          disabled={disabled}
        />

        <CustomMultiSelect
          label="Sucursales"
          {...form?.getMultiSelect("companies", "company")}
          isClearable
          isDisabled={disabled}
        />

        <CustomSwitch
          label="Activo"
          disabled={disabled}
          {...form?.getSwitch("active")}
        />
      </Col>
      <Col md="4">


        <CustomFileImage 
          label="Avatar"
          {...form?.getFileImage("avatarBase64","avatar")}
          disabled={disabled}
        />

      </Col>
    </Row>
  );
};

export default BaseForm;
