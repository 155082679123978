import React, { useState } from "react";

const CustomCheckbox = (props) => {

 const { name, label, disabled, checked, onClick, onChange} = props;

  return (
    <div>
      <div className="mt-4 mt-lg-0">
        <div className="form-check mb-3">
          <input
            name={name}
            type="checkbox"
            disabled={disabled}
            className="form-check-input"
            id={`CustomCheck${name}`}
            onChange={onChange}
            
            checked={checked}

          
          />
          <label
            className="form-check-label"
            onClick={disabled ? null : onClick}
            // onClick={() => {
            //   setcustomchk(!customchk);
            // }}
          >
            {label}
          </label>
        </div>

      </div>
    </div>
  );
};

export default CustomCheckbox;
