import CustomFilters from "../../../../components/Common/CustomFilters"

const AccountMoveCustomFilters = (props) => {
  const { filter, setFilter, columns } = props

  const itemsFilter = [
    {
      name: "onlyMe",
      label: "Mis asientos",
    },
    {
      name: "divider",
    },
    {
      name: "draft",
      label: "Borrador",
    },
    {
      name: "done",
      label: "Validado",
    },
  ]


  return (
    <CustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
    itemsFilter={itemsFilter}
  />
  );
};

export default AccountMoveCustomFilters;
