import React from "react"
import { Row, Col } from "reactstrap"
import CustomSelect from "../../../../components/Common/CustomSelect"
import CustomDateTime from "../../../../components/Common/CustomDateTime"
import SaleOrderTabs from "./SaleOrderTabs"
import SmartButtons from "./SmartButtons"
import { getLabelFromArray } from "../../../../helpers/array_utils"
import { DOCUMENTTYPE_ENUM } from "../../../Core/Partner/components/consts"
import { partnerModal } from "../../../Core/Partner/components/PartnerModal"
import Container from "react-modal-promise";

export const BaseForm = props => {
  const { form, setFormState, disabled } = props

  
  //console.log(form.fields);
  const readOnlyStates = [4, 5]

  return (
    <React.Fragment>
      <Container />
      <Row>
        <Col md="4">
          <CustomSelect
          
            label={`Cliente ${
              form.fields?.partner
                ? `[${getLabelFromArray(
                    form.fields?.partner?.documentType,
                    DOCUMENTTYPE_ENUM
                  )} - ${form.fields?.partner?.document}]`
                : ""
            }`}
            {...form?.getSelect("partner", "partner", "partnerType=1",partnerModal,{active: true, customer: true})}
            isDisabled={
              disabled ||
              (readOnlyStates.includes(form.fields?.state) &&
                form.fields?.id != 0)
            }
            required
          />

          <CustomSelect
            label="Tarifa"
            {...form?.getSelect("priceList", "priceList")}
            required
            isDisabled={
              disabled ||
              (readOnlyStates.includes(form.fields?.state) &&
                form.fields?.id != 0) ||
              form.fields?.saleOrderItems?.length > 0
            }
          />
        </Col>

        <Col md="4">
          {form.fields?.state == 1 || form.fields?.state == 2 ? (
            <CustomDateTime
              label="Válido hasta"
              disabled={disabled}
              {...form?.getInputDate("date", "validityDate")}
            />
          ) : null}
        {form.fields?.state == 3 || form.fields?.state == 4 ? (
          <Row>
            <Col md="12" lg="6">
              <CustomSelect
                label="Plazo de pago"
                {...form?.getSelect("accountPaymentTerm", "accountPaymentTerm")}
                required
                isDisabled={
                  disabled ||
                  form.fields?.partner === null ||
                  readOnlyStates.includes(form.fields?.state)
                }
              />
            </Col>
            <Col md="12" lg="6">
              <CustomDateTime
                label="Fecha vencimiento"
                disabled={
                  disabled ||
                  form.fields?.partner === null ||
                  readOnlyStates.includes(form.fields?.state)
                }
                {...form?.getInputDate("date", "dueDate")}
              />
            </Col>
          </Row>) :null}

          <CustomSelect
            label="Método de envío"
            {...form?.getSelect("deliveryCarrier", "deliverycarrier")}
            required
            isDisabled={disabled || readOnlyStates.includes(form.fields?.state)}
          />

          {form.fields?.invoiceStatus == 2 ? (
            <CustomSelect
              label="Diario"
              {...form?.getSelect(
                "accountJournal",
                "accountJournal",
                "types=1&forInvoice=false"
              )}
              required
              isDisabled={disabled}
            />
          ) : null}
        </Col>
        <Col md="4">
          <SmartButtons form={form} />
        </Col>
      </Row>
      <SaleOrderTabs
        form={form}
        setFormState={setFormState}
        disabled={disabled || readOnlyStates.includes(form.fields?.state)}
      />
    </React.Fragment>
  )
}

export default BaseForm
