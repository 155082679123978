import React from "react"
import { Button, Col, Table } from "reactstrap"
import SimpleBar from "simplebar-react"

const OrderContainer = props => {

  const { value } = props;
  return (
    <div class="order-container">
      <div class="order">
        <ul class="orderlines">
          <li class="orderline">
            <span class="product-name">
              El producto<span> </span>
            </span>
            <span class="price">$ 339,41</span>
            <ul class="info-list">
              <li class="info">
                <em>55,00</em>
                <span> </span>Unidades en $ 6,17 / Unidades
              </li>
            </ul>
          </li>
          <li class="orderline">
            <span class="product-name">
            El producto<span> </span>
            </span>
            <span class="price">$ 38,60</span>
            <ul class="info-list">
              <li class="info">
                <em>10,00</em>
                <span> </span>Unidades en $ 3,86 / Unidades
              </li>
            </ul>
          </li>
          <li class="orderline selected">
            <span class="product-name">
            El producto<span> </span>
            </span>
            <span class="price">$ {value}</span>
            <ul class="info-list">
              <li class="info">
                <em>1,00</em>
                <span> </span>Unidades en $ 2,83 / Unidades
              </li>
            </ul>
          </li>
          
        </ul>
        <div class="summary clearfix">
          <div class="line">
            <div class="entry total">
              {/* <span class="badge">Total: </span> */}
              <span class="value">$ 380,83</span>
              <div class="subentry">
                Impuestos: <span class="value">$ 65,60</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderContainer
