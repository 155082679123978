export const MODULE = 'accounting';
export const MODEL = 'accounttype';

export const INDEX_TITLE = 'Tipos de cuentas';
export const CREATE_TITLE = 'Nuevo tipo de cuenta';

export const INITIAL_STATE = {
    name: "",
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

