import { Row, Col } from "reactstrap"
import CumstomField from "../../../../components/Common/CustomFields"

export const BaseForm = ({ form, handleSubmit,setFormState, disabled }) => {

  const onChangeColor = e => {
    setFormState({
      ...form.fields,
      ["color"]: e.target.value
    })
  }
  return (
    <Row>
      <Col md="4">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />
        <div className="mb-1">
          <label
            htmlFor="example-color-input"
            className="col-md-2 col-form-label"
          >
            Color
          </label>
          <div className="col-md-10">
            <input
              className="form-control form-control-color mw-100"
              type="color"
              disabled={disabled}
              onChange={onChangeColor}
              value={form.fields?.color}
              id="example-color-input"
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default BaseForm
