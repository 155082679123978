export const MODULE = 'accounting';
export const MODEL = 'accountaccount';

export const INDEX_TITLE = 'Plan contable';
export const CREATE_TITLE = 'Nuevo plan contable';

export const INITIAL_STATE = {
    name: "",
    active: true,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


export const ACCOUNTTYPE_ENUM = [
  { value: 1, label: 'Activo'},
  { value: 2, label: 'Pasivo'},
  { value: 3, label: 'Valor neto'},
  { value: 4, label: 'Ingreso'},
  { value: 5, label: 'Gasto'},
  { value: 6, label: 'Otros'},
];

