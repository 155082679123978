import React from "react"
import { useKeyUp } from "../../../../hooks/useKeyUp"

const Index = props => {
  const { setMode } = props
  useKeyUp(() => setMode(0), ["Escape"])

  return (
    <div class="ticket-screen screen">
      <div class="screen-full-width">
        <div class="rightpane pane-border">
          <div class="controls">
            <div class="buttons">
              <button class="discard" onClick={e => setMode(0)}>
                <span class="search-icon">
                  <i class="fa fa-angle-double-left"></i>
                </span>{" "}
                Volver{" "}
              </button>
              <button class="highlight">Nuevo pedido</button>
            </div>
            <div class="pos-search-bar">
              <div class="search">
                <span class="search-icon">
                  <i class="fa fa-search"></i>
                </span>
                <input
                  class="radius-left"
                  type="text"
                  placeholder="Search Orders..."
                />
              </div>
              <div class="filter radius-right">
                <span class="down-icon">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </span>
                <span>Todas las órdenes activas</span>
              </div>
            </div>
          </div>
          <div class="orders">
            <div class="header-row">
              <div class="col wide">Fecha</div>
              <div class="col wide">Número de recibo</div>
              <div class="col">Cliente</div>
              <div class="col">Usuario</div>
              <div class="col end">Total</div>
              <div class="col narrow">Estado</div>
              <div class="col very-narrow" name="delete"></div>
            </div>
            <div class="order-row">
              <div class="col wide">
                <div>2023-02-28 01:35 PM</div>
              </div>
              <div class="col wide">
                <div>Orden 00002-003-0002</div>
              </div>
              <div class="col">
                <div>Administrator</div>
              </div>
              <div class="col">
                <div>Elio AC</div>
              </div>
              <div class="col end">
                <div>$ 19,03</div>
              </div>
              <div class="col narrow">
                <div>En proceso</div>
              </div>
              <div class="col very-narrow">
                <div class="delete-button" name="delete">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
                <div class="delete-button" name="delete">
                  <i class="fa fa-print" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="order-row highlight">
              <div class="col wide">
                <div>2023-02-28 10:13 PM</div>
              </div>
              <div class="col wide">
                <div>Orden 00002-007-0003</div>
              </div>
              <div class="col">
                <div></div>
              </div>
              <div class="col">
                <div>Elio AC</div>
              </div>
              <div class="col end">
                <div>$ 6,69</div>
              </div>
              <div class="col narrow">
                <div>En proceso</div>
              </div>
              <div class="col very-narrow">
                <div class="delete-button" name="delete">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
                <div class="delete-button" name="delete">
                  <i class="fa fa-print" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="leftpane">
          <div class="order-container">
            <div class="order-scroller touch-scrollable">
              <div class="order">
                <div class="order-empty">
                  <i
                    class="fa fa-shopping-cart"
                    role="img"
                    aria-label="Shopping cart"
                    title="Carro de compras"
                  ></i>
                  <h1>Seleccione un pedido</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="pads">
            <div class="control-buttons">
              <div class="control-button">
                <i class="fa fa-file-pdf-o"></i>
                <span> </span>
                <span>Facturas de clientes</span>
              </div>
              <div class="control-button">
                <i class="fa fa-print"></i>
                <span> </span>
                <span>Imprimir recibo</span>
              </div>
            </div>
            <div class="subpads">
              <div class="actionpad">
                <button class="button set-partner">
                  <i
                    class="fa fa-user"
                    role="img"
                    aria-label="Customer"
                    title="Cliente"
                  ></i>{" "}
                  Cliente{" "}
                </button>
                <button class="button pay validation">
                  <div class="pay-circle">
                    <i
                      class="fa fa-chevron-right"
                      role="img"
                      aria-label="Pay"
                      title="Pagar"
                    ></i>
                  </div>
                  Refund
                </button>
              </div>
              <div class="numpad">
                <button class="input-button number-char">1</button>
                <button class="input-button number-char">2</button>
                <button class="input-button number-char">3</button>
                <button class="mode-button">Cant.</button>
                <button class="input-button number-char">4</button>
                <button class="input-button number-char">5</button>
                <button class="input-button number-char">6</button>
                <button class="mode-button disabled-mode" disabled="">
                  % Desc
                </button>
                <button class="input-button number-char">7</button>
                <button class="input-button number-char">8</button>
                <button class="input-button number-char">9</button>
                <button class="mode-button disabled-mode" disabled="">
                  Precio
                </button>
                <button class="input-button numpad-minus disabled-mode">
                  +/-
                </button>
                <button class="input-button number-char">0</button>
                <button class="input-button number-char">,</button>
                <button class="input-button numpad-backspace">
                  <img
                    style="pointer-events: none;"
                    src="/point_of_sale/static/src/img/backspace.png"
                    width="24"
                    height="21"
                    alt="Retroceso"
                  />
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Index
