import React, { useState } from "react"
import { Container, Row, Col, Alert } from "reactstrap"

//Import Images
import SimpleBar from "simplebar-react"

import logoLightPng from "../../assets/images/logo-light.png"

const PagesError = props => {
  const { error, errorInfo, reset } = props

  const [details, setDetails] = useState(false)

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="6">
              <div className="pb-3">
                <img
                  src={logoLightPng}
                  alt=""
                  width={200}
                  className="img-fluid"
                />
              </div>

              <Alert className="alert-danger" role="alert">
                <h4 className="alert-heading">Se ha producido un error</h4>
                <p>{error.message}</p>
                {details && (
                  <div>
                    <hr />
                    <SimpleBar style={{ height: "200px" }}>
                      <p>{errorInfo?.componentStack}</p>
                    </SimpleBar>
                  </div>
                )}
              </Alert>
              <div className="text-center">
                <div className="d-flex justify-content-evenly">
                  <button
                    className="btn btn-secondary waves-effect waves-light"
                    onClick={() => {
                      setDetails(!details)
                    }}
                  >
                    Ver detalles
                  </button>
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                      reset()
                    }}
                  >
                    Recargar página
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PagesError
