import moment from "moment"
import React, { useEffect } from "react"
import {
  Col,
  Row,
  Table,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap"
import CustomLink from "../../../../components/Common/CustomLink"
import AccountPaymentCredit from "./AccountPaymentCredit"

const AccountInvoiceAmounts = props => {
  const { form, setFormState } = props

  useEffect(() => {
    if (form.fields?.accountInvoiceItems == null) return

    var netAmount = 0
    var taxAmount = 0
    var discountAmount = 0
    var totalAmount = 0

    var index = 0

    for (index = 0; index < form.fields?.accountInvoiceItems.length; index++) {
      netAmount = netAmount + form.fields?.accountInvoiceItems[index].netAmount
      taxAmount = taxAmount + form.fields?.accountInvoiceItems[index].taxAmount
      discountAmount =
        discountAmount + form.fields?.accountInvoiceItems[index].discountAmount
      totalAmount =
        totalAmount + form.fields?.accountInvoiceItems[index].totalAmount
    }

    setFormState({
      ...form.fields,
      netAmount: netAmount,
      taxAmount: taxAmount,
      discountAmount: discountAmount,
      totalAmount: totalAmount,
    })
  }, [JSON.stringify(form.fields?.accountInvoiceItems)])

  return (
    <Row>
        <AccountPaymentCredit form={form} />

      <Col lg="6">
        <div className="table-responsive">
          <Table className="table table-borderless table-nowrap mb-0">
            <thead>
              <tr>
                <th className="text-end"></th>
                <th className="text-end" style={{ width: "70px" }}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-end">Base imponible</td>
                <td className="text-end">
                  $ {form.fields?.netAmount?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="text-end">Impuestos</td>
                <td className="text-end">
                  $ {form.fields?.taxAmount?.toFixed(2)}
                </td>
              </tr>
              {form.fields?.discountAmount > 0 ? (
                <tr>
                  <td className="text-end">Descuentos</td>
                  <td className="text-end">
                    $ {form.fields?.discountAmount?.toFixed(2)}
                  </td>
                </tr>
              ) : null}

              <tr>
                <td className="border-0 text-end">
                  <strong>Total</strong>
                </td>
                <td className="border-0 text-end">
                  <h4 className="m-0">
                    $ {form.fields?.totalAmount?.toFixed(2)}
                  </h4>
                </td>
              </tr>
              {form.fields?.accountPaymentItems?.map((payment, index) => (
                <tr key={index}>
                  <td className="text-end fst-italic">
                    <a
                      id={`Popoverdismiss${payment.accountPaymentID}`}
                      onClick={e => e.preventDefault()}
                    >
                      <i className="bx bxs-info-circle text-primary font-size-14 me-2"></i>
                    </a>
                    Pagado{" "}
                    {moment(payment.accountPayment?.paymentDate).format(
                      "DD/MM/YYYY"
                    )}
                    <UncontrolledPopover
                      trigger="legacy"
                      target={`Popoverdismiss${payment.accountPaymentID}`}
                      placement="left"
                    >
                      <PopoverHeader>Información del pago</PopoverHeader>
                      <PopoverBody>
                        <div className="d-flex">
                          <strong>Importe: </strong> $ {payment.amount}
                        </div>
                        <div className="d-flex">
                          <strong>Concepto: </strong> {payment.description}
                        </div>
                        <div className="d-flex">
                          <strong>Fecha:</strong>
                          <span>
                            {moment(payment.accountPayment?.paymentDate).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </div>
                        <CustomLink
                          content={payment.name}
                          className="btn btn-primary btn-sm"
                          to={`/invoices/accountpayment/${payment.accountPaymentID}`}
                        >
                          VER
                        </CustomLink>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </td>
                  <td className="text-end fst-italic">
                    $ {payment.amount?.toFixed(2)}
                  </td>
                </tr>
              ))}
              {form.fields?.accountPaymentItems?.length > 0 ? (
                <tr>
                  <td className="border-0 text-end">
                    <strong>Importe adeudado</strong>
                  </td>
                  <td className="border-0 text-end">
                    <h4 className="m-0">
                      $ {form.fields?.balance?.toFixed(2)}
                    </h4>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  )
}

export default AccountInvoiceAmounts
