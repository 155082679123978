export const MODULE = 'core';
export const MODEL = 'partner';

export const INDEX_TITLE = 'Partners';
export const CREATE_TITLE = 'Nuevo partner';

export const INITIAL_STATE = {
    name: "",
    email: "",
    document: "",
    phone: "",
    city: "",
    zip: "",
    webSite: "",
    movilPhone: "",
    isBusiness: true,
    documentType: 80,
    logoBase64: null,
    logo: null
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const DOCUMENTTYPE_ENUM = [
  { value: 80, label: 'CUIT'},
  { value: 86, label: 'CUIL'},
  { value: 96, label: 'DNI'},
  { value: 94, label: 'Pasaporte'},
  { value: 99, label: 'Otro'},
];

export const FISCALPOSITION_ENUM = [
  { value: 1, label: 'Responsable inscripto'},
  { value: 4, label: 'Sujeto exento'},
  { value: 5, label: 'Consumidor final'},
  { value: 6, label: 'Responsable monotributo'},
  { value: 7, label: 'Sujeto no cagerizado'},
  { value: 13, label: 'Monotributista social'},
];

export const INITIAL_STATE_FILTER = {
  active: true,
  customer: false,
  supplier: false,
  customFilters: []
};

export const SEARCHTYPE_STRING_ENUM = [
  { value: 1, label: 'contiene'},
];

export const SEARCHTYPE_NUMBER_ENUM = [
  { value: 1, label: 'igual a'},
  { value: 2, label: 'mayor a'},
  { value: 3, label: 'menor a'},
  { value: 4, label: 'entre'},
];

