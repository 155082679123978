import { useDispatch } from "react-redux";
import apiCall from "../../../api";
import useAPIFunctions from "../../../hooks/useAPIFunctions";
import useMenu from "../../../hooks/useMenu";
import { setFormAction, setFormRefresh, setApiLoading} from "../../../store/actions";

const useStockInventoryActions = (id) => {

  const { GoTo } = useMenu();
  
  const { doGetAsync } = useAPIFunctions();
  const dispatch = useDispatch();

  const doValidate = async () => {
    try {
      const response = await doGetAsync(`/stockinventory/validate/${id}`);

      // debugger;
      
      // await apiCall.getFile(
      //   `/accountpayment/report/${id}`,
      //   `${response.data.data.name}.pdf`
      // );

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
    } catch (error) {
      console.log("validate", error.message);
    }
  };


  const doInventoryInit = async (name) => {
    try {
      const response = await doGetAsync(`/stockinventory/confirm/${id}`);

      GoTo('Lineas',`/inventory/stockinventory/${id}?view=lines`,true);
      
    } catch (error) {
      console.log("Inventory init", error.message);
    }
  };

  const doCancel = async (name) => {
    try {
      const response = await doGetAsync(`/stockinventory/cancel/${id}`);

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
    } catch (error) {
      console.log("Cancel", error.message);
    }
  };

  const doContinue = async (name) => {
    try {

      GoTo('Lineas',`/inventory/stockinventory/${id}?view=lines`,true);

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
    } catch (error) {
      console.log("doContinue", error.message);
    }
  };

  const doPrint = async (name) => {

    dispatch(setApiLoading(true))

    try {
      await apiCall.getFile(
        `/stockinventory/report/${id}`,
        `${name}.pdf`
      );

      dispatch(setApiLoading(false))
    } catch (error) {
      console.log("Report", error.message);
      dispatch(setApiLoading(false))
    }
  };



  return {
    doValidate,
    doInventoryInit,
    doCancel,
    doContinue,
    doPrint
  };
};

export default useStockInventoryActions;
