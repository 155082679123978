import {put, call, takeLatest, all, fork} from 'redux-saga/effects';
import { BASE_GET_TRY } from './actionTypes';
import {  getBaseOK, getBaseError } from './actions';
import apiCall from '../../../api';

function* getBase ({payload: {target,id}}) {
    try {
        const results = yield call(apiCall.get,`/${target}/${id}`);

        yield put(getBaseOK(results.data));
    } catch (error) {
        yield put(getBaseError(error));
    }
}

export function* watchGetBase(){
    yield takeLatest(BASE_GET_TRY, getBase);
}

function* GetBaseSaga() {
    yield all([
        fork(watchGetBase)
    ]);
  }


export default GetBaseSaga;