import React from "react";
import { useSelector } from "react-redux";
import {Button} from "reactstrap";
import SmartButton from "../../../../components/Common/SmartButton";
import useMenu from "../../../../hooks/useMenu";

const SmartButtons = (props) => {

    const { form } = props;
    const { action } = useSelector(state => state.FormActionStatus);

    const { GoTo} = useMenu();

  const btnPickingsAction = (e) => {

    if(form.fields?.stockPickings?.length === 1){
      GoTo(form.fields?.stockPickings[0].name,`/inventory/stockpicking/${form.fields?.stockPickings[0].id}`)
    }else{
      GoTo('',`/inventory/stockpicking/saleorder/${form.fields?.id}`)
    }
    
  };


  const btnPaymentsAction = (e) => {

    if(form.fields?.accountPaymentItems?.length === 1){
      GoTo(form.fields?.accountPaymentItems[0].accountPayment.name,`/invoices/accountpayment/${form.fields?.accountPaymentItems[0].accountPayment.id}`)
    }else{
      GoTo('Pagos',`/invoices/accountpayment?accountInvoice=${form.fields?.id}`)
    }
    
  };

  const btnCreditNotesAction = (e) => {

    e.preventDefault();
    
    if(form.fields?.accountInvoiceRels?.length === 1){
      GoTo(form.fields?.accountInvoiceRels[0].name,`/invoices/accountinvoice/${form.fields?.accountInvoiceRels[0].id}`)
    }else{
      GoTo('Notas de crédito',`/invoices/accountinvoice?accountInvoice=${form.fields?.id}`)
    }
    
  };

  return (action === 'create' ? null : 
    <div className="d-flex flex-row-reverse">
      {form.fields?.accountInvoiceRels?.length > 0 ? (
         <SmartButton
         onClick={btnCreditNotesAction}
         icon="bxs-detail"
         label="Notas de crédito"
         value={form.fields?.accountInvoiceRels?.length}
       />

      ) : null}
      {form.fields?.stockPickings?.length > 0 ? (
         <Button
         color="primary"
         outline
         className="waves-effect waves-light"
         onClick={btnPickingsAction}
       >
         <i className="bx bxs-truck font-size-16 align-middle mr-2"></i>
         {form.fields?.stockPickings?.length} ENTREGAS
       </Button>
      ) : null}

      {form.fields?.accountPaymentItems?.length > 0 ? (
        <SmartButton
        onClick={btnPaymentsAction}
        icon="bxs-dollar-circle"
        label="Pagos"
        value={form.fields?.accountPaymentItems?.length}
      />
      ) : null}
     
    </div>
  );
};

export default SmartButtons;
