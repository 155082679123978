import moment from "moment"
import React from "react"
import { Label } from "reactstrap"

const CustomDateTime = props => {
  const {
    name,
    label,
    value,
    onChange,
    type,
    disabled,
    autoFocus,
    validate,
    errorMessage,
    required,
  } = props
  return (
    <div className="form-group mb-1">
      {label !== "" ? (
        <Label htmlFor={`validate${name}`}>
          {label} {required ? <span className="text-danger">*</span> : null}
        </Label>
      ) : null}

      {disabled ? (
        <span className="d-block text-muted text-truncate field-muted">{type==="date" ? moment(value).format("DD/MM/YYYY") : moment(value).format("DD/MM/YYYY hh:mm")}</span>
      ) : (
        <input
          name={name}
          className="form-control"
          type={type}
          defaultValue={type === "date" ? value?.substring(0,10) : value}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  )
}

export default CustomDateTime
