import  { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const StockInventoryCustomFilters = (props) => {

  const { filter, setFilter } = props;
  const [menu, setMenu] = useState(false);

  const onClick = ((e) => {
    e.preventDefault();
    setFilter({ ...filter, [e.target.name]: !filter[e.target.name] });
  });

  const ActiveClass = () => {
    if (filter) {
      if( filter["draft"] === true || filter["confirm"] === true || filter["done"] === true) {
        return "btn btn-primary rounded-circle";
      }
    }
    return "btn btn-secondary rounded-circle";
  }

  return (
        <Dropdown isOpen={menu} toggle={(() => setMenu(!menu))}>
          <DropdownToggle 
            className={ActiveClass()} tag="i">
            <i className="bx bx-filter-alt"></i>
          </DropdownToggle>
          
          <DropdownMenu end>
          <DropdownItem header>FILTROS</DropdownItem>

            <DropdownItem 
              onClick={onClick}
              name="draft"
              active={filter ? filter["draft"] : false}
              >
              Borrador
            </DropdownItem>
            <DropdownItem 
              onClick={onClick}
              name="confirm"
              active={filter ? filter["confirm"] : false}
              >
              En proceso
            </DropdownItem>
            <DropdownItem 
            onClick={onClick} 
            name="done"
            active={filter ? filter["done"] : false}
            >
              Validado
            </DropdownItem>
           
          </DropdownMenu>
        </Dropdown>
  );
};

export default StockInventoryCustomFilters;
