import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import ConfirmDelete from "../Common/ConfirmDelete";
import ModelHistory from "../Common/ModelHistory";

const DropdownSecondary = (props) => {

  const { module, model, id , history} = props;
  const [menu, setMenu] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [modalHistory, setModalHistory] = useState(false);

  const doDelete = (e) => {

    e.preventDefault();
    setShowDelete(true);
  };

  const onDelete = () =>{ 
    
    history.push(`/${module}/${model}`);
  };

  return (id != 0 && id != 'create') &&(
    <React.Fragment>
          <Dropdown isOpen={menu} toggle={(() => setMenu(!menu))}>
            <DropdownToggle className="btn btn-secondary rounded-circle" caret>
              <i className="bx bx-dots-horizontal-rounded"></i>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={() => setModalHistory(true)}>
                <i className="mdi mdi-refresh font-size-16 text-default mr-2"></i>
                Información
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={doDelete}>
                <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i>
                Eliminar
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
      <ConfirmDelete
        model={model}
        id={id}
        showDelete={showDelete}
        onDelete={onDelete}
        setShowDelete={setShowDelete}
      />
      <ModelHistory
        modal={modalHistory}
        setModal={setModalHistory}
        id={id}
        model={model}
      />
    </React.Fragment>
  );
};

export default withRouter(DropdownSecondary);
