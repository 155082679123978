import React from "react";
import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomCheckbox from "../../../../components/Common/CustomCheckbox";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
      <Row>
        <Col lg="4">
        <CumstomField
            {...form?.getInput("parentID")}
            label="Parent ID"
            type="number"
            disabled={disabled}
          />

          <CumstomField
            {...form?.getInput("content")}
            label="Content"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />

          <CumstomField
            {...form?.getInput("iconClass")}
            label="IconClass"
            type="text"
            disabled={disabled}
          />

          <CumstomField
            {...form?.getInput("href")}
            label="Href"
            type="text"
            disabled={disabled}
            required
          />

          <CumstomField
            {...form?.getInput("order")}
            label="Order"
            type="number"
            disabled={disabled}
            required
          />

          <CustomCheckbox
              label="Exact path"
              disabled={disabled}
              {...form?.getCheckbox("exactPath")}
            />
           
        </Col>
       
      </Row>
  );
};

export default BaseForm;
