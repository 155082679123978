import React, { useState } from "react";

import useForm from "../../../hooks/useForm";
import BaseForm from "./components/BaseForm";
import { MODULE, MODEL, INITIAL_STATE, CREATE_TITLE } from "./components/consts";
import DetailsPage from "../../../components/Actions/DetailsPage";
import { useSelector } from "react-redux";


const Details = () => {

  const [formState, setFormState] = useState([]);
  const form = useForm({ formState });
  const { action } = useSelector(state => state.FormActionStatus);

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      setFormState={setFormState}
      defaultValues
    >
      <BaseForm
          form={form}
          disabled={ action != 'edit' && action != 'create' ? true : false}
        />
    </DetailsPage>
  );
};

export default Details;
