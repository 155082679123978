import CustomFilters from "../../../../components/Common/CustomFilters"

const StockQuantCustomFilters = (props) => {
  const { filter, setFilter, columns } = props

  const itemsFilter = [
    {
      name: "internal",
      label: "Ubicaciones internas",
    },
    {
      name: "transit",
      label: "Ubicaciones de tránsito",
    },
    {
      name: "divider",
    },
    {
      name: "positiveStock",
      label: "Stock positivo",
    },
    {
      name: "negativeStock",
      label: "Stock negativo",
    },
    {
      name: "reserved",
      label: "Reservado",
    },
  ]


  return (
    <CustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
    itemsFilter={itemsFilter}
  />
  );
};

export default StockQuantCustomFilters;
