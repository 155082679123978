import CustomFilters from "../../../../components/Common/CustomFilters"

const AccountInvoiceCustomFilters = props => {
  const { filter, setFilter, columns } = props

  const itemsFilter = [
    {
      name: "onlyMe",
      label: "Mis comprobantes",
    },
    {
      name: "divider",
    },
    {
      name: "moveType",
      label: "Clientes",
      active: filter ? filter["moveType"] === 1 : false,
      onCustomClick: e => {
        if (filter[e.target.name] != 1)
          setFilter({ ...filter, [e.target.name]: 1 })
      },
    },
    {
      name: "moveType",
      label: "Proveedores",
      active: filter ? filter["moveType"] === 2 : false,
      onCustomClick: e => {
        if (filter[e.target.name] != 2)
          setFilter({ ...filter, [e.target.name]: 2 })
      },
    },
    {
      name: "divider",
    },
    {
      name: "internalType",
      label: "Facturas",
      active: filter ? filter["internalType"] === 1 : false,
      onCustomClick: e => {
        e.preventDefault()
        if (filter[e.target.name] != 1)
          setFilter({ ...filter, [e.target.name]: 1 })
        else setFilter({ ...filter, [e.target.name]: null })
      },
    },
    {
      name: "internalType",
      label: "Notas de crédito",
      active: filter ? filter["internalType"] === 3 : false,
      onCustomClick: e => {
        e.preventDefault()
        if (filter[e.target.name] != 3)
          setFilter({ ...filter, [e.target.name]: 3 })
        else setFilter({ ...filter, [e.target.name]: null })
      },
    },
    {
      name: "divider",
    },
    {
      name: "draft",
      label: "Borrador",
    },
    {
      name: "open",
      label: "Abiertas",
    },
    {
      name: "paid",
      label: "Pagadas",
    },
    {
      name: "due",
      label: "Vencidas",
    },
  ]

  return (
    <CustomFilters
      filter={filter}
      setFilter={setFilter}
      columns={columns}
      itemsFilter={itemsFilter}
    />
  )
}

export default AccountInvoiceCustomFilters
