import React from "react";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import { PRICELISTTYPE_ENUM } from "./consts";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CustomCheckbox from "../../../../components/Common/CustomCheckbox";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
      <Row>
        <Col lg="4">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />

          

        <Row>
          <Col md="4">
          <CustomCheckbox
              label="Variable"
              disabled={disabled}
              {...form?.getCheckbox("isVariable")}
          />
          </Col>
        
        </Row>

        {form.fields?.isVariable ? (

          <Row>
          <Col md="3">
            <CumstomField
                {...form?.getInput("variation")}
                label="Variación"
                type="number"
                disabled={disabled}
                required
              />
            </Col>
              

            <Col md="2" className="mt-2">
            <CustomEnumSelect 
                  label=""
                  {...form?.getEnumSelect("calculationType", PRICELISTTYPE_ENUM)}
                  isDisabled={disabled}
              />
            </Col>
          </Row>
        ) : null}
        
           
          <CustomSwitch
            label="Activo"
            disabled={disabled}
            {...form?.getSwitch("active")}
          />
        </Col>
       
      </Row>
  );
};

export default BaseForm;
