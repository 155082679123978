import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"
import BarcodeReader from "react-barcode-reader"
import { useParams } from "react-router-dom"
import BarcodeScannerComponent from "react-qr-barcode-scanner"

//Import Images
import apiCall from "../../api"
import FloatingButton from "../../components/Buttons/FloatingButton"
import ringer from "./ringer.mp3";

const Index = props => {

  const audio = new Audio(ringer);
  audio.loop = false;
  const { barcode } = useParams()
  const [inputState, setInputState] = useState("")

  const [showCamera, setShowCamera] = useState(false)

  const [loading, setLoading] = useState(false)

  
  const [data, setData] = useState({
    id: "",
    name: "",
    listPrice: "",
    barcode: "",
  })

  const handleScan = data => {

    audio.play();

    setInputState(data)

    fetchItem(data)
  }
  const handleError = err => {
    console.error(err)
  }

  useEffect(() => {
    if (barcode) {
      setInputState(barcode)
      fetchItem(barcode)
    }
  }, [barcode])

  const fetchItem = async barcode => {
    try {
      setLoading(true)

      const { data } = await apiCall.get(`/product/barcode/${barcode}`)

      setLoading(false)
      setData(data.data)
    } catch (error) {
      setLoading(false)
      if (error.response.status === 404) {
        setData({
          id: 0,
          name: "Producto no encontrado",
          listPrice: "",
          barcode: barcode,
        })
      }
      console.log("fetchItem", error.message)
    }
  }
  return (
    <React.Fragment>
      <BarcodeReader onError={handleError} onScan={handleScan} />
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            {showCamera ? (
              <>
                <BarcodeScannerComponent
                  width={500}
                  height={500}
                  onUpdate={(err, result) => {
                    if (result) {
                      setShowCamera(false)
                      handleScan(result.text)
                    }
                  }}
                />
              </>
            ) : (
              <Col lg="12">
                {loading ? (
                  <div className="text-center mb-5">
                    <h1 className="display-2 font-weight-medium">
                      <i className="bx bx-circle bx-burst text-primary display-3"></i>
                    </h1>
                    <h4 className="text-uppercase">Buscando..</h4>

                    {/* <div className="mt-5 text-center">
                                      <Link className="btn btn-primary waves-effect waves-light" to="/dashboard">Volver al inicio</Link>
                                  </div> */}
                  </div>
                ) : (
                  <div className="text-center mb-5">
                    <h1 className="display-2 font-weight-medium">
                      <i className="bx bx-barcode bx-burst text-primary display-3"></i>
                    </h1>
                    <h4 className="text-uppercase">{inputState}</h4>
                    {data.image && (
                      <img
                        width="200px"
                        className="img-thumbnail "
                        src={data.image}
                      />
                    )}
                    <h1 className="display-2 font-weight-medium">
                      {data.name}
                    </h1>
                    <div className="">
                      <h2 className="display-2 fw-bold ">$ {data.listPrice}</h2>
                    </div>

                    {/* <div className="mt-5 text-center">
                                      <Link className="btn btn-primary waves-effect waves-light" to="/dashboard">Volver al inicio</Link>
                                  </div> */}
                  </div>
                )}
              </Col>
            )}
          </Row>
          {/* <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row> */}
        </Container>
        <FloatingButton
          icon="display-6 bx bx-camera"
          onClick={() => {
            setShowCamera(!showCamera)
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default Index