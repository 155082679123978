import React from "react"
import ReactApexChart from "react-apexcharts"

const BarChart = (props) => {

  const { data } = props

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#ff6347"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: data?.categories,
    },
  }

  return (
    <React.Fragment>
      {data ? (
        <ReactApexChart options={options} series={data?.series} type="bar" height="350" />
      ) : null}
    </React.Fragment>
    
  )
}

export default BarChart
