import React from "react"
import { useSelector } from "react-redux"
import { Button, Col, Row } from "reactstrap"
import useStockInventoryActions from "../Actions"
import { useDispatch } from "react-redux"
import { setFormSecondaryAction } from "../../../../store/actions"

const StockInventoryActions = props => {
  const { form, filter } = props
  const { loading } = useSelector(state => state.ApiStatus)
  const {
    doValidate,
    doInventoryInit,
    doCancel,
    doContinue,
    doPrint
  } = useStockInventoryActions(form.fields.id)

  const dispatch = useDispatch()

  return (
    <Row className="mb-2">
      <Col>
        {form.fields?.state === 1 ? (
          <Button
            color="primary"
            className="btn btn-primary btn-sm me-2"
            type="submit"
            form="hook-form"
            onClick={() => {
              dispatch(setFormSecondaryAction("inventoryInit"))
            }}
            disabled={loading}
          >
            Iniciar inventario
          </Button>
        ) : null}
        {form.fields?.id != 0 ? (
          <React.Fragment>
            {form.fields?.state === 2 ? (
              <React.Fragment>
                {filter?.view === "lines" ? null : (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm me-2"
                    onClick={e => {
                      e.preventDefault()
                      doContinue()
                    }}
                    disabled={loading}
                  >
                    Continuar inventario
                  </Button>
                )}

                <Button
                  color="primary"
                  className="btn btn-primary btn-sm me-2"
                  type="submit"
                  form="hook-form"
                  onClick={() => {
                    dispatch(setFormSecondaryAction("validate"))
                  }}
                  disabled={loading}
                >
                  Validar inventario
                </Button>
              </React.Fragment>
            ) : null}
            {form.fields?.state === 2 ? (
              <Button
                color="primary"
                className="btn btn-secondary btn-sm"
                onClick={e => {
                  doCancel()
                }}
                disabled={loading}
              >
                Cancelar
              </Button>
            ) : null}
            {form.fields?.state === 3 ? (
              <Button
                color="primary"
                className="btn btn-secondary btn-sm"
                onClick={e => {
                  e.preventDefault();
                  doPrint(form.fields.name);
                }}
                disabled={loading}
              >
                Imprimir hoja de recuento
              </Button>
            ) : null}
          </React.Fragment>
        ) : null}
      </Col>
    </Row>
  )
}

export default StockInventoryActions
