import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import ConfirmDelete from "../../../../components/Common/ConfirmDelete";
import ModelHistory from "../../../../components/Common/ModelHistory";
import useMenu from "../../../../hooks/useMenu";
import useAccountInvoiceActions from "../Actions";

const AccountInvoiceDropdown = (props) => {

  const { GoTo } = useMenu();
  
  const { id, history } = props;
  const [menu, setMenu] = useState(false);
  const [modalHistory, setModalHistory] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const { doSendMail } = useAccountInvoiceActions(id);

  const doDelete = e => {
    e.preventDefault()
    setShowDelete(true)
  }

  const onDelete = () => {
    GoTo("Facturas", `/invoices/accountinvoice?moveType=1&internaltype=1`, true)
  }

  return id != 0 && (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)}>
        <DropdownToggle className="btn btn-secondary rounded-circle" tag="i">
          <i className="bx bx-dots-horizontal-rounded"></i>
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={() => setModalHistory(true)}>
            <i className="mdi mdi-refresh font-size-16 text-default mr-2"></i>
            Información
          </DropdownItem>
          <DropdownItem 
            onClick={(e) => {
              e.preventDefault();
              doSendMail();
            }} >
            <i className="mdi mdi-email font-size-16 text-default mr-2"></i>
            Enviar por mail
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={doDelete}>
            <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i>
            Eliminar
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ModelHistory
        modal={modalHistory}
        setModal={setModalHistory}
        id={id}
        model="AccountInvoice"
      />
      <ConfirmDelete
        model="accountInvoice"
        id={id}
        showDelete={showDelete}
        onDelete={onDelete}
        setShowDelete={setShowDelete}
      />
    </React.Fragment>
  );
};

export default withRouter(AccountInvoiceDropdown);
