import Index from "..";
import Details from "../Details";
import { MODEL } from "./consts";
import { MODULE } from "../../consts";

export const StockMoveLineRoutes = [
  { path: `/${MODULE}/${MODEL}/:id`, exact: true, component: (props) => <Details  {...props} /> },
  { path: `/${MODULE}/${MODEL}`, exact: true, component: Index },
];

export default StockMoveLineRoutes;
