import React, { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap";


import classnames from "classnames";
import MenuTab from "./Tabs/MenuTab";
import UsersTab from "./Tabs/UsersTab";
import InherithTab from "./Tabs/InherithTab";

const Tabs = (props) => {
  const { form, disabled, setFormState } = props;
  const [customActiveTab, setcustomActiveTab] = useState("1");

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  }

  return (
    <Col lg={12} className="mt-1">
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "1",
            })}
            onClick={() => {
              toggleCustom("1");
            }}
          >
            <span className="d-sm-block">Usuarios</span>
          </NavLink>
        </NavItem>
          <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "2",
            })}
            onClick={() => {
              toggleCustom("2");
            }}
          >
            <span className="d-sm-block">Menú</span>
          </NavLink>
        </NavItem>
          <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "3",
            })}
            onClick={() => {
              toggleCustom("3");
            }}
          >
            <span className="d-sm-block">Herencia</span>
          </NavLink>
        </NavItem>
        
        

      </Nav>

      <TabContent activeTab={customActiveTab}>
        <UsersTab form={form} setFormState={setFormState} disabled={disabled} />
        <MenuTab form={form} setFormState={setFormState} disabled={disabled} />
        <InherithTab form={form} setFormState={setFormState} disabled={disabled} />
      </TabContent>
    </Col>
  );
};

export default Tabs;
