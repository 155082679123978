import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { version } from "../../../package.json"
// Redux
import { useDispatch, connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import ImageFadeIn from "react-image-fade-in"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, setApiLoading } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"

import AsyncSelect from "react-select/async"
import { FormGroup, Label } from "reactstrap"
import apiCall from "../../api"

const Login = props => {
  const { loginUser, history, error, loading } = props
  const [company, setCompany] = useState(null)
  const [companyLogo, setCompanyLogo] = useState(null)
  const [toggleSwitch, settoggleSwitch] = useState(
    localStorage.getItem("theme") == "dark" ? true : false
  )

  const dispatcher = useDispatch()
  const queryParams = new URLSearchParams(window.location.search)

  const onChange = value => {
    setCompany(value)
  }

  useEffect(() => {
    if (company) {
      fetchCompany(company.id)
    }
  }, [company])

  const fetchCompany = async id => {
    dispatcher(setApiLoading(true))

    var request = {
      id: id,
    }

    try {
      const results = await apiCall.post(`/company/fetchcompany`, request)

      dispatcher(setApiLoading(false))
      setCompanyLogo(results.data?.data?.logo)
    } catch (error) {
      dispatcher(setApiLoading(false))
      setCompanyLogo(null)
      console.log("fetchCompany", error.message)
    }
  }

  const onToggle = e => {
    if (toggleSwitch) {
      localStorage.setItem("theme", "light")
    } else {
      localStorage.setItem("theme", "dark")
    }

    window.location.reload()

    settoggleSwitch(!toggleSwitch)
  }

  const searchAsync = async inputValue => {
    await new Promise(resolve => {
      setTimeout(resolve, 200)
    })

    let url = `/company/search?text=${inputValue}`

    return await apiCall
      .get(url)
      .then(res => {
        setCompany(res.data[0])
        return res.data
      })
      .catch(err => {
        console.log(err)
      })
  }

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    values["company"] = company?.id
    loginUser(values, history, queryParams.get("to"))
  }
  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
                </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Bienvenido !</h5>
                        <p>Inicia sesión para continuar.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={logo}
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error && error ? (
                        <Alert color="danger">{error}</Alert>
                      ) : null}

                      <FormGroup className="select2-container mb-3">
                        Sucursal
                        <AsyncSelect
                          name="company"
                          value={company}
                          onChange={onChange}
                          className="react-select"
                          classNamePrefix="react-select"
                          isDisabled={loading}
                          defaultOptions
                          placeholder=""
                          cacheOptions
                          loadOptions={searchAsync}
                          getOptionLabel={e => e.name}
                          getOptionValue={e => e.id}
                        />
                      </FormGroup>
                      {companyLogo && (
                        <div className="mb-3 text-center">
                          <ImageFadeIn
                            src={`data:image/png;base64,${companyLogo}`}
                            alt=""
                            className=""
                            height="34"
                          />
                        </div>
                      )}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          disabled={loading}
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          disabled={loading}
                          type="password"
                          required
                          placeholder="Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          disabled={loading}
                          id="customControlInline"
                          defaultChecked={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Recordarme
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          disabled={loading}
                          type="submit"
                        >
                          {loading ? (
                            <i className="bx bx-loader-alt bx-spin font-size-16 align-middle mr-2"></i>
                          ) : null}
                          Iniciar sesión
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1"></i> Olvidó su
                          contraseña?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 text-center d-flex flex-column align-items-center">
                {/* <p>Don't have an account ? <Link to="register" className="font-weight-medium text-primary"> Signup now </Link> </p> */}
                <p>© {new Date().getFullYear()} . Powered by RevalSoft</p>
                <p>Versión {version}</p>
                <div className="form-check form-switch d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="themeSwitch"
                    checked={toggleSwitch}
                    onClick={onToggle}
                  />
                  <label class="form-check-label font-size-20 m-2" for="themeSwitch">
                    <i className="mdi mdi-weather-night ml-1" />
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { error, loading } = state.Login
  return { error, loading }
}

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(Login)
)
