import React, { useState, useEffect } from "react"

import { useParams, withRouter } from "react-router-dom"
import { CardBody } from "reactstrap"

import { useDispatch, useSelector } from "react-redux"
import useAPI from "../../hooks/useAPI"
import FormLayout from "../Common/FormLayout"
import ErrorMessage from "../Common/ErrorMessage"
import {
  setApiError,
  setApiLoading,
  setFormAction,
  setFormRefresh,
  setFormSecondaryAction,
  setPageTitle,
  setValidationError,
} from "../../store/actions"
import ActionButtons from "../Common/ActionButtons"
import DropdownSecondary from "./DropdownSecondary"
import apiCall from "../../api"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Notification from "../Common/Notification"
import useMenu from "../../hooks/useMenu"

const DetailsPage = props => {
  const {
    module,
    model,
    form,
    setFormState,
    initialState,
    createTitle,
    defaultValues,
    defaultValuesParams = "",
    statusBar,
    dropdownSecondary,
    disableCreate
  } = props
  const { error, loading, validationError } = useSelector(
    state => state.ApiStatus
  )

  const { GoTo} = useMenu();

  const [url, setUrl] = useState("")
  const { action, refresh, secondaryAction } = useSelector(state => state.FormActionStatus)
  const { id } = useParams()
  const { result, doRefresh } = useAPI(url)

  const dispatch = useDispatch()

  useEffect(() => {
    if (id == "create") {
      dispatch(setFormAction("create"))
      dispatch(setPageTitle(createTitle))

      setFormState(initialState)
      
      GoTo('Nuevo', '/#',false);

      if (defaultValues) {
        setUrl(`${model}/create${defaultValuesParams}`)
      }
    } else {
      setUrl(`${model}/${id}`)
      dispatch(setFormAction("details"))
    }
  }, [id])

  useEffect(() => {
    if (refresh) {
      doRefresh()
      dispatch(setFormRefresh(false))
    }
  }, [refresh])

  useEffect(() => {
    if (result != null) {
      setFormState(result.data)
      dispatch(setPageTitle(result.data?.name))
    }
  }, [result])

  const handleValidSubmit = async (e, v) => {
    try {
      var requestData = {
        data: form.fields,
        [secondaryAction] :true,
      }

      //dispatch(setApiError(""))
      dispatch(setValidationError(""))
      dispatch(setApiLoading(true))
      dispatch(setFormSecondaryAction(''));

      if (action == "create") {

        const { data } = await apiCall.post(`/${model}`, requestData);

        let title = data.data.name ?? 'Nuevo';

        if(data.redirect){
          GoTo(data.redirect.title,data.redirect.url+defaultValuesParams,true,null,null,true);
        }else{
          GoTo(title,`/${module}/${model}/${data.data.id}${defaultValuesParams}`,true,null,null,true);
        }
        
        
        if(data.message != ''){
          dispatch(setApiError(data.message))
        }
      } else {
        const { data} = await apiCall.put(`/${model}/${id}`, requestData)
        
        if(data.message != ''){
          dispatch(setApiError(data.message))
        }
        

        if(data.redirect){
          GoTo(data.redirect.title,data.redirect.url,true,null,null,data.redirect.replace);
        }

        dispatch(setFormRefresh(true));
        dispatch(setFormAction("details"));
      }

      dispatch(setApiLoading(false))
    } catch (error) {

      if(error.response?.data?.detail){
        dispatch(setApiError(error.response?.data?.detail))
      }else{
        dispatch(setApiError(error.message))
      }
      
      
      var message = ""

      for (var k in error.response?.data?.errors) {
        message += error.response.data.errors[k][0] + "\n"
      }

      dispatch(setValidationError(message))
    }
  }

  const actionButtons = <ActionButtons module={module} target={model} id={id} disableCreate={disableCreate} />

  const secondaryActions = (
      <div className="d-flex flex-row">
        
        <div className="me-1">{statusBar ?? null}</div>
        <div className="pb-1">
          {dropdownSecondary ?? (
            <DropdownSecondary module={module} model={model} id={id} />
          )}
        </div>
       
      </div>
  )
  return (
    <FormLayout
      actionButtons={actionButtons}
      secondaryActions={secondaryActions}
      
    >
      <CardBody>
        <ErrorMessage error={error} />
        <AvForm
          id="hook-form"
          className="needs-validation"
          onValidSubmit={handleValidSubmit}
          onKeyPress={event => {
            if (event.which === 13 /* Enter */) {
              event.preventDefault()
            }
          }}
        >
          {props.children}
        </AvForm>
      </CardBody>
      {validationError !== "" ? (
        <Notification
          type="error"
          title="Campos inválidos"
          message={validationError}
        />
      ) : null}
    </FormLayout>
  )
}

export default DetailsPage
