import React from "react"
import { useDispatch } from "react-redux"
import { Col } from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import useDynamicTable from "../../../../../hooks/useDynamicTable"
import { setApiError, setFormBounceEdit } from "../../../../../store/actions"
import CustomSelect from "../../../../../components/Common/CustomSelect"
import searchAsync from "../../../../../api/searchAsync"


const InheritedTable = props => {
  const dispatcher = useDispatch()

  const { form, setFormState, disabled } = props

  const updateFields = async (name, index, value) => {
    let tempItems = [...form.fields?.inheritedRoles]

    let tempItem = {
      ...tempItems[index],
      [name]: value,
    }

    tempItems[index] = tempItem

    setFormState({ ...form.fields, inheritedRoles: tempItems })
  }

  const dynamicTable = useDynamicTable(
    form,
    setFormState,
    "inheritedRoles",
    disabled,
    updateFields
  )

  const onSelect = async value => {
    let item = {
      inheritedID: value.id,
      inheritedName: value.name,
    }


    if(form.fields?.inheritedRoles.some(p  => p.inheritedID == item.inheritedID)){
      dispatcher(setApiError("Ya existe el elemento en la lista"))
      return;
    }

    if(item.inheritedID == form.fields?.id){
      dispatcher(setApiError("No puedes añadir este rol"))
      return;
    }

    if (item != null) {
      dynamicTable.addItem(item)
    }

    dynamicTable.setEditable(false)
  }

  return (
    <React.Fragment>
      <Col xs="12">
        <div
          className="table-responsive overflow-visible"
          onClick={() => {
            if (disabled) {
              dispatcher(setFormBounceEdit(true))
            }
          }}
        >
          <Table className="table mb-0">
            <Thead className="table-light">
              <Tr>
                <Th width="10%">Rol</Th>
                <Th width="5%"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {dynamicTable.items?.map((p, key) => (
                <Tr key={"_product_" + key}>
                  <Th >
                    {p.inheritedName}
                  </Th>
                  <Td>
                    {disabled ? null : (
                      <a
                        href="#"
                        onClick={() => dynamicTable.removeItem(key)}
                        className="text-danger"
                      >
                        <i className="mdi mdi-close font-size-18 mr-3"></i>
                      </a>
                    )}
                  </Td>
                
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Col>
      {disabled ? null : (
        <Col lg="4" sm="12">
          {dynamicTable.editable === "select" ? (
            <CustomSelect
              name="inherited"
              value={null}
              customRef={dynamicTable.selectRef}
              onFocusOut={() => dynamicTable.setEditable(null)}
              onChange={onSelect}
              loadOptions={inputValue => {
                return searchAsync("role", inputValue, "withPrefix=true")
              }}
            />
          ) : (
            <a
              className="text-primary"
              onClick={() => {
                dynamicTable.setEditable("select")
              }}
            >
              Agregar línea
            </a>
          )}
        </Col>
      )}
    </React.Fragment>
  )
}

export default InheritedTable
