export const MODULE = 'sales';
export const MODEL = 'deliverycarrier';

export const INDEX_TITLE = 'Métodos de envío';
export const CREATE_TITLE = 'Nuevo método de envío';

export const INITIAL_STATE = {
    name: "",
    active: true
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

