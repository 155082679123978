export const MODULE = 'invoices';
export const MODEL = 'accountpayment';

export const INDEX_TITLE = 'Cobranzas';
export const CREATE_TITLE = 'Nueva cobranza';

export const INITIAL_STATE = {
    name: "",
    paymentType: 1,
    partnerType: 1,
  };

  export const ACCOUNTPAYMENT_STATE_ENUM = [
    { value: 1, label: 'Borrador'},
    { value: 2, label: 'Validado'},
    { value: 4, label: 'Conciliado'},
    { value: 5, label: 'Cancelado'},
  ];

  export const ACCOUNTPAYMENT_PARTNERTYPE_ENUM = [
    { value: 1, label: 'Cliente'},
    { value: 2, label: 'Proveedor'},
  ];

  export const INITIAL_STATE_FILTER = {
    outgoing: false,
    itsForInvoice: false,
    customFilters : []
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;
