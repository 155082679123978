import { useState, useEffect, useRef } from "react"
import searchAsync from "../api/searchAsync"

const useDynamicTable = (form, setFormState, name , disabled , updateFields) => {

  var items = form.fields[name]
  const selectRef = useRef(null)
  const [editable, setEditable] = useState([])

  const removeItem = key => {
    let arr = items.filter((o, index) => index !== key)

    setFormState({ ...form.fields, [name]: arr })
  }

  const addItem = obj => {
    setFormState({
      ...form.fields,
      [name]: [...items, obj],
    })
  }

  
  const onClick = (name, key) => {
    if (!disabled) {
      setEditable(name + "_" + key);
    }
  }

  const onChangeSelect = async (index, name, value, searchMore = null, initFilter = null) => {

    if(value?.id == 'more')
    {
      if(searchMore){
        searchMore({initFilter: initFilter})
        .then((v) => {
          updateFields(name, index, v);
          setEditable(null);
        })
        .catch(); 
      }
      
      return;
    }

    updateFields(name, index, value);
    setEditable(null);
  }

  const onKeyDown = (e, index) => {
    const { name, value } = e.target

    const { key } = e

    if (key === "Escape") {
      setEditable(null)
      return;
    }

    if (key === "Enter") {
      updateFields(name, index, value)
      setEditable(null)
    }
  }

  useEffect(() => {
    if (editable === "select") {
      if (selectRef && selectRef.current) {
        selectRef.current.focus()
      }
    }
  }, [editable])

  return {
    items,
    selectRef,
    editable,
    addItem,
    removeItem,
    setEditable,
    onClick,
    getInput: (name, index) => ({
      name,
      value: items[index][name],
      autoFocus: true,
      disabled: disabled || !(editable === name + "_" + index),
      onKeyDown: e => onKeyDown(e, index),
      onFocusOut: e => {
        updateFields(name, index, e.target.value)
        setEditable(null)
      }
    }),
    getSelect: (name,index, target, params, searchMore,initFilter) => ({
      name,
      value: items[index][name],
      isDisabled: disabled || !(editable === name + "_" + index),
      onKeyDown,
      onChange: event => {
        onChangeSelect(index, name, event,searchMore,initFilter)
      },
      onFocusOut: () => setEditable(null),
      defaultOptions: true,
      cacheOptions: true,
      
      loadOptions: inputValue => {
        return searchAsync(target, inputValue, params)
      },
    }),
    getMultiSelect: (name,index, target, params,searchMore,initFilter) => ({
      name,
      value: items[index][name],
      isDisabled: disabled || !(editable === name + "_" + index),
      onChange: event => {
        onChangeSelect(index, name, event,searchMore,initFilter)
      },
      defaultOptions: true,
      cacheOptions: true,
      loadOptions: inputValue => {
        return searchAsync(target, inputValue, params)
      },
    }),
  }
}

export default useDynamicTable
