import React from 'react'
import {  Label } from 'reactstrap'
import AvField from 'availity-reactstrap-validation/lib/AvField'

const CumstomField = (props) => {

    const { name , label, value , onChange,onFocusOut, type, disabled, autoFocus, validate, errorMessage, required , onKeyDown, maxLength,onFocus} = props;
    return(
        <div className="mb-1">
            {label != null && label !== "" ? (
              <Label htmlFor={`validate${name}`}>{label} {required ? <span className="text-danger">*</span> : null}</Label>
            ) : null}
            
            {disabled ? (
              <span 
              className="d-block text-muted text-truncate field-muted">{value}</span>
            ) : (
              <AvField
              name={name}
              value={value}
              onChange={onChange}
              type={type}
              disabled={disabled}
              onBlur={onFocusOut}
              errorMessage={errorMessage ?? "Campo obligatorio"}
              className="form-control"
              onKeyDown={onKeyDown}
              id={`validate${name}`}
              autoFocus={autoFocus}
              maxLength={maxLength}
              onFocus={onFocus}
              validate={validate ?? required ? { required: { value: true } } : null}
            />
            )}
            
        </div>
    )
}

export default CumstomField