import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import { PartnerRoutes } from "../pages/Core/Partner/components/PartnerRoutes"
import AccountAccountRoutes from "../pages/Accounting/AccountAccount/components/AccountAccountRoutes"
import AccountTypeRoutes from "../pages/Accounting/AccountType/components/AccountTypeRoutes"
import AccountGroupRoutes from "../pages/Accounting/AccountGroup/components/AccountGroupRoutes"
import AccountJournalRoutes from "../pages/Accounting/AccountJournal/components/AccountJournalRoutes"
import AccountMoveRoutes from "../pages/Accounting/AccountMove/components/AccountMoveRoutes"
import AccountMoveLineRoutes from "../pages/Accounting/AccountMoveLine/components/AccountMoveLineRoutes"
import AccountPaymentTermRoutes from "../pages/Invoices/AccountPaymentTerm/components/AccountPaymentTermRoutes"
import AccountInvoiceRoutes from "../pages/Invoices/AccountInvoice/components/AccountInvoiceRoutes"
import AccountPaymentRoutes from "../pages/Invoices/AccountPayment/components/AccountPaymentRoutes"
import PaymentMethodRoutes from "../pages/Invoices/PaymentMethod/components/PaymentMethodRoutes"
import TaxRoutes from "../pages/Invoices/Tax/components/TaxRoutes"
import InvoicesRoutes from "../pages/Invoices/InvoicesRoutes"
import InventoryRoutes from "../pages/Inventory/InventoryRoutes"
import ProductCategoryRoutes from "../pages/Inventory/ProductCategory/components/ProductCategoryRoutes"
import ProductMeasureUnitCategoryRoutes from "../pages/Inventory/ProductMeasureUnitCategory/components/ProductMeasureUnitCategoryRoutes"
import ProductMeasureUnitRoutes from "../pages/Inventory/ProductMeasureUnit/components/ProductMeasureUnitCategoryRoutes"
import ProductRoutes from "../pages/Inventory/Product/components/ProductRoutes"
import StockWareHouseRoutes from "../pages/Inventory/StockWareHouse/components/StockWareHouseRoutes"
import StockLocationRoutes from "../pages/Inventory/StockLocation/components/StockLocationRoutes"
import StockPickingTypeRoutes from "../pages/Inventory/StockPickingType/components/StockPickingTypeRoutes"
import StockQuantRoutes from "../pages/Inventory/StockQuant/components/StockQuantRoutes"
import StockPickingRoutes from "../pages/Inventory/StockPicking/components/StockPickingRoutes"
import StockWareHouseOrderPointRoutes from "../pages/Inventory/StockWareHouseOrderPoint/components/StockWareHouseOrderPointRoutes"
import StockInventoryRoutes from "../pages/Inventory/StockInventory/components/StockInventoryRoutes"
import StockMoveLineRoutes from "../pages/Inventory/StockMoveLine/components/StockMoveLineRoutes"
import PriceListRoutes from "../pages/Sales/PriceList/components/PriceListRoutes"
import DeliveryCarrierRoutes from "../pages/Sales/DeliveryCarrier/components/DeliveryCarrierRoutes"
import SaleOrderRoutes from "../pages/Sales/SaleOrder/components/SaleOrderRoutes"
import SalesRoutes from "../pages/Sales/SalesRoutes"
import PurchaseOrderRoutes from "../pages/Purchases/PurchaseOrder/components/PurchaseOrderRoutes"
import PurchasesRoutes from "../pages/Purchases/PurchasesRoutes"
import SettingsRoutes from "../pages/Settings/SettingsRoutes"
import UserRoutes from "../pages/Settings/User/components/UserRoutes"
import CompanyRoutes from "../pages/Settings/Company/components/CompanyRoutes"
import BillingTypeConfigRoutes from "../pages/Settings/BillingTypeConfig/components/BillingTypeConfigRoutes"
import POSConfigRoutes from "../pages/Settings/POSConfig/components/POSConfigRoutes"
import MenuRoutes from "../pages/Settings/Menu/components/MenuRoutes"
import MailServerRoutes from "../pages/Settings/MailServer/components/MailServerRoutes"
import RoleRoutes from "../pages/Settings/Role/components/RoleRoutes"
import ProductTagRoutes from "../pages/Inventory/ProductTag/components/ProductTagRoutes"


let userRoutes = [
  { path: "/dashboard", component: Dashboard },

  /* accounting */
  ...AccountAccountRoutes,
  ...AccountTypeRoutes,
  ...AccountGroupRoutes,
  ...AccountJournalRoutes,
  ...AccountMoveRoutes,
  ...AccountMoveLineRoutes,
  
  /* invoices */
  ...AccountPaymentTermRoutes,
  ...AccountInvoiceRoutes,
  ...AccountPaymentRoutes,
  ...InvoicesRoutes,
  ...PaymentMethodRoutes,
  ...TaxRoutes,

  /* inventory */
  ...InventoryRoutes,
  ...ProductCategoryRoutes,
  ...ProductMeasureUnitCategoryRoutes,
  ...ProductMeasureUnitRoutes,
  ...ProductRoutes,
  ...StockWareHouseRoutes,
  ...StockLocationRoutes,
  ...StockPickingTypeRoutes,
  ...StockQuantRoutes,
  ...StockPickingRoutes,
  ...StockWareHouseOrderPointRoutes,
  ...StockInventoryRoutes,
  ...StockMoveLineRoutes,
  ...ProductTagRoutes,

  /* sales */
  ...PriceListRoutes,
  ...DeliveryCarrierRoutes,
  ...SaleOrderRoutes,
  ...SalesRoutes,

  /* purchases */
  ...PurchaseOrderRoutes,
  ...PurchasesRoutes,

  /* settings */
  ...SettingsRoutes,
  ...UserRoutes,
  ...CompanyRoutes,
  ...BillingTypeConfigRoutes,
  ...POSConfigRoutes,
  ...MenuRoutes,
  ...MailServerRoutes,
  ...RoleRoutes,

  /* core */
  ...PartnerRoutes,

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]


export { userRoutes, authRoutes }
