import { Row, Col } from "reactstrap";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSelect from "../../../../components/Common/CustomSelect";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import { STOCKPICKINGTYPE_ENUM } from "./consts";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
    <Row>
      <Col md="4">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />


        <CustomSelect
          label="Locación de origen"
          {...form?.getSelect("defaultStockLocationSource", "stockLocation")}
          required
          isDisabled={disabled}
        />

        <CustomSelect
          label="Locación de destino"
          {...form?.getSelect("defaultStockLocationDestiny", "stockLocation")}
          required
          isDisabled={disabled}
        />

        <CustomSwitch
          label="Activo"
          disabled={disabled}
          {...form?.getSwitch("active")}
        />
      </Col>

      <Col md="4">
      <CustomSelect
          label="Almacén"
          {...form?.getSelect("stockWareHouse", "stockWareHouse")}
          required
          isDisabled={disabled}
        />

      <CustomEnumSelect
            label="Tipo de operación"
            {...form?.getEnumSelect("pickingType", STOCKPICKINGTYPE_ENUM)}
            isDisabled={disabled}
          />

      <CumstomField
          {...form?.getInput("prefix")}
          label="Prefijo"
          type="text"
          disabled={disabled}
          required
        />

      </Col>
    </Row>
  );
};

export default BaseForm;
