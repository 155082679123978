import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import ConfirmDelete from "../../../../components/Common/ConfirmDelete"
import ModelHistory from "../../../../components/Common/ModelHistory"
import useMenu from "../../../../hooks/useMenu"
import useSaleOrderActions from "../Actions"
import { useDispatch } from "react-redux"
import { setFormSecondaryAction } from "../../../../store/actions"

const SaleOrderDropdown = props => {
  const { GoTo } = useMenu()

  const dispatch = useDispatch()

  const { id, form, history } = props
  const [menu, setMenu] = useState(false)
  const [modalHistory, setModalHistory] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const { doSendMail, doCancel } = useSaleOrderActions(id)

  const doDelete = e => {
    e.preventDefault()
    setShowDelete(true)
  }

  const onDelete = () => {
    GoTo("Presupuestos", `/sales/saleorder`, true)
  }

  const doShowHistory = e => {
    setModalHistory(true)
  }

  return id != 0 && (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)}>
        <DropdownToggle
          className="ms-1 btn btn-secondary rounded-circle"
          tag="i"
        >
          <i className="bx bx-dots-horizontal-rounded"></i>
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={doShowHistory}>
            <i className="mdi mdi-refresh font-size-16 text-default mr-2"></i>
            Información
          </DropdownItem>

          <DropdownItem
            onClick={e => {
              e.preventDefault()
              doSendMail()
            }}
          >
            <i className="mdi mdi-email font-size-16 text-default mr-2"></i>
            Enviar por mail
          </DropdownItem>
          {form.fields?.state === 3 && (
            <DropdownItem
              onClick={(e) => {
                e.preventDefault()
                doCancel()
              }}
            >
              <i className="mdi mdi-close-thick font-size-16 text-default mr-2"></i>
              Cancelar
            </DropdownItem>
          )}

          <DropdownItem divider />
          <DropdownItem onClick={doDelete}>
            <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i>
            Eliminar
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ModelHistory
        modal={modalHistory}
        setModal={setModalHistory}
        id={id}
        model="SaleOrder"
      />
      <ConfirmDelete
        model="saleOrder"
        id={id}
        showDelete={showDelete}
        onDelete={onDelete}
        setShowDelete={setShowDelete}
      />
    </React.Fragment>
  )
}

export default withRouter(SaleOrderDropdown)
