import React, { useState } from "react"
import { Col, Nav, NavItem, NavLink, TabContent } from "reactstrap"

import classnames from "classnames"
import LineaTab from "./Tabs/LineaTab"
import InformationTab from "./Tabs/InformationTab"
import AccountingTab from "./Tabs/AccountingTab"
import NoteTab from "./Tabs/NoteTab"
import ModuleWrapper from "../../../../components/Common/ModuleWrapper"

const AccountInvoiceTabs = props => {
  const { form, setFormState, disabled, readOnlyStates } = props
  const [customActiveTab, setcustomActiveTab] = useState("1")

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <Col lg={12} className="mt-1">
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "1",
            })}
            onClick={() => {
              toggleCustom("1")
            }}
          >
            <span className="d-sm-block">Líneas de la factura</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "2",
            })}
            onClick={() => {
              toggleCustom("2")
            }}
          >
            <span className="d-sm-block">Otra información</span>
          </NavLink>
        </NavItem>
        <ModuleWrapper module={1}>
          {form.fields?.accountMoveLines?.length > 0 ? (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "3",
                })}
                onClick={() => {
                  toggleCustom("3")
                }}
              >
                <span className="d-sm-block">Apuntes contables</span>
              </NavLink>
            </NavItem>
          ) : null}
        </ModuleWrapper>

        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "4",
            })}
            onClick={() => {
              toggleCustom("4")
            }}
          >
            <span className="d-sm-block">Notas</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={customActiveTab}>
        <LineaTab form={form} setFormState={setFormState} disabled={disabled} />
        <InformationTab form={form} disabled={disabled} readOnlyStates={readOnlyStates} />
        <NoteTab form={form} disabled={disabled} />
        <AccountingTab
          form={form}
          setFormState={setFormState}
          disabled={disabled}
        />
      </TabContent>
    </Col>
  )
}

export default AccountInvoiceTabs
