import { useState } from "react";
import { Input } from "reactstrap";

const SearchBox = (props) => {

  const [value, setValue] = useState();
  const [searchTime, setSearchTime] = useState(0)

  const { setSearchBy } = props;

  const onSearch = searchValue => {

    setValue(searchValue)

    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchBy(searchValue)
      }, 475)
    )
  }

  return (
    <div className="search-box mr-2 mb-2 d-inline-block">
      <div className="position-relative">
        <Input type="search" 
        className="form-control"
        autoFocus={true} 
        placeholder="Buscar..."
        value={value}
        onChange={(e) => onSearch(e.target.value)}
        />
        <i className="bx bx-search-alt search-icon"></i>
      </div>
    </div>
  );
};

export default SearchBox;
