import React from "react"
import { useSelector } from "react-redux"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import CustomLink from "../../components/Common/CustomLink";

const Breadcrumb = props => {
  const pageTitle = useSelector(state => state.PageTitle);

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          {/* <h4 className="mb-0 font-size-16">{pageTitle?.title}</h4> */}
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {pageTitle.breadcrumbs?.length === 0 ? (
                <BreadcrumbItem active>
                  {pageTitle.title}
                </BreadcrumbItem>
              ) : null}
              {pageTitle.breadcrumbs.map((item, index) => {
                const isLast = index === pageTitle.breadcrumbs.length - 1
                return isLast ? (
                  <BreadcrumbItem active key={index}>
                    {item.content}
                  </BreadcrumbItem>
                ) : (
                  <BreadcrumbItem key={index}>
                    <CustomLink index={index} content={item.content} to={item.to}>{item.content}</CustomLink>
                  </BreadcrumbItem>
                )
              })}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Breadcrumb
