import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
} from "reactstrap"
import useAPIFunctions from "../../hooks/useAPIFunctions"
import { setPageTitle } from "../../store/actions"
import classnames from "classnames"
import { INITIAL_STATE_FILTER, INITIAL_STATE } from "./consts"
import useQueryURL from "../../hooks/useQueryURL"
import useForm from "../../hooks/useForm"
import CustomLink from "../../components/Common/CustomLink"

const InventoryDashboard = props => {
  const { history } = props
  const { filter, setFilter, queryURL } = useQueryURL(INITIAL_STATE_FILTER)
  const [formState, setFormState] = useState([])
  const form = useForm({ formState })
  const { action } = useSelector(state => state.FormActionStatus)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle("Ajustes"))
    doFetch()
  }, [])

  const doFetch = async () => {
    // try {
    //   const response = await doGetAsync(`/inventory/dashboard`);
    //     setResults(response);
    // } catch (error) {
    //   console.log("doFetch", error.message);
    // }
  }

  const toggleTab = tab => {
    if (filter.activeTab !== tab) {
      setFilter({ ...filter, activeTab: tab })
    }
  }

  return (
    <div className="page-content">
      {filter ? (
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="2" sm="4">
                    <Nav pills className="flex-column">
                      <NavLink
                        className={classnames({
                          active: filter.activeTab === 1,
                        })}
                        onClick={() => {
                          toggleTab(1)
                        }}
                      >
                        <i className="bx bxs-cog nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">General</p>
                      </NavLink>

                      <NavLink
                        className={classnames({
                          active: filter.activeTab === 2,
                        })}
                        onClick={() => {
                          toggleTab(2)
                        }}
                      >
                        <i className="bx bx-store nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">Ventas</p>
                      </NavLink>

                      <NavLink
                        className={classnames({
                          active: filter.activeTab === 3,
                        })}
                        onClick={() => {
                          toggleTab(3)
                        }}
                      >
                        <i className="bx bx-cart-alt d-block nav-icon mb-2" />
                        <p className="font-weight-bold mb-0">Compras</p>
                      </NavLink>

                      <NavLink
                        className={classnames({
                          active: filter.activeTab === 4,
                        })}
                        onClick={() => {
                          toggleTab(4)
                        }}
                      >
                        <i className="bx bx-package d-block nav-icon mb-2" />
                        <p className="font-weight-bold mb-0">Inventario</p>
                      </NavLink>
                      <NavLink
                        className={classnames({
                          active: filter.activeTab === 5,
                        })}
                        onClick={() => {
                          toggleTab(5)
                        }}
                      >
                        <i className="bx bx-detail d-block nav-icon mb-2" />
                        <p className="font-weight-bold mb-0">Facturación</p>
                      </NavLink>
                    </Nav>
                  </Col>
                  <Col lg="10" sm="8">
                    <TabContent activeTab={filter.activeTab.toString()}>
                      <TabPane tabId="1" id="buy">
                        <Card>
                          <h5 className="card-header bg-transparent border-bottom text-uppercase">
                            Mensajería
                          </h5>
                          <CardBody>
                            {/* <CardTitle className="mt-0">
                                  Special title treatment
                                </CardTitle> */}
                            <CustomLink
                              content="Servidores de correo"
                              to={`settings/mailserver`}
                            >
                              <CardText>Servidores de correo</CardText>
                            </CustomLink>

                            {/* <Link to="#" className="btn btn-primary">
                                  Go somewhere
                                </Link> */}
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="2">
                        <h4 className="card-title mb-4">Ventas</h4>
                      </TabPane>

                      <TabPane tabId="3">
                        <h4 className="card-title mb-4">Compras</h4>
                      </TabPane>
                      <TabPane tabId="4">
                        <h4 className="card-title mb-4">Inventario</h4>
                      </TabPane>
                      <TabPane tabId="5">
                        <h4 className="card-title mb-4">Facturación</h4>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
    </div>
  )
}

export default withRouter(InventoryDashboard)
