import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import apiCall from "../../../api";
import useAPIFunctions from "../../../hooks/useAPIFunctions";
import { setFormAction, setFormRefresh, setApiLoading } from "../../../store/actions";

const useAccountInvoiceActions = (id) => {
  const { doGetAsync } = useAPIFunctions();
  const dispatch = useDispatch();
  let history = useHistory();

  const doValidate = async () => {
    try {

      if(id== 0){

        document
              .getElementById('hook-form')
              .dispatchEvent(new Event('submit', { cancelable: true }))
      }else{
        const response = await doGetAsync(`/accountinvoice/validate/${id}`);

        await apiCall.getFile(
          `/accountinvoice/report/${id}`,
          `${response.data.data.name}.pdf`
        );

        dispatch(setFormAction("details"));
        dispatch(setFormRefresh(true));
      }
      

      

      
    } catch (error) {
      console.log("Validate", error.message);
    }
  };

  const doCreditNote = async () => {

    try {
      const response = await doGetAsync(`/accountinvoice/docreditnote/${id}`);

      history.push(`/invoices/accountinvoice/${response.data.data}`);
      
    } catch (error) {
      console.log("SendMail", error.message);
    }

  };

  const doDebitNote = async () => {
   
  };

  const doPrint = async (name) => {

    dispatch(setApiLoading(true))
    
    try {
      await apiCall.getFile(
        `/accountinvoice/report/${id}`,
        `${name}.pdf`
      );

      dispatch(setApiLoading(false))
    } catch (error) {
      console.log("Report", error.message);
      dispatch(setApiLoading(false))
    }
  };

  const doSendMail = async () => {
    try {
      const results = await doGetAsync(`/accountinvoice/sendmail/${id}`);

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
      
    } catch (error) {
      console.log("SendMail", error.message);
    }
  };

  return {
    doValidate,
    doPrint,
    doSendMail,
    doCreditNote,
    doDebitNote
  };
};

export default useAccountInvoiceActions;
