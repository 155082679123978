import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames';
import { useEffect } from 'react';
import { useState } from 'react';

const StatusBar = (props) => {

    const { saleOrderState, mobile } = props;

    const [cssStatus, setCssStatus] = useState("bg-light rounded");

    useEffect(() => {
        if(mobile){
            setCssStatus("bg-light rounded d-none d-md-flex")
        }else{
            setCssStatus("bg-light rounded");
        }
    },[mobile]);
    

    return(
    // <Nav pills className="bg-light rounded d-none d-xl-flex">
    <Nav pills className={cssStatus}>
    <NavItem>
        <NavLink
            className={classnames({ active: saleOrderState === 1 })}
        >
            Presupuesto
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: saleOrderState === 2 })}
        >
            Enviado
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: saleOrderState === 3 })}
        >
            Pedido
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: saleOrderState === 4 })}
        >
            Entregado
        </NavLink>
    </NavItem>
    {saleOrderState === 5 ? (
        <NavItem>
        <NavLink
            className={classnames({ active: saleOrderState === 5 })}
        >
            Cancelado
        </NavLink>
    </NavItem>
    ) : null}
    
</Nav>
    )
}

export default StatusBar