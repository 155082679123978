import React from "react"
import { Button, Col } from "reactstrap"
import img1 from "../../../../assets/images/product/img-1.png"
import img2 from "../../../../assets/images/product/img-2.png"
import img3 from "../../../../assets/images/product/img-3.png"
import img4 from "../../../../assets/images/product/img-4.png"
import img5 from "../../../../assets/images/product/img-5.png"


const Index = props => {
  return (
    <div className="rightpane">
      <div className="products-widget">
        <div className="products-widget-control">
          <div className="rightpane-header">
            <div className="search-bar-container sb-product">
              <div className="pos-search-bar">
                <i className="fa fa-search"></i>
                <input
                  placeholder="Buscar productos..."
                  type="text"
                  autofocus="autofocus"
                />
              </div>
            </div>
            <div className="categories-header">
              <div className="breadcrumbs">
                <span className="breadcrumb">
                  <span className="breadcrumb-button breadcrumb-home">
                    <i
                      className="fa fa-home"
                      role="img"
                      aria-label="Home"
                      title="Inicio"
                    ></i>
                  </span>
                </span>
              </div>
              <div className="category-list simple">
                <span className="category-simple-button">Remeras</span>
                <span className="category-simple-button">Frutas</span>
              </div>
            </div>
          </div>
        </div>
        <div className="product-list-container">
          <div className="product-list">
            <article
              className="product"
              tabindex="0"
              data-product-id="7"
              aria-labelledby="article_product_7"
            >
              <div className="product-img">
                <img
                  src={img3}
                  alt="Desk Organizer"
                />
              </div>
              <div className="product-content">
                <div className="product-name" id="article_product_7">
                  Desk Organizer
                </div>
                <span className="price-tag">$ 6,17/Unidades</span>
              </div>
            </article>
            <article
              className="product"
              tabindex="0"
              data-product-id="6"
              aria-labelledby="article_product_6"
            >
              <div className="product-img">
                <img
                  src={img1}
                  alt="Monitor Stand"
                />
              </div>
              <div className="product-content">
                <div className="product-name" id="article_product_6">
                  Monitor Stand
                </div>
                <span className="price-tag">$ 3,86/Unidades</span>
              </div>
            </article>
            <article
              className="product"
              tabindex="0"
              data-product-id="5"
              aria-labelledby="article_product_5"
            >
              <div className="product-img">
                <img
                  src={img2}
                  alt="Small Shelf"
                />
              </div>
              <div className="product-content">
                <div className="product-name" id="article_product_5">
                  Small Shelf
                </div>
                <span className="price-tag">$ 2,83/Unidades</span>
              </div>
            </article>
            <article
              className="product"
              tabindex="0"
              data-product-id="4"
              aria-labelledby="article_product_4"
            >
              <div className="product-img">
                <img
                  src={img4}
                  alt="Wall Shelf Unit"
                />
              </div>
              <div className="product-content">
                <div className="product-name" id="article_product_4">
                  Wall Shelf Unit
                </div>
                <span className="price-tag">$ 1,98/Unidades</span>
              </div>
            </article>
            <article
              className="product"
              tabindex="0"
              data-product-id="8"
              aria-labelledby="article_product_8"
            >
              <div className="product-img">
                <img
                  src={img5}
                  alt="Whiteboard Pen"
                />
              </div>
              <div className="product-content">
                <div className="product-name" id="article_product_8">
                  Whiteboard Pen
                </div>
                <span className="price-tag">$ 1,45/Unidades</span>
              </div>
            </article>
          </div>
          <div className="portal search-database-button no-results-message oe_hidden"></div>
        </div>
      </div>
    </div>
  )
}

export default Index
