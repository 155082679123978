import React from "react"
import { Link } from "react-router-dom"
import { Col } from "reactstrap"
import searchAsync from "../../../../api/searchAsync"
import CustomSelect from "../../../../components/Common/CustomSelect"
import DynamicTableField from "../../../../components/Common/DynamicTable/DynamicTableField"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import useDynamicTable from "../../../../hooks/useDynamicTable"

const AccountMoveLinesTable = props => {

  const { form, setFormState, disabled } = props

  var debit = form.fields?.accountMoveLines?.reduce(function(prev, cur) {
    return prev + cur.debit;
  }, 0);

  var credit = form.fields?.accountMoveLines?.reduce(function(prev, cur) {
    return prev + cur.credit;
  }, 0);

  const updateFields = async (name, index, value) => {
    let tempItems = [...form.fields?.accountMoveLines]

    let tempItem = {
      ...tempItems[index],
      [name]: value,
    }

    tempItems[index] = tempItem

    setFormState({ ...form.fields, accountMoveLines: tempItems })
  }

  const dynamicTable = useDynamicTable(
    form,
    setFormState,
    "accountMoveLines",
    disabled,
    updateFields
  )


  const onSelect = async value => {

    let item = {
      product: value,
    }

    if (item != null) {
      dynamicTable.addItem(item)
    }

    dynamicTable.setEditable(false)
  }


  return (
    <React.Fragment>
      <Col xs="12">
        <div className="table-responsive overflow-visible">
          <Table
            className="table mb-0"
          >
            <Thead className="table-light">
              <Tr>
                <Th width="10%">Cuenta</Th>
                <Th width="10%">Descripción</Th>
                <Th width="10%">Debe</Th>
                <Th width="10%">Haber</Th>
                <Th width="5%"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {dynamicTable.items?.map((p, key) => (
                <Tr key={"_product_" + key}>
                  <Th onClick={() => dynamicTable.onClick("accountAccount",key)}>
                    {p.accountAccount.displayName}
                  </Th>
                  <Td onClick={() => dynamicTable.onClick("ref",key)}>
                    <DynamicTableField
                      type="text"
                      {...dynamicTable?.getInput("ref", key)}
                    />
                  </Td>
                  <Td onClick={() => dynamicTable.onClick("debit",key)}>
                    <DynamicTableField
                      name="debit"
                      type="number"
                      value={dynamicTable.items[key]["debit"]}
                      disabled={true}
                    />
                  </Td>
                  <Td onClick={() => dynamicTable.onClick("credit",key)}>
                    <DynamicTableField
                      name="credit"
                      type="number"
                      value={dynamicTable.items[key]["credit"]}
                      disabled={true}
                    />
                  </Td>
                  <Td>
                    {disabled ? null : (
                      <Link
                        to="#"
                        onClick={() => dynamicTable.removeItem(key)}
                        className="text-danger"
                      >
                        <i className="mdi mdi-close font-size-18 mr-3"></i>
                      </Link>
                    )}
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td>
                  
                </Td>
                <Td>

                </Td>
                <Td>
                    <strong>{debit}</strong>
                </Td>
                <Td>
                <strong>{credit}</strong>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>

      </Col>
      {disabled ? null : (
        <Col lg="4" sm="12">
          {dynamicTable.editable === "select" ? (
            <CustomSelect
              name="accountAccount"
              value={null}
              customRef={dynamicTable.selectRef}
              onFocusOut={() => dynamicTable.setEditable(null)}
              onChange={onSelect}
              loadOptions={inputValue => {
                return searchAsync("accountAccount", inputValue, "")
              }}
            />
          ) : (
            <a
              className="text-primary"
              onClick={() => {
                dynamicTable.setEditable("select")
              }}
            >
              Agregar línea
            </a>
          )}
        </Col>
      )}
    </React.Fragment>
  )
}

export default AccountMoveLinesTable
