import React from "react"
import { useDispatch } from "react-redux"
import AsyncSelect from "react-select/async"
import { Label } from "reactstrap"
import { setFormBounceEdit } from "../../store/actions"

const CustomSelect = props => {
  const {
    name,
    label,
    value,
    onChange,
    isDisabled,
    loadOptions,
    required,
    onFocusOut,
    customRef,
    onKeyDown,
    searchMore,
    className,
  } = props
  const dispatch = useDispatch()

  //   const MenuList = props => {
  //     return (
  //       <components.MenuList {...props}>
  //         {props.children}
  //         <DropdownItem
  //         className="text-primary"
  //         name="invoiced"
  //       >
  //         Buscar más..
  //       </DropdownItem>
  //       </components.MenuList>
  //     );
  //   };

  return (
    <React.Fragment>
      <div
        className="mb-1"
        onClick={() => {
          if (isDisabled) {
            dispatch(setFormBounceEdit(true))
          }
        }}
      >
        {(label != null) & (label !== "") ? (
          <Label>
            {label} {required ? <span className="text-danger">*</span> : null}
          </Label>
        ) : null}

        {isDisabled ? (
          <span className="d-block text-muted text-truncate field-muted">
            {value?.name}
          </span>
        ) : (
          <AsyncSelect
            name={name}
            value={value}
            onChange={onChange}
            className={"react-select " + className}
            classNamePrefix="react-select"
            onBlur={onFocusOut}
            onKeyDown={onKeyDown}
            isClearable={!required}
            isDisabled={isDisabled}
            ref={customRef}
            defaultOptions
            placeholder=""
            cacheOptions
            loadOptions={loadOptions}
            getOptionLabel={e => e.name}
            getOptionValue={e => e.id}
            // components={{ MenuList }}
          />
        )}
      </div>
      {searchMore}
    </React.Fragment>
  )
}

export default CustomSelect
