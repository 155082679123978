import React from 'react'
import { TabPane } from 'reactstrap'
import SaleOrderItemsTable from '../SaleOrderItemsTable';

const LineaTab = (props) => {

    const { form, disabled, setFormState } = props;

    return(
        <TabPane tabId="1" className="pt-3">
          <SaleOrderItemsTable
            disabled={disabled}
            form={form}
            setFormState={setFormState}
            // addItem={addItem}
          />
        </TabPane>
    )
}

export default LineaTab