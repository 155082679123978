import React from 'react'
import { useDispatch } from 'react-redux';
import { TabPane } from 'reactstrap'
import StockPickingItemsTable from '../StockPickingItemsTable';

const OperationsTab = (props) => {

    const { form, disabled, setFormState } = props;
    const dispatcher= useDispatch();

    return(
        <TabPane tabId="1" className="pt-3">
          <StockPickingItemsTable
            disabled={disabled}
            form={form}
            setFormState={setFormState}
          />
        </TabPane>
    )
}

export default OperationsTab