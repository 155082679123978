import React, { useState } from "react"

import useForm from "../../../hooks/useForm"
import BaseForm from "./components/BaseForm"
import { MODULE, MODEL, INITIAL_STATE, CREATE_TITLE } from "./components/consts"
import DetailsPage from "../../../components/Actions/DetailsPage"
import { useSelector } from "react-redux"
import StatusBar from "./components/StatusBar"
import StockInventoryActions from "./components/StockInventoryActions"
import StockInventoryItemsTable from "./components/StockInventoryItemsTable"
import useQueryURL from "../../../hooks/useQueryURL"

const Details = () => {
  const [formState, setFormState] = useState([])
  const form = useForm({ formState })
  const { action } = useSelector(state => state.FormActionStatus)

  const statusBar = <StatusBar state={form.fields?.state} />
  const { filter } = useQueryURL([])

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      statusBar={statusBar}
      setFormState={setFormState}
      defaultValues
    >
      <StockInventoryActions form={form} filter={filter} />
      {filter?.view === "lines" ? (
        <StockInventoryItemsTable
          disabled={(action != "edit" && action != "create" ? true : false) || form.fields?.state != 2}
          form={form}
          setFormState={setFormState}
        />
      ) : (
        <BaseForm
          form={form}
          disabled={action != "edit" && action != "create" ? true : false}
          setFormState={setFormState}
        />
      )}
    </DetailsPage>
  )
}

export default Details
