import React from "react"
import AsyncSelect from "react-select/async"
import { Label } from "reactstrap"

const DynamicTableSelect = props => {
  const {
    header,
    name,
    label,
    value,
    onChange,
    isDisabled,
    loadOptions,
    required,
    onFocusOut,
    customRef,
    onKeyDown,
  } = props

  const compLabel =
    (label != null) & (label !== "") ? (
      <Label>
        {label} {required ? <span className="text-danger">*</span> : null}
      </Label>
    ) : null

  const comp = isDisabled ? (
    <span className="d-block text-muted text-truncate field-muted">
      {value?.name}
    </span>
  ) : (
    <AsyncSelect
      name={name}
      value={value}
      onChange={onChange}
      className="react-select"
      classNamePrefix="react-select"
      onBlur={onFocusOut}
      onKeyDown={onKeyDown}
      isClearable={!required}
      isDisabled={isDisabled}
      ref={customRef}
      defaultOptions
      placeholder=""
      cacheOptions
      loadOptions={loadOptions}
      getOptionLabel={e => e.name}
      getOptionValue={e => e.id}
    />
  )

  return (
    <React.Fragment>
      {compLabel}

      {comp}
    </React.Fragment>
      
  )
}

export default DynamicTableSelect
