import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import {  MODEL, BASE_URL, INDEX_TITLE, FISCALPOSITION_ENUM, DOCUMENTTYPE_ENUM  } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch, useSelector } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import { getLabelFromArray } from "../../../helpers/array_utils";
import useAPI from "../../../hooks/useAPI";
import { Card, CardBody, Col, Row, Spinner, Table } from "reactstrap";
import DropdownTable from "../../../components/Common/CustomDatatable/DropdownTable";
import ErrorMessage from "../../../components/Common/ErrorMessage";
import CustomLink from "../../../components/Common/CustomLink";

const Index = (props) => {

  const url = `/${MODEL}`;
  const { result, refresh } = useAPI(url);
  const { error, loading } = useSelector(state => state.ApiStatus);
  const customClass = "p-1";
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        label: "Menu",
        width: 30,
      },
      
     
    ];

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
    

      fields.push(
        <CustomLink
        content={p.name}
        to={`${BASE_URL}/${p.id}`}>{p.name}</CustomLink>
      );
      

      rows.push({fields : fields});
    });

    return rows;
  };

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <Row>
      <Col xs="12">
        <Card className="mb-0">
          <CardBody>
            <ErrorMessage error={error} />
            <Row className="mb-2">
              <Col xs="6">
                {loading ? (<Spinner type="grow" className="ml-2" color="primary" />) : null}
              </Col>
              <Col xs="6">
                
              
              </Col>
            </Row>

            <div className="table-responsive">
              <Table className="table-centered table-nowrap">
                <thead>
                  
                  <tr>
                  
                    {columns?.map((c, index) => {
                    return (
                      <th key={index} width={`${c.width}%`} className={customClass}>
                        {c.label}
                      </th>
                    );
                  })}
                    
                  </tr>
                </thead>
                <tbody>
                  {result?.data.map((p, index) => {
                    return (
                      <tr key={index}>
                        <td><CustomLink content={p.content} to={`${BASE_URL}/${p.id}`}>{p.content}</CustomLink></td>

                        {p.children?.map((c ,index) => {
                          return (
                            <tr key={index}>
                              <td><CustomLink content={c.content} to={`${BASE_URL}/${c.id}`}>{c.content}</CustomLink></td>

                              {c.children?.map((c1 ,index) => {
                                return (
                                  <tr key={index}>
                                    <td><CustomLink content={c1.content} to={`${BASE_URL}/${c1.id}`}>{c1.content}</CustomLink></td>
                                  </tr>)
                              })}
                            </tr>)
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

          </CardBody>
        </Card>
      </Col>
    </Row>
    </FormLayout>
  );
};

export default Index;
