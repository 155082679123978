import {put, call, takeLatest, all, fork} from 'redux-saga/effects';
import {  PAGETITLE_SET } from './actionTypes';

function* setPageTitle ({payload: value}) {
    document.title = value + " | VERP - Sistema de gestión";
    //console.log('PayLoad', value);
}


export function* watchSetPageTitle(){
    yield takeLatest(PAGETITLE_SET, setPageTitle);
}



function* createSetPageTitle() {
    yield all([
        fork(watchSetPageTitle)
    ]);
  }


export default createSetPageTitle;