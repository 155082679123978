import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames';

const StatusBar = (props) => {

    const { state } = props;

    return(
    <Nav pills className="bg-light rounded d-none d-xl-flex">
    <NavItem>
        <NavLink
            className={classnames({ active: state === 1 })}
        >
            Presupuesto
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: state === 2 })}
        >
            Enviado
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: state === 4 })}
        >
            Pedido de compra
        </NavLink>
    </NavItem>
    {state === 5 ? (
        <NavItem>
        <NavLink
            className={classnames({ active: state === 5 })}
        >
            Recibido
        </NavLink>
    </NavItem>
    ) : null}
    {state === 6 ? (
        <NavItem>
        <NavLink
            className={classnames({ active: state === 6 })}
        >
            Cancelado
        </NavLink>
    </NavItem>
    ) : null}
    
</Nav>
    )
}


export default StatusBar