import React, { useState } from "react"

import { Col,  Input, Label, Modal, Row } from "reactstrap"
// reactstrap

import logoLightPng from "../../../assets/images/logo-light.png"
import avatar1 from "../../../assets/images/users/avatar-1.jpg"

const Header = props => {
  const [modal_cash, setmodal_cash] = useState(false)
  const [cashFlow, setCashFlow] = useState("in")

  const { mode, setMode} = props;

  function tog_cash() {
    setmodal_cash(!modal_cash)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <div className="pos-topheader">
        <div className="pos-branding">
          <img className="pos-logo" src={logoLightPng} alt="Logo" />
          <div className="cash-move-button">
            <i class="bx bx-money font-size-16"></i>
            <span style={{ "padding-left": "10px" }}>Efectivo</span>
          </div>
          <div className={`ticket-button${mode == 3 ? " highlight" : ""}`} onClick={() => {
            setMode(3)
          }}>
            <div className="with-badge" badge="1">
              <i class="bx bx-receipt font-size-16"></i>
            </div>
            <div>Pedidos</div>
          </div>
        </div>
        <div className="pos-rightheader">
          <div className="status-buttons">
            <div className="not-clickable">
              <span>
                  <img
                    src={avatar1}
                    alt=""
                    className="avatar"
                  />

                <span className="username">Elio AC</span>
              </span>
            </div>
            <div className="header-button">
              <span>
                <i className="fa fa-sign-out" role="img"></i> Cerrar
              </span>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal_cash}
        toggle={() => {
          tog_cash()
        }}
      >
        <div className="modal-body">
          <Row>
            <Col>
              <Label className="card-radio-label mb-3">
                <Input
                  type="radio"
                  name="cash"
                  value="in"
                  className="card-radio-input"
                  checked={cashFlow == "in"}
                  onClick={() => setCashFlow("in")}
                />

                <div className="card-radio">
                  <i className="bx bx-down-arrow-alt font-size-24 text-primary align-middle me-2" />
                  <span>Entrada en efectivo</span>
                </div>
              </Label>
            </Col>
            <Col>
              <Label className="card-radio-label mb-3">
                <Input
                  type="radio"
                  name="cash"
                  value="in"
                  className="card-radio-input"
                  checked={cashFlow == "out"}
                  onClick={() => setCashFlow("out")}
                />

                <div className="card-radio">
                  <i className="bx bx-up-arrow-alt font-size-24 text-primary align-middle me-2" />
                  <span>Salida de efectivo</span>
                </div>
              </Label>
            </Col>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-number-input"
              className="col-auto col-form-label"
            >
              $
            </label>
            <div className="col-md-4">
              <input
                className="form-control form-control-lg"
                type="text"
                defaultValue=""
                id="example-number-input"
                autoFocus={true}
              />
            </div>
          </Row>
          <h5>Motivo</h5>
          <textarea
            id="basicpill-address-input1"
            className="form-control"
            rows="5"
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_cash()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Cancelar
          </button>
          <button type="button" className="btn btn-primary ">
            Confirmar
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Header
