import { API_LOADING, API_ERROR, API_VALIDATION_ERROR } from './actionTypes';


export const setApiLoading = (loading) => {
    return {
        type: API_LOADING,
        payload: loading
    }
}

export const setApiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}

export const setValidationError = (validationError) => {
    return {
        type: API_VALIDATION_ERROR,
        payload: validationError
    }
}

