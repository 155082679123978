import React from "react"
import { Row, Col, Alert } from "reactstrap"
import CumstomField from "../../../../components/Common/CustomFields"
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect"
import { BILLINGTYPE_ENUM, CONNECTIONTYPE_ENUM } from "./consts"
import CustomCheckbox from "../../../../components/Common/CustomCheckbox"
import { useDispatch } from "react-redux"
import {
  setApiError,
  setApiLoading,
  setFormRefresh,
} from "../../../../store/actions"
import apiCall from "../../../../api"
import moment from "moment"
import CustomSwitch from "../../../../components/Common/CustomSwitch"

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  const dispatcher = useDispatch()

  const onChangeFile = async e => {
    const file = e.target.files[0]

    const data = new FormData()

    data.append("id", form.fields?.id)
    data.append("file", file)

    dispatcher(setApiLoading(true))

    try {
      const results = await apiCall.post(`/billingtypeconfig/uploadcert`, data)

      dispatcher(setApiLoading(false))
      dispatcher(setFormRefresh(true))
    } catch (error) {
      dispatcher(setApiLoading(false))
      dispatcher(setApiError(error.message))
      console.log("uploadcert", error.message)
    }
  }

  return (
    <Row>
      <Col md="4">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />

        <CustomEnumSelect
          label="Tipo"
          {...form?.getEnumSelect("billingType", BILLINGTYPE_ENUM)}
          isDisabled={disabled}
        />
        {form.fields?.billingType === 1 && (
          <React.Fragment>
            <CustomCheckbox
              label="Modo desarrollador"
              disabled={disabled}
              {...form?.getCheckbox("developmentMode")}
            />

            <CustomSwitch
              label="Prdeterminado"
              disabled={disabled}
              {...form?.getSwitch("default")}
            />
          </React.Fragment>
        )}
      </Col>
      <Col md="4">
        {form.fields?.billingType === 1 ? (
          <React.Fragment>
            <CumstomField
              {...form?.getInput("urL_WSAA")}
              label="URL WSAA"
              type="text"
              disabled={disabled}
            />

            <CumstomField
              {...form?.getInput("urL_USFE")}
              label="URL USFE"
              type="text"
              disabled={disabled}
            />

            {form.fields?.id != 0 ? (
              <React.Fragment>
                <CumstomField
                  {...form?.getInput("cert")}
                  label="Certificado"
                  type="text"
                  disabled={true}
                />
                {form.fields?.certExpirationDate != null ? (
                  <Alert color="success" role="alert">
                    Válido hasta:{" "}
                    {moment(form.fields?.certExpirationDate).format(
                      "DD/MM/YYYY"
                    )}
                  </Alert>
                ) : null}

                <input
                  name="certFile"
                  type="file"
                  accept=".pfx"
                  className="form-control-file"
                  onChange={onChangeFile}
                />
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}

        {form.fields?.billingType === 2 || form.fields?.billingType === 3 ? (
          <React.Fragment>
            <CustomEnumSelect
              label="Tipo de conexión"
              {...form?.getEnumSelect("connectionType", CONNECTIONTYPE_ENUM)}
              isDisabled={disabled}
            />

            {form.fields?.connectionType === 1 ? (
              <CumstomField
                {...form?.getInput("comPortNumber")}
                label="Puerto COM"
                type="text"
                disabled={disabled}
              />
            ) : null}

            {form.fields?.connectionType === 2 ? (
              <React.Fragment>
                <CumstomField
                  {...form?.getInput("redIPAddress")}
                  label="Dirección IP"
                  type="text"
                  disabled={disabled}
                />
                <CumstomField
                  {...form?.getInput("redPort")}
                  label="Puerto"
                  type="number"
                  disabled={disabled}
                />
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </Col>
    </Row>
  )
}

export default BaseForm
