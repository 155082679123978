import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'
import AccountMoveLinesTable from '../AccountMoveLinesTable';

const AccountingTab = (props) => {

    const { form, disabled, setFormState } = props;
    
    return(
        <TabPane tabId="3" className="p-3">
          <Row>
            <AccountMoveLinesTable
              form={form}
              disabled={true}
            />
            
          </Row>
        </TabPane>
    )
}

export default AccountingTab