import React, { useState } from "react"

import useForm from "../../../hooks/useForm"
import BaseForm from "./components/BaseForm"
import { MODEL, INITIAL_STATE, CREATE_TITLE } from "./components/consts"
import { MODULE } from "../consts"
import DetailsPage from "../../../components/Actions/DetailsPage"
import { useSelector } from "react-redux"
import UserTabs from "./components/UserTabs"
import ModuleWrapper from "../../../components/Common/ModuleWrapper"

const Details = () => {
  const [formState, setFormState] = useState([])
  const form = useForm({ formState })
  const { action } = useSelector(state => state.FormActionStatus)

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      defaultValues
      setFormState={setFormState}
    >
      <BaseForm
        form={form}
        disabled={action != "edit" && action != "create" ? true : false}
      />
      <ModuleWrapper module={6}>
        <UserTabs
          form={form}
          disabled={action != "edit" && action != "create" ? true : false}
          setFormState={setFormState}
        />
      </ModuleWrapper>
    </DetailsPage>
  )
}

export default Details
