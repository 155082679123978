import React from "react";
import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import { JOURNALTYPE_ENUM } from "./consts";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CustomSelect from "../../../../components/Common/CustomSelect";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
    <Row>
      <Col lg="4">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />

        <CumstomField
          {...form?.getInput("code")}
          label="Código corto"
          type="text"
          disabled={disabled}
        />

        <CustomEnumSelect
          label="Tipo"
          {...form?.getEnumSelect("type", JOURNALTYPE_ENUM)}
          required
          isDisabled={disabled}
        />

        <CustomSelect
          label="Cuenta deudora"
          {...form?.getSelect("accountDebit", "accountAccount")}
          isClearable
          isDisabled={disabled}
        />

        <CustomSelect
          label="Cuenta acreedora"
          {...form?.getSelect("accountCredit", "accountAccount")}
          isClearable
          isDisabled={disabled}
        />

        <CustomSwitch
          label="Activo"
          disabled={disabled}
          {...form?.getSwitch("active")}
        />
      </Col>
      <Col lg="4">
        <CustomSelect
          label="Pagos salientes"
          {...form?.getSelect("paymentCreditAccount", "accountAccount")}
          isClearable
          isDisabled={disabled}
        />
        <CustomSelect
          label="Pagos entrantes"
          {...form?.getSelect("paymentDebitAccount", "accountAccount")}
          isClearable
          isDisabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default BaseForm;
