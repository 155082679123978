import { useEffect, useState } from "react"

import useForm from "../../../hooks/useForm"
import BaseForm from "./components/BaseForm"
import { MODULE, MODEL, INITIAL_STATE, CREATE_TITLE } from "./components/consts"
import DetailsPage from "../../../components/Actions/DetailsPage"
import { useSelector } from "react-redux"
import StatusBar from "./components/StatusBar"
import AccountPaymentActions from "./components/AccountPaymentActions"
import apiCall from "../../../api"
import { INITIAL_STATE_FILTER } from "./components/consts"
import useQueryURL from "../../../hooks/useQueryURL"

const Details = () => {
  const [formState, setFormState] = useState([])
  const form = useForm({ formState })
  const { queryURL } = useQueryURL(INITIAL_STATE_FILTER)
  const { action } = useSelector(state => state.FormActionStatus)

  const statusBar = <StatusBar state={form.fields?.state} />

  useEffect(() => {
    (async () => {
      if (form.fields?.paymentMethod) {
        var request = {
          id: form.fields?.paymentMethod?.id,
        }

        try {
          const results = await apiCall.post(`/paymentmethod/fetch`, request)

          setFormState({
            ...form.fields,
            ["accountJournal"]: form.fields?.itsForInvoice
              ? results.data?.data?.accountJournal
              : results.data?.data?.saleOrderAccountJournal,
          })
        } catch (error) {
          console.log("fetchPaymentMethod", error.message)
        }
      }
    })()
  }, [form.fields?.paymentMethod])

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      statusBar={statusBar}
      setFormState={setFormState}
      defaultValues
      defaultValuesParams={queryURL}
    >
      <AccountPaymentActions form={form} />
      <BaseForm
        form={form}
        disabled={action != "edit" && action != "create" ? true : false}
      />
    </DetailsPage>
  )
}

export default Details
