import {
    BASE_DELETE_TRY,
    BASE_DELETE_ERROR,
    BASE_DELETE_OK,
  } from "./actionTypes";
  
  export const deleteBase = (target,id) => {
    return {
      type: BASE_DELETE_TRY,
      payload: {target, id },
    };
  };
  
  export const deleteBaseOK = (data) => {
    return {
      type: BASE_DELETE_OK,
      payload: data,
    };
  };
  
  export const deleteBaseError = (error) => {
    return {
      type: BASE_DELETE_ERROR,
      payload: error,
    };
  };
  