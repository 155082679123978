export const MODULE = 'inventory';
export const MODEL = 'product';

export const INDEX_TITLE = 'Productos';
export const CREATE_TITLE = 'Nuevo producto';

export const INITIAL_STATE = {
    name: "",
    type: 3,
    active: true,
    category : {
      id : 1,
      name : "Todas"
    },
    productUnitOfMeasure : {
      id : 1,
      name : "Unidad"
    },
    productBarcodes: []
  };

export const INITIAL_STATE_FILTER = {
    active: false,
    product: false,
    service: false,
    saleOK: false,
    purchaseOK: false,
    expenseOK: false,
    available: false,
    out: false,
    negative: false,
    customFilters: []
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


export const PRODUCTTYPE_ENUM = [
  { value: 1, label: 'Consumible'},
  { value: 2, label: 'Servicio'},
  { value: 3, label: 'Producto'},
];
