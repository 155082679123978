import { useDispatch } from "react-redux";
import apiCall from "../../../api";
import useAPIFunctions from "../../../hooks/useAPIFunctions";
import { setFormAction, setFormRefresh, setApiLoading } from "../../../store/actions";

const useStockPickingActions = (id) => {
  const { doGetAsync } = useAPIFunctions();
  const dispatch = useDispatch();

  const doValidate = async () => {
    try {
      const results = await doGetAsync(`/stockpicking/validate/${id}`);

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
    } catch (error) {
      console.log("Validate", error.message);
    }
  };

  const doPrint = async (name) => {

    dispatch(setApiLoading(true))

    try {
      await apiCall.getFile(
        `/stockpicking/report/${id}`,
        `${name}.pdf`
      );
      dispatch(setApiLoading(false))
    } catch (error) {
      console.log("Report", error.message);
      dispatch(setApiLoading(false))
    }
  };


  return {
    doValidate,
    doPrint
  };
};

export default useStockPickingActions;
