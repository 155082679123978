import { useEffect } from "react";

import {  MODEL, BASE_URL, INDEX_TITLE  } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import CustomLink from "../../../components/Common/CustomLink";
import useQueryURL from "../../../hooks/useQueryURL";
import { INITIAL_STATE_FILTER } from "./components/consts";
import AccountPaymentTermCustomFilters from "./components/AccountPaymentTermCustomFilters";

const Index = (props) => {

  const {filter, setFilter, queryURL} = useQueryURL(INITIAL_STATE_FILTER);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        name: "Name",
        label: "Nombre",
        width: 30,
        type: "string"
      },
      {
        name: "Due",
        label: "Plazo",
        width: 60,
        type: "number"
      },
     
    ];

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
    

      fields.push(
        <CustomLink 
        content={p.name}
        to={`${BASE_URL}/${p.id}`}>{p.name}</CustomLink>
      );

      fields.push(
      <span>{p.due}</span>
      );
      
     

      rows.push({fields : fields});
    });

    return rows;
  };

  const customFilters = (
    <AccountPaymentTermCustomFilters
      filter={filter}
      setFilter={setFilter}
      columns={columns}
    />
  );

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={filter}
        customFilters={customFilters}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
      />
    </FormLayout>
  );
};

export default Index;
