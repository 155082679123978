import React from "react"
import { useSelector } from "react-redux"
import ModuleWrapper from "../../../../components/Common/ModuleWrapper"
import SmartButton from "../../../../components/Common/SmartButton"
import useMenu from "../../../../hooks/useMenu"

const SmartButtons = props => {
  const { form } = props
  const { action } = useSelector(state => state.FormActionStatus)

  const { GoTo } = useMenu()

  const btnPickingsAction = e => {
    if (form.fields?.stockPickings?.length === 1) {
      GoTo(
        form.fields?.stockPickings[0].name,
        `/inventory/stockpicking/${form.fields?.stockPickings[0].id}`
      )
    } else {
      GoTo(
        "Recepciones",
        `/inventory/stockpicking?purchaseOrder=${form.fields?.id}`
      )
    }
  }

  const btnInvoicesAction = (e) => {

    if(form.fields?.accountInvoices?.length === 1){
      GoTo(form.fields?.accountInvoices[0].name,`/invoices/accountInvoice/${form.fields?.accountInvoices[0].id}?moveType=2`)
    }else{
      GoTo("Facturas",`/invoices/accountInvoice?saleOrder=${form.fields?.id}`)
    }
    
  };

  const btnPaymentsAction = e => {
    if (form.fields?.accountPaymentItems?.length === 1) {
      GoTo(
        form.fields?.accountPaymentItems[0].accountPayment.name,
        `/invoices/accountpayment/${form.fields?.accountPaymentItems[0].accountPayment.id}`
      )
    } else {
      GoTo("Pagos", `/invoices/accountpayment?purchaseOrder=${form.fields?.id}`)
    }
  }

  return action === "create" ? null : (
    <div className="d-flex flex-row-reverse">
      {form.fields?.stockPickings?.length > 0 ? (
        <SmartButton
          onClick={btnPickingsAction}
          icon="bxs-truck"
          label="Recepciones"
          value={form.fields?.stockPickings?.length}
        />
      ) : null}

    <ModuleWrapper
        module={5}
      >
      {form.fields?.accountInvoices?.length > 0 ? (
        <SmartButton
        onClick={btnInvoicesAction}
        icon="bxs-detail"
        label="Facturas"
        value={form.fields?.accountInvoices?.length}
      />
        
      ) : null}

      {form.fields?.accountPaymentItems?.length > 0 ? (
        <SmartButton
        onClick={btnPaymentsAction}
        icon="bxs-dollar-circle"
        label="Pagos"
        value={form.fields?.accountPaymentItems?.length}
      />
      ) : null}
      </ModuleWrapper>
    </div>
  )
}

export default SmartButtons
