import {put, call, takeLatest, all, fork} from 'redux-saga/effects';
import { BASE_CREATE_TRY,BASE_GET_TRY } from './actionTypes';
import { createBaseOK, createBaseError } from './actions';
import apiCall from '../../../api';

function* createBase ({payload: {history,target,values}}) {
    try {
        const results = yield call(apiCall.post,`/${target}`, values);

        history.push(`/${target}/${results.data.data}`);
        yield put(createBaseOK(results.data));
    } catch (error) {
        yield put(createBaseError(error));
    }
}

export function* watchCreateBase(){
    yield takeLatest(BASE_CREATE_TRY, createBase);
}


function* createBaseSaga() {
    yield all([
        fork(watchCreateBase)
    ]);
  }


export default createBaseSaga;