export const MODULE = 'settings';

export const INITIAL_STATE_FILTER = {
    activeTab:1
  };
  
  export const INITIAL_STATE = {
    name: "",
    avatarBase64: null,
    avatar: null
  };