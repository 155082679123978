import React from "react";
import { Row, Col } from "reactstrap";
import CustomSelect from "../../../../components/Common/CustomSelect";
import CumstomField from "../../../../components/Common/CustomFields";

export const BaseForm = ({form,handleSubmit,disabled}) => {

  return (
      <Row>
        <Col md="4">
          <CumstomField
              {...form?.getInput("name")}
              label="Nombre"
              type="text"
              disabled={disabled}
              autoFocus={true}
            />
          <CustomSelect
              label="Categoría padre"
              {...form?.getSelect("parentCategory", "productcategory")}
              isClearable
              isDisabled={disabled}
            />



        </Col>
        <Col md="4">
         
          <CustomSelect
                label="Cuenta de crédito"
                {...form?.getSelect("creditPriceDiferenceAccount", "accountAccount")}
                isDisabled={disabled}
                isClearable
            />
          <CustomSelect
                label="Cuenta de egreso"
                {...form?.getSelect("expenseAccount", "accountAccount")}
                isDisabled={disabled}
                isClearable
            />
          <CustomSelect
                label="Cuenta de ingreso"
                {...form?.getSelect("incomeAccount", "accountAccount")}
                isDisabled={disabled}
                isClearable
            />

        </Col>
      </Row>
  );
};

export default BaseForm;
