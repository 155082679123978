import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Card, CardBody, Col, Row, Container, CardTitle } from "reactstrap"
import BarChart from "../../components/Charts/BarChart"
import Breadcrumb from "../../components/Common/Breadcrumb"
import CustomLink from "../../components/Common/CustomLink"
import useAPIFunctions from "../../hooks/useAPIFunctions"
import { setPageTitle } from "../../store/actions"
import LatestTransactions from "./LatestTransactions"

const PurchasesDashboard = props => {
  const [results, setResults] = useState([])

  const { doGetAsync } = useAPIFunctions()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle("Compras"))
    doFetch()
  }, [])

  const doFetch = async () => {
    try {
      const response = await doGetAsync(`/purchases/dashboard`)

      setResults(response)
    } catch (error) {
      console.log("doFetch", error.message)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumb />
          <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bxs-user" />
                      </span>
                    </div>
                    <CustomLink content="Proveedores" to={"/purchases/partner"}>
                      <h4 className="font-size-14 mb-0 text-primary">
                        Proveedores
                      </h4>
                    </CustomLink>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>
                      {results.data?.data?.activePartners} activos
                    </h5>
                    <div className="d-flex">
                      <span className="text-truncate">
                        ({results.data?.data?.inactivePartners} inactivos)
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <CustomLink
                      content="Nuevo proveedor"
                      to={"/purchases/partner/create"}
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Crear <i className="mdi mdi-arrow-right ms-1"></i>
                    </CustomLink>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-store-alt" />
                      </span>
                    </div>
                    <CustomLink
                      content="Productos comprables"
                      to={"/purchases/product"}
                    >
                      <h4 className="font-size-14 mb-0 text-primary">
                        Productos comprables
                      </h4>
                    </CustomLink>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>
                      {results.data?.data?.activeProducts} productos activos
                    </h5>
                    <div className="d-flex">
                      <span className="text-truncate">
                        ({results.data?.data?.inactiveProducts} inactivos)
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <CustomLink
                      content="Nuevo producto"
                      to={"/purchases/product/create"}
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Crear <i className="mdi mdi-arrow-right ms-1"></i>
                    </CustomLink>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-file" />
                      </span>
                    </div>
                    <CustomLink
                      content="Presupuestos de compra"
                      to={"/purchases/purchaseorder?draft=true&sent=true"}
                    >
                      <h4 className="font-size-14 mb-0 text-primary">
                        Presupuestos de compra
                      </h4>
                    </CustomLink>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>
                      {results.data?.data?.activePurchaseOrdersDraft} presupuestos
                      activos
                    </h5>
                    <div className="d-flex">
                      <p className="text-truncate">
                        {" "}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <CustomLink
                      content="Nuevo presupuesto"
                      to={"/purchases/purchaseorder/create?draft=true"}
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Crear <i className="mdi mdi-arrow-right ms-1"></i>
                    </CustomLink>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-file" />
                      </span>
                    </div>
                    <CustomLink
                      content="Pedidos de compra"
                      to={"/purchases/purchaseorder?purchase=true"}
                    >
                      <h4 className="font-size-14 mb-0 text-primary">
                        Pedidos de compra
                      </h4>
                    </CustomLink>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>{results.data?.data?.activePurchaseOrders} pedidos</h5>
                    <div className="d-flex">
                      <span className="text-truncate">
                        Saldo deudor $ {results.data?.data?.amountPurchaseOrders}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <CustomLink
                      content="Nuevo pedido de compra"
                      to={"/purchases/purchaseorder/create?purchase=true"}
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Crear <i className="mdi mdi-arrow-right ms-1"></i>
                    </CustomLink>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {results?.data?.data?.bestProducts?.categories?.length > 0 ? (
              <Col lg={results?.data?.data?.bestServices?.categories?.length > 0 ? 6 : 12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Productos más comprados del último mes
                    </CardTitle>
                    <BarChart data={results?.data?.data?.bestProducts} />
                  </CardBody>
                </Card>
              </Col>
            ) : null}

            {results?.data?.data?.bestServices?.categories?.length > 0 ? (
              <Col lg={results?.data?.data?.bestProducts?.categories?.length > 0 ? 6 : 12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                    Servicios más comprados del último mes
                    </CardTitle>
                    <BarChart data={results?.data?.data?.bestServices} />
                  </CardBody>
                </Card>
              </Col>
            ) : null}
            <Col lg="6">
              <LatestTransactions
                label="Últimos presupuestos de compra"
                transactions={results?.data?.data?.lastDrafts}
              />
            </Col>
            <Col lg="6">
              <LatestTransactions
                label="Últimos pedidos de compra"
                transactions={results?.data?.data?.lastPurchases}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PurchasesDashboard
