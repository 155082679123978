import { useEffect, useState } from "react"
import { createModal } from "react-modal-promise"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import useForm from "../../../../hooks/useForm"
import ErrorMessage from "../../../../components/Common/ErrorMessage"
import CustomSelect from "../../../../components/Common/CustomSelect"
import CustomField from "../../../../components/Common/CustomFields"
import client from "axios"
import apiCall from "../../../../api"
import { setApiError, setApiLoading } from "../../../../store/actions"
import useErrors from "../../../../hooks/useErrors"
import Cards from "react-credit-cards"
import "react-credit-cards/es/styles-compiled.css"

const DecidirPayment = ({
  amount,
  endPoint,
  privateKey,
  publicKey,
  isOpen,
  onResolve,
  onReject,
}) => {
  const reject = () => onReject()

  const [focus, setFocus] = useState("")
  const [maxCardLength, setMaxCardLength] = useState("16")

  const dispatch = useDispatch()
  const { error, loading } = useSelector(state => state.ApiStatus)

  const errorHook = useErrors()

  const [formState, setFormState] = useState({
    installments: 1,
  })
  const paymentForm = useForm({ formState })

  const handlePaymentSubmit = async (e, v) => {
    dispatch(setApiError(""))
    dispatch(setApiLoading(true))


    try {
      var requestData = paymentForm.fields

      if(requestData.paymentCard == null){
        dispatch(setApiError("Tarjeta inválida"))
        return
      }

      requestData.card_holder_identification = {
        number: requestData.number,
        type: "dni",
      }

      const headers = {
        "Content-Type": "application/json",
        apikey: publicKey,
      }

      client
        .post(endPoint + "/tokens", requestData, {
          headers: headers,
        })
        .then(response => {
          if (response.status == 201 || response.status == 200) {
            let requestData = {
              paymentMethodID:
                paymentForm.fields.paymentCard.decidiR_PaymentMethodID,
              tokenResponse: response.data,
              amount: amount,
              privateKey: privateKey,
              endPoint: endPoint,
            }
            apiCall
              .post(`/decidir/pay`, requestData)
              .then(response => {
                if (response.status == 201 || response.status == 200) {
                  onResolve(response.data)
                }
              })
              .catch(err => {
                dispatch(setApiError(errorHook.getErrors(err)))
              })
          }
        })
        .catch(err => {
          let msg = "No se pudo crear el token - "
          if (err.response) {
            if (err.response.status == 400) {
              msg = msg + "Request error"
            }
            if (err.response.status == 401) {
              msg = msg + "ApiKey inválido"
            }
            if (err.response.status == 402) {
              msg = msg + "Error por datos inválidos"
            }
            if (err.response.status == 404) {
              msg = msg + "Error con datos no encontrados"
            }
            if (err.response.status == 409) {
              msg = msg + "Error inesperado en la API REST"
            }
          }

          dispatch(setApiError(msg))
        })

      // const results = await apiCall.post(`/accountpayment`, requestData)

      dispatch(setApiLoading(false))
    } catch (error) {
      // dispatch(setApiError(errorsHook.getErrors(error)));
    }

    // onResolve(value)
  }

  const cardCallBack = (issuer, isValid) => {
    let paymentMethodID = 0
    let paymentMethodName = ""
    let decidirPaymentMethodID = 0

    if (issuer.issuer == "unknown") {
      paymentMethodID = 0
      paymentMethodName = "",
      decidirPaymentMethodID = 0
    }

    if (issuer.issuer == "visa") {
      paymentMethodID = 1
      paymentMethodName = "VISA"
      decidirPaymentMethodID = 1
    }

    if (issuer.issuer == "mastercard") {
      paymentMethodID = 3
      paymentMethodName = "MASTERCARD"
      decidirPaymentMethodID = 111
    }

    if (issuer.issuer == "maestro") {
      paymentMethodID = 5
      paymentMethodName = "MAESTRO"
      decidirPaymentMethodID = 106
    }

    if (paymentForm.fields?.paymentCard?.id != paymentMethodID) {
      setMaxCardLength(issuer.maxLength)

      setFormState({
        ...paymentForm.fields,
        ["paymentCard"]: { id: paymentMethodID, name: paymentMethodName, decidiR_PaymentMethodID : decidirPaymentMethodID},
      })

    }
    // console.log('issuer',issuer)
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={reject}>
      <ModalHeader toggle={reject}>DECIDIR</ModalHeader>
      <ModalBody>
        <ErrorMessage error={error} />
        <AvForm
          id="payment-form"
          className="needs-validation"
          onValidSubmit={handlePaymentSubmit}
        >
          <Row>
            <Col md="4">
              <Cards
                number={paymentForm.fields?.card_number ?? ""}
                name={paymentForm.fields?.card_holder_name}
                cvc={paymentForm.fields?.security_code}
                expiry={`${paymentForm.fields?.card_expiration_month ?? ""}/${
                  paymentForm.fields?.card_expiration_year ?? ""
                }`}
                focused={focus}
                locale={{ valid: "válido" }}
                placeholders={{ name: "" }}
                callback={cardCallBack}
              />

              {/* <CustomSelect
                className="mt-3"
                label=""
                required
                {...paymentForm?.getSelect("paymentCard", "paymentCard")}
                isDisabled={loading}
              /> */}
              {/* <Card>
                <CardBody>

                  <div className="card bg-info text-white visa-card mb-0">
                    <CardBody>
                      <div>
                        

                        <div className="float-end">
                          <i className="bx bxl-visa visa-logo display-3" />
                        </div>

                        <div>
                          <i className="bx bx-chip h1 text-warning" />
                        </div>
                      </div>

                      <Row className="mt-5">
                      <p className="font-size-20 align-middle">
                        {FormatCard(paymentForm.fields?.card_number)}
                      </p>
                      </Row>

                      <div className="mt-5">
                        <h5 className="text-white float-end mb-0">{paymentForm.fields?.card_expiration_month}/{paymentForm.fields?.card_expiration_year}</h5>
                        <h5 className="text-white mb-0">{paymentForm.fields?.card_holder_name?.toUpperCase()}</h5>
                      </div>
                    </CardBody>
                  </div>
                </CardBody>
              </Card> */}
            </Col>
            <Col md="4">
              <CustomField
                {...paymentForm?.getInput("card_number")}
                label="Número de tarjeta"
                type="text"
                pattern="\d*"
                maxLength={maxCardLength}
                disabled={loading}
                autoFocus={true}
                onFocus={() => setFocus("number")}
                required
              />
              <CustomField
                {...paymentForm?.getInput("card_holder_name")}
                label="Nombre y apellido"
                type="text"
                disabled={loading}
                required
              />
              <CustomField
                {...paymentForm?.getInput("card_expiration_month")}
                label="Més de expiración"
                type="text"
                pattern="\d*"
                maxLength="2"
                disabled={loading}
                required
              />
              <CustomField
                {...paymentForm?.getInput("card_expiration_year")}
                label="Año de expiración"
                type="text"
                pattern="\d*"
                maxLength="2"
                disabled={loading}
                required
              />
            </Col>
            <Col md="4">
              <CustomField
                {...paymentForm?.getInput("installments")}
                label="Cuotas"
                pattern="\d*"
                type="text"
                disabled={loading}
                maxLength="2"
                required
              />
              <CustomField
                {...paymentForm?.getInput("number")}
                label="DNI"
                type="number"
                disabled={loading}
                required
              />
              <CustomField
                {...paymentForm?.getInput("security_code")}
                label="Código de seguridad"
                type="text"
                pattern="\d*"
                maxLength="3"
                disabled={loading}
                onFocus={() => setFocus("cvc")}
                required
              />
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        {!loading && (
          <button
            type="button"
            onClick={() => {
              reject()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Cancelar
          </button>
        )}

        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          onClick={handlePaymentSubmit}
          disabled={loading}
        >
          {loading && (
            <i className="bx bx-loader-alt bx-spin font-size-16 align-middle mr-2"></i>
          )}
          Pagar
        </button>
      </ModalFooter>
    </Modal>
  )
}

export const decidirPaymentModal = createModal(DecidirPayment)
