import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import base64 from "base-64"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

//Include Both Helper File with needed methods
//import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postJwtLogin  } from '../../../helpers/fakebackend_helper';

//const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history, to } }) {
    try {
        const { data } = yield call(postJwtLogin, '/user/login', {email: user.email, password: user.password, company: user.company});
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("menu", JSON.stringify(data.menu));
        localStorage.setItem("token", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);
        
        if(to== null || to == ''){
            if(data.homepage == null){
                history.replace('/dashboard');
            }else{
                history.replace(data.homepage);
            }
            
        }else{
            to= base64.decode(to)

            history.replace(to);
            
        }
        
        yield put(loginSuccess(response));
         
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
         localStorage.removeItem("user");
         localStorage.removeItem("menu");
         localStorage.removeItem("token");
         localStorage.removeItem("refreshToken");

        //  if(process.env.REACT_APP_DEFAULTAUTH === 'firebase')
        //  {
        //    const response = yield call(fireBaseBackend.logout);
        //    yield put(logoutUserSuccess(response));
        //  }
        window.location.href = "/login";
        //history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;