import useMenu from "../../../hooks/useMenu";

const useProductActions = (id) => {
  
  const { GoTo } = useMenu();

  const doViewStock = async () => {
    
    GoTo('Stock a mano',`/inventory/product/${id}?view=stock`,true);
  };

  return {
    doViewStock
  };
};

export default useProductActions;
