import  { useEffect } from "react";

import {
  MODEL,
  BASE_URL,
  INDEX_TITLE,
  INITIAL_STATE_FILTER,
} from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import moment from "moment";
import AccountPaymentCustomFilters from "./components/AccountPaymentCustomFilters";
import useQueryURL from "../../../hooks/useQueryURL";
import {Badge} from "reactstrap";
import CustomLink from "../../../components/Common/CustomLink";

const Index = (props) => {
  const dispatch = useDispatch();
  const { filter, setFilter, queryURL } = useQueryURL(INITIAL_STATE_FILTER);

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  }, []);

  const columns = [
    {
      name: "ID",
      label: "Nombre",
      width: 30,
    },
    {
      name: "PaymentDate",
      label: "Fecha de pago",
      width: 20,
      type: "date"
    },
    {
      name: "PartnerName",
      label: filter?.out ? "Proveedor" : "Cliente",
      width: 20,
      type: "string"
    },
    {
      name: "PaymentMethodName",
      label: "Método de pago",
      width: 20,
      type: "string"
    },
    {
      name: "Amount",
      label: "Monto",
      width: 10,
      type: "number"
    },
    {
      name: "State",
      label: "Estado",
      width: 20,
    },
  ];

  const rows = (data) => {
    const rows = [];

    const r = data?.map((p, index) => {
      const fields = [];

      fields.push(<CustomLink 
        content={p.name}
        to={`${BASE_URL}/${p.id}${queryURL}`}>{p.name}</CustomLink>);

      fields.push(<span>{moment(p.paymentDate).format("DD/MM/YYYY")}</span>);

      fields.push(
        <span>
          <CustomLink 
          content={p.partnerName}
          to={`/sales/partner/${p.partnerID}`}>{p.partnerName}</CustomLink>
        </span>
      );

      fields.push(
        <span>
          {p.paymentMethodName}
        </span>
      );

      fields.push(<span>$ {p.amount}</span>);

      switch (p.state) {
        case 1:
          fields.push(
            <Badge className="me-1 rounded-pill bg-info font-size-11">
              Borrador
            </Badge>
          );

          break;
        case 2:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">
              Validado
            </Badge>
          );
          break;
        case 3:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">
              Conciliado
            </Badge>
          );
          break;
        case 5:
          fields.push(
            <Badge className="me-1 rounded-pill bg-info font-size-11">
              Cancelado
            </Badge>
          );
          break;
        case 6:
          fields.push(
            <Badge className="me-1 rounded-pill bg-warning font-size-11">
              Pendiente
            </Badge>
          );

          break;
        default:
          break;
      }


      rows.push({ fields: fields });
    });

    return rows;
  };

  const customFilters = (
    <AccountPaymentCustomFilters filter={filter} setFilter={setFilter} columns={columns} />
  );

  const actionButtons = <IndexActionButtons url={BASE_URL} queryURL={queryURL} />;

  return (
    <FormLayout actionButtons={actionButtons}>
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{orderCriteria: "ID", orderDirection: 1}}
      />
    </FormLayout>
  );
};

export default Index;
