import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames';

const StatusBar = (props) => {

    const { state } = props;

    return(
            
    <Nav pills className="bg-light rounded d-none d-xl-flex">
    <NavItem>
        <NavLink
            className={classnames({ active: state === 1 })}
        >
            Borrador
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: state === 4 })}
        >
            En espera
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: state === 6 })}
        >
            Preparado
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: state === 7 })}
        >
            Hecho
        </NavLink>
    </NavItem>
    
    {state === 2 ? (
        <NavItem>
        <NavLink
            className={classnames({ active: state === 2 })}
        >
            Cancelado
        </NavLink>
    </NavItem>
    ) : null}
</Nav>
    )
}

export default StatusBar