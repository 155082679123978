import React, { useEffect, useState } from "react"

import useForm from "../../../hooks/useForm"
import BaseForm from "./components/BaseForm"
import {
  MODEL,
  INITIAL_STATE,
  CREATE_TITLE,
  INITIAL_STATE_FILTER,
} from "./components/consts"
import { MODULE } from "../consts"
import DetailsPage from "../../../components/Actions/DetailsPage"
import { useDispatch, useSelector } from "react-redux"
import StatusBar from "./components/StatusBar"
import { setApiLoading } from "../../../store/actions"
import apiCall from "../../../api"
import SaleOrderAmounts from "./components/SaleOrderAmounts"
import SaleOrderActions from "./components/SaleOrderActions"
import SaleOrderDropdown from "./components/SaleOrderDropdown"
import useQueryURL from "../../../hooks/useQueryURL"
import moment from "moment"

const Details = () => {
  const [formState, setFormState] = useState([])
  const form = useForm({ formState })
  const { queryURL } = useQueryURL(INITIAL_STATE_FILTER)
  const { action } = useSelector(state => state.FormActionStatus)
  const dispatcher = useDispatch()


  useEffect(() => {
    if (action === "edit" || action === "create") {

      const controller = new AbortController()

      const fetchPartner = async () => {
        if (form.fields?.partner == null) {
          setFormState({
            ...form.fields,
            ["priceList"]: { id: 1, name: "Tarifa pública" },
          })
          return
        }
        dispatcher(setApiLoading(true))
    
        var request = {
          id: form.fields?.partner?.id,
        }
    
        try {
          const results = await apiCall.post(`/partner/fetchpartner`, request,controller.signal)
    
          setFormState({
            ...form.fields,
            ["priceList"]: results.data?.data?.priceList,
            ["accountPaymentTerm"]: results.data?.data?.accountPaymentTerm,
          })
        } catch (error) {
          console.log("fetchPartner", error.message)
        }
        finally{
          dispatcher(setApiLoading(false))
        }
      }

      fetchPartner()

      return () => controller.abort()
    }
  }, [form.fields.partner])

  



  useEffect(() => {
    if (action === "edit" || action === "create") {
      if (form.fields.accountPaymentTerm?.due) {
        var mydate = moment(form.fields.dateOrder).format("YYYY-MM-DD")

        setFormState({
          ...form.fields,
          ["dueDate"]: moment(mydate)
            .add(form.fields.accountPaymentTerm?.due, "days")
            .format("YYYY-MM-DD"),
        })
      } else {
        setFormState({
          ...form.fields,
          ["dueDate"]: moment(form.fields.dateOrder).format("YYYY-MM-DD"),
        })
      }
    }
  }, [form.fields.accountPaymentTerm])

  const statusBar = (
    <StatusBar mobile={true} saleOrderState={form.fields?.state} />
  )
  const dropdownSecondary = (
    <SaleOrderDropdown id={form.fields?.id} form={form} />
  )


  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      setFormState={setFormState}
      statusBar={statusBar}
      dropdownSecondary={dropdownSecondary}
      defaultValues
      defaultValuesParams={queryURL}
    >

      <SaleOrderActions form={form} />
      <BaseForm
        form={form}
        setFormState={setFormState}
        disabled={action != "edit" && action != "create" ? true : false}
      />
      <hr />
      <SaleOrderAmounts form={form} setFormState={setFormState} />
    </DetailsPage>
  )
}

export default Details
