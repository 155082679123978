import { MODULE } from "../../consts";

export const MODEL = 'billingtypeconfig';

export const INDEX_TITLE = 'Controladores fiscales';
export const CREATE_TITLE = 'Nuevo controlador fiscal';

export const INITIAL_STATE = {
    id: 0,
    name: "",
    billingType: 0,
    connectionType: 0,
    urL_WSAA: "https://wsaa.afip.gov.ar/ws/services/LoginCms?WSDL",
    urL_USFE: "https://servicios1.afip.gov.ar/wsfev1/service.asmx?WSDL",
    pathProductionCert: "",
    pathDeveloperCert: "",
    pathProductionAccesTicket: "",
    pathDeveloperAccesTicket: "",
    comPortNumber: 1,
    redIPAddress: "",
    redPort: 0,
    developmentMode: false
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const BILLINGTYPE_ENUM = [
  { value: 0, label: 'Ninguna'},
  { value: 1, label: 'Factura electrónica'},
  { value: 2, label: 'HASAR-T'},
  { value: 3, label: 'EPSON-T'},
];

export const CONNECTIONTYPE_ENUM = [
  { value: 0, label: 'USB'},
  { value: 1, label: 'COM'},
  { value: 2, label: 'RED'},
];

