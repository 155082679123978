import React from "react"
import { Container, Row, Col, Card } from "reactstrap"
import Breadcrumb from "./Breadcrumb"

const FormLayout = props => {
  const { actionButtons, secondaryActions, children, modal } = props

  return modal ? (
    <Card>{children}</Card>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb />
          <div className="d-flex flex-row justify-content-between mb-1">
            {actionButtons ?? null}

            {secondaryActions ?? null}
          </div>

          <Row>
            <Col lg="12">
              <Card>{children}</Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormLayout
