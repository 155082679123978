import React from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import CustomDateTime from "../../../../components/Common/CustomDateTime";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomRadioButton from "../../../../components/Common/CustomRadioButton";
import CustomMultiSelect from "../../../../components/Common/CustomMultiSelect";
import SmartButtons from "./SmartButtons";
import { productModal } from "../../../Inventory/Product/components/ProductModal"
import Container from "react-modal-promise";

export const BaseForm = ({ form, handleSubmit, disabled, setFormState }) => {

  return (
    <React.Fragment>
      <Container/>
       <Row>
        <Col md="4">
    
        <CumstomField
            {...form?.getInput("name")}
            label="Referencia del inventario"
            type="text"
            disabled={disabled}
            autofocus={true}
            required
          />

    <CustomMultiSelect
          label="Ubicaciones"
          {...form?.getMultiSelect("stockInventoryLocationsRel", "stockLocation", "withPrefix=true&usage=2")}
          isClearable={false}
          isDisabled={disabled || form.fields?.state != 1}
          required
        />

    <CustomMultiSelect
          label="Productos"
          {...form?.getMultiSelect("stockInventoryProductsRel", "product","",productModal,{active: true})}
          isClearable={false}
          isDisabled={disabled || form.fields?.state != 1}
          required
        />
           
           
        </Col>
       
        <Col md="4">
        <CustomDateTime
            label="Fecha de inventario"
            disabled={true}
            {...form?.getInputDate("date", "dateIni")}
          />

      <FormGroup className="mb-4">
            <Label>Cantidades contadas</Label>
            <CustomRadioButton
              label="Predeterminado a stock disponible"
              disabled={disabled || form.fields?.state != 1}
              {...form?.getRadio("type",1)}
            />
            <CustomRadioButton
              label="Predeterminado a cero"
              disabled={disabled || form.fields?.state != 1}
              {...form?.getRadio("type",2)}
            />
          </FormGroup>
        </Col>

        <Col lg="4">
          <SmartButtons form={form} />
        </Col>
      </Row>
    </React.Fragment>
     
  );
};

export default BaseForm;
