import { useEffect, useState } from "react"

import useForm from "../../../hooks/useForm"
import BaseForm from "./components/BaseForm"
import {
  MODEL,
  INITIAL_STATE,
  CREATE_TITLE,
  INITIAL_STATE_FILTER,
} from "./components/consts"
import { MODULE } from "../consts"
import DetailsPage from "../../../components/Actions/DetailsPage"
import { useDispatch, useSelector } from "react-redux"
import StatusBar from "./components/StatusBar"
import { setApiLoading } from "../../../store/actions"
import apiCall from "../../../api"
import PurchaseOrderAmounts from "./components/PurchaseOrderAmounts"
import PurchaseOrderActions from "./components/PurchaseOrderActions"
import PurchaseOrderDropdown from "./components/PurchaseOrderDropdown"
import useQueryURL from "../../../hooks/useQueryURL"
import moment from "moment"

const Details = () => {
  const [formState, setFormState] = useState([])
  const form = useForm({ formState })
  const { action } = useSelector(state => state.FormActionStatus)
  const { queryURL } = useQueryURL(INITIAL_STATE_FILTER)
  const dispatcher = useDispatch()

  console.log('form', form.fields);

  useEffect(() => {
    if (action === "edit" || action === "create") {
      fetchPartner()
    }
  }, [form.fields.partner])

  useEffect(() => {
    if (action === "edit" || action === "create") {
      if (form.fields.accountPaymentTerm?.due) {

        var mydate = moment(form.fields.dateOrder).format('YYYY-MM-DD');

        setFormState({
          ...form.fields,
          ["dueDate"]: moment(mydate).add(form.fields.accountPaymentTerm?.due,'days').format("YYYY-MM-DD")
        });
      } else {
        setFormState({
          ...form.fields,
          ["dueDate"]: form.fields.dateOrder,
        })
      }
    }
  }, [form.fields.accountPaymentTerm])


  const fetchPartner = async () => {
    if (form.fields?.partner == null) {
      setFormState({
        ...form.fields,
        ["priceList"]: { id: 1, name: "Tarifa pública" },
      })
      return
    }
    dispatcher(setApiLoading(true))

    var request = {
      id: form.fields?.partner?.id,
    }

    try {
      const results = await apiCall.post(`/partner/fetchpartner`, request)

      dispatcher(setApiLoading(false))
      setFormState({
        ...form.fields,
        ["priceList"]: results.data?.data?.priceList,
        ["accountPaymentTerm"]: results.data?.data?.accountPaymentTerm,
      })
    } catch (error) {
      dispatcher(setApiLoading(false))
      console.log("fetchPartner", error.message)
    }
  }

  const statusBar = <StatusBar state={form.fields?.state} />
  const dropdownSecondary = <PurchaseOrderDropdown id={form.fields?.id} />

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      setFormState={setFormState}
      statusBar={statusBar}
      dropdownSecondary={dropdownSecondary}
      defaultValues
      defaultValuesParams={queryURL}
    >
      <PurchaseOrderActions form={form} />

      <BaseForm
        form={form}
        setFormState={setFormState}
        disabled={action != "edit" && action != "create" ? true : false}
      />
      <hr />
      <PurchaseOrderAmounts form={form} setFormState={setFormState} />
    </DetailsPage>
  )
}

export default Details
