import { useEffect, useState } from "react"

import {  useParams } from "react-router-dom"
import { MODEL, INITIAL_STATE_FILTER } from "./components/consts"
import { useDispatch } from "react-redux"
import { setPageTitle } from "../../../store/pagetitle/actions"
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons"
import IndexTable from "./components/IndexTable";

const Index = props => {
  const { module } = useParams()

  const [filter, setFilter] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (module === "sales") {
      dispatch(setPageTitle("Clientes"))
    }

    if (module === "purchases") {
      dispatch(setPageTitle("Proveedores"))
    }

    setFilter({
      ...filter,
      active: true,
      customer: module === "sales" ?? true,
      supplier: module === "purchases" ?? true,
      customFilters: []
    })
  }, [module])

  if (module != "sales" && module != "purchases") return null

  const actionButtons = <IndexActionButtons url={`/${module}/${MODEL}`} />


  return (
      <IndexTable
        filter={filter}
        setFilter={setFilter}
        actionButtons={actionButtons}
        module={module}
      />
  )
}

export default Index
