import React, { useEffect, useState } from "react"

import { Link, useParams } from "react-router-dom"
import {
  MODEL,
  BASE_URL,
  INDEX_TITLE,
  MODULE,
  INITIAL_STATE_FILTER,
} from "./components/consts"
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable"
import FormLayout from "../../../components/Common/FormLayout"
import { useDispatch } from "react-redux"
import { setPageTitle } from "../../../store/pagetitle/actions"
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons"
import CustomFilters from "./components/CustomFilters"
import CustomLink from "../../../components/Common/CustomLink"

const Index = props => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(null)


  const columns = [
    {
      name: "Name",
      label: "Nombre",
      width: 20,
    },
  ]

  const rows = data => {
    const rows = []

    const r = data?.map((p, index) => {
      const fields = []

      fields.push(
        <CustomLink content={p.name} to={`${BASE_URL}/${p.id}`}>{`${p.name}`}</CustomLink>
      )

      rows.push({ fields: fields })
    })

    return rows
  }

  const customFilters = (
    <CustomFilters filter={filter} setFilter={setFilter} />
  )

  const actionButtons = <IndexActionButtons url={BASE_URL} />

  return (
    <FormLayout actionButtons={actionButtons}>
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={null}
        filter={{
          customFilters: [],
        }}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
      />
    </FormLayout>
  )
}

export default Index
