import React from "react"
import { useKeyUp } from "../../../../hooks/useKeyUp";

const Index = props => {

  const {setMode } = props;

  useKeyUp(() => setMode(0), ["Escape"])

  return (
    <div className="partnerlist-screen screen">
      <div className="screen-content">
        <div className="top-content">
          <div
            className="button new-customer highlight"
            role="img"
            aria-label="Add a customer"
            title="Añada el cliente"
          >
            {" "}
            Crear
          </div>
          <div className="button back" onClick={e => setMode(0)}> Descartar</div>
          <div className="top-right-buttons">
            <div className="search-bar-container sb-partner">
              <div className="pos-search-bar">
                <i className="fa fa-search"></i>
                <input placeholder="Buscar clientes..." size="1" />
                <i className="fa fa-times"></i>
              </div>
            </div>
          </div>
        </div>
        <section className="full-content">
          <div className="partner-window">
            <section className="subwindow collapsed">
              <div className="subwindow-container collapsed"></div>
            </section>
            <section className="subwindow list">
              <div className="subwindow-container">
                <div className="subwindow-container-fix scrollable-y">
                  <table className="partner-list">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Dirección</th>
                        <th className="partner-line-email">Contacto</th>
                        <th className="partner-line-details"></th>
                        <th className="partner-line-last-column-placeholder oe_invisible"></th>
                      </tr>
                    </thead>
                    <tbody className="partner-list-contents">
                      <tr className="partner-line highlight" data-id="7">
                        <td>
                          <div>Administrator</div>
                          <div className="company-field"></div>
                          <div className="unselect-tag">
                            <i className="fa fa-times"></i>
                            <span> deseleccionar </span>
                          </div>
                        </td>
                        <td></td>
                        <td className="partner-line-email">
                          <div className="email-field">
                            <i className="fa fa-fw fa-paper-plane-o"></i>
                            admin@example.com
                          </div>
                        </td>
                        <td className="edit-partner-button-cell">
                          <button className="edit-partner-button">DETALLES</button>
                        </td>
                        <td className="partner-line-last-column-placeholder oe_invisible"></td>
                      </tr>
                      <tr className="partner-line" data-id="10">
                        <td>
                          <div>AFIP</div>
                          <div className="company-field"></div>
                        </td>
                        <td></td>
                        <td className="partner-line-email"></td>
                        <td className="edit-partner-button-cell">
                          <button className="edit-partner-button">DETALLES</button>
                        </td>
                        <td className="partner-line-last-column-placeholder oe_invisible"></td>
                      </tr>
                      <tr className="partner-line" data-id="8">
                        <td>
                          <div>Consumidor Final Anónimo</div>
                          <div className="company-field"></div>
                        </td>
                        <td></td>
                        <td className="partner-line-email"></td>
                        <td className="edit-partner-button-cell">
                          <button className="edit-partner-button">DETALLES</button>
                        </td>
                        <td className="partner-line-last-column-placeholder oe_invisible"></td>
                      </tr>
                      <tr className="partner-line" data-id="3">
                        <td>
                          <div>Elio AC</div>
                          <div className="company-field"></div>
                        </td>
                        <td></td>
                        <td className="partner-line-email">
                          <div className="email-field">
                            <i className="fa fa-fw fa-paper-plane-o"></i>
                            elio.ac13@gmail.com
                          </div>
                        </td>
                        <td className="edit-partner-button-cell">
                          <button className="edit-partner-button">DETALLES</button>
                        </td>
                        <td className="partner-line-last-column-placeholder oe_invisible"></td>
                      </tr>
                      <tr className="partner-line" data-id="9">
                        <td>
                          <div>IIBB a pagar</div>
                          <div className="company-field"></div>
                        </td>
                        <td></td>
                        <td className="partner-line-email"></td>
                        <td className="edit-partner-button-cell">
                          <button className="edit-partner-button">DETALLES</button>
                        </td>
                        <td className="partner-line-last-column-placeholder oe_invisible"></td>
                      </tr>
                      <tr className="partner-line" data-id="1">
                        <td>
                          <div>valsoft</div>
                          <div className="company-field"></div>
                        </td>
                        <td>Argentina</td>
                        <td className="partner-line-email">
                          <div className="email-field">
                            <i className="fa fa-fw fa-paper-plane-o"></i>
                            elio.ac13@gmail.com
                          </div>
                        </td>
                        <td className="edit-partner-button-cell">
                          <button className="edit-partner-button">DETALLES</button>
                        </td>
                        <td className="partner-line-last-column-placeholder oe_invisible"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Index
