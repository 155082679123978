import { BASE_DT_TRY,BASE_DT_ERROR, BASE_DT_OK } from "./actionTypes"

export const getDataTable = (target) => {
;    return {
        type: BASE_DT_TRY,
        payload : {target}
    }
}

export const getDataTableOK = (data) => {
    return {
        type: BASE_DT_OK,
        payload: data
    }
}

export const getDataTableError = (error) => {
    return {
        type: BASE_DT_ERROR,
        payload: error
    }
}
