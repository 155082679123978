import { useState, useEffect } from "react"
import apiCall from "../api"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  setApiError,
  setApiLoading,
  setValidationError,
} from "../store/actions"

const useAPI = (url, method = "get", body = null, manual = false) => {
  let history = useHistory()

  const [result, setResult] = useState(null)

  const dispatcher = useDispatch()

  let apiAction

  useEffect(() => {
    if (manual) return

    if (body === null && method === "post") {
      return
    }

    if (url !== "") {
      doFetch()
    }
  }, [url, body])

  const doFetch = async () => {
    // if(loading) return;
    if (url == "") return

    dispatcher(setApiLoading(true))

    switch (method) {
      case "get":
        apiAction = async () => await apiCall.get(url)
        break
      case "post":
        apiAction = async () => await apiCall.post(url, body)
        break
      case "delete":
        apiAction = async () => await apiCall.delete(url)
        break
      default:
        break
    }

    return await apiAction()
      .then(res => {
        if (res.data.ok) {
          //dispatcher(setApiError(''));
          setResult(res.data)

          if (res.data.message != "") {
            dispatcher(setApiError(res.data.message))
          }
        } else {
          dispatcher(setApiError(res.data.errors))
        }
        dispatcher(setApiLoading(false))
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            history.push("/404")
          }

          // if(err.response.status === 401){
          //     history.replace('/login');
          // }
          if (err.response.status === 401) {
            dispatcher(setApiError("La sesión ha caducado"))
            return;
          }

          var message = ""
          var errorMessage = err.message

          for (var k in err.response?.data?.errors) {
            message += err.response.data.errors[k][0] + "\n"
          }

          if (err.response?.data?.detail) {
            errorMessage = err.response?.data?.detail
          }

          dispatcher(setApiError(errorMessage))

          if (message != "") {
            dispatcher(setValidationError(message))
          }
        } else if (err.request) {
          dispatcher(setApiError(err.message))
        } else {
          dispatcher(setApiError(err.message))
        }

        dispatcher(setApiLoading(false))
      })
  }

  return {
    result,
    doRefresh: doFetch,
  }
}

export default useAPI
