import { useDispatch } from "react-redux";
import apiCall from "../../../api";
import useAPIFunctions from "../../../hooks/useAPIFunctions";
import { setFormAction, setFormRefresh, setApiLoading } from "../../../store/actions";

const usePurchaseOrderActions = (id) => {
  const { doGetAsync } = useAPIFunctions();
  const dispatch = useDispatch();

  const doConfirm = async () => {
    try {
      const results = await doGetAsync(`/purchaseorder/confirm/${id}`);

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
    } catch (error) {
      console.log("Confirm", error.message);
    }
  };

  const doPrint = async (name) => {

    dispatch(setApiLoading(true))

    try {
      await apiCall.getFile(
        `/purchaseorder/report/${id}`,
        `${name}.pdf`
      );

      dispatch(setApiLoading(false))
    } catch (error) {
      console.log("Report", error.message);
      dispatch(setApiLoading(false))
    }
  };

  const doSendMail = async () => {
    try {
      const results = await doGetAsync(`/purchaseorder/sendmail/${id}`);

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
      
    } catch (error) {
      console.log("SendMail", error.message);
    }
  };

  return {
    doConfirm,
    doPrint,
    doSendMail,
  };
};

export default usePurchaseOrderActions;
