import React from "react"
import { Card, CardBody, CardTitle} from "reactstrap"
import CustomLink from "../../components/Common/CustomLink"
import moment from "moment";

const LatestTransactions = props => {
  const { transactions } = props

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Próximos vencimientos</CardTitle>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Comprobante</th>
                  <th className="align-middle">Nombre</th>
                  <th className="align-middle">Total</th>
                  <th className="align-middle">Vence</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.map((t, key) => (
                  <tr key={"_tr_" + key}>
                    <td>

                      <CustomLink
                        content={t.name}
                        to={`/invoices/accountinvoice/${t.id}`}
                      >
                        <span className="fw-bold text-primary">
                        {t.name}
                      </span>
                      </CustomLink>
                      
                    </td>
                    <td>
                      {t.partnerID ? (
                        <CustomLink
                          content={t.partnerName}
                          to={`/sales/partner/${t.partnerID}`}
                        >
                          {t.partnerName}
                        </CustomLink>
                      ) : null}
                    </td>
                    <td>$ {t.total}</td>
                    <td>{moment(t.dueInvoice).format("DD/MM/YYYY")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default LatestTransactions
