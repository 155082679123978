import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Col, Modal } from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import useDynamicTable from "../../../../../hooks/useDynamicTable"
import { setApiError, setFormBounceEdit } from "../../../../../store/actions"
import CustomSelect from "../../../../../components/Common/CustomSelect"
import searchAsync from "../../../../../api/searchAsync"
import SimpleBar from "simplebar-react"
import apiCall from "../../../../../api"

const MenuTable = props => {
  const dispatcher = useDispatch()

  const { form, setFormState, disabled } = props

  const [modal, setModal] = useState(false)
  const [menu, setMenu] = useState(null)

  const updateFields = async (name, index, value) => {
    let tempItems = [...form.fields?.rolesMenus]

    let tempItem = {
      ...tempItems[index],
      [name]: value,
    }

    tempItems[index] = tempItem

    setFormState({ ...form.fields, rolesMenus: tempItems })
  }

  useEffect(() => {
    if (modal) {
      fetchMenu()
    }
  }, [modal])

  const fetchMenu = async () => {
    try {
      dispatcher(setApiError(""))

      const { data } = await apiCall.get(`/menu/getallungroup`)

      for (var i = 0; i < data.data.length; i++) {

        let find = form.fields?.rolesMenus.find((r) => r.menuID == data.data[i].id)

        data.data[i].checked = false
        if(find){
          data.data[i].checked = true
        }
        
      }
      setMenu(data.data)
    } catch (error) {
      dispatcher(setApiError(error.message))
    }
  }

  const dynamicTable = useDynamicTable(
    form,
    setFormState,
    "rolesMenus",
    disabled,
    updateFields
  )

  const onSelect = () => {
    let items = [];

    for (var i = 0; i < menu.length; i++) {
      if (menu[i].checked) {
        let item = {
          menuID: menu[i].id,
          menuName: menu[i].treeName,
        }

        items.push(item);
      }
    }

    setFormState({ ...form.fields, rolesMenus: items })
    setModal(false)
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setModal(!modal)
    removeBodyCss()
  }

  const onCheck = index => {
    debugger

    let tempItems = [...menu]

    let tempItem = {
      ...menu[index],
      ["checked"]: !menu[index].checked,
    }

    tempItems[index] = tempItem

    setMenu(tempItems)
  }

  return (
    <React.Fragment>
      <Col xs="12">
        <div
          className="table-responsive overflow-visible"
          onClick={() => {
            if (disabled) {
              dispatcher(setFormBounceEdit(true))
            }
          }}
        >
          <Table className="table mb-0">
            <Thead className="table-light">
              <Tr>
                <Th width="30%">Menu</Th>
                <Th width="%5"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {dynamicTable.items?.map((p, key) => (
                <Tr key={"_product_" + key}>
                  <Th>{p.menuName}</Th>

                  <Td>
                    {disabled ? null : (
                      <a
                        href="#"
                        onClick={() => dynamicTable.removeItem(key)}
                        className="text-danger"
                      >
                        <i className="mdi mdi-close font-size-18 mr-3"></i>
                      </a>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Col>
      {disabled ? null : (
        <Col lg="4" sm="12">
          {dynamicTable.editable === "select" ? (
            <CustomSelect
              name="menu"
              value={null}
              customRef={dynamicTable.selectRef}
              onFocusOut={() => dynamicTable.setEditable(null)}
              onChange={onSelect}
              loadOptions={inputValue => {
                return searchAsync("menu", inputValue, "")
              }}
            />
          ) : (
            <a
              className="text-primary"
              onClick={() => {
                setModal(true)
              }}
            >
              Agregar línea
            </a>
          )}
        </Col>
      )}
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          tog_large()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Seleccionar menu
          </h5>

          <button
            onClick={() => {
              setModal(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <React.Fragment>
            {menu?.length > 0 ? (
              <SimpleBar style={{ maxHeight: "390px" }}>
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="align-middle"></th>
                        <th className="align-middle">Nombre</th>
                      </tr>
                    </thead>
                    <tbody>
                      {menu?.map((m, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="form-check font-size-16">
                                <input
                                  type="checkbox"
                                  checked={menu[index].checked}
                                  onChange={() => onCheck(index)}
                                  className="form-check-input"
                                />
                                <label className="form-check-label">
                                  &nbsp;
                                </label>
                              </div>
                            </td>
                            <td>{m.treeName}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </SimpleBar>
            ) : null}
          </React.Fragment>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_large()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Cancelar
          </button>

          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={onSelect}
          >
            Aceptar
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default MenuTable
