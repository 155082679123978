import React from "react"
import { useDispatch } from "react-redux"
import { Col } from "reactstrap"
import apiCall from "../../../../api"
import DynamicTableField from "../../../../components/Common/DynamicTable/DynamicTableField"
import { setApiLoading, setFormBounceEdit } from "../../../../store/actions"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import useDynamicTable from "../../../../hooks/useDynamicTable"

const ProductStockQuantTable = props => {
  const dispatcher = useDispatch()

  const { form, setFormState, disabled } = props

  const updateFields = async (name, index, value) => {
    let tempItems = [...form.fields?.stockQuants]

    let tempItem = {
      ...tempItems[index],
      [name]: value,
    }

    tempItems[index] = tempItem

    setFormState({ ...form.fields, stockQuants: tempItems })
  }

  const dynamicTable = useDynamicTable(
    form,
    setFormState,
    "stockQuants",
    disabled,
    updateFields
  )

  const fetchStock = async index => {

    let tempItems = [...form.fields?.stockQuants]

    let line = tempItems[index]

    let request = {
      stockLocationID: line.stockLocationID,
      productID: line.productID,
    }

    try {
      dispatcher(setApiLoading(true))

      const { data } = await apiCall.post(`/stockquant/fetch`, request)

      dispatcher(setApiLoading(false))

      line["theoreticalQty"] = data?.data
      line["warning"] = false

      tempItems[index] = line

      setFormState({ ...form.fields, stockInventoryLines: tempItems })
    } catch (error) {
      dispatcher(setApiLoading(false))
      console.log("fetchItem", error.message)
    }
  }

  return (
    <React.Fragment>
      <Col xs="12">
        <div
          className="table-responsive overflow-visible"
          onClick={() => {
            if (disabled) {
              dispatcher(setFormBounceEdit(true))
            }
          }}
        >
          <Table className="table mb-0">
            <Thead className="table-light">
              <Tr>
                <Th width="20%">Ubicación</Th>
                <Th width="10%">Cantidad disponible</Th>
                <Th width="10%">Unidad de medida</Th>
                <Th width="10%">Valor</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dynamicTable.items?.map((p, key) => (
                <Tr key={"_product_" + key}>
                  <Td>
                    <span className="d-block text-muted text-truncate field-muted">
                      {p.location?.name}
                    </span>
                  </Td>
                  <Td onClick={() => dynamicTable.onClick("quantity", key)}>
                    <DynamicTableField
                      type="number"
                      {...dynamicTable?.getInput("quantity", key)}
                    />
                  </Td>
                  <Td>
                    <span className="d-block text-muted text-truncate field-muted">
                      {p.product.productUnitOfMeasure?.name}
                    </span>
                  </Td>
                  <Td>
                    <span className="d-block text-muted text-truncate field-muted">
                      {p.quantity * form.fields?.standardPrice}
                    </span>
                  </Td>
                  
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default ProductStockQuantTable
