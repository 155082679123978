import {  useState } from "react"

import { useParams, withRouter } from "react-router-dom"
import { MODEL, INITIAL_STATE_FILTER } from "./components/consts"
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable"
import FormLayout from "../../../components/Common/FormLayout"
import { useDispatch } from "react-redux"
import AccountCustomFilters from "./components/AccountCustomFilters"
import CustomLink from "../../../components/Common/CustomLink"

const Account = props => {
  const { module } = useParams()

  const [filter, setFilter] = useState(INITIAL_STATE_FILTER)
  const [previewImage, setPreviewImage] = useState(null)
  const [balance, setBalance] = useState(null);
  const dispatch = useDispatch()

  const columns = [
    {
      name: "Name",
      label: "Nombre",
      width: 20,
      string: "name"
    },
    {
      name: "Email",
      label: "Email",
      width: 10,
      type: "string"
    },
    {
      name: "Phone",
      label: "Teléfono",
      width: 10,
      type: "string"
    },
    {
      name: "CreditLimit",
      label: "Crédito disponible",
      width: 10,
      type : "number"
    },
    {
      name: "PositiveBalance",
      label: "Saldo facturas",
      width: 10,
      type: "number"
    },
    {
      name: "PositiveSaleBalance",
      label: "Saldo pedidos",
      width: 10,
      type: "number"
    },
    {
      name: "NegativeBalance",
      label: "Saldo acreedor",
      width: 10,
      type: "number"
    },
    {
      name: "Balance",
      label: "Saldo",
      width: 10,
      sum: balance,
      type: "number"
    },
  ]

  const rows = data => {
    const rows = []

    const r = data?.map((p, index) => {
      const fields = []

      var inputName = p.name.split(" ")

      fields.push(
        <CustomLink
          content="Facturas pendientes"
          to={`/invoices/accountInvoice?moveType=1&partner=${p.id}&open=true`}
        >
          <div className="d-flex align-items-center">
            <span className="me-2">
              {p.logo == null ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {inputName[0].charAt(0)}
                    {inputName.length > 1 ? inputName[1].charAt(0) : null}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    onClick={() => {
                      setPreviewImage(p.logo)
                    }}
                    className="rounded-circle avatar-xs"
                    src={p.logo}
                    alt=""
                  />
                </div>
              )}
            </span>
            {p.name}
          </div>
        </CustomLink>
      )

      fields.push(<span>{p.email}</span>)

      fields.push(<a href={`tel:${p.phone}`}>{p.phone}</a>)

      fields.push(<span>$ {p.creditLimit}</span>)

      fields.push(
        <CustomLink
          content={`Facturas pendientes de ${p.name}`}
          to={`/invoices/accountInvoice?moveType=1&partner=${p.id}&open=true`}
        >
          <span>$ {p.positiveBalance.toFixed(2)}</span>
        </CustomLink>
      )

      fields.push(
        <CustomLink
          content={`Pedidos pendientes de ${p.name}`}
          to={`/sales/saleOrder?partner=${p.id}&sale=true&done=true&balance=true&onlyMe=false`}
        >
          <span>$ {p.positiveSaleBalance.toFixed(2)}</span>
        </CustomLink>
      )
      fields.push(
        <CustomLink
        content={`Pagos de ${p.name}`}
          to={`/invoices/accountPayment?customer=true&partner=${p.id}&posted=true&balance=true`}
        >
          <span>$ {p.negativeBalance.toFixed(2)}</span>
        </CustomLink>
      )
      fields.push(
        <span>$ {p.balance.toFixed(2)}</span>
      )

      setBalance(data?.reduce(function(prev, cur) {
        return prev + cur.balance;
      }, 0));

      rows.push({ fields: fields })
    })

    return rows
  }

  const customFilters = (
    <AccountCustomFilters filter={filter} setFilter={setFilter} columns={columns}/>
  )

  return (
    <FormLayout>
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={filter}
        customFilters={customFilters}
        customURL={`/partner/loadtableaccount`}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
      />
    </FormLayout>
  )
}

export default Account
