import React from "react"
import AsyncSelect from "react-select/async"
import { Label } from "reactstrap"

const CustomMultiSelect = props => {
  const {
    name,
    value,
    label,
    isClearable,
    isDisabled,
    onChange,
    loadOptions,
    onFocusOut
  } = props

  // const colourStyles = {
  //   control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     const color = chroma(data.color);
  //     return {
  //       ...styles,
  //       backgroundColor: isDisabled
  //         ? undefined
  //         : isSelected
  //         ? data.color
  //         : isFocused
  //         ? color.alpha(0.1).css()
  //         : undefined,
  //       color: isDisabled
  //         ? '#ccc'
  //         : isSelected
  //         ? chroma.contrast(color, 'white') > 2
  //           ? 'white'
  //           : 'black'
  //         : data.color,
  //       cursor: isDisabled ? 'not-allowed' : 'default',
  
  //       ':active': {
  //         ...styles[':active'],
  //         backgroundColor: !isDisabled
  //           ? isSelected
  //             ? data.color
  //             : color.alpha(0.3).css()
  //           : undefined,
  //       },
  //     };
  //   },
  //   multiValue: (styles, { data }) => {
  //     const color = chroma(data.color);
  //     return {
  //       ...styles,
  //       backgroundColor: color.alpha(0.1).css(),
  //     };
  //   },
  //   multiValueLabel: (styles, { data }) => ({
  //     ...styles,
  //     color: data.color,
  //   }),
  //   multiValueRemove: (styles, { data }) => ({
  //     ...styles,
  //     color: data.color,
  //     ':hover': {
  //       backgroundColor: data.color,
  //       color: 'white',
  //     },
  //   }),
  // };

  return (
    <div className="mb-0">
      {label ? <Label>{label}</Label> : null}
      {isDisabled ? (
        <span className="d-block text-muted text-truncate field-muted">{value?.map(e => e.name).join(", ")}</span>
      ) : (
        <AsyncSelect
          isMulti
          name={name}
          value={value}
          onChange={onChange}
          className="react-select"
          classNamePrefix="react-select"
          isClearable={isClearable}
          isDisabled={isDisabled}
          defaultOptions
          placeholder=""
          cacheOptions
          onBlur={onFocusOut}
          loadOptions={loadOptions}
          getOptionLabel={e => e.name}
          getOptionValue={e => e.id}
          // styles={colourStyles}
        />
      )}
    </div>
  )
}

export default CustomMultiSelect
