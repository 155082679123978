import React from "react";

const CustomRadioButton = (props) => {
  const { name, label, disabled, value, checked, onChange } = props;

  return (
    <div className="form-check mb-1">
      <input
        type="radio"
        id={`${name}${value}`}
        name={name}
        className="form-check-input"
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={`${name}${value}`}>
        {label}
      </label>
    </div>
  );
};

export default CustomRadioButton;
