import { MODEL, BASE_URL, MODULE } from "./consts"
import CustomDatatable from "../../../../components/Common/CustomDatatable/CustomDatatable"
import FormLayout from "../../../../components/Common/FormLayout"
import ProductCustomFilters from "./ProductCustomFilters"
import CustomLink from "../../../../components/Common/CustomLink"
import { useRef, useState } from "react"
import { ProductFields } from "../ProductFields"
import BarcodeReader from "react-barcode-reader"
import CustomBadge from "../../../../components/Common/CustomBadge"

const IndexTable = props => {
  const { filter, actionButtons, setFilter, modal, setValue } = props

  const [searchBy, setSearchBy] = useState(null)

  const exportRef = useRef()

  const rows = data => {
    const rows = []

    const r = data?.map((p, index) => {
      const fields = []

      fields.push(
        <CustomLink
          content={p.name}
          to={`${BASE_URL}/${p.id}`}
          disabled={modal}
        >{`[${p.id}]${p.name}`}</CustomLink>
      )

      fields.push(<span>$ {p.listPrice}</span>)

      fields.push(<span>$ {p.standardPrice}</span>)

      fields.push(
        <CustomLink
          content={p.productCategoryName}
          to={`/${MODULE}/productcategory/${p.productCategoryID}`}
          disabled={modal}
        >
          {p.productCategoryName}
        </CustomLink>
      )

      fields.push(
        <div>
          {(p.productTags || []).map((tag, key) => (
            <CustomBadge key={key} color={tag.color}>
              {tag.name}
            </CustomBadge>
          ))}
        </div>
      )

      fields.push(
        <CustomLink
          content={`Stock - ${p.name}`}
          to={`/inventory/stockquant?internal=true&product=${p.id}`}
          disabled={modal}
        >
          {p.stock}
        </CustomLink>
      )

      if (p.productUomID) {
        fields.push(
          <CustomLink
            content={p.productUomName}
            to={`/${MODULE}/productmeasureunit/${p.productUomID}`}
            disabled={modal}
          >
            {p.productUomName}
          </CustomLink>
        )
      }

      rows.push({ fields: fields })
    })

    return rows
  }

  const handleError = err => {
    console.log(err)
  }

  const handleScan = async data => {
    setSearchBy(data)
  }

  const onExport = () => {
    if (exportRef.current) {
      exportRef.current.show("product", "Productos")
    }
  }

  const customFilters = (
    <ProductCustomFilters
      filter={filter}
      setFilter={setFilter}
      columns={ProductFields}
      isModal={modal}
    />
  )

  return (
    <FormLayout actionButtons={actionButtons} modal={modal}>
      <CustomDatatable
        model={MODEL}
        checks
        columns={ProductFields}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        modal={modal}
        setValue={setValue}
        defaultSort={{ orderCriteria: "Name", orderDirection: 0 }}
        exportRef={exportRef}
        onExport={onExport}
        onSearchBy={searchBy}
      />
      <BarcodeReader onError={handleError} onScan={handleScan} minLength={8} />
    </FormLayout>
  )
}

export default IndexTable
