import React from "react";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import useProductActions from "../Actions";

const ActionButtons = (props) => {
  const {  form } = props;
  const { doViewStock } = useProductActions(form.fields.id);

  const { loading } = useSelector(state => state.ApiStatus);

  return (
    <Row className="mb-2">
      {form.fields?.id != 0 ? (
        <Col>
            {/* <Button
              color="primary"
              className="btn btn-primary btn-sm me-2"
              onClick={(e) => {
                e.preventDefault();
                doViewStock();
              }}
              disabled={loading}
            >
              Actualizar cantidad
            </Button> */}
        </Col>
      ) : null}
    </Row>
  );
};

export default ActionButtons;
