import {
  BASE_GET_TRY,
  BASE_GET_ERROR,
  BASE_GET_OK,
} from "./actionTypes";

export const getBase = (target,id) => {
  return {
    type: BASE_GET_TRY,
    payload: {target, id },
  };
};

export const getBaseOK = (data) => {
  return {
    type: BASE_GET_OK,
    payload: data,
  };
};

export const getBaseError = (error) => {
  return {
    type: BASE_GET_ERROR,
    payload: error,
  };
};
