import React, { useEffect } from "react"
import { useState } from "react"
import { Row, Col, Modal, Button, FormGroup, Input, Label } from "reactstrap"
import useForm from "../../../../hooks/useForm"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import ErrorMessage from "../../../../components/Common/ErrorMessage"
import CustomDateTime from "../../../../components/Common/CustomDateTime"
import CumstomField from "../../../../components/Common/CustomFields"
import { useDispatch, useSelector } from "react-redux"
import CustomSelect from "../../../../components/Common/CustomSelect"
import CustomRadioButton from "../../../../components/Common/CustomRadioButton"
import {
  setApiError,
  setApiLoading,
  setFormRefresh,
  setValidationError,
} from "../../../../store/actions"
import apiCall from "../../../../api"
import useErrors from "../../../../hooks/useErrors"
import { decidirPaymentModal } from "./DecidirPayment"

export const AccountPaymentHelper = props => {
  const { form, date, model, partnerType, paymentType, forInvoice } = props

  const errorsHook = useErrors()

  const [paymentMethod, setPaymentMethod] = useState("cash")
  const dispatch = useDispatch()
  const { error, loading, validationError } = useSelector(
    state => state.ApiStatus
  )
  const [modal, setModal] = useState(false)

  const [formState, setFormState] = useState([])
  const paymentForm = useForm({ formState })

  useEffect(() => {
    if (paymentForm.fields?.paymentMethod) {
      setFormState({
        ...paymentForm.fields,
        ["accountJournal"]: forInvoice
          ? paymentForm.fields?.paymentMethod.accountJournal
          : paymentForm.fields?.paymentMethod.saleOrderAccountJournal,
      })
    }
  }, [paymentForm.fields?.paymentMethod])

  useEffect(() => {
    if (modal) {
      setFormState({
        originalAmount: form.fields.balance,
        amount: form.fields.balance,
        description: form.fields.name,
        paymentMethod: {
          id: "1",
          name: "Efectivo",
        },
        partner: form.fields.partner,
        [`${model}ID`]: form.fields.id,
        state: 2,
        paymentDate: date,
        diffAction: 1,
        partnerType: partnerType,
        paymentType: paymentType,
      })
    }
  }, [modal])

  useEffect(() => {
    var diffAmount =
      paymentForm.fields.originalAmount - paymentForm.fields.amount

    if (diffAmount === 0) {
      setFormState({
        ...paymentForm.fields,
        ["amountDiff"]: diffAmount,
        ["diffAction"]: 1,
      })
    } else {
      setFormState({
        ...paymentForm.fields,
        ["amountDiff"]: diffAmount,
        ["diffAction"]: 2,
      })
    }
  }, [paymentForm.fields.amount])

  useEffect(() => {
    (async () => {
      let paymentMethodID = 0

      if (paymentMethod == "card") {
        paymentMethodID = 2
      }

      if (paymentMethod == "cash") {
        paymentMethodID = 1
      }

      let request = {
        id: paymentMethodID,
      }
      try {
        const results = await apiCall.post(`/paymentmethod/fetch`, request)

        setFormState({
          ...paymentForm.fields,
          ["paymentMethod"]: results.data?.data,
        })
      } catch (error) {
        console.log("fetchPaymentMethod", error.message)
      }
    })()
  }, [paymentMethod])

  const handlePaymentSubmit = async (e, v) => {
    try {
      var requestData = {
        data: paymentForm.fields,
      }

      dispatch(setApiError(""))
      dispatch(setValidationError(""))

      if (paymentForm.fields.paymentMethod.decidiR_PublicKey) {
        try {
          const paymentResult = await decidirPaymentModal({
            amount: paymentForm.fields.amount,
            endPoint: paymentForm.fields.paymentMethod.decidiR_EndPoint,
            privateKey: paymentForm.fields.paymentMethod.decidiR_PrivateKey,
            publicKey: paymentForm.fields.paymentMethod.decidiR_PublicKey,
          })

          debugger

          requestData.data.gateway = "DECIDIR"
          requestData.data.gatewayID = paymentResult.data
        } catch {
          return
        }
      }

      dispatch(setApiLoading(true))

      const results = await apiCall.post(`/accountpayment`, requestData)

      tog_large()

      dispatch(setApiLoading(false))
      dispatch(setFormRefresh(true))
    } catch (error) {
      dispatch(setApiError(errorsHook.getErrors(error)))
    }
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setModal(!modal)
    removeBodyCss()
  }

  return (
    <React.Fragment>
      <Button
        color="primary"
        className="btn btn-secondary btn-sm me-1"
        onClick={e => {
          tog_large()
        }}
        disabled={loading}
      >
        Pagar
      </Button>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <Modal
          size="lg"
          isOpen={modal}
          toggle={() => {
            tog_large()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Registrar pago
            </h5>
            <button
              onClick={() => {
                setModal(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ErrorMessage error={error} />
            <AvForm
              id="payment-form"
              className="needs-validation"
              onValidSubmit={handlePaymentSubmit}
            >
              <Row>
                <Col lg="3" sm="4">
                  <Label className="card-radio-label mb-3">
                    <Input
                      type="radio"
                      name="paymentmethod"
                      value="cash"
                      className="card-radio-input"
                      checked={paymentMethod == "cash"}
                      onClick={() => setPaymentMethod("cash")}
                    />

                    <div className="card-radio">
                      <i className="far fa-money-bill-alt font-size-24 text-primary align-middle me-2" />

                      <span>Efectivo</span>
                    </div>
                  </Label>
                </Col>
                <Col lg="3" sm="4">
                  <Label className="card-radio-label mb-3">
                    <Input
                      type="radio"
                      name="paymentmethod"
                      value="card"
                      className="card-radio-input"
                      checked={paymentMethod == "card"}
                      onClick={() => setPaymentMethod("card")}
                    />

                    <div className="card-radio">
                      <i className="fab fa-cc-mastercard font-size-24 text-primary align-middle me-2" />

                      <span>Tarjeta</span>
                    </div>
                  </Label>
                </Col>
                <Col lg="3" sm="4">
                  <Label className="card-radio-label mb-3">
                    <Input
                      type="radio"
                      name="paymentmethod"
                      value="other"
                      className="card-radio-input"
                      checked={paymentMethod == "other"}
                      onClick={() => setPaymentMethod("other")}
                    />

                    <div className="card-radio">
                      <i className="fab fa-cc-mastercard font-size-24 text-primary align-middle me-2" />

                      <span>Otro</span>
                    </div>
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <CumstomField
                    {...paymentForm?.getInput("amount")}
                    label="Monto"
                    type="number"
                    disabled={loading}
                    required
                  />

                  {paymentMethod == "other" && (
                    <CustomSelect
                      label="Método de pago"
                      required
                      {...paymentForm?.getSelect(
                        "paymentMethod",
                        "paymentMethod"
                      )}
                      isDisabled={loading}
                    />
                  )}

                  <CustomSelect
                    label="Diario de pago"
                    {...paymentForm?.getSelect(
                      "accountJournal",
                      "accountJournal",
                      `types=3,4,5,7&forInvoice=${forInvoice}`
                    )}
                    isDisabled={loading}
                  />
                </Col>
                <Col md="6">
                  <CustomDateTime
                    label="Fecha de pago"
                    disabled={loading}
                    required
                    {...paymentForm?.getInputDate("date", "paymentDate")}
                  />
                  <CumstomField
                    {...paymentForm?.getInput("description")}
                    label="Concepto"
                    disabled={true}
                  />
                </Col>
              </Row>

              <hr />
              <Row>
                <Col lg="4">
                  <FormGroup className="mb-4">
                    <CumstomField
                      {...paymentForm?.getInput("amountDiff")}
                      label="Diferencia"
                      type="number"
                      disabled={true}
                    />
                    <CustomRadioButton
                      label="Mantener abierto"
                      disabled={paymentForm.fields.amountDiff === 0}
                      {...paymentForm?.getRadio("diffAction", 2)}
                    />
                    <CustomRadioButton
                      label="Marcar como totalmente pagado"
                      disabled={paymentForm.fields.amountDiff === 0}
                      {...paymentForm?.getRadio("diffAction", 1)}
                    />
                  </FormGroup>
                  {paymentForm.fields.amountDiff !== 0 &&
                  paymentForm.fields.diffAction == 1 ? (
                    <React.Fragment>
                      <CustomSelect
                        label="Contabilizar diferencia en"
                        {...paymentForm?.getSelect(
                          "accountAccount",
                          "accountAccount"
                        )}
                        isDisabled={loading}
                      />
                      <CumstomField
                        {...paymentForm?.getInput("accountingDescription")}
                        label="Descripción"
                        disabled={loading}
                      />
                    </React.Fragment>
                  ) : null}
                </Col>
              </Row>
            </AvForm>
          </div>
          <div className="modal-footer">
            {loading ? null : (
              <button
                type="button"
                onClick={() => {
                  tog_large()
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            )}

            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={handlePaymentSubmit}
              disabled={loading}
            >
              {loading ? (
                <i className="bx bx-loader-alt bx-spin font-size-16 align-middle mr-2"></i>
              ) : null}
              Validar
            </button>
          </div>
        </Modal>
      </Col>
    </React.Fragment>
  )
}

export default AccountPaymentHelper
