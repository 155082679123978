import React, { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap";


import classnames from "classnames";
import GeneralTab from "./Tabs/GeneralTab";
import SalesTab from "./Tabs/SalesTab";
import PurchasesTab from "./Tabs/PurchasesTab";
import InvoicesTab from "./Tabs/InvoicesTab";
import AccountingTab from "./Tabs/AccountingTab";

const ProductTabs = (props) => {
  const { form, disabled, setFormState } = props;
  const [customActiveTab, setcustomActiveTab] = useState("1");

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  }

  return (
    <Col lg={12} className="mt-1">
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "1",
            })}
            onClick={() => {
              toggleCustom("1");
            }}
          >
            <span className="d-sm-block">General</span>
          </NavLink>
        </NavItem>
        {form.fields?.saleOK ? (
          <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "2",
            })}
            onClick={() => {
              toggleCustom("2");
            }}
          >
            <span className="d-sm-block">Ventas</span>
          </NavLink>
        </NavItem>
        ) : null}
        
        {form.fields?.purchaseOK ? (
          <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "3",
            })}
            onClick={() => {
              toggleCustom("3");
            }}
          >
            <span className="d-sm-block">Compras</span>
          </NavLink>
        </NavItem>
        ) : null}
        
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "4",
            })}
            onClick={() => {
              toggleCustom("4");
            }}
          >
            <span className="d-sm-block">Facturación</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "5",
            })}
            onClick={() => {
              toggleCustom("5");
            }}
          >
            <span className="sm-block">Contabilidad</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={customActiveTab}>
        <GeneralTab form={form} disabled={disabled} />
        <SalesTab form={form} setFormState={setFormState} disabled={disabled} />
        <PurchasesTab form={form} setFormState={setFormState} disabled={disabled} />
        <InvoicesTab form={form} disabled={disabled} />
        <AccountingTab form={form} disabled={disabled} />
      </TabContent>
    </Col>
  );
};

export default ProductTabs;
