import { BASE_DT_TRY, BASE_DT_OK, BASE_DT_ERROR } from "./actionTypes";

const initialState = {
    data: [],
    error : '',
    loading: false
}

const dataTable = (state = initialState, action) => {
    switch (action.type) {
        case BASE_DT_TRY:
            state = {
                ...state,
                loading: true
            }
            break;
        case BASE_DT_OK:
                state = {
                    ...state,
                    loading: false,
                    data: action.payload.data
                }
                break;
        case BASE_DT_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default dataTable;