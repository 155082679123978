import moment from "moment"
import React, { useEffect } from "react"
import { Col, Row, Table } from "reactstrap"
import CumstomField from "../../../../components/Common/CustomFields"

const PurchaseOrderAmounts = props => {
  const { form, setFormState } = props

  useEffect(() => {
    if (form.fields?.purchaseOrderItems == null) return

    var netAmount = 0
    var taxAmount = 0
    var discountAmount = 0
    var totalAmount = 0

    var index = 0

    debugger

    for (index = 0; index < form.fields?.purchaseOrderItems.length; index++) {
      netAmount = netAmount + form.fields?.purchaseOrderItems[index].netAmount
      taxAmount = taxAmount + form.fields?.purchaseOrderItems[index].taxAmount
      discountAmount =
        discountAmount + form.fields?.purchaseOrderItems[index].discountAmount
      totalAmount =
        totalAmount + form.fields?.purchaseOrderItems[index].totalAmount
    }

    setFormState({
      ...form.fields,
      netAmount: netAmount,
      taxAmount: taxAmount,
      discountAmount: discountAmount,
      totalAmount: totalAmount,
    })
  }, [JSON.stringify(form.fields?.purchaseOrderItems)])

  return (
    <div className="table-responsive">
      <Table className="table table-borderless table-nowrap mb-0">
        <thead>
          <tr>
            <th className="text-end"></th>
            <th className="text-end" style={{ width: "70px" }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-end">Base imponible</td>
            <td className="text-end">$ {form.fields?.netAmount?.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="text-end">Impuestos</td>
            <td className="text-end">$ {form.fields?.taxAmount?.toFixed(2)}</td>
          </tr>
          {form.fields?.discountAmount > 0 ? (
            <tr>
              <td className="text-end">Descuentos</td>
              <td className="text-end">
                $ {form.fields?.discountAmount?.toFixed(2)}
              </td>
            </tr>
          ) : null}

          <tr>
            <td className="border-0 text-end">
              <strong>Total</strong>
            </td>
            <td className="border-0 text-end">
              <h4 className="m-0">$ {form.fields?.totalAmount?.toFixed(2)}</h4>
            </td>
          </tr>
          {form.fields?.accountPaymentItems?.map((payment, index) => (
            <tr key={index}>
              <td className="text-end fst-italic">
                Pagado{" "}
                {moment(payment.accountPayment?.paymentDate).format(
                  "DD/MM/YYYY"
                )}
              </td>
              <td className="text-end fst-italic">
                $ {payment.amount?.toFixed(2)}
              </td>
            </tr>
          ))}
          {form.fields?.accountPaymentItems?.length > 0 ? (
            <tr>
              <td className="border-0 text-end">
                <strong>Importe adeudado</strong>
              </td>
              <td className="border-0 text-end">
                <h4 className="m-0">$ {form.fields?.balance?.toFixed(2)}</h4>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </div>
  )
}

export default PurchaseOrderAmounts
