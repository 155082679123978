import { MODULE } from "../../consts";

export const MODEL = 'accountmoveline';

export const INDEX_TITLE = 'Movimiento de productos';
export const CREATE_TITLE = 'Nuevo tipo de operación';

export const INITIAL_STATE = {
    quantity: 0,
    reserved: 0,
    product: null,
    location: null,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


export const INITIAL_STATE_FILTER = {
  onlyMe: true,
  netvalue: false,
  expense: false,
  income: false,
  liabilities: false,
  asset: false,
  customFilters: []
};
