import apiCall from "../api";
import { useDispatch} from "react-redux";
import { setApiError, setApiLoading, setValidationError } from "../store/actions";

const useAPIFunctions = () => {

  const dispatch = useDispatch();

 
  const doGetAsync = async (url) => {
    
    try {
  
      dispatch(setApiError(""));
      dispatch(setValidationError(""));
      dispatch(setApiLoading(true));
  
      const results = await apiCall.get(url);
  
      dispatch(setApiLoading(false));
  
      return results;
    } catch (error) {
      
  
      var message = "";
      var errorMessage=error.message;

      for (var k in error.response?.data?.errors) {
        message += error.response.data.errors[k][0] + "\n";
      }
      
      if(error.response?.data?.detail){
        errorMessage= error.response?.data?.detail;
      }

      dispatch(setApiError(errorMessage));

      if(message != ''){
        dispatch(setValidationError(message));
      }
      
      throw error;
    }
  
  };

  return {
    doGetAsync : doGetAsync
  };
};

export default useAPIFunctions;
