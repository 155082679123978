import Index from "..";
import Details from "../Details";
import { MODEL, MODULE } from "./consts";

export const AccountPaymentTermRoutes =[
  { path: `/${MODULE}/${MODEL}/:id`, exact: true, component: Details },
  { path: `/${MODULE}/${MODEL}`, exact: true, component: Index },
];

export default AccountPaymentTermRoutes;
