import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {  MODEL, BASE_URL, INDEX_TITLE, STOCKINVENTORY_STATE_ENUM, INITIAL_STATE_FILTER  } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import moment from "moment";
import { getLabelFromArray } from "../../../helpers/array_utils";
import StockInventoryCustomFilters from "./components/StockInventoryCustomFilters";
import useQueryURL from "../../../hooks/useQueryURL";
import CustomLink from "../../../components/Common/CustomLink";
import { Badge } from "reactstrap";

const Index = (props) => {

  const dispatch = useDispatch();
  const {filter, setFilter, queryURL} = useQueryURL(INITIAL_STATE_FILTER);

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        name: "Reference",
        label: "Referencia",
        width: 30,
      },
      {
        label: "Fecha del inventario",
        width: 30,
      },
      {
        name: "State",
        label: "Estado",
        width: 30,
      },
     
    ];

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
    

      fields.push(
        <CustomLink 
        content={p.name}
        to={`${BASE_URL}/${p.id}`}>{p.name}</CustomLink>
      );

      fields.push(
        <span>
        {moment(p.DateIni).format("DD/MM/YYYY hh:mm:ss")}</span>
      );
      
      switch (p.state) {
        case 1:
          fields.push(
            <Badge className="me-1 rounded-pill bg-info font-size-11">Borrador</Badge>
          );
          break;
        case 2:
          fields.push(
            <Badge className="me-1 rounded-pill bg-warning font-size-11">En progreso</Badge>
          );
          break;
        case 3:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">Validado</Badge>
          );
          break;
        case 4:
          fields.push(
            <Badge className="me-1 rounded-pill bg-danger font-size-11">Cancelado</Badge>
          );
          break;
        default:
          break;
      }

      rows.push({fields : fields});
    });

    return rows;
  };

  const customFilters = (
    <StockInventoryCustomFilters
      filter={filter}
      setFilter={setFilter}
    />
  );

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{orderCriteria: "ID", orderDirection: 1}}
      />
    </FormLayout>
  );
};

export default Index;
