import { Row, Col, FormGroup, Label } from "reactstrap"
import CustomDateTime from "../../../../components/Common/CustomDateTime"
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect"
import CumstomField from "../../../../components/Common/CustomFields"
import CustomLink from "../../../../components/Common/CustomLink"
import CustomRadioButton from "../../../../components/Common/CustomRadioButton"
import CustomSelect from "../../../../components/Common/CustomSelect"
import { ACCOUNTPAYMENT_PARTNERTYPE_ENUM } from "./consts"
import SmartButtons from "./SmartButtons"
import { partnerModal } from "../../../Core/Partner/components/PartnerModal"
import Container from "react-modal-promise";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  const readOnlyStates = [2, 4, 5,6]

  return (
    <Row>
      <Container />
      <Col md="4">
        <FormGroup className="mb-4">
          <Label>Tipo de pago</Label>
          <CustomRadioButton
            label="Enviar dinero"
            disabled={disabled || readOnlyStates.includes(form.fields?.state)}
            {...form?.getRadio("paymentType", 2)}
          />
          <CustomRadioButton
            label="Recibir dinero"
            disabled={disabled || readOnlyStates.includes(form.fields?.state)}
            {...form?.getRadio("paymentType", 1)}
          />
        </FormGroup>

        <CustomEnumSelect
          label="Tipo de partner"
          {...form?.getEnumSelect(
            "partnerType",
            ACCOUNTPAYMENT_PARTNERTYPE_ENUM
          )}
          isDisabled={disabled || readOnlyStates.includes(form.fields?.state)}
        />

        <CustomSelect
          key={JSON.stringify(form.fields?.partnerType)}
          label="Cliente/Proveedor"
          {...form?.getSelect(
            "partner",
            "partner",
            `partnerType=${form.fields?.partnerType}`,
            partnerModal,{active: true, customer: form.fields?.partnerType === 1 ? true : false,supplier: form.fields?.partnerType === 2 ? true : false}
          )}
          isDisabled={disabled || readOnlyStates.includes(form.fields?.state)}
          required
        />

        <CustomSelect
          label="Método de pago"
          {...form?.getSelect("paymentMethod", "paymentMethod")}
          isDisabled={disabled || readOnlyStates.includes(form.fields?.state)}
          required
        />
        {form.fields?.itsForInvoice != null && (
          <CustomSelect
          required
          label="Diario de pago"
          {...form?.getSelect(
            "accountJournal",
            "accountJournal",
            `types=3,4,5,7&forInvoice=${form.fields?.itsForInvoice}`
          )}
          isDisabled={disabled || readOnlyStates.includes(form.fields?.state)}
        />
        )}
        

        <CumstomField
          {...form?.getInput("amount")}
          label="Monto"
          type="number"
          disabled={disabled || readOnlyStates.includes(form.fields?.state)}
          required
        />
      </Col>

      <Col md="4">
        <CustomDateTime
          label="Fecha de pago"
          disabled={disabled || readOnlyStates.includes(form.fields?.state)}
          {...form?.getInputDate("date", "paymentDate")}
        />
        <CumstomField
          {...form?.getInput("description")}
          label="Concepto"
          disabled={disabled || readOnlyStates.includes(form.fields?.state)}
        />
        {form.fields?.accountMove ? (
          <FormGroup className="mb-4">
          <Label>Asiento contable</Label>
          <CustomLink
            content={form.fields.accountMove.name}
            to={`/accounting/accountmove/${form.fields.accountMove.id}`}
          ><p>{form.fields.accountMove.name}</p></CustomLink>
        </FormGroup>
        ) : null}
      </Col>

      <Col md="4">
        <SmartButtons form={form} />
      </Col>
    </Row>
  )
}

export default BaseForm
