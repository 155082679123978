import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import SwitchCompanies from "../../Common/SwitchCompanies";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"

  const { user } = props;
  
  const [switchCompaniesModal, setSwitchCompaniesModal] = useState(false)

  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" /> */}

          {user?.avatar == null ? (
            <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Avatar"
          />
          ) : (
            <img
              className="rounded-circle header-profile-user"
              src={user.avatar}
              alt="Avatar"
            />
          )}

          <span className="d-none d-xl-inline-block ms-2 me-1">{user?.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu end>
        <DropdownItem tag="a" 
          href="#"
          onClick={() => setSwitchCompaniesModal(true)}
          >
            <i className="bx bx-data font-size-16 align-middle me-1"></i>
            {user?.company.name}
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem tag="a" href="#">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"></i>
            {"Perfil"}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {"Lock screen"}
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            <span>{"Salir"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <SwitchCompanies
        modal={switchCompaniesModal}
        setModal={setSwitchCompaniesModal}
      />
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(React.memo(ProfileMenu))
);
