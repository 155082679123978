import React from "react"
import { Card, CardBody, CardTitle  } from "reactstrap"
import CustomLink from "../../components/Common/CustomLink"
import moment from "moment";

const LatestTransactions = props => {
  const { label, transactions } = props

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">{label}</CardTitle>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Nombre</th>
                  <th className="align-middle">Fecha</th>
                  <th className="align-middle">Proveedor</th>
                  <th className="align-middle">Comercial</th>
                  <th className="align-middle">Total</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.map((t, key) => (
                  <tr key={"_tr_" + key}>
                    <td>
                    <CustomLink
                          content={t.name}
                          to={`/purchases/purchaseorder/${t.id}`}
                        >
                          <span className="text-primary fw-bold">
                        {t.name}
                      </span>
                        </CustomLink>
                      
                    </td>
                    <td>{moment(t.dateOrder).format("DD/MM/YYYY hh:mm")}</td>
                    <td>
                      {t.partnerID ? (
                        <CustomLink
                          content={t.partnerName}
                          to={`/purchases/partner/${t.partnerID}`}
                        >
                          {t.partnerName}
                        </CustomLink>
                      ) : null}
                    </td>
                    <td>
                      {t.userID ? (
                        <CustomLink
                          content={t.userName}
                          to={`/settings/user/${t.userID}`}
                        >
                          {t.userName}
                        </CustomLink>
                      ) : null}
                    </td>
                    <td>$ {t.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default LatestTransactions
