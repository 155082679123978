import { MODULE } from "../../consts";

export const MODEL = 'user';

export const INDEX_TITLE = 'Usuarios';
export const CREATE_TITLE = 'Nuevo usuario';

export const INITIAL_STATE = {
    name: "",
    email: "",
    password: "",
    active: true
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

