import React from "react"
import { Col } from "reactstrap"
import apiCall from "../../../../api";
import { useDispatch } from "react-redux"
import { setApiError, setApiLoading, setFormRefresh } from "../../../../store/actions";
import useErrors from "../../../../hooks/useErrors";

function AccountPaymentCredit(props) {
  const { form } = props
  const dispatcher = useDispatch()
  const errorHook = useErrors();

  const fetchPay = async index => {

    let tempItems = [...form.fields?.creditPayments]

    let line = tempItems[index]

    let request = {
      accountInvoiceID: form.fields?.id,
      accountPaymentID: line.id,
    }

    try {
      dispatcher(setApiLoading(true))

      const { data } = await apiCall.post(`/accountPayment/pay`, request)

      dispatcher(setApiLoading(false))

      dispatcher(setFormRefresh(true));

    } catch (error) {
      dispatcher(setApiLoading(false))
      dispatcher(setApiError(errorHook.getErrors(error)));
      console.log("fetchItem", error.message)
    }
  }

  return (
    <Col lg="6">
      {form.fields?.creditPayments &&
      form.fields?.invoiceState == 1 &&
      form.fields?.balance > 0 ? (
        <React.Fragment>
          <strong>Créditos pendientes</strong>
          {form.fields?.creditPayments?.map((payment,key) => (
            <div key={key} className="d-flex flex-row justify-content-start">
              <div className="p-1">{payment.name}</div>
              <div className="p-1">$ {payment.balance?.toFixed(2)}</div>
              <a onClick={() => fetchPay(key)}>
                <div className="p-1 text-primary">Añadir</div>
              </a>
            </div>
          ))}
        </React.Fragment>
      ) : null}
    </Col>
  )
}

export default AccountPaymentCredit
