import { MODULE } from "../../consts";

export const MODEL = 'stockpicking';

export const INDEX_TITLE = 'Transferencias';
export const CREATE_TITLE = 'Nueva transferencia';

export const INITIAL_STATE = {
    name: '',
    reference: '',
    origin: '',
    stockPickingType: null,
    stockLocationIni: null,
    stockLocationDest: null,
    dateDone: null,
    scheduledDate: null,
    saleOrder: null,
    partner: null,
    deliveryCarrier: null,
    productMeasureUnit: null,
    stockMove: null
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const STOCKPICKING_STATE_ENUM = [
  { value: 1, label: 'Nuevo'},
  { value: 2, label: 'Cancelado'},
  { value: 3, label: 'Esperando otro movimiento'},
  { value: 4, label: 'Esperando disponibilidad'},
  { value: 5, label: 'Parcialmente disponible'},
  { value: 6, label: 'Preparado'},
  { value: 7, label: 'Hecho'},
];

export const INITIAL_STATE_FILTER = {
  confirmed: false,
  done: false,
  isDue: false,
  partialAvailable: false,
  waiting: false,
  stockPickingType: null,
  saleOrder: null,
  assigned: false,
  customFilters: []
};
