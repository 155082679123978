import React, { useState } from "react"
import { Button, Col } from "reactstrap"
import OrderContainer from "./OrderContainer"
import Pads from "./Pads"


const Index = props => {

  const [value, setValue] = useState(0)

  const { setMode} = props;
  return (
    <div className="leftBar">
      <OrderContainer value={value}/>
      <Pads setValue={setValue} setMode={setMode}/>
    </div>
  )
}

export default Index
