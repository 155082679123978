import React, { useEffect, useState } from "react"

import useForm from "../../../hooks/useForm"
import BaseForm from "./components/BaseForm"
import { MODULE, MODEL, INITIAL_STATE, CREATE_TITLE } from "./components/consts"
import DetailsPage from "../../../components/Actions/DetailsPage"
import { useDispatch, useSelector } from "react-redux"
import { setApiLoading } from "../../../store/actions"
import apiCall from "../../../api"
import { useParams } from "react-router-dom"
import ActionButtons from "./components/ActionButtons"
import ProductStockQuantTable from "./components/ProductStockQuantTable"
import useQueryURL from "../../../hooks/useQueryURL"

const Details = () => {
  const [formState, setFormState] = useState([])
  const form = useForm({ formState })
  const { action } = useSelector(state => state.FormActionStatus)
  const dispatcher = useDispatch()
  const { filter } = useQueryURL([])

  const { module } = useParams()

  let type = ""

  if (module === "sales") {
    type = "?type=saleOK"
  }

  if (module === "purchases") {
    type = "?type=purchaseOK"
  }

  useEffect(() => {
    if (action === "edit" || action === "create") {

      const controller = new AbortController()

      const fetchPrice = async () => {
        
    
        var request = {
          productTaxes: form.fields.productTaxes,
          listPrice: form.fields.listPrice,
          standardPrice: form.fields.standardPrice,
        }
    
        try {
          const { data } = await apiCall.post(
            `/${MODEL}/fetchprice`,
            request,
            controller.signal
          )
          
          setFormState({
            ...form.fields,
            ["unitPrice"]: data.data?.unitPrice,
            ["margin"]: data.data?.margin,
          })
        } catch (error) {
          console.log("FetchPrice", error.message)
        }finally{
          dispatcher(setApiLoading(false))
        }
    
      }

      const timer = setTimeout(() => {
        dispatcher(setApiLoading(true))
        fetchPrice()

        return () => controller.abort()
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [
    form.fields.listPrice,
    form.fields.standardPrice,
    form.fields.productTaxes,
  ])

  

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      setFormState={setFormState}
      defaultValues
      defaultValuesParams={type}
      embedData
    >
      {filter?.view === "stock" ? (
        <ProductStockQuantTable
          disabled={action != "edit" && action != "create" ? true : false}
          form={form}
          setFormState={setFormState}
        />
      ) : (
        <React.Fragment>
          <ActionButtons form={form} />
          <BaseForm
            form={form}
            setFormState={setFormState}
            disabled={action != "edit" && action != "create" ? true : false}
          />
        </React.Fragment>
      )}
    </DetailsPage>
  )
}

export default Details
