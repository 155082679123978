import React from "react"
import { Col, Row, TabPane } from "reactstrap"
import CustomDateTime from "../../../../../components/Common/CustomDateTime"
import CustomEnumSelect from "../../../../../components/Common/CustomEnumSelect"
import CustomSelect from "../../../../../components/Common/CustomSelect"

const InformationTab = props => {
  const { form, disabled, readOnlyStates } = props

  return (
    <TabPane tabId="2" className="p-3">
      <Row>
        <Col lg="6">
          <CustomSelect
            label="Comercial"
            {...form?.getSelect("user", "user")}
            required
            isDisabled={
              disabled || readOnlyStates.includes(form.fields?.invoiceState)
            }
          />
        </Col>
        <Col lg="4"></Col>
      </Row>
    </TabPane>
  )
}

export default InformationTab
