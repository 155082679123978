import Index from "..";
import Details from "../Details";
import { MODEL } from "./consts";
import { MODULE } from "../../consts";

export const AccountInvoiceRoutes = [
  { path: `/${MODULE}/${MODEL}/:id`, exact: true, component: Details },
  { path: `/${MODULE}/${MODEL}`, exact: true, component: (props) => <Index {...props} /> },
]

export default AccountInvoiceRoutes;
