export const MODULE = 'sales';
export const MODEL = 'pricelist';

export const INDEX_TITLE = 'Tarifas';
export const CREATE_TITLE = 'Nueva tarifa';

export const INITIAL_STATE = {
    name: "",
    calculationType: 1,
    isVariable: false,
    active: true,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


export const PRICELISTTYPE_ENUM = [
  { value: 1, label: '%'},
  { value: 2, label: '$'},
];
