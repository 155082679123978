import React, { useState } from "react"
import { useKeyUp } from "../../../../hooks/useKeyUp"
import backSpaceImg from "../../../../assets/images/pos/backspace.png"

const Index = props => {
  const { setMode } = props
  const [value, setValue] = useState(0)

  return (
    <div class="receipt-screen screen">
      <div class="screen-content">
        <div class="top-content">
          <div class="top-content-center">
            <h1>$ 80,24</h1>
          </div>
        </div>
        <div class="default-view">
          <div class="actions">
            <h1>¿Cómo le gustaría recibir su pedido??</h1>
            <div class="buttons">
              <div class="button print">
                <i class="fa fa-print"></i> Imprimir pedido{" "}
              </div>
            </div>
            <form class="send-email">
              <div class="input-email">
                <input type="email" placeholder="Recibo por email" />
                <button class="send highlight" type="submit">
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </div>
            </form>
            <div class="notice"></div>
          </div>
          <div class="pos-receipt-container">
            <div class="pos-receipt">
              
              <br />
              <div class="pos-receipt-contact">
                <div>RevalSoft</div>
                <div>sa@revalsoft.com.ar</div>
                <div class="cashier">
                  <div>--------------------------------</div>
                  <div>Servido por Elio AC</div>
                </div>
              </div>
              <br />
              <br />
              <div class="orderlines">
                <div>Monitor Stand</div>
                <span></span>
                <div class="pos-receipt-left-padding">
                  6 x $ 3,86
                  <span class="price_display pos-receipt-right-align">
                    23,16
                  </span>
                </div>
                <div>Small Shelf</div>
                <span></span>
                <div class="pos-receipt-left-padding">
                  4 x $ 2,83
                  <span class="price_display pos-receipt-right-align">
                    11,32
                  </span>
                </div>
                <div>Wall Shelf Unit</div>
                <span></span>
                <div class="pos-receipt-left-padding">
                  6 x $ 1,98
                  <span class="price_display pos-receipt-right-align">
                    11,88
                  </span>
                </div>
                <div>Desk Organizer</div>
                <span></span>
                <div class="pos-receipt-left-padding">
                  4 x $ 6,17
                  <span class="price_display pos-receipt-right-align">
                    24,68
                  </span>
                </div>
                <div>Alta remera</div>
                <span></span>
                <div class="pos-receipt-left-padding">
                  4 x $ 1,21
                  <span class="price_display pos-receipt-right-align">
                    4,84
                  </span>
                </div>
                <div>Whiteboard Pen</div>
                <span></span>
                <div class="pos-receipt-left-padding">
                  3 x $ 1,45
                  <span class="price_display pos-receipt-right-align">
                    4,36
                  </span>
                </div>
              </div>
              <div class="pos-receipt-right-align">--------</div>
              <br />
              <div class="pos-receipt-amount">
                {" "}
                TOTAL <span class="pos-receipt-right-align">$ 80,24</span>
              </div>
              <br />
              <br />
              <div>
                Cash<span class="pos-receipt-right-align">20,00</span>
              </div>
              <div>
                Customer Account
                <span class="pos-receipt-right-align">60,24</span>
              </div>
              <br />
              <div class="pos-receipt-amount receipt-change">
                {" "}
                CAMBIO <span class="pos-receipt-right-align">$ 0,00</span>
              </div>
              <br />
              <div>
                IVA 21%<span class="pos-receipt-right-align">9,90</span>
              </div>
              <div id="post_receipt_taxes_container">
                {" "}
                Total de Impuestos{" "}
                <span class="pos-receipt-right-align">$ 9,90</span>
              </div>
              <div class="before-footer"></div>
              <div class="after-footer"></div>
              <br />
              <br />
              <div class="pos-receipt-order-data">
                <div>Orden 00006-005-0004</div>
                <div>02/03/2023 12:59:24</div>
              </div>
            </div>
          </div>
        </div>
        <div class="button next validation highlight" onClick={() => setMode(0)}>
          <div class="pay-circle">
            <i
              class="fa fa-arrow-circle-up"
              role="img"
              aria-label="Pay"
              title="Pagar"
            ></i>
          </div>{" "}
          Nuevo pedido{" "}
        </div>
      </div>
    </div>
  )
}

export default Index
