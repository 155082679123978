export const MODULE = 'accounting';
export const MODEL = 'accountgroup';

export const INDEX_TITLE = 'Grupos de cuentas';
export const CREATE_TITLE = 'Nuevo grupo de cuenta';

export const INITIAL_STATE = {
    name: "",
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;



