import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import LayoutSaga from './layout/saga';

//Base
import BaseLoadDataTableSaga from './base/loadtable/saga';
import CreateBaseSaga from './base/create/saga';
import EditBaseSaga from './base/edit/saga';
import GetBaseSaga from './base/detail/saga';
import DeleteBaseSaga from './base/delete/saga';

import SetPageTitleSaga from './pagetitle/saga';

export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ProfileSaga(),
        ForgetSaga(),
        LayoutSaga(),

        BaseLoadDataTableSaga(),
        CreateBaseSaga(),
        EditBaseSaga(),
        GetBaseSaga(),
        DeleteBaseSaga(),
        SetPageTitleSaga(),
    ])
}