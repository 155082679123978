import { Col, Row, TabPane } from "reactstrap"
import CustomDateTime from "../../../../../components/Common/CustomDateTime"
import CustomEnumSelect from "../../../../../components/Common/CustomEnumSelect"
import CustomSelect from "../../../../../components/Common/CustomSelect"

const InformationTab = props => {
  const { form, disabled } = props

  return (
    <TabPane tabId="2" className="p-3">
      <Row>
        <Col lg="4">
          <CustomSelect
            label="Representante"
            {...form?.getSelect("user", "user")}
            isClearable
            isDisabled={disabled}
          />

          <CustomDateTime
            label="Fecha prevista"
            disabled={disabled}
            {...form?.getInputDate("datetime-local", "datePlanned")}
            required
          />

          <CustomSelect
            label="Entregar a"
            {...form?.getSelect("stockPickingType", "stockPickingType", "type=1")}
            isDisabled={disabled}
            required
          />
        </Col>
        <Col lg="4">
          <CustomSelect
            label="Plazo de pago"
            {...form?.getSelect("accountPaymentTerm", "accountPaymentTerm")}
            isDisabled={disabled}
            required
          />
          <CustomDateTime
            label="Fecha vencimiento"
            disabled={disabled || form.fields?.partner === null}
            {...form?.getInputDate("date", "dueDate")}
          />
        </Col>
      </Row>
    </TabPane>
  )
}

export default InformationTab
