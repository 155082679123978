import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Col, Row } from "reactstrap"
import { setFormSecondaryAction } from "../../../../store/actions"
import AccountPaymentHelper from "../../../Invoices/AccountPayment/components/AccountPaymentHelper"
import useSaleOrderActions from "../Actions"
import { useDispatch } from "react-redux"
import ModuleWrapper from "../../../../components/Common/ModuleWrapper"
import useMenu from "../../../../hooks/useMenu"
import apiCall from "../../../../api";
import {PrintIFrame} from "../../../../components/Print/PrintIFrame"

const SaleOrderActions = props => {
  const { form } = props
  const { loading } = useSelector(state => state.ApiStatus)
  const dispatch = useDispatch()
  const { GoTo} = useMenu();
  const iFrameRef = useRef();

  const [settings, setSettings] = useState({
    printReport: false,
    printTicket: false
  });

  const { doConfirm, doPrint, doCreateInvoice } = useSaleOrderActions(
    form.fields.id
  )

  const btnPickingsAction = (e) => {

    if(form.fields?.stockPickings?.length === 1){
      GoTo(form.fields?.stockPickings[0].name,`/inventory/stockpicking/${form.fields?.stockPickings[0].id}`)
    }else{
      GoTo("Entregas",`/inventory/stockpicking?saleOrder=${form.fields?.id}`)
    }
    
  };

  useEffect(() => {
    async function fetchSettings() {
      try {
        const result = await apiCall.get(`/saleorder/settings`);
        setSettings(result.data);
      } catch (error) {
        console.error("SaleOrder Settings", error.message);
      } finally {
  
      }
    }
  
    fetchSettings();  
  }, []);



  return (
    <Row className="mb-2">
      <Col>
        {form.fields?.state === 3 && form.fields?.id != 0 ? (
          <Button
            color="primary"
            className="btn btn-primary btn-sm me-1"
            onClick={btnPickingsAction}
            disabled={loading}
          >
            Entregar
          </Button>
        ) : null}
        {form.fields?.state === 1 || form.fields?.state === 2 ? (
          <Button
            color="primary"
            className="btn btn-primary btn-sm me-1"
            type="submit"
            form="hook-form"
            onClick={() => {
              dispatch(setFormSecondaryAction("confirm"))
            }}
            disabled={loading}
          >
            Confirmar
          </Button>
        ) : null}
        {form.fields?.id != 0 ? (
          <React.Fragment>
            <ModuleWrapper module={5}>
              {(form.fields?.state === 3 ||
              form.fields?.state === 4) &&
              (form.fields?.invoiceStatus === 0 ||form.fields?.invoiceStatus === 2) &&
                form.fields?.accountInvoices?.length === 0 ? (
                <Button
                  color="primary"
                  className="btn btn-primary btn-sm me-1"
                  type="submit"
                  form="hook-form"
                  onClick={() => {
                    dispatch(setFormSecondaryAction("createInvoice"))
                  }}
                  disabled={loading}
                >
                  Crear factura
                </Button>
              ) : null}
            </ModuleWrapper>
            
            {settings?.printReport && (
              <Button
              color="secondary"
              className="btn btn-secondary btn-sm me-1"
              onClick={e => {
                e.preventDefault()
                doPrint(form.fields.name)
              }}
              disabled={loading}
            >
              Imprimir
            </Button>
            )}

            {settings?.printTicket && (
              <Button
              color="secondary"
              className="btn btn-secondary btn-sm me-1"
              onClick={async(e) => {
                e.preventDefault()

                if (!iFrameRef.current) return;

                await iFrameRef.current.print(`../print/saleorder/${form.fields?.id}`);
              }}
              disabled={loading}
            >
              <i className={`bx bx-receipt font-size-16 align-middle`}></i>
            </Button>
            )}
            


            {(form.fields?.state == 3 ||
            form.fields?.state == 4) && form.fields?.balance > 0 ? (
              <AccountPaymentHelper
                form={form}
                loading={loading}
                model={"saleOrder"}
                date={form.fields?.dateOrder}
                partnerType={1}
                paymentType={1}
                forInvoice={false}
              />
            ) : null}
          </React.Fragment>
        ) : null}
      </Col>
      <PrintIFrame
        ref={iFrameRef}
      />
    </Row>
  )
}

export default SaleOrderActions
