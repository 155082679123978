import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'
import CustomEnumSelect from '../../../../../components/Common/CustomEnumSelect'
import CumstomField from '../../../../../components/Common/CustomFields'
import CustomSelect from '../../../../../components/Common/CustomSelect'
import { PRODUCTTYPE_ENUM, MODEL } from '../consts'

const GeneralTab = (props) => {

    const { form, disabled } = props;
    
    return(
        <TabPane tabId="1" className="p-3">
          <Row>
            <Col md="4">
              <CustomEnumSelect
                label="Tipo"
                {...form?.getEnumSelect("type", PRODUCTTYPE_ENUM)}
                isDisabled={disabled}
              />
              <CustomSelect
                label="Categoría"
                {...form?.getSelect("category", "productcategory")}
                isDisabled={disabled}
              />
              <CustomSelect
                label="Unidad de medida"
                {...form?.getSelect(
                  "productUnitOfMeasure",
                  "productmeasureunit"
                )}
                isClearable
                isDisabled={disabled}
              />
            </Col>
            <Col md="4">
              <CumstomField
                {...form?.getInput("listPrice")}
                label="Precio de venta"
                type="number"
                disabled={disabled}
                required
              />
              <CumstomField
                {...form?.getInput("unitPrice")}
                label="Precio unitario"
                type="number"
                disabled={true}
                required
              />
              <CumstomField
                {...form?.getInput("standardPrice")}
                label="Precio de costo"
                type="number"
                disabled={disabled}
                required
              />
              
            </Col>
            <Col md="4">
              <CumstomField
                {...form?.getInput("margin")}
                label="Margen (%)"
                type="number"
                disabled={true}
              />
              
            </Col>
          </Row>
        </TabPane>
    )
}

export default GeneralTab