import { MODULE } from "../../consts";

export const MODEL = 'company';

export const INDEX_TITLE = 'Compañias';
export const CREATE_TITLE = 'Nueva compañia';

export const INITIAL_STATE = {
    name: "",
    documentType: 80,
    ficalPosition: 1,
    email: "",
    phone: "",
    document: ""
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const DOCUMENTTYPE_ENUM = [
  { value: 80, label: 'CUIT'},
  { value: 86, label: 'CUIL'},
  { value: 96, label: 'DNI'},
  { value: 94, label: 'Pasaporte'},
  { value: 99, label: 'Otro'},
];

export const FISCALPOSITION_ENUM = [
  { value: 1, label: 'Responsable inscripto'},
  { value: 4, label: 'Sujeto exento'},
  { value: 5, label: 'Consumidor final'},
  { value: 6, label: 'Responsable monotributo'},
  { value: 7, label: 'Sujeto no cagerizado'},
  { value: 13, label: 'Monotributista social'},
];

