export const MODULE = 'invoices';
export const MODEL = 'paymentmethod';

export const INDEX_TITLE = 'Métodos de pago';
export const CREATE_TITLE = 'Nuevo método de pago';

export const INITIAL_STATE = {
    name: "",
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

