import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'
import CumstomField from '../../../../../components/Common/CustomFields';
import CustomSelect from '../../../../../components/Common/CustomSelect'

const InformationTab = (props) => {

    const { form, disabled } = props;
    
    return(
        <TabPane tabId="2" className="p-3">
          <Row>
            <Col lg="6">
            
            <CustomSelect
                label="Método de envío"
                {...form?.getSelect("deliveryCarrier", "deliveryCarrier")}
                isDisabled={disabled}
                required
              />
            
            <CumstomField
              {...form?.getInput("carrierTrackingReference")}
              label="Tracking"
              type="text"
              disabled={disabled}
            />
            
            </Col>
            <Col lg="4">
            <CumstomField
              {...form?.getInput("weight")}
              label="Peso"
              type="number"
              disabled={disabled}
            />

            <CumstomField
              {...form?.getInput("shippingWeight")}
              label="Peso del envío"
              type="number"
              disabled={disabled}
            />

            <CumstomField
              {...form?.getInput("numberOfPackages")}
              label="Cantidad de paquetes"
              type="number"
              disabled={disabled}
            />
              
            </Col>
          </Row>
        </TabPane>
    )
}

export default InformationTab