import { useSelector } from "react-redux"
import { Button, Col, Row } from "reactstrap"
import { useDispatch } from "react-redux"
import useMenu from "../../../../hooks/useMenu"
import { setFormSecondaryAction } from "../../../../store/actions"
import AccountPaymentHelper from "../../../Invoices/AccountPayment/components/AccountPaymentHelper"
import usePurchaseOrderActions from "../Actions"

const PurchaseOrderActions = props => {
  const { form } = props
  const { loading } = useSelector(state => state.ApiStatus)
  const dispatch = useDispatch()
  const { GoTo } = useMenu()
  const { doConfirm, doPrint } = usePurchaseOrderActions(form.fields.id)

  const btnPickingsAction = e => {
    if (form.fields?.stockPickings?.length === 1) {
      GoTo(
        form.fields?.stockPickings[0].name,
        `/inventory/stockpicking/${form.fields?.stockPickings[0].id}`
      )
    } else {
      GoTo(
        "Recepciones",
        `/inventory/stockpicking?purchaseOrder=${form.fields?.id}`
      )
    }
  }

  return (
    <Row className="mb-1">
      {form.fields?.id != 0 ? (
        <Col>
          {form.fields?.state === 4 && form.fields?.id != 0 ? (
            <Button
              color="primary"
              className="btn btn-primary btn-sm me-1"
              onClick={btnPickingsAction}
              disabled={loading}
            >
              Recibir
            </Button>
          ) : null}
          {form.fields?.invoiceStatus === 2 && form.fields?.accountInvoices?.length === 0? (
            <Button
            color="primary"
            className="btn btn-primary btn-sm me-1"
            type="submit"
            form="hook-form"
            onClick={() => {
              dispatch(setFormSecondaryAction("createInvoice"))
            }}
              disabled={loading}
            >
              Crear factura
            </Button>
          ) : null}

          {form.fields?.state === 1 || form.fields?.state === 2 ? (
            <Button
              color="primary"
              className="btn btn-primary btn-sm me-1"
              onClick={e => {
                e.preventDefault()
                doConfirm()
              }}
              disabled={loading}
            >
              Confirmar
            </Button>
          ) : null}

          <Button
            color="secondary"
            className="btn btn-secondary btn-sm me-1"
            onClick={e => {
              e.preventDefault()
              doPrint(form.fields.name)
            }}
            disabled={loading}
          >
            Imprimir
          </Button>

          {form.fields?.state > 3 && form.fields?.balance > 0 ? (
            <AccountPaymentHelper
              form={form}
              loading={loading}
              model={"purchaseOrder"}
              date={form.fields?.dateOrder}
              partnerType={2}
              paymentType={2}
              forInvoice={false}
            />
          ) : null}
        </Col>
      ) : null}
    </Row>
  )
}

export default PurchaseOrderActions
