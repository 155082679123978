import {
  BREADCRUMBS_SET,
  CURREENTMENU_SET,
    PAGETITLE_SET
  } from "./actionTypes";
  
  export const setPageTitle = (value) => {
    return {
      type: PAGETITLE_SET,
      payload:  value ,
    };
  };

  export const setCurrentMenu = (value) => {
    return {
      type: CURREENTMENU_SET,
      payload:  value ,
    };
  };

  export const setBreadcrumbs = (value) => {
    return {
      type: BREADCRUMBS_SET,
      payload:  value ,
    };
  };
  
  