import CustomFilters from "../../../../components/Common/CustomFilters"

const StockPickingCustomFilters = (props) => {
  const { filter, setFilter, columns } = props

  const itemsFilter = [
    {
      name: "assigned",
      label: "Preparado",
    },
    {
      name: "waiting",
      label: "Esperando otro movimiento",
    },
    {
      name: "confirmed",
      label: "Esperando disponibilidad",
    },
    {
      name: "isDue",
      label: "Vencidos",
    },,
    {
      name: "done",
      label: "Hecho",
    },
  ]


  return (
    <CustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
    itemsFilter={itemsFilter}
  />
  );
};

export default StockPickingCustomFilters;
