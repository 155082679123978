import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";


//Base
import LoadTableResult from "./base/loadtable/reducer";
import CreateResult from "./base/create/reducer";
import EditResult from "./base/edit/reducer";
import DetailsResult from "./base/detail/reducer";
import DeleteResult from "./base/delete/reducer";


import ApiStatus from "./api/reducer";

import FormActionStatus from "./formAction/reducer";

import PageTitle from "./pagetitle/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,

  PageTitle,
  LoadTableResult,
  CreateResult,
  EditResult,
  DetailsResult,
  DeleteResult,
  ApiStatus,
  FormActionStatus,
});

export default rootReducer;
