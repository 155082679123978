import React, { useState } from "react"
import { useKeyUp } from "../../../../hooks/useKeyUp"
import backSpaceImg from "../../../../assets/images/pos/backspace.png"
import MercadoPagoImg from "../../../../assets/images/pos/paymentmethod/mercadopago.png"
import CashImg from "../../../../assets/images/pos/paymentmethod/cash.png"
import ccImg from "../../../../assets/images/pos/paymentmethod/cc.png"

const Index = props => {
  const { setMode } = props
  const [value, setValue] = useState(0)

  useKeyUp(() => setMode(0), ["Escape"])

  useKeyUp(() => AddDigit(0), ["Digit0", "Numpad0"])
  useKeyUp(() => AddDigit(1), ["Digit1", "Numpad1"])
  useKeyUp(() => AddDigit(2), ["Digit2", "Numpad2"])
  useKeyUp(() => AddDigit(3), ["Digit3", "Numpad3"])
  useKeyUp(() => AddDigit(4), ["Digit4", "Numpad4"])
  useKeyUp(() => AddDigit(5), ["Digit5", "Numpad5"])
  useKeyUp(() => AddDigit(6), ["Digit6", "Numpad6"])
  useKeyUp(() => AddDigit(7), ["Digit7", "Numpad7"])
  useKeyUp(() => AddDigit(8), ["Digit8", "Numpad8"])
  useKeyUp(() => AddDigit(9), ["Digit9", "Numpad9"])
  useKeyUp(() => setValue(0), ["Backspace"])

  const AddDigit = char => {
    setValue(prev => prev + "" + char)
  }

  return (
    <div className="payment-screen screen">
      <div className="screen-content">
        <div className="top-content">
          <div className="button back" onClick={e => setMode(0)}>
            <i className="fa fa-angle-double-left fa-fw"></i>
            <span className="back_text">Volver</span>
          </div>
          <div className="top-content-center">
            <h1>Pagos</h1>
          </div>
        </div>
        <div className="main-content">
          <div className="left-content">
            <div className="paymentmethods-container">
              <div className="paymentmethods">
                <p className="title-category">Métodos de pago</p>
                <div className="button paymentmethod">
                  <div className="payment-method-display">
                    <div>
                      <img
                        className="payment-method-icon"
                        src={CashImg}
                      />
                    </div>
                    <span className="payment-name">Efectivo</span>
                  </div>
                </div>
                <div className="button paymentmethod">
                  <div className="payment-method-display">
                    <div>
                      <img
                        className="payment-method-icon"
                        src={MercadoPagoImg}
                      />
                    </div>
                    <span className="payment-name">Mercado Pago</span>
                  </div>
                </div>
                <div className="button paymentmethod">
                  <div className="payment-method-display">
                    <div>
                      <img
                        className="payment-method-icon"
                        src={ccImg}
                      />
                    </div>
                    <span className="payment-name">Cuenca corriente</span>
                  </div>
                </div>
              </div>
              <div className="paymentlines">
                <p className="title-category">Resumen</p>
                {value != 0 && (
                  <div class="paymentline selected">
                    <div class="payment-name">Efectivo</div>
                    <div class="payment-amount">{value}</div>
                    <div
                      class="delete-button"
                      aria-label="Delete"
                      title="Eliminar"
                    >
                      <i class="fa fa-times-circle"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`button next validation ${
                value == 0 ? "" : "highlight"
              }`}
              onClick={e => setMode(4)}
            >
              <div className="pay-circle">
                <i
                  className="fa fa-chevron-right"
                  role="img"
                  aria-label="Pay"
                  title="Pagar"
                ></i>
              </div>
              <span className="next_text">Validar</span>
            </div>
          </div>
          <div className="center-content">
            <section className="paymentlines-container">
              {value == 0 ? (
                <div className="paymentlines-empty">
                  <div className="total">$ 19,03</div>
                  <div className="message">
                    {" "}
                    Seleccione un método de pago{" "}
                  </div>
                </div>
              ) : (
                <div>
                  <div class="payment-status-container">
                    <div>
                      <div class="payment-status-remaining">
                        <span class="label">restantes</span>
                        <span class="amount">$ 0,00</span>
                      </div>
                      <div class="payment-status-total-due">
                        <span class="label">Total</span>
                        <span>$ 400,64</span>
                      </div>
                    </div>
                    <div>
                      <div class="payment-status-change">
                        <span class="label">Cambio</span>
                        <span class="amount highlight">$ 99,36</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
            <div className="payment-buttons-container">
              <section className="payment-numpad">
                <div className="numpad">
                  <button className="input-button number-char">1</button>
                  <button className="input-button number-char">2</button>
                  <button className="input-button number-char">3</button>
                  <button disabled className="mode-button">+10</button>
                  <button className="input-button number-char">4</button>
                  <button className="input-button number-char">5</button>
                  <button className="input-button number-char">6</button>
                  <button className="mode-button">+20</button>
                  <button className="input-button number-char">7</button>
                  <button className="input-button number-char">8</button>
                  <button className="input-button number-char">9</button>
                  <button className="mode-button">+50</button>
                  <button className="input-button number-char">+/-</button>
                  <button className="input-button number-char">0</button>
                  <button className="input-button number-char">,</button>
                  <button className="input-button number-char">
                  <i className="fa fa-angle-double-left fa-fw"></i>
                    {/* <img
                      src={backSpaceImg}
                      width="24"
                      height="21"
                      alt="Retroceso"
                    /> */}
                  </button>
                </div>
              </section>
            </div>
          </div>
          <div className="right-content">
            <div className="payment-buttons">
              <div className="partner-button">
                <div className="button highlight">
                  <i className="fa fa-user" role="img" title="Cliente"></i>
                  <span>Administrator</span>
                </div>
              </div>
              <div className="payment-controls">
                <div className="button js_invoice">
                  <i className="fa fa-file"></i> Factura
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
