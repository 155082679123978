import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import { AFIP_CODE_ENUM, AFIP_TRIBUTE_CODE_ENUM, CALCULATION_TYPE_ENUM, PRICELISTTYPE_ENUM, SCOPE_ENUM } from "./consts";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CustomSelect from "../../../../components/Common/CustomSelect";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
      <Row>
        <Col md="4">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />
           
          <CumstomField
            {...form?.getInput("description")}
            label="Descripción"
            type="text"
            disabled={disabled}
          />

          <CustomEnumSelect
                      label="Ambito del impuesto"
                      {...form?.getEnumSelect("scope", SCOPE_ENUM)}
                      isDisabled={disabled}
                      />

          <CustomEnumSelect
                      label="Cálculo del impuesto"
                      {...form?.getEnumSelect("calculationType", CALCULATION_TYPE_ENUM)}
                      isDisabled={disabled}
                      />
          <CumstomField
            {...form?.getInput("amount")}
            label="Valor"
            type="Number"
            disabled={disabled}
            required
          />
           
          <CustomSwitch
            label="Activo"
            disabled={disabled}
            {...form?.getSwitch("active")}
          />
        </Col>
        <Col md="4">
         

          <CustomEnumSelect
                      label="Código IVA en AFIP"
                      {...form?.getEnumSelect("codeAFIP", AFIP_CODE_ENUM)}
                      isDisabled={disabled}
                      />

          <CustomEnumSelect
                      label="Código tributo en AFIP"
                      {...form?.getEnumSelect("codeTributeAFIP", AFIP_TRIBUTE_CODE_ENUM)}
                      isDisabled={disabled}
                      />

        <CustomSelect
              label="Cuenta de impuestos"
              {...form?.getSelect("account", "accountAccount")}
              isClearable
              isDisabled={disabled}
            />
        
        <CustomSelect
              label="Cuenta de impuestos en notas de crédito"
              {...form?.getSelect("refundAccount", "accountAccount")}
              isClearable
              isDisabled={disabled}
            />
        </Col>
       
      </Row>
  );
};

export default BaseForm;
