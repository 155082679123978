import { Button, Col, Row, TabPane } from 'reactstrap'
import CustomSelect from '../../../../../components/Common/CustomSelect';

const InvoicesTab = (props) => {
    
    const { form, disabled, setFormState } = props;
    

  

    return(
      <TabPane tabId="1" className="p-3">
        <Row>
         
          <Col md="4">
          <CustomSelect
              label="Comercial"
              {...form?.getSelect("comercial", "user")}
              isClearable
              isDisabled={disabled}
            />
          </Col>

          <Col md="2"></Col>
        </Row>
        <Row>

          <Col md="3">
            <CustomSelect
              label="Plazo de pago"
              {...form?.getSelect("accountPaymentTerm", "accountPaymentTerm")}
              isClearable
              isDisabled={disabled}
            />
          </Col>
          <Col lg="4">
            <CustomSelect
              label="Tarifa"
              {...form?.getSelect("priceList", "priceList")}
              isClearable
              isDisabled={disabled}
            />
          </Col>

          <Col lg="2"></Col>
        </Row>
      </TabPane>
    )
}

export default InvoicesTab