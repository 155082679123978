import React from "react";

const CustomSwitch = (props) => {

 const { name, label, disabled, checked, onChange} = props;

  return (
    <div>
      <div className="mt-4 mt-lg-0">
        <div className="form-check form-switch mb-2">
          <input
            name={name}
            type="checkbox"
            disabled={disabled}
            className="form-check-input"
            id={`customSwitch${name}`}
            onChange={onChange}
            checked={checked}
          />
          <label
            className="form-check-label"
            htmlFor={`customSwitch${name}`}
            // onClick={() => {
            //   setcustomchk(!customchk);
            // }}
          >
            {label}
          </label>
        </div>

      </div>
    </div>
  );
};

export default CustomSwitch;
