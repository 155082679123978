import React, { useRef } from "react"
import { Row, FormGroup, Label, Input } from "reactstrap"
import noImage from "../../assets/images/no-image.png"
import Dropzone from "react-dropzone"

export const CustomFileImage = props => {
  const { name, label, required, disabled, value, onChange, viewValue } = props

  const dropzoneRef = useRef()

  const openFileDialog = e => {
    if (disabled) return;
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  const handleAcceptedFiles = files => {

    if (disabled) return

    let target = {
      name: name,
      files: files,
    }

    let eTarget = {
      target: target,
    }

    onChange(eTarget)
  }

  return (
    <FormGroup className="select2-container">
      {label !== "" ? (
        <Label>
          {label} {required ? <span className="text-danger">*</span> : null}
        </Label>
      ) : null}
      <Dropzone
        ref={dropzoneRef}
        accept={"image/*"}
        maxFiles="1"
        onDrop={acceptedFiles => {
          handleAcceptedFiles(acceptedFiles)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Row {...getRootProps()} onClick={openFileDialog}>
            {value ? (
              <img
                className="rounded avatar-lg card-img img-thumbnail mb-1 ms-1"
                src={value}
                alt="img"
                
              />
            ) : (
              <React.Fragment>
                {viewValue ? (
                  <img
                    className="rounded avatar-lg card-img img-thumbnail mb-1 ms-1"
                    src={viewValue}
                    alt="img"
                  />
                ) : (
                  <img
                    className="rounded avatar-lg card-img img-thumbnail mb-1 ms-1"
                    src={noImage}
                    alt="img"
                  />
                )}
              </React.Fragment>
            )}
          </Row>
        )}
      </Dropzone>

      {!disabled ? (
        <div>
          <div className="input-group mb-3">
            {(viewValue || value) && (
              <button
                className="btn btn-danger btn-sm"
                type="button"
                id="inputGroupFileAddon03"
                onClick={e => {
                  let target = {
                    name: name,
                    files: null,
                  }

                  let eTarget = {
                    target: target,
                  }

                  onChange(eTarget)
                }}
              >
                <i className="bx bx-trash" />
              </button>
            )}

          </div>
        </div>
      ) : null}
    </FormGroup>
  )
}

export default CustomFileImage
