export const MODULE = 'settings';
export const MODEL = 'role';

export const INDEX_TITLE = 'Roles';
export const CREATE_TITLE = 'Nuevo rol';

export const INITIAL_STATE = {
    name: "",
  };

export const INITIAL_STATE_FILTER = {

  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


export const PRODUCTTYPE_ENUM = [
  { value: 1, label: 'Consumible'},
  { value: 2, label: 'Servicio'},
  { value: 3, label: 'Producto'},
];
