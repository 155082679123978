import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
} from "reactstrap"

import AvForm from "availity-reactstrap-validation/lib/AvForm"
import useForm from "../../hooks/useForm"
import CustomEnumSelect from "./CustomEnumSelect"
import CustomField from "./CustomFields"
import CustomDateTime from "./CustomDateTime"
import moment from "moment"
import { getLabelFromArray } from "../../helpers/array_utils"

const CustomFilters = props => {
  const { filter, setFilter, columns, itemsFilter, isModal } = props

  const SEARCHTYPE_STRING_ENUM = [{ value: 1, label: "contiene" }]

  const SEARCHTYPE_DATE_ENUM = [{ value: 1, label: "entre" }]

  const SEARCHTYPE_NUMBER_ENUM = [
    { value: 1, label: "igual a" },
    { value: 2, label: "mayor a" },
    { value: 3, label: "menor a" },
    { value: 4, label: "entre" },
  ]
  const SEARCHTYPE_ENUM_ENUM = [
    { value: 1, label: "igual a" },
  ]

  const INIT_STATE = {
    searchType: 1,
    searchFieldString: "",
    searchFieldNumber1: "",
    searchFieldNumber2: "",
    searchFieldDate1: moment().format("YYYY-MM-DD"),
    searchFieldDate2: moment().format("YYYY-MM-DD"),
  }
  const [menu, setMenu] = useState(false)
  const [modal, setModal] = useState(false)
  const [fields, setFields] = useState([])
  const [currentType, setCurrentType] = useState([])
  const [currentEnum, setCurrentEnum] = useState([])

  const [formState, setFormState] = useState(INIT_STATE)
  const searchForm = useForm({ formState })

  const onClick = e => {
    e.preventDefault()

    setFilter({ ...filter, [e.target.name]: filter ? !filter[e.target.name] : false })
  }
  const onCustomClick = (e, index) => {
    e.preventDefault()

    let tempItems = [...filter.customFilters]

    let tempItem = {
      ...tempItems[index],
      ["active"]: !tempItems[index].active,
    }

    tempItems[index] = tempItem

    setFilter({ ...filter, customFilters: tempItems })
  }

  useEffect(() => {
    if (modal) {
      let items = []

      for (var i = 0; i < columns.length; i++) {
        let col = columns[i]

        if (col.type != null) {
          let temp = {
            label: col.label,
            type: col.type,
            value: col.name,
          }

          items.push(temp)
        }
      }

      setFields(items)
      setFormState({
        ...searchForm.fields,
        ["field"]: items[0].value,
        ["searchType"]: 1,
        ["searchFieldString"]: "",
        ["searchFieldNumber1"]: "",
        ["searchFieldNumber2"]: "",
        ["searchFieldDate1"]: moment().format("YYYY-MM-DD"),
        ["searchFieldDate2"]: moment().format("YYYY-MM-DD"),
        ["searchFieldEnum"] : null
      })
    }
  }, [modal])

  useEffect(() => {
    if (searchForm.fields?.field) {
      let col = columns.find(f => f.name == searchForm.fields?.field)

      if (col) {
        setCurrentType(col.type)

        if(col.type === "enum"){
            setCurrentEnum(col.customEnum)
        }

        setFormState({
          ...searchForm.fields,
          ["searchType"]: 1,
          ["searchFieldString"]: "",
          ["searchFieldNumber1"]: "",
          ["searchFieldNumber2"]: "",
          ["searchFieldDate1"]: moment().format("YYYY-MM-DD"),
          ["searchFieldDate2"]: moment().format("YYYY-MM-DD"),
          ["searchFieldEnum"] : null
        })
      }
    }
  }, [searchForm.fields?.field])

  const ActiveClass = () => {
    // if (filter) {
    //   if (
    //     filter["customer"] === true ||
    //     filter["supplier"] === true ||
    //     filter["active"] === true
    //   ) {
    //     return "btn btn-primary rounded-circle"
    //   }
    // }

    return "btn btn-secondary rounded-circle"
  }

  const onApply = e => {
    let searchTypeEnum = []

    let item = searchForm.fields

    let col = columns.find(f => f.name == item.field)

    let customFilter = {
      active: true,
      type: col.type,
      name: item.field,
      searchType: item.searchType,
    }

    switch (col.type) {
      case "string":
        searchTypeEnum = SEARCHTYPE_STRING_ENUM.find(
          e => e.value == item.searchType
        )

        customFilter.searchFieldString = item.searchFieldString
        customFilter.displayName = `${col.label} ${searchTypeEnum.label} ${item.searchFieldString}`
        break
      case "enum":
        searchTypeEnum = SEARCHTYPE_ENUM_ENUM.find(
          e => e.value == item.searchType
        )

        customFilter.searchFieldEnum = item.searchFieldEnum
        customFilter.displayName = `${col.label} ${searchTypeEnum.label} ${getLabelFromArray(customFilter.searchFieldEnum,currentEnum)}`
        break
      case "number":
        searchTypeEnum = SEARCHTYPE_NUMBER_ENUM.find(
          e => e.value == item.searchType
        )

        customFilter.searchFieldNumber1 = item.searchFieldNumber1
        customFilter.searchFieldNumber2 = item.searchFieldNumber2

        if (item.searchType === 4) {
          customFilter.displayName = `${col.label} ${searchTypeEnum.label} ${item.searchFieldNumber1} y ${item.searchFieldNumber2}`
        } else {
          customFilter.displayName = `${col.label} ${searchTypeEnum.label} ${item.searchFieldNumber1}`
        }

        break
      case "date":
        searchTypeEnum = SEARCHTYPE_DATE_ENUM.find(
          e => e.value == item.searchType
        )

        customFilter.searchFieldDate1 = moment(item.searchFieldDate1).format(
          "DD/MM/YYYY"
        )
        customFilter.searchFieldDate2 = moment(item.searchFieldDate2).format(
          "DD/MM/YYYY"
        )

        customFilter.displayName = `${col.label} ${searchTypeEnum.label} ${customFilter.searchFieldDate1} y ${customFilter.searchFieldDate2}`

        break

      default:
        break
    }

    let tempItems = null
    if(filter?.customFilters){
      tempItems = [...filter.customFilters]
    }else{
      tempItems=[]
    }
    

    let findIndex = tempItems.findIndex(f => f.name == customFilter.name)

    if (findIndex === -1) {
      tempItems.push(customFilter)
    } else {
      tempItems[findIndex] = customFilter
    }

    // let tempItem = {
    //   ...tempItems[index],
    //   [name]: value,
    // }

    // tempItems[index] = tempItem

    setFilter({ ...filter, customFilters: tempItems })

    tog()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog() {
    setModal(!modal)
    removeBodyCss()
  }

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)}>
        <DropdownToggle className={ActiveClass()} tag="i">
          <i className="bx bx-filter-alt"></i>
        </DropdownToggle>

        <DropdownMenu end>
          <DropdownItem className="dropdown-item-custom" header>
            FILTROS
          </DropdownItem>

          <DropdownItem className="dropdown-divider-custom" divider />
          {!isModal && itemsFilter?.map((i, index) => {
            return i.name === "divider" ? (
              <DropdownItem key={index} className="dropdown-divider-custom" divider />
            ) : (
              <DropdownItem
                key={index}
                className="dropdown-item-custom"
                name={i.name}
                active={
                  i.active == null
                    ? filter
                      ? filter[i.name]
                      : false
                    : i.active
                }
                onClick={i.onCustomClick == null ? onClick : i.onCustomClick}
              >
                {i.label}
              </DropdownItem>
            )
          })}
          <DropdownItem className="dropdown-divider-custom" divider />
          {filter?.customFilters?.map((c, index) => {
            return (
              <DropdownItem
                key={index}
                className="dropdown-item-custom"
                name="active"
                active={c.active}
                onClick={e => onCustomClick(e, index)}
              >
                {c.displayName}
              </DropdownItem>
            )
          })}
          <DropdownItem
            className="dropdown-item-custom"
            name="custom"
            onClick={() => tog()}
          >
            Añadir filtro personalizado
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal
        size="sm"
        isOpen={modal}
        toggle={() => {
          tog()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Filtro personalizado
          </h5>
          <button
            onClick={() => {
              setModal(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm id="search-form" className="needs-validation">
            <Row>
              <CustomEnumSelect
                {...searchForm?.getEnumSelect("field", fields)}
              />
              {currentType === "string" && (
                <React.Fragment>
                  <CustomEnumSelect
                    {...searchForm?.getEnumSelect(
                      "searchType",
                      SEARCHTYPE_STRING_ENUM
                    )}
                  />
                  <CustomField
                    {...searchForm?.getInput("searchFieldString")}
                    type="text"
                  />
                </React.Fragment>
              )}
              {currentType === "enum" && (
                <React.Fragment>
                  <CustomEnumSelect
                    {...searchForm?.getEnumSelect(
                      "searchType",
                      SEARCHTYPE_ENUM_ENUM
                    )}
                  />
                  <CustomEnumSelect
                    {...searchForm?.getEnumSelect(
                      "searchFieldEnum",
                      currentEnum
                    )}
                  />
                </React.Fragment>
              )}
              {currentType === "number" && (
                <React.Fragment>
                  <CustomEnumSelect
                    {...searchForm?.getEnumSelect(
                      "searchType",
                      SEARCHTYPE_NUMBER_ENUM
                    )}
                  />
                  <CustomField
                    {...searchForm?.getInput("searchFieldNumber1")}
                    type="number"
                  />
                  {searchForm.fields?.searchType === 4 && (
                    <CustomField
                      {...searchForm?.getInput("searchFieldNumber2")}
                      type="number"
                    />
                  )}
                </React.Fragment>
              )}
              {currentType === "date" && (
                <React.Fragment>
                  <CustomDateTime
                    {...searchForm?.getInputDate("date", "searchFieldDate1")}
                  />
                  <CustomDateTime
                    {...searchForm?.getInputDate("date", "searchFieldDate2")}
                  />
                </React.Fragment>
              )}
            </Row>
          </AvForm>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={onApply}
            className="btn btn-primary waves-effect waves-light"
          >
            Aplicar
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CustomFilters
