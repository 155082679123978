import { FORM_ACTION, FORM_REFRESH,FORM_SECONDARY_ACTION, FORM_BOUNCE_EDIT, SET_USER } from './actionTypes';


export const setFormAction = (action) => {
    return {
        type: FORM_ACTION,
        payload: action
    }
}

export const setFormSecondaryAction = (action) => {
    return {
        type: FORM_SECONDARY_ACTION,
        payload: action
    }
}

export const setFormRefresh = (action) => {
    return {
        type: FORM_REFRESH,
        payload: action
    }
}

export const setFormBounceEdit = (action) => {
    return {
        type: FORM_BOUNCE_EDIT,
        payload: action
    }
}
export const setUser = (action) => {
    return {
        type: SET_USER,
        payload: action
    }
}
