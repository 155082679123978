import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {Button} from "reactstrap";
import useMenu from "../../../../hooks/useMenu";

const SmartButtons = (props) => {

    const { history, form } = props;
    const { action } = useSelector(state => state.FormActionStatus);
    const { GoTo } = useMenu();


  const btnInvoicesAction = (e) => {

    if(form.fields?.accountPaymentItems?.length === 1){
      GoTo(form.fields?.accountPaymentItems[0].accountInvoice.name,`/invoices/accountInvoice/${form.fields?.accountPaymentItems[0].accountInvoice.id}`)
    }else{
      GoTo('Facturas',`/invoices/accountInvoice?saleOrder=${form.fields?.id}`)
    }
    
  };

  return (action === 'create' ? null : 
    <div className="button-items">

      {form.fields?.accountPaymentItems?.length > 0 ? (
         <Button
         color="primary"
         outline
         className="waves-effect waves-light"
         onClick={btnInvoicesAction}
       >
         <i className="bx bx-detail font-size-16 align-middle mr-2"></i>
         {form.fields?.accountPaymentItems?.length} FACTURAS
       </Button>
      ) : null}
     
    </div>
  );
};

export default withRouter(SmartButtons);
