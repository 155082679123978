import CustomFilters from "../../../../components/Common/CustomFilters"

const PurchaseOrderCustomFilters = (props) => {
  const { filter, setFilter, columns } = props

  const itemsFilter = [
    {
      name: "onlyMe",
      label: "Mis operaciones",
    },
    {
      name: "divider",
    },
    {
      name: "draft",
      label: "Presupuestos",
    },
    {
      name: "sent",
      label: "Enviados",
    },
    {
      name: "purchase",
      label: "Pedidos de compra",
    },
    {
      name: "toApprove",
      label: "Para aprobar",
    },
    {
      name: "done",
      label: "Recibidos",
    },
    {
      name: "divider",
    },
    {
      name: "noInvoice",
      label: "Nada para facturar",
    },
    {
      name: "toInvoice",
      label: "Para facturar",
    },
    {
      name: "invoiced",
      label: "Facturado",
    },
  ]


  return (
    <CustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
    itemsFilter={itemsFilter}
  />
  );
};

export default PurchaseOrderCustomFilters;
