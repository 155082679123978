import React from "react"

import { MODEL, BASE_URL } from "./components/consts"
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable"
import FormLayout from "../../../components/Common/FormLayout"
import CustomLink from "../../../components/Common/CustomLink"

const Index = props => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(setPageTitle(INDEX_TITLE));
  // },[]);

  const columns = [
    {
      name: "Name",
      label: "Nombre",
      width: 40,
    },
  ]

  const rows = data => {
    const rows = []

    const r = data?.map((p, index) => {
      const fields = []

      fields.push(
        <CustomLink content={p.name} to={`${BASE_URL}/${p.id}`}>
          {p.name}
        </CustomLink>
      )

      rows.push({ fields: fields })
    })

    return rows
  }

  const actionButtons = (
    <div className="button-items mb-1">
      <CustomLink
        color="primary"
        className="btn btn-primary waves-effect waves-light"
        content="Nuevo"
        to={`${BASE_URL}/create`}
      >
        Crear
      </CustomLink>
    </div>
  )

  return (
    <FormLayout actionButtons={actionButtons}>
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={{
          customFilters: [],
        }}
        defaultSort={{ orderCriteria: "Name", orderDirection: 0 }}
      />
    </FormLayout>
  )
}

export default Index
