import React from "react"
import { useSelector } from "react-redux"

const ModuleWrapper = props => {
  const { module, children } = props

  const { user } = useSelector(state => state.FormActionStatus)

  return (
    <React.Fragment>
      {user?.modules.includes(module) || module == null ? children : null}
    </React.Fragment>
  )
}

export default ModuleWrapper
