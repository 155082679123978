import React from "react"
import { WithContext as ReactTags } from "react-tag-input"
import { Label } from "reactstrap"

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

const CustomTagInput = props => {
  const { disabled, label, placeholder, tags, handleDelete, handleAddition } =
    props

  const handleDrag = (tag, currPos, newPos) => {}

  return (
    <div className="mb-1">
      <Label>{label}</Label>
      <ReactTags
        autofocus={false}
        tags={tags ?? []}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
        placeholder={placeholder}
        autocomplete={false}
        allowDragDrop={false}
        allowUnique={true}
        readOnly={disabled}
      />
    </div>
  )
}

export default CustomTagInput
