import React from "react";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSelect from "../../../../components/Common/CustomSelect";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
      <Row>
        <Col lg="4">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />

        <CumstomField
            {...form?.getInput("codePrefixStart")}
            label="Prefijo desde"
            type="text"
            disabled={disabled}
            required
          />

        <CumstomField
            {...form?.getInput("codePrefixEnd")}
            label="Prefijo hasta"
            type="text"
            disabled={disabled}
            required
          />
        </Col>
       
      </Row>
  );
};

export default BaseForm;
