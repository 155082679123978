import React from "react";
import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSelect from "../../../../components/Common/CustomSelect";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
    <Row>
      <Col md="4">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />

        <CumstomField
          {...form?.getInput("posNumber")}
          label="Número"
          type="number"
          disabled={disabled}
          required
        />

        <CustomSelect
          label="Controlador fiscal"
          {...form?.getSelect("billingTypeConfig", "billingtypeconfig")}
          required
          isDisabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default BaseForm;
