import CustomFilters from "../../../../components/Common/CustomFilters"

const StockQuantCustomFilters = (props) => {
  const { filter, setFilter, columns, isModal } = props

  const itemsFilter = [
    {
      name: "active",
      label: "Activos",
    },
    {
      name: "divider",
    },
    {
      name: "product",
      label: "Productos",
    },
    {
      name: "service",
      label: "Servicios",
    },
    {
      name: "divider",
    },
    {
      name: "saleOK",
      label: "Puede ser vendido",
    },
    {
      name: "purchaseOK",
      label: "Puede ser comprado",
    },
    {
      name: "expenseOK",
      label: "Puede ser gasto",
    },
    {
      name: "divider",
    },
    {
      name: "available",
      label: "Disponible",
    },
    {
      name: "out",
      label: "Agotado",
    },
    {
      name: "negative",
      label: "Stock negativo",
    },
  ]


  return (
    <CustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
    itemsFilter={itemsFilter}
    isModal={isModal}
  />
  );
};

export default StockQuantCustomFilters;
