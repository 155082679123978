import React from 'react'
import Select from 'react-select';
import {  Label } from 'reactstrap';

const CustomEnumSelect = (props) => {

    const { label , name, isDisabled, options, defaultValue, value, onChange} = props;

    return(
        <div className="mb-1">
            <Label>{label}</Label>
            {isDisabled ? (
              <span className="d-block text-muted text-truncate field-muted">{value[0]?.label}</span>
            ) : (
                <Select
                name={name}
                value={value}
                onChange={onChange}
                className="react-select"
                classNamePrefix="react-select"
                isDisabled={isDisabled}
                placeholder=""
                options={options}
                defaultValue={defaultValue}
            />
            )}

            
        </div>
    )
}

export default CustomEnumSelect