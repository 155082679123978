import { MODULE } from "../../consts";

export const MODEL = 'accountinvoice';

export const INDEX_TITLE = 'Comprobantes';
export const CREATE_TITLE = 'Nuevo comprobante';

export const INITIAL_STATE = {
    name: "",
    partner: null,
    priceList : {
      id : 1,
      name : "Tarifa pública"
    },
    invoiceState: 0,
    dateInvoice: null,
    accountInvoiceItems: []
  };

  export const INITIAL_STATE_FILTER = {
    partner: null,
    onlyMe: false,
    draft: false,
    open: false,
    paid: false,
    due: false,
    internalType: null,
    moveType: null,
    dateInvoice : null,
    customFilters: []
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const INVOICE_STATUS_ENUM = [
  { value: 0, label: 'Borrador'},
  { value: 1, label: 'Abierta'},
  { value: 2, label: 'Pagada'},
  { value: 3, label: 'Cancelada'},
];

export const ACCOUNTINVOICE_TYPE_ENUM = [
  { value: 1, label: 'Factura A"'},
  { value: 2, label: 'Nota de débito B'},
  { value: 3, label: 'Nota de crédito A'},
  { value: 4, label: 'Recibo A'},
  { value: 5, label: 'Nota al contado A'},
  { value: 6, label: 'Factura B'},
  { value: 7, label: 'Nota de crédito B'},
  { value: 8, label: 'Nota de débito B'},
  { value: 9, label: 'Recibo B'},
  { value: 10, label: 'Nota de venta al contado B'},
  { value: 11, label: 'Factura C'},
  { value: 12, label: 'Nota de débito C'},
  { value: 13, label: 'Nota de crédito C'},
  { value: 14, label: 'Recibo C'},
  { value: 15, label: 'Nota de venta al contado B'},
];

export const CONDITIONSALE_TYPE_ENUM = [
  { value: 0, label: 'Contado'},
  { value: 1, label: 'Cuenta corriente'},
];
