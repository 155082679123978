import { Row, TabPane } from 'reactstrap';
import InheritedTable from '../Tables/InheritedTable';

const InherithTab = (props) => {
    
    const { form, disabled, setFormState } = props;

    return(
      <TabPane tabId="3" className="p-3">
        <Row>
        <InheritedTable
          form={form}
          setFormState={setFormState}
          disabled={disabled}
        />
        </Row>
      </TabPane>
    )
}

export default InherithTab