import React from "react";
import { Row, Col, Label, FormGroup } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSelect from "../../../../components/Common/CustomSelect";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
    <Row>
      <Col lg="4">
        <CustomSelect
          label="Producto"
          {...form?.getSelect("product", "product")}
          required
          isDisabled={disabled}
        />

        <CustomSelect
          label="Locación"
          {...form?.getSelect("location", "stockLocation")}
          required
          isDisabled={disabled}
        />
      </Col>

      <Col lg="4">
        <CumstomField
          {...form?.getInput("quantity")}
          label="Cantidad"
          type="number"
          disabled={disabled}
          required
        />
        <CumstomField
          {...form?.getInput("reserved")}
          label="Reservado"
          type="number"
          disabled={disabled}
          required
        />
      </Col>
    </Row>
  );
};

export default BaseForm;
