import { MODULE } from "../../consts";

export const MODEL = 'accountmove';

export const INDEX_TITLE = 'Asientos';
export const CREATE_TITLE = 'Nuevo asiento';

export const INITIAL_STATE = {
    name: "",
    partner: null,
    priceList : {
      id : 1,
      name : "Tarifa pública"
    },
    pickingPolicy: 2,
    validityDate: null,
    saleOrderItems: []
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;



export const INITIAL_STATE_FILTER = {
  draft: false,
  done: false,
  customFilters: []
};
