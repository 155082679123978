import {  FORM_ACTION, FORM_BOUNCE_EDIT,FORM_SECONDARY_ACTION, FORM_REFRESH, SET_USER } from './actionTypes';

const initialState = {
    action: '',
    secondaryAction: '',
    refresh: false,
    bounceEdit: false,
    user: null
}

const FormActionStatus = (state = initialState, action) => {
    switch (action.type) {
        case FORM_ACTION:
            state = { ...state, action: action.payload};
            break;
        case FORM_SECONDARY_ACTION:
            state = { ...state, secondaryAction: action.payload};
            break;
        case FORM_REFRESH:
            state = { ...state, refresh: action.payload};
            break;
        case FORM_BOUNCE_EDIT:
            state = { ...state, bounceEdit: action.payload};
            break;
        case SET_USER:
            state = { ...state, user: action.payload};
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default FormActionStatus;