import React from "react";

import {  MODEL, BASE_URL, JOURNALTYPE_ENUM  } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { getLabelFromArray } from "../../../helpers/array_utils";
import CustomLink from "../../../components/Common/CustomLink";
import useQueryURL from "../../../hooks/useQueryURL";
import {INITIAL_STATE_FILTER} from "./components/consts"

const Index = (props) => {
  
  const {filter, setFilter, queryURL} = useQueryURL(INITIAL_STATE_FILTER);

  const columns = [
      {
        name: "Name",
        label: "Nombre",
        width: 40,
      },
      {
        label: "Código corto",
        width: 20,
      },
      {
        label: "Tipo",
        width: 20,
      }
    
     
    ];

  const btnCreateAction = () => {
    history.push(`${BASE_URL}/create`);
  };

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
    

      fields.push(
        <CustomLink content={p.name} to={`${BASE_URL}/${p.id}`}>{p.name}</CustomLink>
      );
      
      fields.push(
        <span>{p.code}</span>
      );
      
      fields.push(
        <span>{getLabelFromArray(p.type,JOURNALTYPE_ENUM)}</span>
      );
      rows.push({fields : fields});
    });

    return rows;
  };

  const actionButtons =  (
    <div className="button-items mb-1">
      <CustomLink
          color="primary"
          className="btn btn-primary waves-effect waves-light"
          to={`${BASE_URL}/create`}
          content="Nuevo"
        >Crear
      </CustomLink>
               
    </div> 
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={filter}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
      />
    </FormLayout>
  );
};

export default Index;
