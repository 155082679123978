import React, {  useEffect, useState } from "react"
import {  useDispatch, useSelector } from "react-redux"

import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
  setUser,
} from "../../store/actions"

// Other Layout related Component
import Navbar from "./Navbar"
import Header from "./Header"
import Footer from "./Footer"

const Index = props => {
  const {
    children
  } = props;


  const Layout = useSelector(state => state.Layout);
  const dispatch= useDispatch();

  const [isMenuOpened, setMenuOpened] = useState(false)

  useEffect(() => {
    dispatch(setUser(JSON.parse(window.localStorage.getItem("user"))));
    //setUser(JSON.parse(window.localStorage.getItem("user")))

    if (Layout.isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    // Scrollto 0,0
    window.scrollTo(0, 0)

    // const title = this.props.location.pathname;
    // let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    // document.title =
    //   currentage + " | Skote - Responsive Bootstrap 4 Admin Dashboard";

    dispatch(changeLayout("horizontal"));

    if (Layout.topbarTheme) {
      dispatch(changeTopbarTheme(Layout.topbarTheme));
    }
    if (Layout.layoutWidth) {
      dispatch(changeLayoutWidth(Layout.layoutWidth));
    }
  }, [])

  /**
   * Opens the menu - mobile
   */
  const openMenu = e => {
    setMenuOpened(!isMenuOpened);
  }

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          theme={Layout.topbarTheme}
          isMenuOpened={isMenuOpened}
          openLeftMenuCallBack={openMenu}
        />
        <Navbar 
        menuOpen={isMenuOpened} />
        <div className="main-content overflow-visible">
          {children}
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Index
