import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";

export const BaseForm = ({form,handleSubmit,disabled}) => {

  return (
      <Row>
        <Col md="6">
          <CumstomField
              {...form?.getInput("name")}
              label="Nombre"
              type="text"
              disabled={disabled}
              required
              autoFocus={true}
            />
        </Col>
      </Row>
  );
};

export default BaseForm;
