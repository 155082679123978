import React, { useEffect, useState } from "react"

import useForm from "../../../hooks/useForm"
import BaseForm from "./components/BaseForm"
import { MODULE, MODEL, INITIAL_STATE, CREATE_TITLE } from "./components/consts"
import DetailsPage from "../../../components/Actions/DetailsPage"
import { useDispatch, useSelector } from "react-redux"
import { setApiLoading } from "../../../store/actions"
import apiCall from "../../../api"
import { useParams } from "react-router-dom"
import useQueryURL from "../../../hooks/useQueryURL"

const Details = () => {
  const [formState, setFormState] = useState([])
  const form = useForm({ formState })
  const { action } = useSelector(state => state.FormActionStatus)
  const dispatcher = useDispatch()
  const { filter } = useQueryURL([])

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      setFormState={setFormState}
      defaultValues
      embedData
    >
      <BaseForm
        form={form}
        setFormState={setFormState}
        disabled={action != "edit" && action != "create" ? true : false}
      />
    </DetailsPage>
  )
}

export default Details
