import React, { useEffect, useState } from "react";

import useForm from "../../../hooks/useForm";
import BaseForm from "./components/BaseForm";
import {  MODEL, INITIAL_STATE, CREATE_TITLE } from "./components/consts";
import {  MODULE } from "../consts";
import DetailsPage from "../../../components/Actions/DetailsPage";
import { useSelector } from "react-redux";
import StatusBar from "./components/StatusBar";
import ActionButtons from "./components/ActionButtons";


const Details = () => {

  const [formState, setFormState] = useState([]);
  const form = useForm({ formState });
  const { action } = useSelector(state => state.FormActionStatus);
  const readOnlyStates = [7];
  
  const statusBar = <StatusBar state={form.fields?.state} />;

  useEffect(() => {
    if(action === 'edit' || action === 'create'){
      if(form.fields?.stockPickingType){

        setFormState({
          ...form.fields,
          ["stockLocationIni"]: form.fields?.stockPickingType?.defaultStockLocationSource,
          ["stockLocationDest"]: form.fields?.stockPickingType?.defaultStockLocationDestiny,
        });
      }
    }
  }, [form.fields?.stockPickingType]);

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      statusBar={statusBar}
      setFormState={setFormState}
      defaultValues
    >
      <ActionButtons form={form} />

      <BaseForm
          form={form}
          setFormState={setFormState}
          disabled={ (action != 'edit' && action != 'create' ? true : false) ||readOnlyStates.includes(form.fields?.state) || form.fields?.saleOrderID != null || form.fields?.purchaseOrderID != null}
        />
    </DetailsPage>
  );
};

export default Details;
