import React from "react";

import {  MODEL, BASE_URL, ACCOUNTTYPE_ENUM  } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import CustomLink from "../../../components/Common/CustomLink";
import { getLabelFromArray } from "../../../helpers/array_utils";

const Index = () => {

  // const { GoTo } = useMenu();
  

  // useEffect(() => {
  //   dispatch(setPageTitle(INDEX_TITLE));
  // },[]);

  const columns = [
      {
        name: "Name",
        label: "Nombre",
        width: 30,
      },
      {
        name: "Code",
        label: "Cuenta",
        width: 10,
      },
      {
        label: "Grupo",
        width: 20,
      },
      {
        name: "Type",
        label: "Tipo",
        width: 20,
      },
    
     
    ];

  const btnCreateAction = () => {
    // GoTo("Nuevo",`${BASE_URL}/create`);
  };

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
    

      fields.push(
        <CustomLink content={p.name} to={`${BASE_URL}/${p.id}`}>{p.name}</CustomLink>
      );
      
      fields.push(
        <span>{p.code}</span>
      );

      fields.push(
        <CustomLink content={`Grupo: ${p.accountGroup?.name}`} to={`/accounting/accountgroup/${p.accountGroup?.id}`}>{p.accountGroup?.name}</CustomLink>
      );
      
      fields.push(
        <span>{getLabelFromArray(p.type,ACCOUNTTYPE_ENUM)}</span>
      );

      rows.push({fields : fields});
    });

    return rows;
  };

  const actionButtons =  (
    <div className="button-items mb-1">
      <CustomLink
          content="Nuevo"
          color="primary"
          className="btn btn-primary waves-effect waves-light"
          onClick={btnCreateAction}
          to={`${BASE_URL}/create`}
          
        >Crear
      </CustomLink>
               
    </div> 
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={{
          customFilters: [],
        }}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
      />
    </FormLayout>
  );
};

export default Index;
