import React from "react";
import { Row, Col, Button } from "reactstrap";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CumstomField from "../../../../components/Common/CustomFields";
import { MAILSERVER_SMTPENCRYPTION_ENUM } from "./consts";

export const BaseForm = ({ form, handleSubmit, disabled }) => {

  console.log(form.fields)

  return (
    <React.Fragment>
      <Row>
        <Col md="4">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />

        <CumstomField
            {...form?.getInput("smtP_Host")}
            label="Servidor SMTP"
            type="text"
            disabled={disabled}
            required
          />
        <CumstomField
            {...form?.getInput("smtP_Port")}
            label="Puerto SMTP"
            type="number"
            disabled={disabled}
            required
          />
          <hr/>
        </Col>
      </Row>
      
      <Row>
        <Col md="4">

        <CustomEnumSelect
            label="Seguridad"
            {...form?.getEnumSelect("smtpEncryption", MAILSERVER_SMTPENCRYPTION_ENUM)}
            isDisabled={disabled}
          />
        <CumstomField
            {...form?.getInput("smtP_User")}
            label="Usuario"
            type="text"
            disabled={disabled}
          />
        <CumstomField
            {...form?.getInput("smtP_Password")}
            label="Password"
            type="text"
            disabled={disabled}
          />

        <Button
                color="primary"
                className="btn btn-primary"
              >
                Probar conexión
              </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BaseForm;
