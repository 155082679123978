import React from "react"
import { Container, Button } from "react-floating-action-button"

const FloatingButton = props => {

  const { icon, onClick} = props;

  return (
    <Container>
      <Button
        icon={icon}
        onClick={onClick}
      />
    </Container>
  )
}

export default FloatingButton
