export const MODULE = 'inventory';
export const MODEL = 'stockinventory';

export const INDEX_TITLE = 'Ajustes de inventario';
export const CREATE_TITLE = 'Nuevo ajuste de inventario';

export const INITIAL_STATE = {
    name: "",
    paymentType: 1,
    partnerType: 1,
  };

  export const STOCKINVENTORY_STATE_ENUM = [
    { value: 1, label: 'Borrador'},
    { value: 2, label: 'En progreso'},
    { value: 4, label: 'Validado'},
    { value: 5, label: 'Cancelado'},
  ];


  export const INITIAL_STATE_FILTER = {
    customer: false,
    supplier: false,
    in: false,
    out: false,
    draft: false,
    posted: false,
    reconciled: false
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;
