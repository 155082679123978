import React,{ useEffect, useState } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import apiCall from "../../../api"
import { useDispatch } from "react-redux"
import * as signalR from "@microsoft/signalr"
import logoLightPng from "../../../assets/images/logo-light.png"
import { version } from "../../../../package.json"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import images

import { setApiError } from "../../../store/actions"
import moment from "moment"
import "moment/locale/es"
import CustomLink from "../../Common/CustomLink"

const NotificationDropdown = props => {
  const dispatch = useDispatch()
  const [result, setResult] = useState(null)
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(true)

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  moment.locale("es")

  useEffect(() => {
    if (localStorage.token == null) return

    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/notifications?appVersion=" + version, {
        accessTokenFactory: () => localStorage.token,
      })
      .withAutomaticReconnect()
      .build()

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification")
    } else {
      Notification.requestPermission()
    }

    hubConnection.start()

    hubConnection.on("notifications", n => {
      setRefresh(true)

      var options = {
        body: n.body,
        icon: logoLightPng,
        dir: "ltr",
      }
      var notification = new Notification(n.header, options)
      notification.onclick(e => {
        e.preventDefault()
        window.open("http://www.mozilla.org", "_blank")
      })
      console.log("message", n.header)
    })

    hubConnection.on("update", u => {
      toastr.options = {
        "closeButton": false,
        "debug": false,
        "positionClass": "toast-bottom-right",
        "preventDuplicates": true,
        "onclick": onToastClick,
        "showDuration": "1000",
        "hideDuration": "1000",
        "timeOut": "0",
        "extendedTimeOut": "0",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }
    toastr.success('¡Nueva versión de la app! <br> Click aquí para actualizar');
    })
  }, [])

  const onToastClick = e => {
    refreshCacheAndReload()
  }

  const refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...')
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
  }

  

  useEffect(() => {
    if (refresh) {

      const fetchData = async () => {
        try {
          const body = {
            pageNumber: 1,
            pageSize: 5,
          }
    
          dispatch(setApiError(""))
    
          const { data } = await apiCall.post(`/notification/loadtable`, body)
    
          let count = 0
          for (var i = 0; i < data.data.length; ++i) {
            if (data.data[i].unread === true) count++
          }
    
          setCount(count)
          setResult(data.data)
        } catch (error) {
          dispatch(setApiError(error.message))
        }
      }

      fetchData()
      setRefresh(false)
    }
  }, [refresh])

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={`bx bx-bell ${count > 0 ? "bx-tada" : ""}`}></i>
          {count == 0 ? null : (
            <span className="badge bg-danger rounded-pill">{count}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" end>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {"Notificaciones"} </h6>
              </Col>
              <div className="col-auto">
                <CustomLink
                  content="Notifications"
                  to="/settings/notifications"
                  className="small"
                >
                  {" "}
                  Ver todas
                </CustomLink>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {result?.map((n, index) => {
              return (
                <CustomLink
                  key={index}
                  content="Notificación"
                  to={n.url}
                  className="text-reset notification-item"
                >
                  <div className="media">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-cart"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">{n.header}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{n.body}</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          {moment(n.date).fromNow()}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </CustomLink>
              )
            })}
            {/*               
              <Link to="" className="text-reset notification-item">
                <div className="media">
                  <img src={avatar3} className="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">James Lemire</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{'It will seem like simplified English' + "."}</p>
                      <p className="mb-0"><i className="mdi mdi-clock-outline"></i>{'1 hours ago'} </p>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="" className="text-reset notification-item">
                <div className="media">
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-success rounded-circle font-size-16">
                      <i className="bx bx-badge-check"></i>
                    </span>
                  </div>
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">{'Your item is shipped'}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{'If several languages coalesce the grammar'}</p>
                      <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {'3 min ago'}</p>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="" className="text-reset notification-item">
                <div className="media">
                  <img src={avatar4} className="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">Salena Layfield</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{'As a skeptical Cambridge friend of mine occidental' + "."}</p>
                      <p className="mb-0"><i className="mdi mdi-clock-outline"></i>{'1 hours ago'} </p>
                    </div>
                  </div>
                </div>
              </Link> */}
          </SimpleBar>
          <div className="p-2 border-top">
            <CustomLink
              content="Notificaciones"
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/settings/notifications"
            >
              {" "}
              {"Ver todas"}{" "}
            </CustomLink>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default React.memo(NotificationDropdown)
