import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'
import CustomMultiSelect from '../../../../../components/Common/CustomMultiSelect';

const InvoicesTab = (props) => {
    
    const { form, disabled } = props;

    return(
      <TabPane tabId="4" className="p-3">
      <Row>
        <Col md="6">
          <CustomMultiSelect
            label="Impuestos de cliente"
            {...form?.getMultiSelect("productTaxes", "tax", "scope=1")}
            param="scope=1"
            isClearable
            isDisabled={disabled}
          />
        </Col>
        <Col md="6">
          <CustomMultiSelect
            label="Impuestos de proveedor"
            {...form?.getMultiSelect(
              "productPurchaseTaxes",
              "tax",
              "scope=2"
            )}
            isClearable
            isDisabled={disabled}
          />
        </Col>
      </Row>
    </TabPane>
    )
}

export default InvoicesTab