import { useEffect } from "react";

import {  useParams } from "react-router-dom";
import {  MODEL, BASE_URL, INDEX_TITLE, STOCKPICKING_STATE_ENUM } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import { MODULE } from "../consts";
import { INITIAL_STATE_FILTER } from "./components/consts";
import StockPickingCustomFilters from "./components/StockPickingCustomFilters";
import moment from "moment";
import useQueryURL from "../../../hooks/useQueryURL";
import CustomLink from "../../../components/Common/CustomLink";
import { Badge } from "reactstrap";

const Index = (props) => {

  // let query = useQueryURL(INITIAL_STATE_FILTER);

  const dispatch = useDispatch();
  const {filter, setFilter} = useQueryURL(INITIAL_STATE_FILTER);

  let { id } = useParams();


  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  // useEffect(() => {
  //     setFilter({...filter,
  //       saleOrder: query.get('saleOrder'),
  //       stockPickingType: query.get('stockPickingType'),
  //       assigned: query.get('assigned') === 'true',
  //       confirmed: query.get('confirmed') === 'true',
  //       done: query.get('done') === 'true',
  //       isDue: query.get('isDue') === 'true',
  //     });

  // },[]);

  const columns = [
      {
        name: "ID",
        label: "Referencia",
        width: 20,
      },
      {
        name: "FromLocationName",
        label: "Desde",
        width: 20,
        type: "string"
      },
      {
        name: "ToLocationName",
        label: "Hasta",
        width: 20,
        type: "string"
      },
      {
        name: "PartnerName",
        label: "Contacto",
        width: 20,
        type: "string"
      },
      {
        name: "ScheduledDate",
        label: "Fecha prevista",
        width: 10,
        type: "date"
      },
      {
        name: "Origin",
        label: "Documento origen",
        width: 20,
        type: "string"
      },
      {
        name: "State",
        label: "Estado",
        width: 20,
        type: "enum",
        customEnum: STOCKPICKING_STATE_ENUM
      },

    ];

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
    
      fields.push(
        <span>
          <CustomLink 
          content={p.name}
          to={`/${MODULE}/stockpicking/${p.id}`}>{p.name}</CustomLink>
        </span>
        
      );
      
      fields.push(
        <span>
          <CustomLink 
          content={p.fromLocationNname}
          to={`/${MODULE}/stocklocation/${p.fromLocationID}`}>{p.fromLocationName}</CustomLink>
            
        </span>
      );
      fields.push(
        <span>
          <CustomLink 
          content={p.toLocationName}
          to={`/${MODULE}/stocklocation/${p.toLocationID}`}>{p.toLocationName}</CustomLink>
            
        </span>
      );
      fields.push(
        <span>
          <CustomLink 
          content={p.partnerName}
          to={`/core/partner/${p.partnerID}`}>{p.partnerName}</CustomLink>
            
        </span>
      );

      fields.push(
        <span>
          {moment(p.scheduledDate).format("DD/MM/YYYY")}
        </span>
      );
      fields.push(
        <span>
          {p.origin}
            
        </span>
      );
        
      switch (p.state) {
        case 2:
          fields.push(
            <Badge className="me-1 rounded-pill bg-secondary font-size-11">Cancelado</Badge>
          );
          break;
        case 6:
          fields.push(
            <Badge className="me-1 rounded-pill bg-warning font-size-11">Preparado</Badge>
          );
          break;
        case 7:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">Hecho</Badge>
          );
          break;
        default:
          break;
      }


      rows.push({fields : fields});
    });

    return rows;
  };

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
    />
);

const customFilters = (
  <StockPickingCustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
  />
);


  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{orderCriteria: "ID", orderDirection: 1}}
      />
    </FormLayout>
  );
};

export default Index;
