import React from "react"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { Row, Col } from "reactstrap"
import CumstomField from "../../../../components/Common/CustomFields"
import CustomSwitch from "../../../../components/Common/CustomSwitch"
import CustomSelect from "../../../../components/Common/CustomSelect"
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect"
import { ACCOUNTTYPE_ENUM } from "./consts"

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
    <Row>
      <Col lg="4">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />

        <CumstomField
          {...form?.getInput("code")}
          label="Cuenta"
          type="text"
          disabled={disabled}
          required
        />

        <CustomSelect
          label="Grupo"
          {...form?.getSelect("accountGroup", "accountGroup")}
          isClearable
          isDisabled={disabled}
        />

        <CustomEnumSelect
          label="Tipo"
          {...form?.getEnumSelect("type", ACCOUNTTYPE_ENUM)}
          required
          isDisabled={disabled}
        />

        <CustomSwitch
          label="Activo"
          disabled={disabled}
          {...form?.getSwitch("active")}
        />
      </Col>
    </Row>
  )
}

export default BaseForm
