import CustomFilters from "../../../../components/Common/CustomFilters"

const StockQuantCustomFilters = (props) => {
  const { filter, setFilter, columns } = props

  const itemsFilter = [
    {
      name: "in",
      label: "Entrada",
    },
    {
      name: "out",
      label: "Salida",
    },
    {
      name: "internal",
      label: "Interno",
    },
    {
      name: "divider",
    },
    {
      name: "assigned",
      label: "Preparado",
    },
    {
      name: "done",
      label: "Hecho",
    }
  ]


  return (
    <CustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
    itemsFilter={itemsFilter}
  />
  );
};

export default StockQuantCustomFilters;
