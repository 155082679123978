export const SmartButton = props => {
  const { label, value, icon, onClick } = props


  return (
        <button className="btn btn-outline-secondary me-1" onClick={onClick}>
          <i className={`bx ${icon} font-size-16 align-middle`}></i>
          {label} <span className="badge bg-primary ms-1">{value}</span>
        </button>
      
  )
}

export default SmartButton
