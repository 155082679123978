import CustomFilters from "../../../../components/Common/CustomFilters"

const AccountCustomFilters = props => {
  const { filter, setFilter, columns } = props

  const itemsFilter = [
    
  ]


  return (
      <CustomFilters
        filter={filter}
        setFilter={setFilter}
        columns={columns}
        itemsFilter={itemsFilter}
      />
  )
}

export default AccountCustomFilters
