import React from "react"
import { useDispatch } from "react-redux"
import { Col } from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import useDynamicTable from "../../../../../hooks/useDynamicTable"
import { setApiError, setFormBounceEdit } from "../../../../../store/actions"
import DynamicTableSelect from "../../../../../components/Common/DynamicTable/DynamicTableSelect"
import DynamicTableField from "../../../../../components/Common/DynamicTable/DynamicTableField"
import CustomSelect from "../../../../../components/Common/CustomSelect"
import searchAsync from "../../../../../api/searchAsync"


const ProductPriceListTable = props => {
  const dispatcher = useDispatch()

  const { form, setFormState, disabled } = props

  const updateFields = async (name, index, value) => {
    let tempItems = [...form.fields?.productPriceLists]

    let tempItem = {
      ...tempItems[index],
      [name]: value,
    }

    tempItems[index] = tempItem

    setFormState({ ...form.fields, productPriceLists: tempItems })
  }

  const dynamicTable = useDynamicTable(
    form,
    setFormState,
    "productPriceLists",
    disabled,
    updateFields
  )

  const onSelect = async value => {
    let item = {
      priceList: value,
      price: 0,
      minAmount : 0
    }

    if(item.price < 0){
      dispatcher(setApiError("Precio inválido"))
      return;
    }


    if(form.fields?.productPriceLists.some(p  => p.priceList.id == item.priceList.id)){
      dispatcher(setApiError("Ya existe el elemento en la lista"))
      return;
    }

    if (item != null) {
      dynamicTable.addItem(item)
    }

    dynamicTable.setEditable(false)
  }

  return (
    <React.Fragment>
      <Col xs="12">
        <div
          className="table-responsive overflow-visible"
          onClick={() => {
            if (disabled) {
              dispatcher(setFormBounceEdit(true))
            }
          }}
        >
          <Table className="table mb-0">
            <Thead className="table-light">
              <Tr>
                <Th width="10%">Tarifa</Th>
                <Th width="10%">Precio</Th>
                <Th width="10%">Cantidad mínima</Th>
                <Th width="5%"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {dynamicTable.items?.map((p, key) => (
                <Tr key={"_product_" + key}>
                  <Th onClick={() => dynamicTable.onClick("priceList", key)}>
                    <DynamicTableSelect
                      header={true}
                      required
                      {...dynamicTable?.getSelect(
                        "priceList",
                        key,
                        "priceList",
                        "ignoreFirst=true"
                      )}
                    />
                  </Th>

                  <Td onClick={() => dynamicTable.onClick("price", key)}>
                    <DynamicTableField
                      type="number"
                      {...dynamicTable?.getInput("price", key)}
                    />
                  </Td>
                  <Td onClick={() => dynamicTable.onClick("minAmount", key)}>
                    <DynamicTableField
                      type="number"
                      {...dynamicTable?.getInput("minAmount", key)}
                    />
                  </Td>
                  <Td>
                    {disabled ? null : (
                      <a
                        href="#"
                        onClick={() => dynamicTable.removeItem(key)}
                        className="text-danger"
                      >
                        <i className="mdi mdi-close font-size-18 mr-3"></i>
                      </a>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Col>
      {disabled ? null : (
        <Col lg="4" sm="12">
          {dynamicTable.editable === "select" ? (
            <CustomSelect
              name="priceList"
              value={null}
              customRef={dynamicTable.selectRef}
              onFocusOut={() => dynamicTable.setEditable(null)}
              onChange={onSelect}
              loadOptions={inputValue => {
                return searchAsync("priceList", inputValue, "ignoreFirst=true")
              }}
            />
          ) : (
            <a
              className="text-primary"
              onClick={() => {
                dynamicTable.setEditable("select")
              }}
            >
              Agregar línea
            </a>
          )}
        </Col>
      )}
    </React.Fragment>
  )
}

export default ProductPriceListTable
