import React, { useEffect, useState } from 'react';

import { Row, Col, Card, CardBody, FormGroup, Button, Label,Container } from "reactstrap";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const Notification = (props) => {

    const { type,title, message } = props;

    const [showEasing, setshowEasing] = useState("swing");
    const [hideEasing, sethideEasing] = useState("linear");
    const [showMethod, setshowMethod] = useState("fadeIn");
    const [hideMethod, sethideMethod] = useState("fadeIn");
    const [showDuration, setshowDuration] = useState(300);
    const [hideDuration, sethideDuration] = useState(1000);
    const [timeOut, settimeOut] = useState(5000);
    const [extendedTimeOut, setextendedTimeOut] = useState(1000);

    useEffect(() => {
        showToast();
    },[]);

    function showToast(){
        var toastType = type;

        //Close Button
        var closeButton = true;

        //Debug
        var debug = false;

        //Progressbar
        var progressBar = false;

        //Duplicates
        var preventDuplicates = false;

        //Newest on Top
        var newestOnTop = true;

        //position class
        var positionClass = "toast-top-right";

        toastr.options = {
            positionClass : positionClass,
            timeOut: timeOut,
            extendedTimeOut : extendedTimeOut,
            closeButton : closeButton,
            debug : debug,
            progressBar : progressBar,
            preventDuplicates : preventDuplicates,
            newestOnTop : newestOnTop,
            showEasing : showEasing,
            hideEasing : hideEasing
        }
        
        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if(toastType === "info")
            toastr.info(message,title)
        else if(toastType === "warning")
            toastr.warning(message,title)
        else if(toastType === "error")
            toastr.error(message,title)
        else
            toastr.success(message,title)
        
    }

    return null;
}

export default Notification