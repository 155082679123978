import React, { useEffect } from "react"
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap"
import CumstomField from "../../../../components/Common/CustomFields"
import CustomSwitch from "../../../../components/Common/CustomSwitch"
import CustomSelect from "../../../../components/Common/CustomSelect"
import CustomCheckbox from "../../../../components/Common/CustomCheckbox"
import CustomFileImage from "../../../../components/Common/CustomFileImage"
import { SmartButtons } from "./SmartButtons"
import PartnerTabs from "./PartnerTabs"
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect"
import { DOCUMENTTYPE_ENUM } from "./consts"
import { setApiError, setApiLoading } from "../../../../store/actions"
import useErrors from "../../../../hooks/useErrors"
import { useDispatch, useSelector } from "react-redux"
import apiCall from "../../../../api"
import { partnerModal } from "./PartnerModal"

export const BaseForm = ({
  form,
  handleSubmit,
  disabled,
  setFormState,
  module,
}) => {
  const dispatcher = useDispatch()
  const errorHook = useErrors()
  const { action } = useSelector(state => state.FormActionStatus);

  useEffect(() => {
    if (action === "edit" || action === "create") {
      if (form.fields?.business) {
        setFormState({
          ...form.fields,
          ["documentType"]: form.fields?.business.documentType,
          ["document"]: form.fields?.business.document,
        })
      }
    }
  }, [form.fields?.business])
  const onGetDataFromAFIPClick = async e => {
    dispatcher(setApiLoading(true))

    var request = {
      cuit: form.fields?.document,
    }

    try {
      const results = await apiCall.post(`/partner/fetchDataAFIP`, request)

      dispatcher(setApiLoading(false))
      setFormState({
        ...form.fields,
        ["name"]: results.data?.data?.name,
        ["state"]: results.data?.data?.state,
        ["city"]: results.data?.data?.city,
        ["zipCode"]: results.data?.data?.zip,
        ["address"]: results.data?.data?.address,
        ["fiscalPosition"]: results.data?.data?.fiscalPosition,
      })
    } catch (error) {
      dispatcher(setApiLoading(false))
      dispatcher(setApiError(errorHook.getErrors(error)))
      console.error("fetchDataAFIP", error.message)
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="2" sm="3">
          <Label className="card-radio-label mb-3">
            <Input
              type="radio"
              name="businesstype"
              value="card"
              disabled={disabled}
              className="card-radio-input"
              checked={form.fields?.isBusiness}
              onClick={() => {
                if (action === "edit" || action === "create") {
                  setFormState({
                    ...form.fields,
                    ["isBusiness"]: true,
                    ["business"]: null,
                  })
                }
              }}
            />

            <div className="card-radio">
              <i className="bx bxs-business font-size-24 text-primary align-middle me-2" />

              <span>Empresa</span>
            </div>
          </Label>
        </Col>
        <Col lg="2" sm="3">
          <Label className="card-radio-label mb-3">
            <Input
              type="radio"
              name="businesstype"
              value="cash"
              disabled={disabled}
              className="card-radio-input"
              checked={!form.fields?.isBusiness}
              onClick={() => {
                if (action === "edit" || action === "create") {
                  setFormState({
                    ...form.fields,
                    ["isBusiness"]: false,
                    ["business"]: null,
                  })
                }
              }}
            />

            <div className="card-radio">
              <i className="bx bxs-user-circle font-size-24 text-primary align-middle me-2" />

              <span>Individuo</span>
            </div>
          </Label>
        </Col>
        {!form.fields?.isBusiness && form.fields?.isBusiness != null && (
          <Col lg="2" sm="3">
            <CustomSelect
              {...form?.getSelect(
                "business",
                "partner",
                `isBusiness=true&partnerType=${module == "purchases" ? 2 : 1}`
              )}
              isClearable
              isDisabled={disabled}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md="3">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />
          <CustomSwitch
            label="Activo"
            disabled={disabled}
            {...form?.getSwitch("active")}
          />

          <Row>
            <Col md="4" xs="4">
              <CustomEnumSelect
                label="Tipo"
                {...form?.getEnumSelect("documentType", DOCUMENTTYPE_ENUM)}
                isDisabled={
                  disabled ||
                  (!form.fields?.isBusiness && form.fields?.business != null)
                }
              />
            </Col>

            <Col md="8" xs="8">
              <CumstomField
                {...form?.getInput("document")}
                label="Documento"
                type="number"
                disabled={
                  disabled ||
                  (!form.fields?.isBusiness && form.fields?.business != null)
                }
              />
              {form.fields?.documentType == 80 &&
                !disabled &&
                !(
                  !form.fields?.isBusiness && form.fields?.business != null
                ) && (
                  <Button
                    color="primary"
                    className="btn btn-primary btn-sm me-1"
                    onClick={onGetDataFromAFIPClick}
                    disabled={
                      disabled ||
                      (!form.fields?.isBusiness &&
                        form.fields?.business != null)
                    }
                  >
                    Obtener datos desde AFIP
                  </Button>
                )}
            </Col>
            <CustomSelect
              label="Posición fiscal"
              {...form?.getSelect("fiscalPosition", "fiscalPosition")}
              isDisabled={
                disabled ||
                (!form.fields?.isBusiness && form.fields?.business != null)
              }
            />
          </Row>
        </Col>
        <Col md="2">
          <FormGroup className="mb-4">
            <Label>Condición</Label>
            <CustomCheckbox
              label="Cliente"
              disabled={disabled}
              {...form?.getCheckbox("customer")}
            />
            <CustomCheckbox
              label="Proveedor"
              disabled={disabled}
              {...form?.getCheckbox("supplier")}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <CustomFileImage
            label="Logo"
            {...form?.getFileImage("logoBase64", "logo")}
            disabled={disabled}
          />
        </Col>
        <Col md="5">
          <SmartButtons form={form} />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <CumstomField
            {...form?.getInput("email")}
            label="Email"
            type="email"
            disabled={disabled}
          />
        </Col>
        <Col md="3">
          <CumstomField
            {...form?.getInput("webSite")}
            label="Sitio web"
            type="text"
            disabled={disabled}
          />
        </Col>
        <Col md="3">
          <CustomSelect
            label="País"
            {...form?.getSelect("country", "country")}
            isClearable
            isDisabled={disabled}
          />
        </Col>
        <Col md="3">
          <CustomSelect
            label="Provincia"
            {...form?.getSelect("state", "state", true)}
            isClearable
            isDisabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <CumstomField
            {...form?.getInput("phone")}
            label="Teléfono"
            type="text"
            disabled={disabled}
          />
        </Col>
        <Col md="3">
          <CumstomField
            {...form?.getInput("movilPhone")}
            label="Celular"
            type="text"
            disabled={disabled}
          />
        </Col>
        <Col md="2">
          <CumstomField
            {...form?.getInput("zipCode")}
            label="Código postal"
            type="text"
            disabled={disabled}
          />
        </Col>

        <Col md="2">
          <CumstomField
            {...form?.getInput("city")}
            label="Ciudad"
            type="text"
            disabled={disabled}
          />
        </Col>

        <Col md="2">
          <CumstomField
            {...form?.getInput("address")}
            label="Dirección"
            type="text"
            disabled={disabled}
          />
        </Col>
      </Row>
      <PartnerTabs
        form={form}
        disabled={disabled}
        setFormState={setFormState}
      />
    </React.Fragment>
  )
}

export default BaseForm
