import React, { useEffect, useState } from "react";

import useForm from "../../../hooks/useForm";
import BaseForm from "./components/BaseForm";
import { MODEL, INITIAL_STATE, CREATE_TITLE, INITIAL_STATE_FILTER } from "./components/consts";
import { MODULE } from "../consts";
import DetailsPage from "../../../components/Actions/DetailsPage";
import { useDispatch, useSelector } from "react-redux";
import StatusBar from "./components/StatusBar";
import { setApiLoading } from "../../../store/actions";
import apiCall from "../../../api";
import AccountMoveActions from "./components/AccountMoveActions";
import AccountMoveDropdown from "./components/AccountMoveDropdown";
import useQueryURL from "../../../hooks/useQueryURL";

const Details = () => {
  const [formState, setFormState] = useState([]);
  const form = useForm({ formState });
  const { queryURL} = useQueryURL(INITIAL_STATE_FILTER);
  const { action } = useSelector((state) => state.FormActionStatus);
  const dispatcher = useDispatch();

  useEffect(() => {
    if(action === 'edit' || action === 'create'){
      fetchPartner();
    }
  }, [form.fields.partner]);

  const fetchPartner = async () => {
    
    if (form.fields?.partner == null) {
      setFormState({
        ...form.fields,
        ["priceList"]: { id: 1, name: "Tarifa pública" },
      });
      return;
    }
    dispatcher(setApiLoading(true));

    var request = {
      id: form.fields?.partner?.id,
    };

    try {
      const results = await apiCall.post(`/partner/fetchpartner`, request);

      dispatcher(setApiLoading(false));
      setFormState({
        ...form.fields,
        ["priceList"]: results.data?.data?.priceList,
      });
    } catch (error) {
      dispatcher(setApiLoading(false));
      console.log("fetchPartner", error.message);
    }
  };

  const statusBar = <StatusBar mobile={true} state={form.fields?.state} />;
  const dropdownSecondary= <AccountMoveDropdown id={form.fields?.id} />;

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      setFormState={setFormState}
      statusBar={statusBar}
      dropdownSecondary={dropdownSecondary}
      defaultValues
      defaultValuesParams={queryURL}
    >
      <AccountMoveActions form={form} />
      <BaseForm
        form={form}
        setFormState={setFormState}
        disabled={action != "edit" && action != "create" ? true : false}
      />
      <hr />
    </DetailsPage>
  );
};

export default Details;
