import  { useEffect } from "react";

import {  MODEL, BASE_URL, INDEX_TITLE, SALEINVOICE_STATUS_ENUM, SALEINVOICE_STATE_ENUM } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import { INITIAL_STATE_FILTER } from "./components/consts";
import {MODULE } from "../consts";
import SaleOrderCustomFilters from "./components/SaleOrderCustomFilters";
import moment from "moment";
import useQueryURL from "../../../hooks/useQueryURL";
import {Badge} from "reactstrap";
import CustomLink from "../../../components/Common/CustomLink";
import CustomBadge from "../../../components/Common/CustomBadge";

const Index = (props) => {

  const dispatch = useDispatch();
  const {filter, setFilter, queryURL} = useQueryURL(INITIAL_STATE_FILTER);

  
  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        name: "ID",
        label: "Número",
        width: 10,
        type: "number"
      },
      {
        name: "DateOrder",
        label: "Fecha",
        width: 10,
        type: "date"
      },
      {
        name: "PartnerName",
        label: "Cliente",
        width: 20,
        type: "string"
      },
      {
        name: "UserName",
        label: "Comercial",
        width: 20,
        type: "string"
      },
      {
        name: "Total",
        label: "Total",
        width: 10,
        type: "number"
      },
      {
        name: "DueDate",
        label: "Vencimiento",
        width: 10,
        type: "date",
        invisible: !filter?.sale
      },
      {
        name : "State",
        label: "Estado",
        width: 10,
        type:"enum",
        customEnum: SALEINVOICE_STATE_ENUM
      },
      {
        name: "InvoiceStatus",
        type: "enum",
        label: "Facturación",
        width: 10,
        customEnum: SALEINVOICE_STATUS_ENUM
      },
    ];

  const rows = (data) => {

    
    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
      
      fields.push(
        <CustomLink content={p.name} to={`${BASE_URL}/${p.id}${queryURL}`}>{p.name}</CustomLink>
      );
      
      fields.push(
        <span>
           {moment(p.dateOrder).format("DD/MM/YYYY hh:mm")}</span>
        );

      fields.push(
        <span>
          <CustomLink 
          content={p.partnerName}
          to={`/${MODULE}/partner/${p.partnerID}`}>{p.partnerName}</CustomLink>

            
        </span>
      );
      
      fields.push(
        <span>
          <CustomLink 
          content={p.userName}
          to={`/settings/user/${p.userID}`}>{p.userName}</CustomLink>

            
        </span>
      );

      fields.push(
      <span>$ {p.total.toFixed(2)}</span>
      );
      
      filter?.sale &&
      fields.push(
        <span>
           {moment(p.dueDate).format("DD/MM/YYYY")}</span>
        );

      switch (p.state) {
        case 1:
          fields.push(
            <Badge  className="me-1 rounded-pill bg-info font-size-11">Presupuesto</Badge>
          );
          
          break;
        case 2:
          fields.push(
            <Badge  className="me-1 rounded-pill bg-info font-size-11">Presupuesto enviado</Badge>
          );
          
          break;
        case 3:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">Pedido de venta</Badge>
          );
          break;
        case 5:
          fields.push(
            <Badge className="me-1 rounded-pill bg-secondary font-size-11">Cancelado</Badge>
          );
          break;
        case 4:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">Entregado</Badge>
          );
          break;
        default:
          break;
      }


      switch (p.invoiceStatus) {
        case 0:
          fields.push(
            <Badge  className="me-1 rounded-pill bg-info font-size-11">Nada para facturar</Badge>
          );
          
          break;
        case 1:
          fields.push(
            <Badge  className="me-1 rounded-pill bg-success font-size-11">Facturado</Badge>
          );
          
          break;
        case 2:
          fields.push(
            <Badge className="me-1 rounded-pill bg-warning font-size-11">Para facturar</Badge>
          );
          break;
       
        default:
          break;
      }

      rows.push({fields : fields});
    });

    return rows;
  };

  const customFilters = (
    <SaleOrderCustomFilters
      filter={filter}
      setFilter={setFilter}
      columns={columns}
    />
  );

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
      queryURL={queryURL}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{orderCriteria: "ID", orderDirection: 1}}
      />
    </FormLayout>
  );
};

export default Index;
