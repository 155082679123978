import React from "react";
import { Row, Col, Label, FormGroup } from "reactstrap";
import CustomCheckbox from "../../../../components/Common/CustomCheckbox";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSelect from "../../../../components/Common/CustomSelect";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import { STOCKWAREHOUSEORDERPOINT_LEADTYPE_ENUM } from "./consts";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="4">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />

          <CustomSelect
            label="Product"
            {...form?.getSelect("product", "product")}
            required
            isDisabled={disabled}
          />

          <CustomSwitch
            label="Activo"
            disabled={disabled}
            {...form?.getSwitch("active")}
          />
        </Col>

        <Col lg="4">
          <CustomSelect
            label="Almacén"
            {...form?.getSelect("stockWareHouse", "stockWareHouse")}
            required
            isDisabled={disabled}
          />
          
          <CustomSelect
            label="Unidad de medida"
            {...form?.getSelect(
              "product.productUnitOfMeasure",
              "productMeasureUnit"
            )}
            required
            isDisabled={true}
          />
          <CustomSelect
            label="Ubicación"
            {...form?.getSelect("stockLocation", "stockLocation")}
            required
            isDisabled={disabled}
          />
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col lg="4">
          <CumstomField
            {...form?.getInput("productMinQuantity")}
            label="Cantidad mínima"
            type="number"
            disabled={disabled}
            required
          />

          <CumstomField
            {...form?.getInput("productMaxQuantity")}
            label="Cantidad máxima"
            type="number"
            disabled={disabled}
            required
          />
          <CumstomField
            {...form?.getInput("quantityMultiple")}
            label="Múltiplo de la cantidad"
            type="number"
            disabled={disabled}
            required
          />
        </Col>
        <Col lg="4">
          <Row>
            <Col lg="4">
              <CumstomField
                {...form?.getInput("leadDays")}
                label="Plazo de entrega"
                type="number"
                disabled={disabled}
              />
            </Col>
            <Col lg="8">
              <CustomEnumSelect
                label="Tipo de plazo"
                {...form?.getEnumSelect(
                  "leadType",
                  STOCKWAREHOUSEORDERPOINT_LEADTYPE_ENUM
                )}
                isDisabled={disabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BaseForm;
