export const MODULE = 'inventory';
export const MODEL = 'productmeasureunit';

export const INDEX_TITLE = 'Unidades de medida';
export const CREATE_TITLE = 'Nueva unidad de medida';

export const INITIAL_STATE = {
    name: "",
    rounding: "0",
    uom_type: 2,
    active: true,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


export const UOMTYPE_ENUM = [
  { value: 1, label: 'Mas grande que la unidad de medida de referencia'},
  { value: 2, label: 'Unidad de medida de referencia para esta categoria'},
  { value: 3, label: 'Mas pequeña que la unidad de medida de referencia'},
];
