import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { useState } from "react"
import { Row, Col, Modal, Button, FormGroup, Input, Label } from "reactstrap"
import ErrorMessage from "../../components/Common/ErrorMessage"
import { useDispatch, useSelector } from "react-redux"
import {
  setApiError,
  setApiLoading,
  setValidationError,
} from "../../store/actions"
import apiCall from "../../api"
import useErrors from "../../hooks/useErrors"

export const Export = forwardRef((props, ref) => {
  const { columns, selected, searchBy, filter, sort, totalRecords } = props

  const [fields, setFields] = useState(columns)
  const errorsHook = useErrors()

  const [model, setModel] = useState()
  const [fileName, setFileName] = useState()

  const [exportFormat, setExportFormat] = useState("pdf")
  const dispatch = useDispatch()
  const { error, loading, validationError } = useSelector(
    state => state.ApiStatus
  )
  const [modal, setModal] = useState(false)

  const handleExport = async (e, v) => {
    try {
      const body = {
        fields: fields,
        selected: selected,
        format: exportFormat,
        paginationFilter: {
          filter: filter,
          text: searchBy,
          sort: sort,
        },
      }

      dispatch(setApiError(""))
      dispatch(setValidationError(""))

      dispatch(setApiLoading(true))


      const results = await apiCall.getFile(
        `/${model}/export`,
        `${fileName}.${exportFormat}`,
        "POST",
        body
      )

      tog_large()

      dispatch(setApiLoading(false))
    } catch (error) {
      dispatch(setApiError(errorsHook.getErrors(error)))
    }
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const handleChange = index => {
    let tempItems = [...fields]
    let field = tempItems[index]
    field.active = !field.active

    tempItems[index] = field
    setFields(tempItems)
  }

  const publicRef = {
    show: (model, fileName) => {
      setModel(model)
      setFileName(fileName)
      tog_large()
    },
  }

  useImperativeHandle(ref, () => publicRef)

  const tog_large = () => {
    setModal(!modal)
    removeBodyCss()
  }

  return (
    <React.Fragment>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <Modal
          size="lg"
          isOpen={modal}
          toggle={() => {
            tog_large()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Exportar
            </h5>
            <button
              onClick={() => {
                setModal(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ErrorMessage error={error} />
            <Row>
              <h5 className="font-size-14 mb-4">Campos</h5>
              {fields?.map((c, index) => {
                return (
                  <Col key={index} lg="3" sm="4">
                    <div className="form-check form-check-end">
                      <input
                        name={c.name}
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => handleChange(index)}
                        checked={c.active}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck2"
                      >
                        {c.label}
                      </label>
                    </div>
                  </Col>
                )
              })}
            </Row>
            <hr />
            <Row>
              <Col lg="3" sm="4">
                <Label className="card-radio-label mb-3">
                  <Input
                    type="radio"
                    name="paymentmethod"
                    value="cash"
                    className="card-radio-input"
                    checked={exportFormat == "pdf"}
                    onClick={() => setExportFormat("pdf")}
                  />

                  <div className="card-radio">
                    <i className="fas fa-file-pdf font-size-24 text-primary align-middle me-2" />
                    <span>PDF</span>
                  </div>
                </Label>
              </Col>
              <Col lg="3" sm="4">
                <Label className="card-radio-label mb-3">
                  <Input
                    type="radio"
                    name="paymentmethod"
                    value="card"
                    className="card-radio-input"
                    checked={exportFormat == "xlsx"}
                    onClick={() => setExportFormat("xlsx")}
                  />

                  <div className="card-radio">
                    <i className="fas fa-file-excel font-size-24 text-primary align-middle me-2" />

                    <span>Excel</span>
                  </div>
                </Label>
              </Col>
              <Col lg="3" sm="4">
                <Label className="card-radio-label mb-3">
                  <Input
                    type="radio"
                    name="paymentmethod"
                    value="card"
                    className="card-radio-input"
                    checked={exportFormat == "csv"}
                    onClick={() => setExportFormat("csv")}
                  />

                  <div className="card-radio">
                    <i className="fas fa-file-csv font-size-24 text-primary align-middle me-2" />

                    <span>CSV</span>
                  </div>
                </Label>
              </Col>
            </Row>

            <h5 className="font-size-14 mt-2">
              <i className="mdi mdi-arrow-right text-primary mr-1"></i>Se
              exportarán {selected.length === 0 ? totalRecords: selected.length} elementos
            </h5>
          </div>
          <div className="modal-footer">
            {loading ? null : (
              <button
                type="button"
                onClick={() => {
                  tog_large()
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            )}

            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={handleExport}
              disabled={loading}
            >
              {loading ? (
                <i className="bx bx-loader-alt bx-spin font-size-16 align-middle mr-2"></i>
              ) : null}
              Exportar
            </button>
          </div>
        </Modal>
      </Col>
    </React.Fragment>
  )
})
