import { MODULE } from "../../consts";

export const MODEL = 'posConfig';

export const INDEX_TITLE = 'Puntos de venta';
export const CREATE_TITLE = 'Nuevo punto de venta';

export const INITIAL_STATE = {
    name: "",
    posNumber: 0,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


