import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { Button, Spinner } from "reactstrap"
import useMenu from "../../hooks/useMenu"
import {
  setFormAction,
  setFormBounceEdit,
  setFormRefresh,
} from "../../store/actions"
import "../../assets/scss/custom/animations.scss"

const ActionButtons = props => {
  const { module, target, disableCreate, id } = props
  const { error, loading } = useSelector(state => state.ApiStatus)
  const { action, bounceEdit } = useSelector(state => state.FormActionStatus)
  const { GoTo } = useMenu()
  let history = useHistory()
  let location = useLocation()

  const dispatch = useDispatch()

  const btnCancelAction = () => {
    if (action.toUpperCase() === "EDIT") {
      dispatch(setFormRefresh(true))
      dispatch(setFormAction("details"))
      //history.push(`/${module}/${target}/${id}`);
    } else {
      //GoTo("-1",`/${module}/${target}`);
      history.goBack()
    }
  }

  const btnCreateAction = () => {
    GoTo(
      "Nuevo",
      `/${module}/${target}/create${location.search}`,
      true,
      null,
      null,
      true
    )
    //dispatch(setFormAction('create'));
  }

  const btnEditAction = () => {
    dispatch(setFormAction("edit"))
    //history.push(`/${module}/${target}/edit/${id}`);
  }

  return (
    <div className="bd-highlight">
      {action.toUpperCase() === "CREATE" || action.toUpperCase() === "EDIT" ? (
        <React.Fragment>
          <Button
            color="primary"
            className="btn btn-primary btn-sm me-1"
            type="submit form="
            form="hook-form"
            disabled={loading}
          >
            Guardar
          </Button>
          <Button
            color="secondary"
            className="btn btn-secondary btn-sm"
            onClick={btnCancelAction}
            disabled={loading}
          >
            Descartar
          </Button>
        </React.Fragment>
      ) : null}
      {action.toUpperCase() === "DETAILS" ? (
        <React.Fragment>
          <Button
            color="primary"
            className={`btn btn-primary btn-sm me-1 ${
              bounceEdit ? "bounce-effect" : ""
            }`}
            onClick={btnEditAction}
            disabled={loading}
            onAnimationEnd={() => {
              dispatch(setFormBounceEdit(false))
            }}
          >
            Editar
          </Button>
          {!disableCreate && (
            <Button
              color="secondary"
              className="btn btn-secondary btn-sm"
              onClick={btnCreateAction}
              disabled={loading}
            >
              Crear
            </Button>
          )}
        </React.Fragment>
      ) : null}

      {loading ? (
        <Spinner
          style={{ width: "1.0rem", height: "1.0rem" }}
          type="grow"
          className="ms-2"
          color="primary"
        />
      ) : null}
    </div>
  )
}

export default ActionButtons
