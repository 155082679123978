import React, { useState } from "react"

import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authRoutes, userRoutes } from "./routes/allRoutes"

// layouts Format
//import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
// import "./assets/scss/theme.scss"

import Pages404 from "./pages/Utils/pages-404"
import PagesError from "./pages/Utils/pages-error"
import Autoscanner from "./pages/Autoscanner/index"
import Pos from "./pages/Pos/index"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import { useErrorBoundary } from "use-error-boundary"

const App = props => {

  const [errorInfo, setErrorInfo] = useState()

  const { ErrorBoundary, didCatch, error, reset } = useErrorBoundary({
    onDidCatch: (error, errorInfo) => {

      setErrorInfo(errorInfo)
      // ModalError({
      //   error: error,
      //   errorInfo: errorInfo,
      // }).then(() => {
      //   reset()
      // })
    },
  })

  const { layout } = props

  function getLayout() {
    let layoutCls = HorizontalLayout
    // let layoutCls = VerticalLayout

    // switch (layout.layoutType) {
    //   case "horizontal":
    //     layoutCls = HorizontalLayout
    //     break
    //   default:
    //     layoutCls = HorizontalLayout
    //     // layoutCls = VerticalLayout
    //     break
    // }
    return layoutCls
  }

  const Layout = getLayout()

  const NonAuthmiddleware = ({ component: Component, layout: Layout }) => (
    <Route
      render={props => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )

  return didCatch ? (
    <PagesError error={error} errorInfo={errorInfo} reset={reset} />
  ) : (
    <ErrorBoundary>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
            />
          ))}

          {/* <Layout>
            <Routes />
          </Layout> */}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          <Route path="/autoscanner/:barcode" component={Autoscanner} />
          <Route path="/autoscanner" component={Autoscanner} />
          <Route path="/pos" component={Pos} />
          <Route component={Pages404} />
        </Switch>
      </Router>
    </ErrorBoundary>
  )
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
