import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Container, CardTitle } from "reactstrap"
import BarChart from "../../components/Charts/BarChart"
import CustomLink from "../../components/Common/CustomLink"
import FormLayout from "../../components/Common/FormLayout"
import useAPIFunctions from "../../hooks/useAPIFunctions"
import { setPageTitle } from "../../store/actions"
import LatestTransactions from "./LatestTransactions"

const SalesDashboard = props => {
  const [results, setResults] = useState([])

  const { doGetAsync } = useAPIFunctions()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle("Ventas"))
    doFetch()
  }, [])

  const doFetch = async () => {
    try {
      const response = await doGetAsync(`/sales/dashboard`)

      setResults(response)
    } catch (error) {
      console.log("doFetch", error.message)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bxs-user" />
                      </span>
                    </div>
                    <CustomLink content="Clientes" to={"/sales/partner"}>
                      <h4 className="font-size-14 mb-0 text-primary">
                        Clientes
                      </h4>
                    </CustomLink>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>
                      {results.data?.data?.activeCustomers} activos
                    </h5>
                    <div className="d-flex">
                      <span className="text-truncate">
                        ({results.data?.data?.inactiveCustomers} inactivos)
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <CustomLink
                      content="Nuevo cliente"
                      to={"/sales/partner/create"}
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Crear <i className="mdi mdi-arrow-right ms-1"></i>
                    </CustomLink>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-store-alt" />
                      </span>
                    </div>
                    <CustomLink
                      content="Productos vendible"
                      to={"/sales/product"}
                    >
                      <h4 className="font-size-14 mb-0 text-primary">
                        Productos
                      </h4>
                    </CustomLink>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>
                      {results.data?.data?.activeProducts} productos activos
                    </h5>
                    <div className="d-flex">
                      <span className="text-truncate">
                        ({results.data?.data?.inactiveProducts} inactivos)
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <CustomLink
                      content="Nuevo producto"
                      to={"/sales/product/create"}
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Crear <i className="mdi mdi-arrow-right ms-1"></i>
                    </CustomLink>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-file" />
                      </span>
                    </div>
                    <CustomLink
                      content="Presupuestos"
                      to={"/sales/saleorder?draft=true&sent=true"}
                    >
                      <h4 className="font-size-14 mb-0 text-primary">
                        Presupuestos
                      </h4>
                    </CustomLink>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>
                      {results.data?.data?.activeSaleOrdersDraft} presupuestos
                      activos
                    </h5>
                    <div className="d-flex">
                      <p className="text-truncate">
                        {" "}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <CustomLink
                      content="Nuevo presupuesto"
                      to={"/sales/saleorder/create?draft=true"}
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Crear <i className="mdi mdi-arrow-right ms-1"></i>
                    </CustomLink>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-file" />
                      </span>
                    </div>
                    <CustomLink
                      content="Pedidos"
                      to={"/sales/saleorder?sale=true"}
                    >
                      <h4 className="font-size-14 mb-0 text-primary">
                        Pedidos
                      </h4>
                    </CustomLink>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>{results.data?.data?.activeSaleOrders} pedidos</h5>
                    <div className="d-flex">
                      <span className="text-truncate">
                        Saldo acreedor $ {results.data?.data?.amountSaleOrders}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <CustomLink
                      content="Nuevo pedido"
                      to={"/sales/saleOrder/create?sale=true"}
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Crear <i className="mdi mdi-arrow-right ms-1"></i>
                    </CustomLink>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {results?.data?.data?.bestProducts?.categories?.length > 0 ? (
              <Col lg={results?.data?.data?.bestServices?.categories?.length > 0 ? 6 : 12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Productos más vendidos del último mes
                    </CardTitle>
                    <BarChart data={results?.data?.data?.bestProducts} />
                  </CardBody>
                </Card>
              </Col>
            ) : null}

            {results?.data?.data?.bestServices?.categories?.length > 0 ? (
              <Col lg={results?.data?.data?.bestProducts?.categories?.length > 0 ? 6 : 12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                    Servicios más vendidos del último mes
                    </CardTitle>
                    <BarChart data={results?.data?.data?.bestServices} />
                  </CardBody>
                </Card>
              </Col>
            ) : null}
            <Col lg="6">
              <LatestTransactions
                label="Últimos presupuestos"
                transactions={results?.data?.data?.lastDrafts}
              />
            </Col>
            <Col lg="6">
              <LatestTransactions
                label="Últimos pedidos"
                transactions={results?.data?.data?.lastSales}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SalesDashboard
