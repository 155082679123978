import React from "react"
import { Row, Col } from "reactstrap"
import CumstomField from "../../../../components/Common/CustomFields"
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect"
import { DOCUMENTTYPE_ENUM, FISCALPOSITION_ENUM } from "./consts"
import CustomSelect from "../../../../components/Common/CustomSelect"
import CustomFileImage from "../../../../components/Common/CustomFileImage"

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
    <Row>
      <Col md="3">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />

        <CumstomField
          {...form?.getInput("email")}
          label="Email"
          type="email"
          disabled={disabled}
          required
        />

        <CumstomField
          {...form?.getInput("phone")}
          label="Teléfono"
          type="phone"
          disabled={disabled}
        />

        <CustomSelect
          label="Compañia central"
          {...form?.getSelect("parentCompany", "company")}
          isDisabled={disabled}
        />
      </Col>
      <Col md="3">
        <CumstomField
          {...form?.getInput("fiscalName")}
          label="Razón social"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />

        <CustomEnumSelect
          label="Tipo de documento"
          {...form?.getEnumSelect("documentType", DOCUMENTTYPE_ENUM)}
          isDisabled={disabled}
        />
        <CumstomField
          {...form?.getInput("document")}
          label="Documento"
          type="text"
          disabled={disabled}
        />

        <CustomSelect
          label="Posición fiscal"
          {...form?.getSelect("fiscalPosition", "fiscalPosition")}
          isDisabled={disabled}
        />
        <CustomSelect
          label="Provincia"
          {...form?.getSelect("state", "state", true)}
          isClearable
          isDisabled={disabled}
        />
        <CumstomField
          {...form?.getInput("address")}
          label="Dirección fiscal"
          type="text"
          disabled={disabled}
        />
        <CumstomField
          {...form?.getInput("grossIncome")}
          label="IIBB"
          type="text"
          disabled={disabled}
        />
        <CumstomField
          {...form?.getInput("startDate")}
          label="Inicio de actividades"
          type="text"
          disabled={disabled}
        />
      </Col>

      <Col md="2">
        <CustomFileImage
          label="Logo"
          {...form?.getFileImage("logo")}
          disabled={disabled}
        />
      </Col>
    </Row>
  )
}

export default BaseForm
