import { Row, TabPane } from 'reactstrap'
import RolesTable from '../Tables/RolesTable';

const RolesTab = (props) => {
    
    const { form, disabled, setFormState } = props;

    return(
        <TabPane tabId="1" className="p-3">
          <Row>
          <RolesTable
          form={form}
          setFormState={setFormState}
          disabled={disabled}
        />
          </Row>
        </TabPane>
    )
}

export default RolesTab