import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Button } from "reactstrap"
import useMenu from "../../../hooks/useMenu"

const IndexActionButtons = props => {
  const { GoTo } = useMenu()

  const { url, history, queryURL, disableCreate } = props

  const { error, loading } = useSelector(state => state.ApiStatus)

  const btnCreateAction = () => {

    if (queryURL) {
      GoTo("Nuevo", `${url}/create${queryURL}`, true)
      //history.push(`${url}/create${queryURL}`);
    } else {
      GoTo("Nuevo", `${url}/create`, true)
      //history.push(`${url}/create`);
    }
  }

  return (
    <div className="button-items mb-1">
      {!disableCreate && (
        <Button
          color="primary"
          className="btn btn-primary waves-effect waves-light btn-sm"
          onClick={btnCreateAction}
        >
          Crear
        </Button>
      )}

      {/* {loading ? (<Spinner type="grow" className="ml-2" color="primary" />) : null} */}
    </div>
  )
}

export default withRouter(IndexActionButtons)
