import { MODULE } from "../../consts";

export const MODEL = 'saleorder';

export const INDEX_TITLE = 'Presupuestos';
export const CREATE_TITLE = 'Nueva presupuesto';

export const INITIAL_STATE = {
    name: "",
    partner: null,
    priceList : {
      id : 1,
      name : "Tarifa pública"
    },
    pickingPolicy: 2,
    validityDate: null,
    saleOrderItems: []
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const SALEINVOICE_STATUS_ENUM = [
  { value: 0, label: 'Nada que facturar'},
  { value: 1, label: 'Facturado'},
  { value: 2, label: 'A facturar'},
];

export const SALEINVOICE_STATE_ENUM = [
  { value: 1, label: 'Presupuesto'},
  { value: 2, label: 'Presupuesto enviado'},
  { value: 3, label: 'Pedido de venta'},
  { value: 4, label: 'Entregado'},
  { value: 5, label: 'Cancelado'},
];


export const SALE_PICKINGPOLICY_ENUM = [
  { value: 1, label: 'Entregar cada producto cuando este disponible"'},
  { value: 2, label: 'Entregar todos los productos a la vez'},
];

export const INITIAL_STATE_FILTER = {
  product:null,
  partner: null,
  onlyMe: true,
  draft: false,
  sent: false,
  sale: false,
  done: false,
  customFilters: []
};
