import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import useAPI from "../../hooks/useAPI";
import useMenu from "../../hooks/useMenu";

const ConfirmDelete = (props) => {

  const { showDelete, setShowDelete, model, id, onDelete } = props;
  const url = `/${model}/${id}`;

  const {result, doRefresh} = useAPI(url,'delete',null,true);
  const {  GoBack } = useMenu()
  const { error, loading } = useSelector(state => state.ApiStatus);

  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [error_dlg, seterror_dlg] = useState(false);

  

  useEffect(() => {

    if(result != null){

        setShowDelete(false);
        setsuccess_dlg(true);
        setdynamic_title("¡Borrado!");
        setdynamic_description("El registro ha sido borrado");
    }
    

  },[result]);

  // useEffect(() => {

  //   if(error != ''){

  //       setShowDelete(false);
  //       seterror_dlg(true);
  //       setdynamic_title("Error");
  //       setdynamic_description(error);
  //   }
    

  // },[error]);

  const deleteRecord = () => {
    setShowDelete(false);
    doRefresh();
    GoBack();
  }


  return (
    <React.Fragment>
      {showDelete ? (
        <SweetAlert
          title="¿Está seguro que desea eliminar este elemento?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={deleteRecord}
          onCancel={() =>
            {
             setShowDelete(false);
            }
        }
        >
          ¡Esta operación es irreversible!
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false);
            onDelete();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {error_dlg ? (
        <SweetAlert
          error
          title={dynamic_title}
          onConfirm={() => {
            seterror_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  );
};

export default ConfirmDelete;
