import React from "react"
import AsyncSelect from "react-select/async"
import {  Label } from "reactstrap"

const DynamicTableMultiSelect = props => {
  const {
    name,
    value,
    label,
    isClearable,
    isDisabled,
    onChange,
    loadOptions,
    onFocusOut
  } = props

  return (
    <React.Fragment>
      {label ? <Label>{label}</Label> : null}
      {isDisabled ? (
        <span className="d-block text-muted text-truncate field-muted">{value?.map(e => e.name).join(", ")}</span>
      ) : (
        <AsyncSelect
          isMulti
          name={name}
          value={value}
          onChange={onChange}
          className="react-select"
          classNamePrefix="react-select"
          isClearable={isClearable}
          isDisabled={isDisabled}
          defaultOptions
          placeholder=""
          cacheOptions
          onBlur={onFocusOut}
          loadOptions={loadOptions}
          getOptionLabel={e => e.name}
          getOptionValue={e => e.id}
        />
      )}
    </React.Fragment>
  )
}

export default DynamicTableMultiSelect
