import { Row, Col } from "reactstrap";
import CustomSelect from "../../../../components/Common/CustomSelect";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSwitch from "../../../../components/Common/CustomSwitch";

import { UOMTYPE_ENUM } from "./consts";

export const BaseForm = ({form,handleSubmit,disabled}) => {


  return (
      <Row>
        <Col md="6">
          <CumstomField
              {...form?.getInput("name")}
              label="Nombre"
              type="text"
              disabled={disabled}
              required
              autoFocus={true}
            />
          <CustomSelect
              label="Categoría"
              {...form?.getSelect("productUnitOfMeasureCategory", "productmeasureunitcategory")}
              isClearable
              isDisabled={disabled}
            />
          <CustomEnumSelect
              label="Tipo"
              {...form?.getEnumSelect("uom_type", UOMTYPE_ENUM)}
              isDisabled={disabled}
            />
          
          <CumstomField
              {...form?.getInput("ratio")}
              label="Ratio"
              type="number"
              disabled={disabled}
              required
            />
          <CumstomField
              {...form?.getInput("rounding")}
              label="Precisión"
              type="number"
              disabled={disabled}
              required
            />
          <CustomSwitch
              label="Activo"
              disabled={disabled}
              {...form?.getSwitch("active")}
            />
          
        </Col>
      </Row>
  );
};

export default BaseForm;
