import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import {  MODEL, BASE_URL, INDEX_TITLE, FISCALPOSITION_ENUM, DOCUMENTTYPE_ENUM  } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import { getLabelFromArray } from "../../../helpers/array_utils";
import CustomLink from "../../../components/Common/CustomLink";

const Index = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        label: "Nombre",
        width: 30,
      },
      {
        label: "Posición fiscal",
        width: 30,
      },
      {
        label: "Tipo de documento",
        width: 30,
      },
      {
        label: "Documento",
        width: 30,
      },
     
    ];

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
    

      fields.push(
        <CustomLink
        content={p.name}
        to={`${BASE_URL}/${p.id}`}>{p.name}</CustomLink>
      );
      
      
      fields.push(
        <span>
            {p.fiscalPosition?.name}
        </span>
      );

      fields.push(
        <span>
            {getLabelFromArray(p.documentType, DOCUMENTTYPE_ENUM)}
        </span>
      );

      fields.push(
      <span>{p.document}</span>
      );
      
     

      rows.push({fields : fields});
    });

    return rows;
  };

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={{
          customFilters: [],
        }}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
        
      />
    </FormLayout>
  );
};

export default Index;
