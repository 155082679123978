import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";

function isBoolean(val) {
    return val === 'false' || val === 'true';
  }

function isNumeric(num){
    return !isNaN(num)
  }

const useQueryURL = (initFilter) => {

    const [filter, setFilter] = useState(null);
    let location =useLocation();

    let params = new URLSearchParams(location.search);
    
    useEffect(() => {
        
        var filtered = false;

        let tempFilter = cloneDeep(initFilter);

        for (let p of params) {
        
            if(isBoolean(p[1])){
                tempFilter[p[0]] = p[1] === 'true';
                filtered= true;
            }
            else if(isNumeric(p[1])){
                tempFilter[p[0]] = parseInt(p[1]);
                filtered= true;
            }else{
                tempFilter[p[0]] = p[1];
                filtered= true;
            }
        }
        // if(filtered){
        //     debugger;
            setFilter(tempFilter);
        // }

    }, [location]);



    return {
        filter,
        setFilter,
        queryURL: location.search
      };
};

export default useQueryURL;