import { MODULE } from "../../consts";

export const MODEL = 'stockpickingtype';

export const INDEX_TITLE = 'Tipos de operaciones';
export const CREATE_TITLE = 'Nuevo tipo de operación';

export const INITIAL_STATE = {
    name: "",
    prefix: "",
    pickingType: 3,
    stockWareHouse: null,
    DefaultStockLocationSource: null,
    DefaultStockLocationDestiny: null,
    active: true,

  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const STOCKPICKINGTYPE_ENUM = [
  { value: 1, label: 'Entrada'},
  { value: 2, label: 'Salida'},
  { value: 3, label: 'Interna'},
];
