import { useDispatch } from "react-redux";
import apiCall from "../../../api";
import useAPIFunctions from "../../../hooks/useAPIFunctions";
import { setApiLoading, setFormAction, setFormRefresh } from "../../../store/actions";
import useMenu from "../../../hooks/useMenu";

const useSaleOrderActions = (id) => {
  const { doGetAsync } = useAPIFunctions();
  const dispatch = useDispatch();
  const { GoTo} = useMenu();

  const doConfirm = async () => {
    try {
      const results = await doGetAsync(`/saleorder/confirm/${id}`);

      
      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
    } catch (error) {
      console.log("Confirm", error.message);
    }
  };
  const doCancel = async () => {
    try {
      const results = await doGetAsync(`/saleorder/cancel/${id}`);

      
      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
    } catch (error) {
      console.log("Cancel", error.message);
    }
  };

  const doCreateInvoice = async () => {

    try {
      const results = await doGetAsync(`/saleorder/createinvoice/${id}`);
      GoTo('Factura',`/invoices/accountInvoice/${results.data.data}`)

    } catch (error) {
      console.log("Create invoice", error.message);
    }
  };


  const doPrint = async (name) => {

    dispatch(setApiLoading(true))

    try {
      await apiCall.getFile(
        `/saleorder/report/${id}`,
        `${name}.pdf`
      );

      dispatch(setApiLoading(false))
    } catch (error) {
      console.log("Report", error.message);
      dispatch(setApiLoading(false))
    }
  };

  const doSendMail = async () => {
    try {
      const results = await doGetAsync(`/saleorder/sendmail/${id}`);

      dispatch(setFormAction("details"));
      dispatch(setFormRefresh(true));
      
    } catch (error) {
      console.log("SendMail", error.message);
    }
  };

  return {
    doConfirm,
    doPrint,
    doSendMail,
    doCreateInvoice,
    doCancel
  };
};

export default useSaleOrderActions;
