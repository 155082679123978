export const MODULE = 'inventory';
export const MODEL = 'productTag';

export const INDEX_TITLE = 'Etiquetas de producto';
export const CREATE_TITLE = 'Nueva etiqueta';

export const INITIAL_STATE = {
    name: "",
    color: "#707070"
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


