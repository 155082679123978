import React from "react";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import CustomSelect from "../../../../components/Common/CustomSelect";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
      <Row>
        <Col lg="4">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />
        
        <CustomSelect
            label="Diario de pago"
            {...form?.getSelect("accountJournal", "accountJournal","types=3,4,5,7&forInvoice=true")}
            isDisabled={disabled}
          />

        <CustomSelect
            label="Diario de pago de pedido"
            {...form?.getSelect("saleOrderAccountJournal", "accountJournal", "types=3,4,5,7&forInvoice=false")}
            isDisabled={disabled}
          />
        </Col>
       
      </Row>
  );
};

export default BaseForm;
