import React from "react"
import { Col, Row } from "reactstrap"
import CustomDateTime from "../../../../components/Common/CustomDateTime"
import CumstomField from "../../../../components/Common/CustomFields"
import CustomSelect from "../../../../components/Common/CustomSelect"
import { getLabelFromArray } from "../../../../helpers/array_utils"
import { DOCUMENTTYPE_ENUM } from "../../../Core/Partner/components/consts"
import { partnerModal } from "../../../Core/Partner/components/PartnerModal"

const BaseFormPurchase = props => {
  const { form, disabled, internalType, readOnlyStates } = props

  return (
    <React.Fragment>
      <Col md="4">
        <Row>
          <Col md="12" lg="6">
            <CustomSelect
              key={JSON.stringify(
                internalType + (form.fields?.partner?.id ?? 0)
              )}
              label="Comprobante"
              {...form?.getSelect(
                "accountInvoiceType",
                "accountInvoiceType",
                `internalType=${internalType}`
              )}
              isDisabled={
                disabled || readOnlyStates.includes(form.fields?.invoiceState)
              }
              required
            />
          </Col>
          <Col md="12" lg="6">
          <CumstomField
            {...form?.getInput("invoiceRef")}
            label="Referencia"
            type="number"
            disabled={disabled || readOnlyStates.includes(form.fields?.invoiceState)}
            required
            autoFocus={true}
          />
          </Col>
        </Row>

        <CustomSelect
          label={`Proveedor ${
            form.fields?.partner
              ? `[${getLabelFromArray(
                  form.fields?.partner?.documentType,
                  DOCUMENTTYPE_ENUM
                )} - ${form.fields?.partner?.document}]`
              : ""
          }`}
          {...form?.getSelect("partner", "partner", "partnerType=2",partnerModal,{active: true, supplier: true})}
          isDisabled={
            disabled || readOnlyStates.includes(form.fields?.invoiceState)
          }
          required
        />

        <Row>
          <Col md="12" lg="6">
            <CustomSelect
              label="Plazos de pago"
              {...form?.getSelect("accountPaymentTerm", "accountPaymentTerm")}
              required
              isDisabled={
                disabled ||
                readOnlyStates.includes(form.fields?.invoiceState) ||
                form.fields?.partner === null
              }
            />
          </Col>
          <Col md="12" lg="6">
            <CustomDateTime
              label="Fecha vencimiento"
              disabled={
                disabled || readOnlyStates.includes(form.fields?.invoiceState)
              }
              {...form?.getInputDate("date", "dueInvoice")}
            />
          </Col>
        </Row>
      </Col>

      <Col md="4">
            <CustomDateTime
              label="Fecha de la factura"
              disabled={
                disabled || readOnlyStates.includes(form.fields?.invoiceState)
              }
              {...form?.getInputDate("date", "dateInvoice")}
            />

            <CustomDateTime
              label="Fecha contable"
              disabled={
                disabled || readOnlyStates.includes(form.fields?.invoiceState)
              }
              {...form?.getInputDate("date", "accountingDate")}
            />

        <CustomSelect
          label="Diario"
          {...form?.getSelect(
            "accountJournal",
            "accountJournal",
            "types=2&forInvoice=true"
          )}
          required
          isDisabled={
            disabled || readOnlyStates.includes(form.fields?.invoiceState)
          }
        />
      </Col>
    </React.Fragment>
  )
}

export default BaseFormPurchase
