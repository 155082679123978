const useErrors = () => {
  const getErrors = err => {

    debugger;

    if (err.response) {
      if (err.response.status === 404) {
        return err.message
      }

      if (err.response.status === 401) {
        return err.message
      }

      var message = ""

      for (var k in err.response?.data?.errors) {
        message += err.response.data.errors[k][0] + "\n"
      }

      if (err.response?.data?.detail) {
        message = err.response?.data?.detail
      }

      if (message != "") {
        return message
      }

      return err.message


    }
  }
  return {
    getErrors,
  }
}

export default useErrors
