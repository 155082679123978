import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import SmartButton from "../../../../components/Common/SmartButton";
import useMenu from "../../../../hooks/useMenu";

const SmartButtons = (props) => {

    const { history, form } = props;
    const { action } = useSelector(state => state.FormActionStatus);

    const { GoTo} = useMenu();


  const btnInvoicesAction = (e) => {

    if(form.fields?.accountPaymentItems?.length === 1){
      GoTo(form.fields?.accountPaymentItems[0].name,`/invoices/accountInvoice/${form.fields?.accountPaymentItems[0].id}`)
    }else{
      GoTo('Facturas',`/invoices/accountInvoice?accountPayment=${form.fields?.id}`)
    }
    
  };

  const btnSalesAction = (e) => {

    if(form.fields?.accountPaymentItemSaleOrder?.length === 1){
      GoTo(form.fields?.accountPaymentItemSaleOrder[0].name,`/sales/saleorder/${form.fields?.accountPaymentItemSaleOrder[0].id}`)
    }else{
      GoTo('Facturas',`/sales/saleorder?accountPayment=${form.fields?.id}`)
    }
    
  };

  return (action === 'create' ? null : 
    <div className="d-flex flex-row-reverse">

      {form.fields?.accountPaymentItems?.length > 0 ? (
         <SmartButton
         onClick={btnInvoicesAction}
         icon="detail"
         label="Facturas"
         value={form.fields?.accountPaymentItems?.length}
       />
        
      ) : null}
      {form.fields?.accountPaymentItemSaleOrder?.length > 0 ? (
         <SmartButton
         onClick={btnSalesAction}
         icon="detail"
         label="Pedidos"
         value={form.fields?.accountPaymentItemSaleOrder?.length}
       />
        
      ) : null}
     
    </div>
  );
};

export default withRouter(SmartButtons);
