import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Nav,
  NavItem,
  Form,
  Input,
  CardBody,
  NavLink,
} from "reactstrap"
import Footer from "./Components/Footer"
import Header from "./Components/Header"
import classnames from "classnames"
import { isEmpty, map } from "lodash"
import img1 from "../../assets/images/product/img-1.png"
import Leftbar from "./Components/Leftbar"
import Rightpanel from "./Components/Rightpanel"
import Partner from "./Components/Partner"
import Payment from "./Components/Payment"
import PostPayment from "./Components/PostPayment"
import Sales from "./Components/Sales"
import("../../assets/scss/pos/Pos.scss")

const Index = props => {
  useEffect(() => {
    document.getElementById("preloader").style.display = "block"
    document.getElementById("status").style.display = "block"

    setTimeout(function () {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }, 1000)
  }, [])

  const [productList, setProductList] = useState([])

  const [mode, setMode] = useState(0)

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
      <div className="pos">
        <Header mode={mode} setMode={setMode} />
        <div className="pos-content">
          <div className="window">
            <div className="screen-full-width">
            {mode == 0 && (
                <React.Fragment>
                <Leftbar setMode={setMode} />
                <Rightpanel />
              </React.Fragment>
              )}
              {mode == 1 && (
                <Payment setMode={setMode} />
              )}
              {mode == 2 && (
                <Partner setMode={setMode} />
              )}
              {mode == 3 && (
                <Sales setMode={setMode} />
              )}
              {mode == 4 && (
                <PostPayment setMode={setMode} />
              )}
              
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  )
}

export default Index
