import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'
import CustomDateTime from '../../../../../components/Common/CustomDateTime'
import CustomEnumSelect from '../../../../../components/Common/CustomEnumSelect'
import CustomSelect from '../../../../../components/Common/CustomSelect'
import { SALE_PICKINGPOLICY_ENUM } from '../consts'

const InformationTab = (props) => {

    const { form, disabled } = props;
    
    return(
        <TabPane tabId="2" className="p-3">
          <Row>
            <Col lg="6">
            <CustomSelect
                label="Almacén"
                {...form?.getSelect("stockWareHouse", "stockwarehouse")}
                isDisabled={disabled}
                required
              />
              <CustomEnumSelect
                label="Acuerdo de entrega"
                {...form?.getEnumSelect("pickingPolicy", SALE_PICKINGPOLICY_ENUM)}
                isDisabled={true}
              />
              
              <CustomDateTime
              label="Fecha de solicitud"
              disabled={disabled}
              {...form?.getInputDate("datetime-local","dateOrder")}
            />
            
            </Col>
            <Col lg="4">
            <CustomSelect
                label="Comercial"
                {...form?.getSelect(
                  "user",
                  "user"
                )}
                isClearable
                isDisabled={disabled}
              />
              
            </Col>
          </Row>
        </TabPane>
    )
}

export default InformationTab