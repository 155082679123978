import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from "react-redux"
import { setApiError } from '../../store/actions';

export const ErrorMessage = (props) => {

    const { error } = props;
    const dispatch =useDispatch()
    
    return(
        <React.Fragment>
        {error !=null && error !='' ? (
            <SweetAlert
              title="Error"
              warning
              onConfirm={() => {
                dispatch(setApiError(""))
                // setwith_title(false)
              }}
            >
              {error}
            </SweetAlert>
          ) : null}
        </React.Fragment>
    )
}

export default ErrorMessage