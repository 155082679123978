import React from "react";
import { Row, Col } from "reactstrap";
import CustomSelect from "../../../../components/Common/CustomSelect";
import CustomDateTime from "../../../../components/Common/CustomDateTime";
import SmartButtons  from "./SmartButtons";
import CumstomField from "../../../../components/Common/CustomFields";
import AccountMoveTabs from "./AccountMoveTabs";

export const BaseForm = (props) => {
  const { form, setFormState, disabled } = props;

  const readOnlyStates = [1];

  return (
    <React.Fragment>
      <Row>
        
        <Col md="4">
        <CumstomField
            {...form?.getInput("ref")}
            label="Referencia"
            type="text"
            disabled={disabled || (readOnlyStates.includes(form.fields?.state))}
            required
            autoFocus={true}
          />

        </Col>

        <Col md="4">
            <CustomDateTime
            label="Fecha contable"
            disabled={disabled}
            {...form?.getInputDate("date", "date")}
          />
          
          <CustomSelect
            label="Diario"
            {...form?.getSelect("accountJournal", "accountJournal")}
            required
            isDisabled={disabled || (readOnlyStates.includes(form.fields?.state))}
          />
        </Col>
        <Col md="4">
          <SmartButtons form={form} />
        </Col>
      </Row>
      <AccountMoveTabs
        form={form}
        setFormState={setFormState}
        disabled={disabled || readOnlyStates.includes(form.fields?.state)}
      />
    </React.Fragment>
  );
};

export default BaseForm;
