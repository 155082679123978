import React from 'react'
import { useDispatch } from 'react-redux';
import { TabPane } from 'reactstrap'
import AccountInvoiceItemsTable from '../AccountInvoiceItemsTable';

const LineaTab = (props) => {

    const { form, disabled, setFormState } = props;

    return(
        <TabPane tabId="1" className="pt-3">
          <AccountInvoiceItemsTable
            disabled={disabled}
            // addItem={addItem}
            form={form}
            setFormState={setFormState}
          />
        </TabPane>
    )
}

export default LineaTab