import React, { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap";


import classnames from "classnames";
import RolesTab from "./Tabs/RolesTab";

const UserTabs = (props) => {
  const { form, setFormState ,disabled } = props;
  const [customActiveTab, setcustomActiveTab] = useState("1");

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  }

  return (
    <Col lg={12} className="mt-1">
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "1",
            })}
            onClick={() => {
              toggleCustom("1");
            }}
          >
            <span className="d-sm-block">Roles</span>
          </NavLink>
        </NavItem>
       
      </Nav>

      <TabContent activeTab={customActiveTab}>
        <RolesTab 
        form={form}
        setFormState={setFormState} 
        disabled={disabled} />
      </TabContent>
    </Col>
  );
};

export default UserTabs;
