import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react"
import apiCall from "../../api"

export const PrintIFrame = forwardRef((props, ref) => {
  const iFrameRef = useRef()

  useEffect(() => {
    window.addEventListener("message", handleMessage)

    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [])

  const handleMessage = event => {
    if (event.data.action === "receipt-loaded") {
      if (!iFrameRef.current) return
      if (iFrameRef.current.srcdoc == "") return

      const iframe = iFrameRef.current
      const iframeWindow = iframe.contentWindow || iframe

      iframe.focus()
      iframeWindow.print()
    }
  }

  const handleIframeLoad = () => {
    debugger

    // if (!iFrameRef.current) return
    // if (iFrameRef.current.srcdoc == "") return

    // const iframe = iFrameRef.current
    // const iframeWindow = iframe.contentWindow || iframe

    // iframe.focus()
    // iframeWindow.print()
  }

  const publicRef = {
    print: async url => {
      try {
        const results = await apiCall.get(url)

        iFrameRef.current.srcdoc = results.data
        // var contentBody = iFrameRef.current.body;

        // contentBody.innerHTML = '<style>@import url(https://fonts.googleapis.com/css?family=Niconne);* { font-family: Niconne; }</style><h1>G</h1>';
        // setContent(results.data)
      } catch (error) {
        console.error("Print: ", error.message)
      }

      return false
    },
  }

  useImperativeHandle(ref, () => publicRef)

  return (
    <div
      style={{
        display: "none",
      }}
    >
      <iframe
        ref={iFrameRef}
        id="receipt"
        width="100%"
        height={450}
        onLoad={handleIframeLoad}
        style={{}}
      />
    </div>
  )
})
