import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'

const NoteTab = (props) => {

    const { form, disabled } = props;
    
    return(
        <TabPane tabId="3" className="p-3">
          <Row>
            <Col lg="6">
            
            
            </Col>
            
          </Row>
        </TabPane>
    )
}

export default NoteTab