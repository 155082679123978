import React from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import CustomCheckbox from "../../../../components/Common/CustomCheckbox";

import ProductTabs from "./ProductTabs";
import CustomTagInput from "../../../../components/Common/CustomTagInput";
import SmartButtons from "./SmartButtons";
import CustomFileImage from "../../../../components/Common/CustomFileImage";
import CustomMultiSelect from "../../../../components/Common/CustomMultiSelect";

export const BaseForm = ({ form, handleSubmit, disabled, setFormState }) => {

  
  // useEffect(() => {
  //   console.log(form.fields);

  // },[form.fields['category']]);

  return (
    
      <Row>
        <Col md="3">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />
          <CustomSwitch
            label="Activo"
            disabled={disabled}
            {...form?.getSwitch("active")}
          />
        </Col>
        <Col md="3">
          <FormGroup className="mb-4">
            <Label>Características</Label>
            <CustomCheckbox
              label="Puede ser vendible"
              disabled={disabled}
              {...form?.getCheckbox("saleOK")}
            />
            <CustomCheckbox
              label="Puede ser comprable"
              disabled={disabled}
              {...form?.getCheckbox("purchaseOK")}
            />
            <CustomCheckbox
              label="Puede ser gasto"
              disabled={disabled}
              {...form?.getCheckbox("canBeExpensed")}
            />
            <CustomMultiSelect
            label="Etiquetas"
            {...form?.getMultiSelect("productProductTags", "productTag")}
            isClearable
            isDisabled={disabled}
          />
          </FormGroup>
        </Col>
        <Col md="3">
        <CustomFileImage 
          label="Imagen"
          {...form?.getFileImage("imageBase64","image")}
          disabled={disabled}
        />
          <CustomTagInput
            label="Códigos de barra"
            placeholder="Escanea aquí.."
            disabled={disabled}
            {...form?.getTagInput("productBarcodes")}
          />
        </Col>
        <Col md="3">
          <SmartButtons
            form={form}
          />
        </Col>
        <ProductTabs form={form} disabled={disabled} setFormState={setFormState} />
      </Row>
  );
};

export default BaseForm;
