import Index from "..";
import Details from "../Details";
import { MODEL } from "./consts";

export const RoleRoutes = [
  { path: `/:module/${MODEL}/:id`, exact: true, component: Details },
  { path: `/:module/${MODEL}`, exact: true, component: Index },
];

export default RoleRoutes;
