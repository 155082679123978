import React from "react"
import { useDispatch } from "react-redux"
import { Col } from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import useDynamicTable from "../../../../../hooks/useDynamicTable"
import DynamicTableSelect from "../../../../../components/Common/DynamicTable/DynamicTableSelect"


const RolesTable = props => {
  const dispatcher = useDispatch()

  const { form, setFormState, disabled } = props

  const updateFields = async (name, index, value) => {
    let tempItems = [...form.fields?.userRoleModules]

    let tempItem = {
      ...tempItems[index],
      [name]: value,
    }

    tempItems[index] = tempItem

    setFormState({ ...form.fields, userRoleModules: tempItems })
  }

  const dynamicTable = useDynamicTable(
    form,
    setFormState,
    "userRoleModules",
    disabled,
    updateFields
  )


  return (
    <React.Fragment>
      <Col xs="12">
        <div
          className="table-responsive overflow-visible"
          onClick={() => {
            if (disabled) {
              dispatcher(setFormBounceEdit(true))
            }
          }}
        >
          <Table className="table mb-0">
            <Thead className="table-light">
              <Tr>
                <Th width="10%">Módulo</Th>
                <Th width="10%">Rol</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dynamicTable.items?.map((p, key) => (
                <Tr key={"_product_" + key}>
                  <Td >
                    <strong>{p.applicationModule.name}</strong>
                  </Td>
                  <Td onClick={() => dynamicTable.onClick("role", key)}>
                    <DynamicTableSelect
                      header={true}
                      {...dynamicTable?.getSelect(
                        "role",
                        key,
                        "role",
                        `module=${p.applicationModule.id}`
                      )}
                    />
                  </Td>

                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Col>

    </React.Fragment>
  )
}

export default RolesTable
