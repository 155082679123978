export const MODULE = 'accounting';
export const MODEL = 'accountjournal';

export const INDEX_TITLE = 'Diarios';
export const CREATE_TITLE = 'Nuevo diario';

export const INITIAL_STATE = {
    name: "",
    active: true,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const INITIAL_STATE_FILTER = {
  types: null
};

export const JOURNALTYPE_ENUM = [
  { value: 1, label: 'Venta'},
  { value: 2, label: 'Compra'},
  { value: 3, label: 'Efectivo'},
  { value: 4, label: 'Banco'},
  { value: 5, label: 'General'},
  { value: 7, label: 'Tarjeta de crédito'},
  { value: 8, label: 'Medios virtuales'},
  { value: 9, label: 'Cheques de terceros'},
  { value: 10, label: 'Cheques propios'},
];