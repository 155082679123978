import React, { useState } from "react";
import DropdownTable from "./DropdownTable";

const TableActions = (props) => {
  const { onRefresh,onExport, customFilters, count,modal } = props;

  return (
    <div className="d-flex flex-row-reverse">
      
      {!modal && (
        <DropdownTable onRefresh={onRefresh} onExport={onExport} count={count} />
      )}
      
      {customFilters ? customFilters : null}
    </div>
  );
};

export default TableActions;
