import React from "react"
import { Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"

const StatusBar = props => {
  const { state } = props

  return (
    <Nav pills className="bg-light rounded d-none d-xl-flex">
      <NavItem>
        <NavLink className={classnames({ active: state === 1 })}>
          Borrador
        </NavLink>
      </NavItem>
      {state === 6 && (
        <NavItem>
          <NavLink className={classnames({ active: state === 6 })}>
            Pendiente
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink className={classnames({ active: state === 2 })}>
          Validado
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className={classnames({ active: state === 4 })}>
          Conciliado
        </NavLink>
      </NavItem>
      {state === 5 && (
        <NavItem>
          <NavLink className={classnames({ active: state === 5 })}>
            Cancelado
          </NavLink>
        </NavItem>
      )}
    </Nav>
  )
}

export default StatusBar
