import React, { useEffect } from "react"

import { MODEL, BASE_URL, INDEX_TITLE } from "./components/consts"
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable"
import FormLayout from "../../../components/Common/FormLayout"
import { useDispatch } from "react-redux"
import { setPageTitle } from "../../../store/pagetitle/actions"
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons"
import CustomLink from "../../../components/Common/CustomLink"

const Index = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE))
  }, [])

  const columns = [
    {
      name: "Name",
      label: "Nombre",
      width: 95,
    },
  ]

  const rows = data => {
    const rows = []

    const r = data?.map((p, index) => {
      const fields = []

      fields.push(
        <CustomLink content={p.name} to={`${BASE_URL}/${p.id}`}>
          {p.name}
        </CustomLink>
      )

      rows.push({ fields: fields })
    })

    return rows
  }

  const actionButtons = <IndexActionButtons url={BASE_URL} />

  return (
    <FormLayout actionButtons={actionButtons}>
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={{
          customFilters: [],
        }}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
      />
    </FormLayout>
  )
}

export default Index
