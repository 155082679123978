import { BASE_CREATE_TRY,BASE_CREATE_ERROR,BASE_CREATE_OK} from "./actionTypes"

const initialState = {
    data: [],
    error : '',
    loading: false
}

const result = (state = initialState, action) => {
    switch (action.type) {
        case BASE_CREATE_TRY:
            state = {
                ...state,
                loading: true
            }
            break;
        case BASE_CREATE_OK:
                state = {
                    ...state,
                    loading: false,
                    data: action.payload.data
                }
                break;
        case BASE_CREATE_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default result;