import { useState, useEffect } from "react"
import apiCall from "../api"
import Resizer from "react-image-file-resizer"

const useForm = ({ formState }) => {
  const [fields, setFields] = useState(formState)

  useEffect(() => {
    setFields(formState)
  }, [formState])

  const onChange = event => {
    const { value, name, type, checked } = event.target

    setFields({
      ...fields,
      [name]:
        type === "checkbox"
          ? checked
          : type === "radio"
          ? parseInt(value)
          : value,
    })
  }

  const onClickCheckbox = name => {
    setFields({ ...fields, [name]: !fields[name] })
  }

  const onChangeSelect = (
    name,
    value,
    searchMore = null,
    initFilter = null
  ) => {
    if (value?.id == "more") {
      if (searchMore) {
        searchMore({ initFilter: initFilter })
          .then(v => setFields({ ...fields, [name]: v }))
          .catch()
      }

      return
    }

    setFields({ ...fields, [name]: value })
  }
  const onChangeMultiSelect = (
    name,
    value,
    searchMore = null,
    initFilter = null
  ) => {
    if (value) {
      if (value[value.length - 1]?.id == "more") {
        if (searchMore) {
          searchMore({ initFilter: initFilter })
            .then(v => {

              let tempItems = [...fields[name]]

              if (!tempItems.some(p => p.id == v.id)) {
                tempItems.push(v)

                setFields({ ...fields, [name]: tempItems })
              }
            })
            .catch()
        }
        return
      }
    }

    setFields({ ...fields, [name]: value })
  }

  const onChangeSelectEnum = (name, value) => {
    setFields({ ...fields, [name]: value.value })
  }

  const searchAsync = async (target, inputValue, name, params = null) => {
    await new Promise(resolve => {
      setTimeout(resolve, 200)
    })

    let url = `/${target}/search?text=${inputValue}`

    if (params != null) {
      url = `${url}&${params}`
    }

    return await apiCall
      .get(url)
      .then(res => {
        // if (first) {
        //   setFields({ ...fields, [name]: res.data[0] });
        // }

        if (res.data.length > 6) {
          res.data.push({
            id: "more",
            name: "Buscar más...",
          })
        }

        return res.data
      })
      .catch(err => {
        console.log(err)
      })
  }

  const addField = (name, defaultValue) => {
    setFields({ ...fields, [name]: defaultValue })
  }

  const removeField = name => {
    const newFields = { ...fields }
    delete newFields[name]
    setFields(newFields)
  }

  const getSelectEnumField = (name, options) => {
    return options
      .filter(o => o.value === fields[name])
      .map(filtered => filtered)
  }

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "PNG",
        80,
        0,
        uri => {
          resolve(uri)
        },
        "base64"
      )
    })
  const handleDelete = (name, i) => {
    let arr = fields[name].filter((tag, index) => index !== i)

    setFields({ ...fields, [name]: arr })
  }

  const handleAddition = (name, tag) => {
    setFields({ ...fields, [name]: [...fields[name], tag] })
  }

  const onChangeFile = async (e, viewValue) => {

    debugger

    const { name, files } = e.target

    if(!files){

      setFields({
        ...fields,
        [name]: null,
        [viewValue]: null
      })
      
      return;
    }
    const file = files[0]

    const image = await resizeFile(file)

    setFields({ ...fields, [name]: image })
  }

  const doDelete = (name, key) => {
    let arr = fields[name].filter((o, index) => index !== key)

    setFields({ ...fields, [name]: arr })
  }

  const doAdd = (name, obj) => {
    setFields({ ...fields, [name]: [...fields[name], obj] })
  }

  return {
    fields,
    addField,
    removeField,
    getInput: name => ({
      name,
      value: fields[name],
      onChange,
    }),
    getInputDate: (type, name) => ({
      name,
      value: fields[name],
      type: type,
      onChange,
    }),
    getCheckbox: name => ({
      name,
      checked: fields[name],
      onChange,
    }),
    getSwitch: name => ({
      name,
      checked: fields[name],
      onChange,
    }),
    getRadio: (name, value) => ({
      name,
      value,
      checked: value === fields[name],
      onChange,
    }),
    getFileImage: (name, viewName) => ({
      name,
      value: fields[name],
      viewValue: fields[viewName],
      onChange: e => {
        onChangeFile(e, viewName)
      },
    }),
    getSelect: (name, target, param, searchMore, initFilter) => ({
      name,
      value: fields[name],
      onChange: event => {
        onChangeSelect(name, event, searchMore, initFilter)
      },
      defaultOptions: true,
      cacheOptions: true,
      searchMore: searchMore,
      loadOptions: inputValue => {
        return searchAsync(target, inputValue, name, param)
      },
    }),
    getMultiSelect: (name, target, params, searchMore, initFilter) => ({
      name,
      value: fields[name],
      onChange: event => {
        onChangeMultiSelect(name, event, searchMore, initFilter)
      },
      defaultOptions: true,
      cacheOptions: true,
      loadOptions: inputValue => {
        return searchAsync(target, inputValue, name, params)
      },
    }),
    getEnumSelect: (name, options) => ({
      name,
      value: getSelectEnumField(name, options),
      onChange: event => {
        onChangeSelectEnum(name, event)
      },
      options: options,
    }),
    getTagInput: (name, value) => ({
      name,
      tags: fields[name],
      handleDelete: i => handleDelete(name, i),
      handleAddition: tag => handleAddition(name, tag),
    }),
    getTable: name => ({
      name,
      data: fields[name],
      doDelete: key => doDelete(name, key),
      doAdd: obj => doAdd(name, obj),
    }),
  }
}

export default useForm
