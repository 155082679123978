import { BREADCRUMBS_SET, CURREENTMENU_SET, PAGETITLE_SET} from "./actionTypes"

const initialState = {
    title: "",
    currentMenu: null,
    breadcrumbs: []
};

const PageTitle = (state = initialState, action) => {
    switch (action.type) {
        case PAGETITLE_SET:
                state = {
                    ...state,
                    title: action.payload
                }
                break;
        case CURREENTMENU_SET:
                state = {
                    ...state,
                    currentMenu: action.payload
                }
                break;
        case BREADCRUMBS_SET:
                    state = {
                        ...state,
                        breadcrumbs: action.payload
                    }
                    break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default PageTitle;