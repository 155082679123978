import {put, call, takeLatest, all, fork} from 'redux-saga/effects';
import { BASE_DT_TRY } from './actionTypes';
import { getDataTableOK, getDataTableError } from './actions';
import apiCall from '../../../api';

function* getDataTable ({payload : {target}}) {
    try {
        const results = yield call(apiCall.get,`/${target}`);

        yield put(getDataTableOK(results.data));
    } catch (error) {
        yield put(getDataTableError(error));
    }
}

export function* watchGetDataTable(){
    yield takeLatest(BASE_DT_TRY, getDataTable);
}

function* getDataTableSaga() {
    yield all([fork(watchGetDataTable)]);
  }
  
export default getDataTableSaga;