import React from "react";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { setFormSecondaryAction } from "../../../../store/actions";
import useStockPickingActions from "../Actions";
import { useDispatch } from "react-redux"

const ActionButtons = (props) => {
  const {  form } = props;
  const { doValidate, doPrint } = useStockPickingActions(form.fields.id);
  const dispatch = useDispatch();

  const { loading } = useSelector(state => state.ApiStatus);

  return (
    <Row className="mb-2">
      <Col>
      {form.fields?.state === 6 || form.fields?.state === 1 ? (
            <Button
              color="primary"
              className="btn btn-primary btn-sm me-2"
              type="submit"
              form="hook-form"
              onClick={() => { dispatch(setFormSecondaryAction('validate')) }}
              disabled={loading}
            >
              Validar
            </Button>
           ) : null}
      {form.fields?.id != 0 ? (
        <React.Fragment>
           
            <Button
              color="primary"
              className="btn btn-primary btn-sm me-2"
              onClick={(e) => {
                e.preventDefault();
                doPrint(form.fields.name);
              }}
              disabled={loading}
            >
              Imprimir
            </Button>
        </React.Fragment>
      ) : null}
      </Col>
    </Row>
  );
};

export default ActionButtons;
