import React from "react";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { Row, Col } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSwitch from "../../../../components/Common/CustomSwitch";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
      <Row>
        <Col lg="4">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />
           
          <CumstomField
            {...form?.getInput("due")}
            label="Plazo"
            type="number"
            disabled={disabled}
            required
          />
          
           
          <CustomSwitch
            label="Activo"
            disabled={disabled}
            {...form?.getSwitch("active")}
          />
        </Col>
       
      </Row>
  );
};

export default BaseForm;
