import { MODULE } from "../../consts";

export const MODEL = 'stockWareHouse';

export const INDEX_TITLE = 'Almacenes';
export const CREATE_TITLE = 'Nuevo almacen';

export const INITIAL_STATE = {
    name: "",
    prefix: "",
    type: 1,
    address: "",
    stockLocation: null,
    initialLocation: null,
    finalLocation: null,
    active: true,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const STOCKWAREHOUSE_TYPE_ENUM = [
  { value: 1, label: 'Simple'},
  { value: 2, label: 'Complejo'},
];


