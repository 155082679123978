import {put, call, takeLatest, all, fork} from 'redux-saga/effects';
import { BASE_DELETE_TRY } from './actionTypes';
import {  deleteBaseOK, deleteBaseError } from './actions';
import apiCall from '../../../api';

function* deleteBase ({payload: {target,id}}) {
    try {
        const results = yield call(apiCall.delete,`/${target}/${id}`);

        yield put(deleteBaseOK(results.data));
    } catch (error) {
        yield put(deleteBaseError(error));
    }
}

export function* watchDeleteBase(){
    yield takeLatest(BASE_DELETE_TRY, deleteBase);
}

function* DeleteBaseSaga() {
    yield all([
        fork(watchDeleteBase)
    ]);
  }


export default DeleteBaseSaga;