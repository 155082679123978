import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames';
import { useEffect } from 'react';
import { useState } from 'react';

const StatusBar = (props) => {

    const { state, mobile } = props;

    const [cssStatus, setCssStatus] = useState("bg-light rounded");

    useEffect(() => {
        if(mobile){
            setCssStatus("bg-light rounded d-none d-md-flex")
        }else{
            setCssStatus("bg-light rounded");
        }
    },[mobile]);
    

    return(
    // <Nav pills className="bg-light rounded d-none d-xl-flex">
    <Nav pills className={cssStatus}>
    <NavItem>
        <NavLink
            className={classnames({ active: state === 0 })}
        >
            Borrador
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: state === 1 })}
        >
            Validado
        </NavLink>
    </NavItem>

    
</Nav>
    )
}

export default StatusBar