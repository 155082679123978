import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import apiCall from "../../api"
import ErrorMessage from "./ErrorMessage"

import parse from "html-react-parser"

import SimpleBar from "simplebar-react"

import noImage from "../../assets/images/no-image.png"

import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { setApiError, setApiLoading } from "../../store/actions"
import moment from "moment"

export const ModelHistory = props => {
  const { model, id, modal, setModal } = props

  const [addingModal, setAddingModal] = useState(false)
  const [results, setResults] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState(false)
  const [followerData, setFollowerData] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const [editorState, setEditorState] = React.useState(false)

  const openFile = async file => {
    await apiCall.getFile(`/attachment/getfile/${file.id}`, `${file.name}`)
  }
  const onEditorStateChange = editorState => {
    setEditorState(editorState)

    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  const dispatch = useDispatch()
  const { error, loading, validationError } = useSelector(
    state => state.ApiStatus
  )

  const fetchMessages = async () => {
    try {
      const body = {
        pageNumber: pageNumber,
        pageSize: 10,
        text: "",
        filter: {
          tableName: model,
          recordID: id,
        },
      }

      dispatch(setApiError(""))

      const { data } = await apiCall.post(`/message/loadtable`, body)

      setHasMore(data.pageNumber < data.totalPages)

      if (pageNumber === 1) {
        setResults(data.data)
      } else {
        setResults(results.concat(data.data))
      }
    } catch (error) {
      dispatch(setApiError(error.message))
    }
  }

  const fetchFollowers = async () => {
    try {
      const body = {
        modelName: model,
        modelID: id,
      }

      dispatch(setApiError(""))

      const { data } = await apiCall.post(`/follower`, body)

      setFollowerData(data)
    } catch (error) {
      dispatch(setApiError(error.message))
    }
  }

  const doFollow = async () => {
    try {
      const body = {
        modelName: model,
        modelID: id,
      }

      dispatch(setApiError(""))

      const { data } = await apiCall.post(`/follower/follow`, body)

      await fetchFollowers()
    } catch (error) {
      dispatch(setApiError(error.message))
    }
  }

  useEffect(() => {
    if (pageNumber > 0) {
      fetchMessages()
    }
  }, [pageNumber])

  useEffect(() => {
    if (modal) {
      setAddingModal(false)

      if (pageNumber == 1) {
        fetchMessages()
      } else {
        setPageNumber(1)
      }

      fetchFollowers()
    }
  }, [modal])

  const addNewMessage = async () => {
    try {
      const body = {
        data: {
          description: draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          ),
          tableName: model,
          recordID: id,
        },
      }

      dispatch(setApiError(""))
      dispatch(setApiLoading(true))

      await apiCall.post(`/message`, body)

      dispatch(setApiLoading(false))

      tog_large()
    } catch (error) {
      dispatch(setApiLoading(false))
      dispatch(setApiError(error.message))
    }
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setModal(!modal)
    removeBodyCss()
  }

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          tog_large()
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(false)
          }}
        >
          Historial
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between">
            {addingModal || id == 0 ? null : (
              <a href="#" onClick={() => setAddingModal(true)} className="">
                Agregar nota
              </a>
            )}
            {followerData && addingModal == false && id != 0 ? (
              <div className="d-flex flex-row ">
                {followerData.following ? (
                  <a
                    className="btn btn-light btn-sm me-1 text-success"
                    onClick={() => doFollow()}
                  >
                    <i className="bx bx-check me-1" />
                    <span className="d-none d-sm-inline-block">Siguiendo</span>
                  </a>
                ) : (
                  <a
                    className="btn btn-light btn-sm me-1"
                    onClick={() => doFollow()}
                  >
                    <span className="d-none d-sm-inline-block">Seguir</span>
                  </a>
                )}
                <Dropdown
                  isOpen={menuOpen}
                  toggle={() => setMenuOpen(!menuOpen)}
                >
                  <DropdownToggle
                    type="button"
                    tag="button"
                    className="btn btn-light btn-sm"
                  >
                    <i className="bx bxs-user me-1" />
                    <span className="d-none d-sm-inline-block">
                      {followerData.followers.length}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
                    {followerData.followers?.map((f, index1) => {
                      return <DropdownItem key={index1} href="#">{f.name}</DropdownItem>
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : null}
          </div>

          <ErrorMessage error={error} />
          {addingModal ? (
            <React.Fragment>
              <Editor
                onEditorStateChange={onEditorStateChange}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
              />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => addNewMessage()}
                  disabled={loading}
                >
                  {loading ? (
                    <i className="bx bx-loader-alt bx-spin font-size-16 align-middle mr-2"></i>
                  ) : null}
                  Agregar
                </button>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {results?.length > 0 ? (
                <SimpleBar style={{ maxHeight: "390px" }}>
                  <ul className="list-group">
                    {results?.map((c, index) => {
                      return (
                        <li key={index} className="list-group-item border-0">
                          <Media>
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-light">
                                {c.userLogo ? (
                                  <img
                                    className="rounded-circle avatar-xs"
                                    src={c.userLogo}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="rounded-circle avatar-xs"
                                    src={noImage}
                                    alt=""
                                  />
                                )}
                              </span>
                            </div>

                            <Media body>
                              <h5 className="font-size-14">{c.userName}</h5>
                              <div>
                                <div className="d-flex">
                                  <span className="text-muted">
                                    {parse(c.description)}
                                  </span>
                                </div>

                                {c.attachments?.length > 0 ? (
                                  <div className="d-inline-flex p-1 bd-highlight">
                                    {c.attachments?.map((a, index1) => {
                                      return (
                                        <a
                                          onClick={() => openFile(a)}
                                          key={index1}
                                          className="text-dark fw-medium"
                                          href="#"
                                        >
                                          <i className="mdi mdi-file-pdf-outline font-size-18 align-middle text-primary me-1"></i>{" "}
                                          {a.name}
                                        </a>
                                      )
                                    })}
                                  </div>
                                ) : null}
                              </div>

                              <div className="float-end">
                                <p className="text-muted mb-0">
                                  {moment(c.noteDate).format(
                                    "DD/MM/YYYY hh:mm"
                                  )}
                                </p>
                              </div>
                            </Media>
                          </Media>
                        </li>
                      )
                    })}
                    {hasMore ? (
                      <li>
                        <a onClick={e => setPageNumber(pageNumber + 1)}>
                          Cargar más..
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </SimpleBar>
              ) : null}
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ModelHistory
