import Index from "..";
import Details from "../Details";
import { MODEL } from "./consts";
import { MODULE } from "../../consts";

export const StockLocationRoutes = [
  { path: `/${MODULE}/${MODEL}/:id`, exact: true, component: Details },
  { path: `/${MODULE}/${MODEL}`, exact: true, component: Index },
];


export default StockLocationRoutes;
