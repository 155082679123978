import CustomFilters from "../../../../components/Common/CustomFilters"

const SaleOrderCustomFilters = (props) => {
  const { filter, setFilter, columns } = props

  const itemsFilter = [
    {
      name: "onlyMe",
      label: "Mis operaciones",
    },
    {
      name: "divider",
    },
    {
      name: "draft",
      label: "Presupuestos",
    },
    {
      name: "sent",
      label: "Enviados",
    },
    {
      name: "sale",
      label: "Pedidos",
    },
    {
      name: "done",
      label: "Entregados",
    },
    {
      name: "cancelled",
      label: "Cancelados",
    },
    {
      name: "divider",
    },
    {
      name: "noInvoice",
      label: "Nada para facturar",
    },
    {
      name: "toInvoice",
      label: "Para facturar",
    },
    {
      name: "invoiced",
      label: "Facturado",
    },
    {
      name: "divider",
    },
    {
      name: "open",
      label: "Abiertos",
    },
    {
      name: "paid",
      label: "Pagados",
    },
    {
      name: "due",
      label: "Vencidos",
    },
  ]


  return (
    <CustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
    itemsFilter={itemsFilter}
  />
  );
};

export default SaleOrderCustomFilters;
