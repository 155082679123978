import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import {  MODEL, BASE_URL, INDEX_TITLE, MAILSERVER_SMTPENCRYPTION_ENUM } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import { MODULE } from "../consts";
import { getLabelFromArray } from "../../../helpers/array_utils";
import CustomLink from "../../../components/Common/CustomLink";

const Index = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        label: "Nombre",
        width: 20,
      },
      {
        label: "Servidor SMTP",
        width: 15,
      },
      {
        label: "Nombre de usuario",
        width: 15,
      },
      {
        label: "Seguridad",
        width: 15,
      },

    ];

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
    
      fields.push(
        <CustomLink
        content={p.name}
        to={`${BASE_URL}/${p.id}`}>{p.name}</CustomLink>
      );
      
      fields.push(
        <span>
          {p.smtP_Host}
        </span>
      );

      fields.push(
        <span>
          {p.smtP_User}
        </span>
      );


      fields.push(
        <span>{getLabelFromArray(p.smtpEncryption,MAILSERVER_SMTPENCRYPTION_ENUM)}</span>
      );

      rows.push({fields : fields});
    });

    return rows;
  };

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={{
          customFilters: [],
        }}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
      />
    </FormLayout>
  );
};

export default Index;
