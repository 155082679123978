import { useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import { MODEL, BASE_URL, INDEX_TITLE } from "./components/consts"
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable"
import FormLayout from "../../../components/Common/FormLayout"
import { useDispatch } from "react-redux"
import { setPageTitle } from "../../../store/pagetitle/actions"
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons"
import { MODULE } from "../consts"
import { INITIAL_STATE_FILTER } from "./components/consts"
import AccountMoveLineCustomFilters from "./components/AccountMoveLineCustomFilters"
import useQueryURL from "../../../hooks/useQueryURL"
import CustomLink from "../../../components/Common/CustomLink"
import moment from "moment"
import { Badge } from "reactstrap"

const Index = props => {
  const dispatch = useDispatch()
  const { filter, setFilter } = useQueryURL(INITIAL_STATE_FILTER)

  const [credit, setCredit] = useState(0)
  const [debit, setDebit] = useState(0)
  const [balance, setBalance] = useState(0)
  let { id } = useParams()

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE))
  }, [])

  const columns = [
    {
      name: "Date",
      label: "Fecha",
      width: 10,
      type: "date"
    },
    {
      name: "AccountJournalName",
      label: "Diario",
      width: 10,
      type: "string"
    },
    {
      name: "AccountMoveName",
      label: "Asiento",
      width: 20,
      type: "string"
    },
    {
      name: "AccountAccountName",
      label: "Cuenta",
      width: 20,
      type: "string"
    },
    {
      name: "Ref",
      label: "Referencia",
      width: 20,
      type: "string"
    },
    {
      name: "Debit",
      label: "Debe",
      width: 10,
      sum: debit,
      type: "number"
    },
    {
      name: "Credit",
      label: "Haber",
      width: 10,
      sum: credit,
      type: "number"
    },
    {
      name: "balance",
      label: "Balance",
      width: 10,
      sum: balance,
      type: "number"
    },
  ]

  const rows = data => {
    const rows = []

    setCredit(
      data?.reduce(function (prev, cur) {
        return prev + cur.credit
      }, 0)
    )

    setDebit(
      data?.reduce(function (prev, cur) {
        return prev + cur.debit
      }, 0)
    )

    setBalance(
      data?.reduce(function (prev, cur) {
        return prev + cur.balance
      }, 0)
    )

    const r = data?.map((p, index) => {
      const fields = []

      fields.push(<span>{moment(p.date).format("DD/MM/YYYY hh:mm")}</span>)

      fields.push(
        <CustomLink
          content={p.accountJournalName}
          to={`/accounting/accountjournal/${p.accountJournalID}`}
        >
          {p.accountJournalName}
        </CustomLink>
      )

      fields.push(
        <CustomLink
          content={p.accountMoveName}
          to={`/accounting/accountmove/${p.accountMoveID}`}
        >
          {p.accountMoveName}
        </CustomLink>
      )
      fields.push(
        <CustomLink
          content={p.accountAccountName}
          to={`/accounting/accountaccount/${p.accountAccountID}`}
        >
          {p.accountAccountName}
        </CustomLink>
      )

      fields.push(<span>{p.ref}</span>)

      fields.push(<span>$ {p.debit}</span>)
      fields.push(<span>$ {p.credit}</span>)
      fields.push(<span>$ {p.balance}</span>)

      switch (p.state) {
        case 6:
          fields.push(
            <Badge className="me-1 rounded-pill bg-warning font-size-11">
              Preparado
            </Badge>
          )
          break
        case 7:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">
              Hecho
            </Badge>
          )
          break
        default:
          break
      }

      setCredit(
        data?.reduce(function (prev, cur) {
          return prev + cur.credit
        }, 0)
      )

      setDebit(
        data?.reduce(function (prev, cur) {
          return prev + cur.debit
        }, 0)
      )

      setBalance(
        data?.reduce(function (prev, cur) {
          return prev + cur.balance
        }, 0)
      )

      rows.push({ fields: fields })
    })

    return rows
  }

  const actionButtons = <IndexActionButtons url={BASE_URL} />

  const customFilters = (
    <AccountMoveLineCustomFilters filter={filter} setFilter={setFilter} columns={columns} />
  )

  return (
    <FormLayout actionButtons={null}>
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{ orderCriteria: "Date", orderDirection: 1 }}
      />
    </FormLayout>
  )
}

export default Index
