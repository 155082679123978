import { MODULE } from "../../consts";

export const MODEL = 'purchaseorder';

export const INDEX_TITLE = 'Pedidos de presupuesto';
export const CREATE_TITLE = 'Nueva pedido de presupuesto';

export const INITIAL_STATE = {
    name: "",
    partner: null,
    accountPaymentTerm: null,
    stockPickingType: null,
    dateOrder: null,
    datePlanned: null,
    purchaseOrderItems: []
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const PURCHASEORDER_STATE_ENUM = [
  { value: 1, label: 'Petición de presupuesto'},
  { value: 2, label: 'Petición de presupuesto enviada'},
  { value: 3, label: 'Para aprobar'},
  { value: 4, label: 'Pedido de compra'},
  { value: 5, label: 'Bloqueado'},
  { value: 6, label: 'Cancelado'},
];

export const PURCHASEORDER_INVOICESTATUS_ENUM = [
  { value: 1, label: 'Nada para facturar"'},
  { value: 2, label: 'Para facturar'},
  { value: 3, label: 'Sin factura para recibir'},
];


export const INITIAL_STATE_FILTER = {
  onlyMe: false,
  partner: null,
  draft: false,
  purchase: false,
  toApprove: false,
  customFilters: []
};