import CustomFilters from "../../../../components/Common/CustomFilters"

const AccountMoveLineCustomFilters = (props) => {
  const { filter, setFilter, columns } = props

  console.log(filter)
  
  const itemsFilter = [
    {
      name: "onlyMe",
      label: "Mis apuntes",
    },
    {
      name: "divider",
    },
    {
      name: "asset",
      label: "Activo",
    },
    {
      name: "liabilities",
      label: "Positivo",
    },
    {
      name: "netvalue",
      label: "Valor neto",
    },
    {
      name: "income",
      label: "Ingreso",
    },
    {
      name: "expense",
      label: "Gasto",
    },
    {
      name: "others",
      label: "Otros",
    },
  ]


  return (
    <CustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
    itemsFilter={itemsFilter}
  />
  );
};

export default AccountMoveLineCustomFilters;
