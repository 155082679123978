import React from 'react';

const LightTheme = React.lazy(() => import('./ThemeLight'));
const DarkTheme = React.lazy(() => import('./ThemeDark'));

const ThemeSelector = ({ children }) => {
  const CHOSEN_THEME = localStorage.getItem("theme") === "dark" ? "dark" : "light";
  return (
    <>
      <React.Suspense fallback={() => null}>
        {(CHOSEN_THEME === "light") && <LightTheme />}
        {(CHOSEN_THEME === "dark") && <DarkTheme />}
      </React.Suspense>
      {children}
    </>
  )
}

export default ThemeSelector