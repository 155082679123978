import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBreadcrumbs, setCurrentMenu, setPageTitle } from "../store/actions";

const useMenu = () => {
  
  let history = useHistory();

  const dispatch= useDispatch();
  const pageTitle = useSelector(state => state.PageTitle)

  const GoTo = (content, to, redirect = true, menu = null, index = null, replace = false) => {

    if(to === "/#") return;
    
    const bread = {
      content: content,
      to: to,
      menu: menu == null ? pageTitle.currentMenu : menu
    }

    let arrBreadcrumbs = null;

    if(menu == null){
      arrBreadcrumbs = pageTitle.breadcrumbs;
    }else{
      dispatch(setCurrentMenu(menu));
      arrBreadcrumbs = [];
    }
    
    if(index == null){

      if(replace){
        arrBreadcrumbs[arrBreadcrumbs.length - 1] = bread;
      }else{
        arrBreadcrumbs.push(bread);
      }
      
    }else{
      var auxArray = [];

      for (var i = 0; i < arrBreadcrumbs.length - 1; i++) {
        auxArray.push(arrBreadcrumbs[i]);
        if(i === index) break;
      }

      arrBreadcrumbs= auxArray;
    }
    
    dispatch(setBreadcrumbs(arrBreadcrumbs));
    dispatch(setPageTitle(content));
    

    if(redirect){
      history.push(to);
    }

  };

  const GoBack = () => {

    let arrBreadcrumbs;

    arrBreadcrumbs = pageTitle.breadcrumbs;

    debugger;

    if(arrBreadcrumbs.length > 1){
      arrBreadcrumbs.pop();
      dispatch(setBreadcrumbs(arrBreadcrumbs));
    }
  }

  return {
    GoTo,
    GoBack
  };
};

export default useMenu;
