import React from "react"
import { useDispatch } from "react-redux"
import { Col } from "reactstrap"
import apiCall from "../../../../api"
import DynamicTableField from "../../../../components/Common/DynamicTable/DynamicTableField"
import { setApiLoading, setFormAction, setFormBounceEdit } from "../../../../store/actions"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import useDynamicTable from "../../../../hooks/useDynamicTable"

const StockInventoryItemsTable = props => {
  const dispatcher = useDispatch()

  const { form, setFormState, disabled } = props

  const updateFields = async (name, index, value) => {
    let tempItems = [...form.fields?.stockInventoryLines]

    let tempItem = {
      ...tempItems[index],
      [name]: value,
    }

    tempItems[index] = tempItem

    setFormState({ ...form.fields, stockInventoryLines: tempItems })
  }

  const dynamicTable = useDynamicTable(
    form,
    setFormState,
    "stockInventoryLines",
    disabled || form.fields?.type === 2,
    updateFields
  )

  const fetchStock = async index => {

    let tempItems = [...form.fields?.stockInventoryLines]

    let line = tempItems[index]

    let request = {
      stockLocationID: line.stockLocationID,
      productID: line.productID,
    }

    try {
      dispatcher(setApiLoading(true))

      const { data } = await apiCall.post(`/stockquant/fetch`, request)

      dispatcher(setApiLoading(false))

      line["theoreticalQty"] = data?.data
      line["warning"] = false

      tempItems[index] = line

      setFormState({ ...form.fields, stockInventoryLines: tempItems })
    } catch (error) {
      dispatcher(setApiLoading(false))
      console.log("fetchItem", error.message)
    }
  }

  return (
    <React.Fragment>
      <Col xs="12">
        <div
          className="table-responsive overflow-visible"
          onClick={() => {
            if (disabled) {
              dispatcher(setFormBounceEdit(true))
            }
          }}
        >
          <Table className="table mb-0">
            <Thead className="table-light">
              <Tr>
                <Th width="20%">Producto</Th>
                <Th width="10%">Ubicación</Th>
                <Th width="10%">A mano</Th>
                <Th width="10%">Contado</Th>
                <Th width="10%">Diferencia</Th>
                <Th width="10%">Unidad</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dynamicTable.items?.map((p, key) => (
                <Tr key={"_product_" + key}>
                  <Td>
                    <span className="d-block text-muted text-truncate field-muted">
                      {p.product?.name}
                    </span>
                  </Td>
                  <Td>
                    <span className="d-block text-muted text-truncate field-muted">
                      {p.stockLocation?.name}
                    </span>
                  </Td>
                  <Td>
                    <div className="d-flex flex-sm-row">
                      <span className="d-block text-muted text-truncate field-muted">
                        {p.theoreticalQty}
                      </span>
                      {p.warning ? (
                        <a
                          onClick={() => {
                            dispatcher(setFormAction('edit'));
                            fetchStock(key)
                          }}
                        >
                          <i className="bx bx-repost text-primary font-size-24 ms-2 mt-1"></i>
                        </a>
                      ) : null}
                    </div>
                  </Td>
                  <Td onClick={() => dynamicTable.onClick("productQty", key)}>
                    <DynamicTableField
                      type="number"
                      {...dynamicTable?.getInput("productQty", key)}
                    />
                  </Td>
                  <Td>
                    <span className="d-block text-muted text-truncate field-muted">
                      {p.productQty - p.theoreticalQty}
                    </span>
                  </Td>
                  <Td>
                    <span className="d-block text-muted text-truncate field-muted">
                      {p.product.productUnitOfMeasure?.name}
                    </span>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default StockInventoryItemsTable
