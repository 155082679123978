import  { useEffect } from "react";

import {  MODEL, BASE_URL, INDEX_TITLE, INITIAL_STATE_FILTER, PURCHASEORDER_STATE_ENUM, PURCHASEORDER_INVOICESTATUS_ENUM } from "./components/consts";
import {  MODULE } from "../consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import PurchaseOrderCustomFilters from "./components/PurchaseOrderCustomFilters";
import moment from "moment";
import useQueryURL from "../../../hooks/useQueryURL";
import {Badge} from "reactstrap";
import CustomLink from "../../../components/Common/CustomLink";

const Index = (props) => {

  const dispatch = useDispatch();
  const {filter, setFilter,queryURL} = useQueryURL(INITIAL_STATE_FILTER);


  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        name: "ID",
        label: "Número",
        width: 10,
        type: "number"
      },
      {
        name: "DateOrder",
        label: "Fecha",
        width: 20,
        type: "date",
      },
      {
        name: "Partner",
        label: "Proveedor",
        width: 20,
        type: "string"
      },
      {
        name: "ScheduledDate",
        label: "Fecha prevista",
        width: 20,
        type: "date"
      },
      {
        name: "Origin",
        label: "Origen",
        width: 10,
        type: "string"
      },
      {
        name: "Total",
        label: "Total",
        width: 10,
        type: "number"
      },
      {
        name: "State",
        label: "Estado",
        width: 10,
        type: "enum",
        customEnum: PURCHASEORDER_STATE_ENUM
      },
      {
        name: "InvoiceStatus",
        label: "Estado de facturación",
        width: 10,
        type: "enum",
        customEnum: PURCHASEORDER_INVOICESTATUS_ENUM
      },
    ];

  const rows = (data) => {

    console.log('rows', data);

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
      
      fields.push(
        <CustomLink 
        content={p.name}
        to={`${BASE_URL}/${p.id}${queryURL}`}>{p.name}</CustomLink>
      );
      
      fields.push(
        <span>
           {moment(p.dateOrder).format("DD/MM/YYYY hh:mm")}</span>
        );

      fields.push(
        <span>
          <CustomLink 
          content={p.partnerName}
          to={`/${MODULE}/partner/${p.partnerID}`}>{p.partnerName}</CustomLink>
            
        </span>
      );
      
      fields.push(
        <span>
           {moment(p.datePlanned).format("DD/MM/YYYY hh:mm")}</span>
        );

      fields.push(
      <span> {p.origin}</span>
      );

      fields.push(
      <span>$ {p.total}</span>
      );

      switch (p.state) {
        case 1:
          fields.push(
            <Badge className="me-1 rounded-pill bg-info font-size-11">Petición de presupuesto</Badge>
          );
          
          break;
        case 2:
          fields.push(
            <Badge className="me-1 rounded-pill bg-info font-size-11">Petición de presupuesto enviada</Badge>
          );
          
          break;
        case 3:
          fields.push(
            <Badge className="me-1 rounded-pill bg-warning font-size-11">Para aprobar</Badge>
          );
          break;
        case 4:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">Pedido de compra</Badge>
          );
          break;
        case 5:
          fields.push(
            <Badge className="me-1 rounded-pill bg-success font-size-11">Recibido</Badge>
          );
          break;
        case 6:
          fields.push(
            <Badge className="me-1 rounded-pill bg-danger font-size-11">Cancelado</Badge>
          );
          break;
        default:
          break;
      }

      switch (p.invoiceStatus) {
        case 0:
          fields.push(
            <Badge  className="me-1 rounded-pill bg-info font-size-11">Nada para facturar</Badge>
          );
          
          break;
        case 1:
          fields.push(
            <Badge  className="me-1 rounded-pill bg-success font-size-11">Facturado</Badge>
          );
          
          break;
        case 2:
          fields.push(
            <Badge className="me-1 rounded-pill bg-warning font-size-11">Para facturar</Badge>
          );
          break;
       
        default:
          break;
      }

      rows.push({fields : fields});
    });

    return rows;
  };

  const customFilters = (
    <PurchaseOrderCustomFilters
      filter={filter}
      setFilter={setFilter}
      columns={columns}
    />
  );

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
      queryURL={queryURL}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{orderCriteria: "ID", orderDirection: 1}}
      />
    </FormLayout>
  );
};

export default Index;
