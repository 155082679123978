import { Row, Col, Label, FormGroup } from "reactstrap";
import CustomCheckbox from "../../../../components/Common/CustomCheckbox";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSelect from "../../../../components/Common/CustomSelect";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import { STOCKLOCATION_USAGETYPE_ENUM } from "./consts";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
    <Row>
      <Col md="4">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />

        <CumstomField
          {...form?.getInput("prefix")}
          label="Prefijo"
          type="text"
          disabled={disabled}
          required
        />

      <CustomEnumSelect
            label="Tipo de ubicación"
            {...form?.getEnumSelect("usage", STOCKLOCATION_USAGETYPE_ENUM)}
            isDisabled={disabled}
          />

        <CustomSelect
          label="Cliente/Proveedor"
          {...form?.getSelect("partner", "partner")}
          isDisabled={disabled}
        />

        <CustomSwitch
          label="Activo"
          disabled={disabled}
          {...form?.getSwitch("active")}
        />
      </Col>

      <Col md="4">
        <CumstomField
          {...form?.getInput("pasilloX")}
          label="Pasillo (X)"
          type="text"
          disabled={disabled}
        />

        <CumstomField
          {...form?.getInput("estanteriaY")}
          label="Estantería (Y)"
          type="text"
          disabled={disabled}
        />

        <CumstomField
          {...form?.getInput("AlturaZ")}
          label="Altura (Z)"
          type="text"
          disabled={disabled}
        />

        <FormGroup className="mb-4">
          <Label>Condición</Label>
          <CustomCheckbox
            label="Ubicación de desechos"
            disabled={disabled}
            {...form?.getCheckbox("scrapLocation")}
          />
          <CustomCheckbox
            label="Ubicación virtual"
            disabled={disabled}
            {...form?.getCheckbox("virtualLocation")}
          />
          <CustomCheckbox
            label="Ubicación  de devolución"
            disabled={disabled}
            {...form?.getCheckbox("returnLocation")}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default BaseForm;
