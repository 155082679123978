import React, { useState, useEffect } from "react"
import { Collapse } from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"
import classname from "classnames"

import { connect, useDispatch, useSelector } from "react-redux"
import useMenu from "../../hooks/useMenu"
import { setCurrentMenu, setPageTitle, toggleLeftmenu } from "../../store/actions"

const Navbar = props => {


  const pageTitle = useSelector(state => state.PageTitle)
  const {user} = useSelector(state => state.FormActionStatus)
  
  let history = useHistory();
  const dispatch = useDispatch()

  const location = useLocation()
  const [menuItems, setMenuItems] = useState(null)
  const [locationKeys, setLocationKeys] = useState([])

  const [menuOpened, setMenuOpened] = useState(0)
  const [menuOpened1, setMenuOpened1] = useState(0)

  const {  GoTo, GoBack } = useMenu()

  useEffect(() => {
    const getMenu = async () => {
      //const result = await apiCall.get("/menu");
      const menu = JSON.parse(window.localStorage.getItem('menu'));

      setMenuItems(menu)
    }

    getMenu()
  }, [user])

  useEffect(() => {
    return history.listen(location => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key])
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)

          
          // dispatch(setCurrentMenu(null))
        } else {
          setLocationKeys(keys => [location.key, ...keys])
          // Handle back event
          GoBack();
        }
      }
    })
  }, [locationKeys])

  useEffect(() => {
    if (!menuItems) return;

    if (pageTitle.breadcrumbs.length == 0){
        const pathname = location.pathname + location.search;
        GoTo(pageTitle.title, pathname,false);
    }
    // if (pageTitle.currentMenu) {
    //   dispatch(setPageTitle(pageTitle.currentMenu.content))
    // } else {
    //   let matchingMenuItem = null
    //   let pathName = location.pathname + location.search
    //   let tempMenuItems = []

    //   if (menuItems) {
    //     for (let i = 0; i < menuItems.length; ++i) {
    //       tempMenuItems.push(menuItems[i])

    //       if (menuItems[i].children.length > 0) {
    //         for (let j = 0; j < menuItems[i].children.length; ++j) {
    //           tempMenuItems.push(menuItems[i].children[j])

    //           if (menuItems[i].children[j].children.length > 0) {
    //             for (
    //               let k = 0;
    //               k < menuItems[i].children[j].children.length;
    //               ++k
    //             ) {
    //               tempMenuItems.push(menuItems[i].children[j].children[k])
    //             }
    //           }
    //         }
    //       }
    //     }

    //     for (let i = 0; i < tempMenuItems.length; ++i) {
    //       if (tempMenuItems[i].exactPath === true) {
    //         if (pathName === tempMenuItems[i].href) {
    //           matchingMenuItem = tempMenuItems[i]
    //           break
    //         }
    //       } else {
    //         if (
    //           pathName === tempMenuItems[i].href ||
    //           pathName.includes(`${tempMenuItems[i].href}/`)
    //         ) {
    //           matchingMenuItem = tempMenuItems[i]
    //           break
    //         }
    //       }
    //     }

    //     if (matchingMenuItem === null) {
    //       dispatch(setCurrentMenu(tempMenuItems[0]));
    //       GoTo(tempMenuItems[0].content, tempMenuItems[0].href,false, true);
    //     } else {
    //       dispatch(setCurrentMenu(matchingMenuItem))
    //       GoTo(matchingMenuItem.content, matchingMenuItem.href,false, true);
    //     }
    //   }
    // }
  }, [pageTitle.title])

  useEffect(() => {
    let matchingMenuItem = null
    let ul = document.getElementById("navigation")
    let items = ul.getElementsByTagName("a")

    for (let i = 0; i < items.length; ++i) {
      removeParentDropdown(items[i])
    }

    for (let i = 0; i < items.length; ++i) {
      let menuID = parseInt(items[i].attributes["data-id"]?.value ?? 0)
      let exactPath = items[i].attributes["data-exactpath"].value ?? true

      if (pageTitle.currentMenu && menuID != 0) {
        if (menuID == pageTitle.currentMenu.id) {
          matchingMenuItem = items[i]
          break
        }
      }
    }

    if (matchingMenuItem === null) {
      matchingMenuItem = items[0]
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [menuItems, pageTitle.currentMenu])


  function removeParentDropdown(item) {
    item.classList.remove("active")
    const parent = item.parentElement

    if (parent) {
      parent.classList.remove("active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.remove("active")

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.remove("active") // li
          parent3.childNodes[0].classList.remove("active") //a
          const parent4 = parent3.parentElement
          // if (parent4) {
          //   parent4.classList.remove("mm-show");

          //   const parent5 = parent4.parentElement;

          //   if (parent5) {
          //     //parent5.classList.add("mm-active");
          //     parent5.childNodes[0].classList.remove("mm-active");
          //   }
          // }
        }
      }
      return false
    }
    return false
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  const handleOnClick = (e, menu, level) => {

    if(menu.href === "/#") {e.preventDefault();}

    // if(menu.href=!"/#") {
      GoTo(menu.content,menu.href, false,menu);
    // }


    switch (level) {
      case 0:
        if (menu.children.length === 0) {
          return
        }

        //e.preventDefault()

        if (menuOpened === menu.id) {
          setMenuOpened(0)
        } else {
          setMenuOpened(menu.id)
        }
        break
      case 1:
        //if (menu.children.length === 0) return;

        //e.preventDefault()

        if (menuOpened === menu.id) {
          setMenuOpened1(0)
        } else {
          if (menu.children.length > 0){
            setMenuOpened1(menu.id)
          }else{
            setMenuOpened(0)
            setMenuOpened1(0)

            dispatch(toggleLeftmenu())
          }
          
        }
        break
      case 2:
        setMenuOpened(0)
        setMenuOpened1(0)

        dispatch(toggleLeftmenu())

        break

      default:
        break
    }

    //onMenuClick(menu)
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {menuItems?.map((menu, index) => {
                  //console.log(menu);
                  return (
                    <li key={menu.id} className="nav-item dropdown">
                      <Link
                        data-exactpath={menu.exactPath}
                        onClick={e => {
                          handleOnClick(e, menu, 0)
                        }}
                        data-id={menu.id}
                        className="nav-link dropdown-toggle arrow-none"
                        to={menu.href}
                      >
                        <i className={`${menu.iconClass} mr-2`}></i>
                        {menu.content} {props.menuOpen}
                        <div
                          className={
                            menu.children.length === 0 ? "" : "arrow-down"
                          }
                        ></div>
                      </Link>

                      {menu.children.length > 0 ? (
                        <div
                          className={classname("dropdown-menu", {
                            show: menuOpened === menu.id,
                          })}
                        >
                          {menu.children.map((menu1, index) => {
                            if (menu1.content === "Divider") return null
                            return (
                              <div key={menu1.id} className="dropdown">
                                <Link
                                  data-exactpath={menu1.exactPath}
                                  onClick={e => {
                                    handleOnClick(e, menu1, 1)
                                  }}
                                  data-id={menu1.id}
                                  className={`no-active-color ${
                                    menu1.children.length > 0
                                      ? "dropdown-item dropdown-toggle arrow-none"
                                      : "dropdown-item"}
                                      `}
                                  to={menu1.href}
                                >
                                  <i className={`${menu1.iconClass} mr-2`}></i>
                                  {menu1.content} {props.menuOpen}
                                  <div
                                    className={
                                      menu1.children.length === 0
                                        ? ""
                                        : "arrow-down"
                                    }
                                  ></div>
                                </Link>

                                {menu1.children.length > 0 ? (
                                  <div
                                    className={classname("dropdown-menu", {
                                      show: menuOpened1 === menu1.id,
                                    })}
                                  >
                                    {menu1.children.map((menu2, index) => {
                                      if (menu2.content === "Divider")
                                        return null
                                      return (
                                        <Link
                                          data-exactpath={menu2.exactPath}
                                          onClick={e => {
                                            handleOnClick(e, menu2, 2)
                                          }}
                                          data-id={menu2.id}
                                          key={menu2.id}
                                          className="dropdown-item no-active-color"
                                          to={menu2.href}
                                        >
                                          <i
                                            className={`${menu2.iconClass} mr-2`}
                                          ></i>
                                          {menu2.content} {props.menuOpen}
                                          <div
                                            className={
                                              menu2.children.length === 0
                                                ? ""
                                                : "arrow-down"
                                            }
                                          ></div>
                                        </Link>
                                      )
                                    })}
                                  </div>
                                ) : null}
                              </div>
                            )
                          })}
                        </div>
                      ) : null}
                    </li>
                  )
                })}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default connect(mapStatetoProps, {})(React.memo(Navbar))
