import  { useEffect, useState } from "react"
import { createModal } from "react-modal-promise"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import IndexTable from "./IndexTable"

const ProductModal = ({ initFilter, isOpen, onResolve, onReject }) => {
  const [value, setValue] = useState(null)
  const reject = () => onReject()

  useEffect(() => {
    if (value) {
      onResolve(value)
    }
  }, [value])
  const [filter, setFilter] = useState(initFilter)


  return (
    <Modal size="xl" isOpen={isOpen} toggle={reject}>
      <ModalHeader toggle={reject}>Búsqueda avanzada</ModalHeader>
      <ModalBody>
        <IndexTable
          filter={filter}
          setFilter={setFilter}
          modal={true}
          setValue={setValue}
        />
      </ModalBody>
    </Modal>
  )
}

export const productModal = createModal(ProductModal)
