import React from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Col } from "reactstrap"
import apiCall from "../../../../api"
import searchAsync from "../../../../api/searchAsync"
import CustomSelect from "../../../../components/Common/CustomSelect"
import DynamicTableSelect from "../../../../components/Common/DynamicTable/DynamicTableSelect"
import DynamicTableField from "../../../../components/Common/DynamicTable/DynamicTableField"
import { setApiLoading } from "../../../../store/actions"
// import BarcodeReader from "react-barcode-reader"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import useDynamicTable from "../../../../hooks/useDynamicTable"
import { productModal } from "../../../Inventory/Product/components/ProductModal"

const StockPickingItemsTable = props => {
  const dispatcher = useDispatch()

  const { form, setFormState, disabled } = props

  const updateFields = async (name, index, value) => {
    let tempItems = [...form.fields?.stockMove]

    let tempItem = {
      ...tempItems[index],
      [name]: value,
    }

    tempItems[index] = tempItem

    setFormState({ ...form.fields, stockMove: tempItems })

    tempItem = await fetchItem(name, tempItem)

    tempItems[index] = tempItem

    setFormState({ ...form.fields, stockMove: tempItems })
  }

  const dynamicTable = useDynamicTable(
    form,
    setFormState,
    "stockMove",
    disabled,
    updateFields
  )

  const handleError = err => {
    console.log(err)
  }

  const handleScan = async data => {
    if (disabled) return

    let productItem = await fetchItem(null, data)

    if (productItem != null) {
      dynamicTable.addItem(productItem)
    }
  }

  const onSelect = async value => {

    if(value?.id == 'more')
    {
      try {
        const result = await productModal({initFilter: {active: true, saleOK: true}});

        value = result
      } catch (error) {
        return
      }
    }

    let item = {
      product: value,
    }

    let productItem = await fetchItem(null, item)

    if (productItem != null) {
      dynamicTable.addItem(productItem)
    }

    dynamicTable.setEditable(false)
  }

  const fetchItem = async (name, item = null, barcode = null) => {
    let request = {
      name: name,
      item: item,
      priceListID: form.fields?.priceList?.id,
      barcode: barcode,
    }

    try {
      dispatcher(setApiLoading(true))

      const { data } = await apiCall.post(`/stockpicking/fetchItem`, request)

      dispatcher(setApiLoading(false))
      return data?.data
    } catch (error) {
      dispatcher(setApiLoading(false))
      console.log("fetchItem", error.message)
    }
  }

  return (
    <React.Fragment>
      <Col xs="12">
        <div className="table-responsive overflow-visible">
          <Table
            className="table mb-0"
          >
            <Thead className="table-light">
              <Tr>
                <Th width="20%">Producto</Th>
                <Th width="10%">Demanda inicial</Th>
                <Th width="10%">Hecho</Th>
                <Th width="10%">Medida</Th>
                <Th width="5%"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {dynamicTable.items?.map((p, key) => (
                <Tr key={"_product_" + key}>
                  <Th onClick={() => dynamicTable.onClick("product",key)}>
                    <DynamicTableSelect
                      header={true}
                      required
                      {...dynamicTable?.getSelect(
                        "product",
                        key,
                        "product",
                        "",
                        productModal,{active: true}
                      )}
                    />
                  </Th>
                  <Td onClick={() => dynamicTable.onClick("orderedQty",key)}>
                    <DynamicTableField
                      type="number"
                      {...dynamicTable?.getInput("orderedQty", key)}
                    />
                  </Td>
                  
                  <Td>
                    <DynamicTableField
                      name="qtyDone"
                      type="number"
                      value={dynamicTable.items[key]["qtyDone"]}
                      disabled={true}
                    />
                  </Td>
                  <Td>
                    <span className="d-block text-muted text-truncate field-muted">
                      {p.productMeasureUnit?.name}
                    </span>
                  </Td>
                  <Td>
                    {disabled ? null : (
                      <Link
                        to="#"
                        onClick={() => dynamicTable.removeItem(key)}
                        className="text-danger"
                      >
                        <i className="mdi mdi-close font-size-18 mr-3"></i>
                      </Link>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        {/* <BarcodeReader onError={handleError} onScan={handleScan} /> */}
      </Col>
      {disabled ? null : (
        <Col lg="4" sm="12">
          {dynamicTable.editable === "select" ? (
            <CustomSelect
              name="product"
              value={null}
              customRef={dynamicTable.selectRef}
              onFocusOut={() => dynamicTable.setEditable(null)}
              onChange={onSelect}
              loadOptions={inputValue => {
                return searchAsync("product", inputValue, "saleOK=true")
              }}
            />
          ) : (
            <a
              className="text-primary"
              onClick={() => {
                dynamicTable.setEditable("select")
              }}
            >
              Agregar línea
            </a>
          )}
        </Col>
      )}
    </React.Fragment>
  )
}

export default StockPickingItemsTable
