export const MODULE = 'invoices';
export const MODEL = 'tax';

export const INDEX_TITLE = 'Impuestos';
export const CREATE_TITLE = 'Nuevo impuesto';

export const INITIAL_STATE = {
    name: "",
    calculationType: 1,
    scope: 0,
    codeAFIP: 0,
    codeTributeAFIP: 0,
    active: true,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


export const SCOPE_ENUM = [
  { value: 0, label: 'Ninguno'},
  { value: 1, label: 'Ventas'},
  { value: 2, label: 'Compras'},
];

export const AFIP_TRIBUTE_CODE_ENUM = [
  { value: 0, label: 'Ninguno'},
  { value: 1, label: 'Impuestos nacionales'},
  { value: 2, label: 'Impuestos provinciales'},
  { value: 3, label: 'Impuestos municipales'},
  { value: 4, label: 'Impuestos internos'},
  { value: 99, label: 'Otros'},
];

export const AFIP_CODE_ENUM = [
  { value: 0, label: 'Ninguno'},
  { value: 3, label: 'IVA 0%'},
  { value: 4, label: 'IVA 10.5%'},
  { value: 5, label: 'IVA 21 %'},
  { value: 6, label: 'IVA 27 %'},
  { value: 8, label: 'IVA 5 %'},
  { value: 9, label: 'IVA 2.5 %'},
];

export const CALCULATION_TYPE_ENUM = [
  { value: 1, label: 'Porcentaje sobre el precio'},
  { value: 2, label: 'Fijo'},
];

