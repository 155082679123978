import CustomFilters from "../../../../components/Common/CustomFilters"

const PartnerCustomFilters = props => {
  const { filter, setFilter, columns, isModal } = props

  const itemsFilter = [
    {
      name: "business",
      label: "Empresas",
    },
    {
      name: "individual",
      label: "Individuos",
    },
    {
      name: "divider",
    },
    {
      name: "customer",
      label: "Clientes",
    },
    {
      name: "supplier",
      label: "Proveedores",
    },
    {
      name: "divider",
    },
    {
      name: "active",
      label: "Activos",
    },
  ]


  return (
      <CustomFilters
        filter={filter}
        setFilter={setFilter}
        columns={columns}
        itemsFilter={itemsFilter}
        isModal={isModal}
      />
  )
}

export default PartnerCustomFilters
