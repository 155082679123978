export const MODULE = 'invoices';
export const MODEL = 'accountpaymentterm';

export const INDEX_TITLE = 'Plazos de pago';
export const CREATE_TITLE = 'Nuevo plazo de pago';

export const INITIAL_STATE = {
    name: "",
    active: true,
    due: 0,
  };

  export const INITIAL_STATE_FILTER = {
    customFilters: []
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

