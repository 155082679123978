import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames';

const StatusBar = (props) => {

    const { state } = props;

    return(
            
    <Nav pills className="bg-light rounded d-none d-xl-flex">
    <NavItem>
        <NavLink
            className={classnames({ active: state === 0 })}
        >
            Borrador
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: state === 1 })}
        >
            Abierto
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            className={classnames({ active: state === 2 })}
        >
            Pagado
        </NavLink>
    </NavItem>
    {state === 4 ? (
        <NavItem>
        <NavLink
            className={classnames({ active: state === 3 })}
        >
            Cancelado
        </NavLink>
    </NavItem>
    ) : null}
    
</Nav>
    )
}

export default StatusBar