import React from 'react'
import { Col, Row, TabPane } from 'reactstrap'
import CustomSelect from '../../../../../components/Common/CustomSelect';

const AccountingTab = (props) => {
    
    const { form, disabled } = props;

    return(
      <TabPane tabId="5" className="p-3">
        <Row>
        <Col md="6">
              <CustomSelect
                label="Cuenta de ingreso"
                {...form?.getSelect("incomeAccount", "accountAccount")}
                isClearable
                isDisabled={disabled}
              />
              <CustomSelect
                label="Cuenta de egreso"
                {...form?.getSelect("expenseccount", "accountAccount")}
                isClearable
                isDisabled={disabled}
              />
              <CustomSelect
                label="Cuenta de costo"
                {...form?.getSelect("costAccount", "accountAccount")}
                isClearable
                isDisabled={disabled}
              />
            </Col>
        </Row>
      </TabPane>
    )
}

export default AccountingTab