import React from "react"
import { useSelector } from "react-redux"
import { Button, Col, Row } from "reactstrap"
import { setFormSecondaryAction } from "../../../../store/actions"
import { useDispatch } from "react-redux"

const AccountMoveActions = props => {
  const { form } = props
  const { loading } = useSelector(state => state.ApiStatus)
  const dispatch = useDispatch()


  return (
    <Row className="mb-2">
      <Col>
        {form.fields?.state === 0  ? (
          <Button
            color="primary"
            className="btn btn-primary btn-sm me-2"
            type="submit"
            form="hook-form"
            onClick={() => {
              dispatch(setFormSecondaryAction("validate"))
            }}
            disabled={loading}
          >
            Validar
          </Button>
        ) : null}

      </Col>
    </Row>
  )
}

export default AccountMoveActions
