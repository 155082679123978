import {  API_ERROR, API_LOADING, API_VALIDATION_ERROR } from './actionTypes';

const initialState = {
    error: "",
    validationError: "",
    loading: false
}

const ApiStatus = (state = initialState, action) => {
    switch (action.type) {
        case API_LOADING:
            state = { ...state, loading: action.payload};
            break;
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        case API_VALIDATION_ERROR:
            state = { ...state, validationError: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ApiStatus;