import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Row, Col, Card, CardBody, Label, Input, Spinner } from "reactstrap"
import useAPI from "../../../hooks/useAPI"
import ErrorMessage from "../ErrorMessage"
import CheckboxTable from "./CheckboxTable"
import SearchBox from "./SearchBox"
import TableActions from "./TableActions"
import Paginations from "./Paginations"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Export } from "../../../pages/Core/Export"

const CustomDatatable = props => {
  const {
    model,
    columns,
    checks,
    rows,
    customFilters,
    filter,
    customURL,
    defaultSort,
    modal,
    setValue,
    exportRef,
    onExport,
    onSearchBy
  } = props

  const url = customURL ?? `/${model}/loadtable`

  const customClass = ""

  const [body, setBody] = useState(null)
  const { result, doRefresh } = useAPI(url, "post", body)
  const { error, loading } = useSelector(state => state.ApiStatus)

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [searchBy, setSearchBy] = useState("")
  const [sort, setSort] = useState(defaultSort)


  const [allSelected, setAllSecleted] = useState(false)
  const [selected, setSelected] = useState([])
  const [selectedStr, setSelectedStr] = useState([])
  const [count, setCount] = useState(0)

  useEffect(() => {
    refresh()
  }, [pageNumber])

  useEffect(() => {
    if(onSearchBy){
      //onSearch(onSearchBy)
    }
  }, [onSearchBy])

  useEffect(() => {
    if (pageNumber > 1) {
      setPageNumber(1)
    } else {
      refresh()
    }
  }, [pageSize, searchBy, filter, sort])

  const refresh = () => {
    if (filter && sort) {
      const body = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        text: searchBy,
        filter: filter,
        sort: sort,
      }

      setBody(body)
    }
  }
  const handleChange = e => {
    setAllSecleted(!allSelected)
  }

  

  const onChangePageSize = e => {
    setPageSize(e.target.value)
  }

  const onSort = (e, name) => {
    if (name) {
      setSort({
        orderCriteria: name,
        orderDirection: sort?.orderDirection === 1 ? 0 : 1,
      })
    }
  }

  const onCheck = index => {
    setSelected({
      ...selected,
      [index]: !selected[index],
    })

    //console.log('OnCheck', id)
  }

  useEffect(() => {
    if (result != null) {
      setTotalPages(result.totalPages)
    }
  }, [result])

  useEffect(() => {
    setSelected([])
  }, [result?.data])

  useEffect(() => {
    if (allSelected) {
    } else {
      setSelected([])
    }
  }, [allSelected])

  useEffect(() => {
    var tempCount = 0

    let str = []

    for (var i = 0; i < result?.data?.length; ++i) {
      if (selected[i] === true) {
        str.push(result?.data[i].id);
        tempCount++
      }
    }

    setSelectedStr(str)

    setCount(tempCount)
  }, [selected])

  const onRowClick = (e, item) => {
    e.preventDefault()
    setValue(item)
  }
  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault()
      setPageNumber(page)
    },
    [setPageNumber]
  )

  return (
    <Row>
      <Col xs="12">
        <Card className="mb-0">
          <CardBody>
            <ErrorMessage error={error} />
            <Row className="mb-2">
              <Col xs="6">
                <SearchBox setSearchBy={setSearchBy} />
                {loading ? (
                  <Spinner
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    type="grow"
                    className="ms-2"
                    color="primary"
                  />
                ) : null}
              </Col>
              <Col xs="6">
                <TableActions
                  onRefresh={doRefresh}
                  onExport={onExport}
                  customFilters={customFilters}
                  count={count}
                  modal={modal}
                />
              </Col>
            </Row>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <Thead>
                  <Tr>
                    {checks && !modal ? (
                      <Th className={customClass} width={"3%"}>
                        <div className="d-none d-sm-block custom-conTrol custom-checkbox">
                          <Input
                            type="checkbox"
                            checked={allSelected}
                            onChange={handleChange}
                            className="custom-control-input"
                            id="customCheckAll"
                          />
                          <Label
                            className="custom-control-label"
                            htmlFor="customCheckAll"
                          >
                            &nbsp;
                          </Label>
                        </div>
                      </Th>
                    ) : null}
                    {columns?.map((c, index) => {
                      return (
                        !c.invisible && (
                          <Th
                            key={index}
                            width={`${c.width}%`}
                            className={customClass}
                          >
                            {c.name ? (
                              <a onClick={e => onSort(e, c.name)}>
                                {c.label}
                                {sort?.orderCriteria === c.name ? (
                                  <i
                                    className={`p-1 bx  font-size-14 ${
                                      sort.orderDirection === 1
                                        ? "text-danger bx-down-arrow-alt"
                                        : "text-success bx-up-arrow-alt"
                                    }`}
                                  />
                                ) : null}
                              </a>
                            ) : (
                              <span>{c.label}</span>
                            )}
                          </Th>
                        )
                      )
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {rows(result?.data).map((p, index) => {
                    return (
                      <Tr
                        key={index}
                        style={modal && { cursor: "pointer" }}
                        onClick={e =>
                          modal && onRowClick(e, result?.data[index])
                        }
                      >
                        {checks && !modal && (
                          <Td className={customClass}>
                            <CheckboxTable
                              checked={selected[index]}
                              index={index}
                              onChange={() => onCheck(index)}
                            />
                          </Td>
                        )}

                        {p.fields?.map((f, index1) => {
                          return index1 == 0 ? (
                            <Th key={index1} className={customClass}>
                              {f}
                            </Th>
                          ) : (
                            <Td key={index1} className={customClass}>
                              {f}
                            </Td>
                          )
                        })}
                      </Tr>
                    )
                  })}
                  {columns?.filter(c => c.sum).length > 0 ? (
                    <Tr>
                      {checks ? <Td className={customClass}></Td> : null}
                      {columns?.map((c, index) => {
                        return (
                          <React.Fragment>
                            {c.sum != null ? (
                              <Td className={customClass}>
                                <strong>{c.sum.toFixed(2)}</strong>
                              </Td>
                            ) : (
                              <Td className={customClass}></Td>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </Tr>
                  ) : null}
                </Tbody>
              </Table>
            </div>

            {result ? (
              <Row className="">
                <Col xs="6">
                  <div className="w-25 mb-2">
                    <select
                      onChange={onChangePageSize}
                      className="custom-select custom-select-sm"
                    >
                      <option value="10" defaultValue>
                        10
                      </option>
                      <option value="25">25</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="">
                    <p>{`Mostrando ${result?.data?.length}/${result?.filteredRecords} de ${result?.totalRecords} registros.`}</p>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="float-right">
                    <Paginations
                      totalRecords={result?.filteredRecords}
                      pageLimit={pageSize}
                      pageNeighbours={1}
                      onPageChanged={onPageChanged}
                      currentPage={pageNumber}
                    />
                    {/* <PaginationTable
                      setPageNumber={setPageNumber}
                      pageNumber={pageNumber}
                      totalPages={totalPages}
                    /> */}
                  </div>
                </Col>
              </Row>
            ) : null}
          </CardBody>
        </Card>
      </Col>
      <Export
        ref={exportRef}
        columns={columns}
        selected={selectedStr}
        totalRecords={result?.totalRecords}
        searchBy={searchBy}
        filter={filter}
        sort={sort}
      />
    </Row>
  )
}

export default CustomDatatable
