import { MODULE } from "../../consts";

export const MODEL = 'menu';

export const INDEX_TITLE = 'Menu';
export const CREATE_TITLE = 'Nuevo menu';

export const INITIAL_STATE = {
    content: "",
    iconClass: "",
    order: 0,
    parentID: "",
    href: "",
    exactPath: true
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


