import axios from "axios"
import { version } from "../../package.json"
import useLogin from "../hooks/useLogin"

const baseURL = "/api"

const loginHook = useLogin()

let headers = {}

if (localStorage.token) {
  headers.Authorization = `Bearer ${localStorage.token}`
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
})

axiosInstance.interceptors.request.use(
  config => {
    if (localStorage.token) {
      config.headers.Authorization = "Bearer " + localStorage.token
    }

    config.headers["AppVersion"] = version

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

function createAxiosResponseInterceptor(axiosInstance) {
  const interceptor = axiosInstance.interceptors.response.use(
      response => response,
      error => {
          // Reject promise if usual error
          if (error.response.status !== 401) {
              return Promise.reject(error);
          }

          /*
           * When response code is 401, try to refresh the token.
           * Eject the interceptor so it doesn't loop in case
           * token refresh causes the 401 response
           */
          axiosInstance.interceptors.response.eject(interceptor);

          

          loginHook.destroyToken();
              
          loginHook.ToLogin()
          
          return Promise.reject(error);

          // return axiosInstance.post('/user/refreshtoken', {
          //     'token': localStorage.getItem("token"),
          //     'refreshToken': localStorage.getItem("refreshToken")
          // }).then(response => {
          //     //saveToken();
          //     error.response.config.headers['Authorization'] = 'Bearer ' + response.refreshToken;
          //     return axiosInstance(error.response.config);
          // }).catch(error => {
          //     loginHook.destroyToken();
              
          //     loginHook.ToLogin()
          //     return Promise.reject(error);
          // }).finally(createAxiosResponseInterceptor);
      }
  );
}


createAxiosResponseInterceptor(axiosInstance);

export default axiosInstance
