export const MODULE = 'inventory';
export const MODEL = 'productmeasureunitcategory';

export const INDEX_TITLE = 'Categorías UdM';
export const CREATE_TITLE = 'Nueva categoría UdM';

export const INITIAL_STATE = {
    name: "",
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;
