import { MODULE } from "../../consts";

export const MODEL = 'mailserver';

export const INDEX_TITLE = 'Servidores de correo';
export const CREATE_TITLE = 'Nuevo servidor de correo';

export const INITIAL_STATE = {
    name: "",
    sMTP_Encryption: 2,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const MAILSERVER_SMTPENCRYPTION_ENUM = [
  { value: 0, label: 'Ninguno'},
  { value: 1, label: 'TLS (STARTTLS)'},
  { value: 2, label: 'SSL/TLS'},
];
