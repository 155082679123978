import { useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import { MODEL, BASE_URL, INDEX_TITLE, MODULE } from "./components/consts"
import { useDispatch } from "react-redux"
import { setPageTitle } from "../../../store/pagetitle/actions"
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons"
import IndexTable from "./components/IndexTable"

const Index = props => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(null)
  const { module } = useParams()

  useEffect(() => {
    if (module === "sales") {
      dispatch(setPageTitle("Productos vendibles"))
    } else if (module === "purchases") {
      dispatch(setPageTitle("Productos comprables"))
    } else {
      dispatch(setPageTitle(INDEX_TITLE))
    }

    setFilter({
      ...filter,
      active: true,
      saleOK: module === "sales" ?? true,
      purchaseOK: module === "purchases" ?? true,
      customFilters: [],
    })
  }, [module])

  if (module != "sales" && module != "purchases" && module != "inventory")
    return null

  const actionButtons = <IndexActionButtons url={BASE_URL} />

  return (
    <IndexTable
      filter={filter}
      setFilter={setFilter}
      actionButtons={actionButtons}
    />
  )
}

export default Index
