import Index from ".."
import Account from "../Account"
import SupplierAccount from "../SupplierAccount"
import Details from "../Details"
import { MODEL, MODULE } from "./consts"

export const PartnerRoutes = [
  { path: "/sales/partner/account", exact: true, component: Account },
  {
    path: "/purchases/partner/account",
    exact: true,
    component: SupplierAccount,
  },
  { path: "/:module/partner/:id", exact: true, component: Details },
  { path: "/:module/partner", exact: true, component: Index },
]

export default PartnerRoutes
