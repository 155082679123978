import React, { useEffect } from "react"
import { Row, Col, Label, FormGroup } from "reactstrap"
import CustomDateTime from "../../../../components/Common/CustomDateTime"
import CumstomField from "../../../../components/Common/CustomFields"
import CustomSelect from "../../../../components/Common/CustomSelect"
import StockPickingTabs from "./StockPickingTabs"
import { partnerModal } from "../../../Core/Partner/components/PartnerModal"
import Container from "react-modal-promise";

export const BaseForm = ({ form, handleSubmit, setFormState, disabled }) => {
  // useEffect(() => {
  //   if(action === 'edit' || action === 'create'){
  //
  //   }
  // },[form.fields?.stockPickingType]);
  
  return (
    <React.Fragment>
      
      <Row>
      <Container />
        <Col md="4">
          <CustomSelect
            label="Cliente/Proveedor"
            {...form?.getSelect("partner", "partner","",partnerModal,{active: true})}
            required
            isDisabled={disabled}
          />

          <CustomSelect
            label="Tipo de operación"
            {...form?.getSelect("stockPickingType", "stockPickingType")}
            isDisabled={disabled}
            required
          />
          {form.fields?.stockPickingType ? (
            <React.Fragment>
              {form.fields?.stockPickingType.pickingType === 2 || form.fields?.stockPickingType.pickingType === 3 ? (
                <CustomSelect
                label="Ubicación de origen"
                {...form?.getSelect(
                  "stockLocationIni",
                  "stockLocation",
                  "usage=2"
                )}
                isDisabled={disabled}
                required
              />
              ) : null}
              {form.fields?.stockPickingType.pickingType === 1 || form.fields?.stockPickingType.pickingType === 3 ? (
                <CustomSelect
                label="Ubicación destino"
                {...form?.getSelect(
                  "stockLocationDest",
                  "stockLocation",
                  "usage=2"
                )}
                isDisabled={disabled}
                required
              />
              ) : null}
              
              
            </React.Fragment>
          ) : null}
        </Col>

        <Col md="4">
          <CustomDateTime
            label="Fecha prevista de entrega"
            disabled={disabled}
            {...form?.getInputDate("date", "scheduledDate")}
          />
          <CumstomField
            {...form?.getInput("origin")}
            label="Documento origen"
            type="text"
            disabled={disabled}
            required
          />
        </Col>
      </Row>
      <StockPickingTabs
        form={form}
        setFormState={setFormState}
        disabled={disabled}
      />
    </React.Fragment>
  )
}

export default BaseForm
