export const MODULE = 'inventory';
export const MODEL = 'productcategory';

export const INDEX_TITLE = 'Categorías de productos';
export const CREATE_TITLE = 'Nueva categoría';

export const INITIAL_STATE = {
    name: "",
    parentId: null,
    parentCategory: {
      id: 1,
      name: "Todas"
    },
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;