import React from "react"
import { Col, Row, TabPane } from "reactstrap"
import CumstomField from "../../../../../components/Common/CustomFields"
import CustomSelect from "../../../../../components/Common/CustomSelect"

const AccountingTab = props => {
  const { form, disabled } = props

  return (
    <TabPane tabId="2" className="p-3">
      <Row>
        <Col md="6">
          <CumstomField
            {...form?.getInput("creditLimit")}
            label="Crédito"
            type="number"
            disabled={disabled}
          />
          <CustomSelect
            label="Cuenta a pagar"
            {...form?.getSelect("payableAccount", "accountAccount")}
            isClearable
            isDisabled={disabled}
          />
          <CustomSelect
            label="Cuenta a cobrar"
            {...form?.getSelect("receibableAccount", "accountAccount")}
            isClearable
            isDisabled={disabled}
          />
        </Col>
      </Row>
    </TabPane>
  )
}

export default AccountingTab
