import React, { useEffect, useState } from "react";

import useForm from "../../../hooks/useForm";
import BaseForm from "./components/BaseForm";
import { MODEL, INITIAL_STATE, CREATE_TITLE } from "./components/consts";
import DetailsPage from "../../../components/Actions/DetailsPage";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


const Details = () => {

  const [formState, setFormState] = useState([]);
  const form = useForm({ formState });
  const { action } = useSelector(state => state.FormActionStatus);
  const { module } = useParams();

  let type = '';

  // if(module==="sales"){
  //   type= "?type=customer";
  // }

  if(module==="purchases"){
    type= "?type=supplier";
  }else{
    type= "?type=customer";
  }

  
  // useEffect(() => {

  //   if(form.fields?.document){

  //     let str = form.fields?.document.toString()

  //     debugger

  //     if(str.includes('-')){

  //       console.log(form.fields?.document.replace('-', ''))
  //     }
  //   }
  // }, [form.fields?.document])
  return (
    <DetailsPage
      model={MODEL}
      module={module}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      setFormState={setFormState}
      defaultValues
      defaultValuesParams={type}
    >
      <BaseForm
          form={form}
          disabled={ action != 'edit' && action != 'create' ? true : false}
          setFormState={setFormState}
          module={module}
        />
    </DetailsPage>
  );
};

export default Details;
