import { MODULE } from "../../consts";

export const MODEL = 'stockquant';

export const INDEX_TITLE = 'Stock a mano';
export const CREATE_TITLE = 'Nuevo tipo de operación';

export const INITIAL_STATE = {
    quantity: 0,
    reserved: 0,
    product: null,
    location: null,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const STOCKQUANT_ENUM = [
  { value: 1, label: 'Ubicación de proveedor'},
  { value: 2, label: 'Ubicación de stock'},
  { value: 3, label: 'Ubicación interna'},
  { value: 4, label: 'Ubicación de cliente'},
  { value: 5, label: 'Ajuste de inventario'},
];

export const INITIAL_STATE_FILTER = {
  product: null,
  internal: false,
  negativeStock: false,
  positiveStock: false,
  reserved: false,
  transit: false,
  customFilters: []
};
