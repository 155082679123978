import { MODULE } from "../../consts";

export const MODEL = 'stockwarehouseorderpoint';

export const INDEX_TITLE = 'Reglas de almacenamiento';
export const CREATE_TITLE = 'Nueva regla de almacenamiento';

export const INITIAL_STATE = {
    name: "",
    product: "",
    leadType: 2,
    stockWareHouse: null,
    stockLocation: null,
    productMinQuantity: 0,
    productMaxQuantity: 0,
    quantityMultiple: 0,
    leadDays: 0,
    active: true,

  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const STOCKWAREHOUSEORDERPOINT_LEADTYPE_ENUM = [
  { value: 1, label: 'Día(s) para obtener los productos'},
  { value: 2, label: 'Día(s) de compra'},
];
