export const ProductFields = [
  {
    name: "Name",
    label: "Nombre",
    width: 20,
    type: "string",
    active: true
  },
  {
    name: "ListPrice",
    label: "Precio de venta",
    width: 15,
    type: "number",
    active: true
  },
  {
    name: "StandardPrice",
    label: "Precio de costo",
    width: 15,
    type: "number",
    active: false,
  },
  {
    name: "ProductCategoryName",
    label: "Categoría",
    width: 20,
    type: "string",
    active: true
  },
  {
    name: "ProductTags",
    label: "Etiquetas",
    width: 10,
    type: "string",
    active: true
  },
  {
    name: "Stock",
    label: "Stock",
    width: 10,
    type: "number",
    active: false
  },
  {
    name: "ProductUomName",
    label: "Unidad de medida",
    width: 40,
    type: "string",
    active: true
  },
  {
    name: "Barcodes",
    label: "Códigos de barra",
    width: 20,
    type: "string",
    active: false,
    invisible: true
  },

  {
    name: "WriteDate",
    label: "Última modificación",
    width: 40,
    type: "date",
    active: false,
    invisible: true
  },
]
