import {put, call, takeLatest, all, fork} from 'redux-saga/effects';
import { BASE_EDIT_TRY } from './actionTypes';
import { editBaseOK, editBaseError } from './actions';
import apiCall from '../../../api';

function* editBase ({payload: {history,target,values}}) {
    try {
        const results = yield call(apiCall.put,`/${target}`, values);
        
        history.push(`/${target}/${values.id}`);
        yield put(editBaseOK(results.data));
    } catch (error) {
        yield put(editBaseError(error));
    }
}

export function* watchEditBase(){
    yield takeLatest(BASE_EDIT_TRY, editBase);
}


function* editBaseSaga() {
    yield all([
        fork(watchEditBase)
    ]);
  }


export default editBaseSaga;