import React, { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap";


import classnames from "classnames";
import LineaTab from "./Tabs/LineaTab";
import InformationTab from "./Tabs/InformationTab";

const AccountMoveTabs = (props) => {
  const { form, setFormState ,disabled } = props;
  const [customActiveTab, setcustomActiveTab] = useState("1");

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  }

  return (
    <Col lg={12} className="mt-1">
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "1",
            })}
            onClick={() => {
              toggleCustom("1");
            }}
          >
            <span className="d-sm-block">Apuntes contables</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "2",
            })}
            onClick={() => {
              toggleCustom("2");
            }}
          >
            <span className="d-sm-block">Otra información</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={customActiveTab}>
        <LineaTab 
        form={form}
        setFormState={setFormState} 
        disabled={disabled} />
        <InformationTab form={form} disabled={disabled} />
      </TabContent>
    </Col>
  );
};

export default AccountMoveTabs;
