import React from 'react'
import { TabPane } from 'reactstrap'

import PurchaseOrderItemsTable from '../PurchaseOrderItemsTable';

const LineaTab = (props) => {

    const { form, setFormState, disabled } = props;
  
    return(
        <TabPane tabId="1" className="pt-3">
          <PurchaseOrderItemsTable
            disabled={disabled}
            form={form}
            setFormState={setFormState}
          />
        </TabPane>
    )
}

export default LineaTab