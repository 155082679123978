import apiCall from ".";

const searchAsync = async (target, inputValue, params = null) => {
    await new Promise((resolve) => {
      setTimeout(resolve, 200);
    });

    let url = `/${target}/search?text=${inputValue}`;

    if (params != null) {
      url = `${url}&${params}`;
    }

    return await apiCall
      .get(url)
      .then((res) => {

        if(res.data.length > 6){
          res.data.push({
            "id": "more",
            "name": "Buscar más..."
          });
        }
        
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  export default searchAsync;