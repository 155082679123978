import React, { useState } from "react";

import useForm from "../../../hooks/useForm";
import BaseForm from "./components/BaseForm";
import {  MODEL, INITIAL_STATE, CREATE_TITLE } from "./components/consts";
import {  MODULE } from "../consts";
import DetailsPage from "../../../components/Actions/DetailsPage";
import { useSelector } from "react-redux";
import { useEffect } from "react";


const Details = () => {

  const [formState, setFormState] = useState([]);
  const form = useForm({ formState });
  const { action } = useSelector(state => state.FormActionStatus);

  useEffect(() => {

    if(action === 'edit' || action === 'create'){
      if(form.fields?.billingType === 1){
        if(form.fields?.developmentMode){
          setFormState({
            ...form.fields,
            ["urL_WSAA"]: "https://wsaahomo.afip.gov.ar/ws/services/LoginCms?WSDL",
            ["urL_USFE"]: "https://wswhomo.afip.gov.ar/wsfev1/service.asmx?WSDL",
            ["url_PADRON"]: "https://awshomo.afip.gov.ar/sr-padron/webservices/personaServiceA5?WSDL"
          });
        }else{
          setFormState({
            ...form.fields,
            ["urL_WSAA"]: "https://wsaa.afip.gov.ar/ws/services/LoginCms",
            ["urL_USFE"]: "https://servicios1.afip.gov.ar/wsfev1/service.asmx?WSDL",
            ["urL_PADRON"]: "https://aws.afip.gov.ar/sr-padron/webservices/personaServiceA5?WSDL",
          });
        }
        
      }
    }

  },[form.fields?.developmentMode]);

  return (
    <DetailsPage
      model={MODEL}
      module={MODULE}
      form={form}
      initialState={INITIAL_STATE}
      createTitle={CREATE_TITLE}
      setFormState={setFormState}
    >
      <BaseForm
          form={form}
          disabled={ action != 'edit' && action != 'create' ? true : false}
        />
    </DetailsPage>
  );
};

export default Details;
