import CustomFilters from "../../../../components/Common/CustomFilters"

const StockQuantCustomFilters = (props) => {
  const { filter, setFilter, columns } = props

  const itemsFilter = [
    {
      name: "customer",
      label: "Clientes",
    },
    {
      name: "supplier",
      label: "Proveedores",
    },
    {
      name: "divider",
    },
    {
      name: "out",
      label: "A pagar",
    },
    {
      name: "in",
      label: "A cobrar",
    },
    {
      name: "divider",
    },
    {
      name: "draft",
      label: "Borrador",
    },
    {
      name: "pending",
      label: "Pendiente",
    },
    {
      name: "posted",
      label: "Validado",
    },
    {
      name: "reconciled",
      label: "Conciliado",
    },
  ]


  return (
    <CustomFilters
    filter={filter}
    setFilter={setFilter}
    columns={columns}
    itemsFilter={itemsFilter}
  />
  );
};

export default StockQuantCustomFilters;
