import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, Container } from "reactstrap";
import FormLayout from "../../components/Common/FormLayout";
import useAPIFunctions from "../../hooks/useAPIFunctions";
import { setPageTitle } from "../../store/actions";

const InventoryDashboard = (props) => {

  const [results, setResults] = useState([]);
  
  const { doGetAsync } = useAPIFunctions();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setPageTitle('Inventario'));
    doFetch();
  }, []);

  const doFetch = async () => {
    try {
      const response = await doGetAsync(`/inventory/dashboard`);
        
        setResults(response);
    } catch (error) {
      console.log("doFetch", error.message);
    }
  };

  return (
      <React.Fragment>
          <div className="page-content">
          <Container fluid>
            <Row>
            <Col sm="4" >
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <i className="bx bx-copy-alt"/>
                    </span>
                  </div>
                  <Link
                        to="/inventory/product"
                      >
                        <h5 className="font-size-14 mb-0">Productos</h5>
                      </Link>
                  
                </div>
                <div className="text-muted mt-4">
                  <h4>
                    {`Hay ${results?.data?.data?.productCount} productos`} 
                  </h4>
                  <div className="d-flex">
                  <Link
                        to="/inventory/product/create"
                        className="btn btn-primary waves-effect waves-light btn-sm"
                      >
                        Crear {" "}
                        <i className="mdi mdi-chevron-right ml-1"></i>
                      </Link>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
            <Col sm="4" >
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <i className="bx bx-copy-alt"/>
                    </span>
                  </div>
                  <Link
                        to="/inventory/productcategory"
                      >
                        <h5 className="font-size-14 mb-0">Categorías</h5>
                      </Link>
                  
                </div>
                <div className="text-muted mt-4">
                  <h4>
                    {`Hay ${results?.data?.data?.productCategoryCount} categorías`} 
                  </h4>
                  <div className="d-flex">
                  <Link
                        to="/inventory/productcategory/create"
                        className="btn btn-primary waves-effect waves-light btn-sm"
                      >
                        Crear {" "}
                        <i className="mdi mdi-chevron-right ml-1"></i>
                      </Link>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
            </Row>
          <Row>
            {results?.data?.data?.stockPickingTypes.map((stockPickingType) => (
              <Col key={stockPickingType.id} md="4">
                  
                <Card>
              <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-2">
                    {stockPickingType.pickingType === 1 ? (
                        <span className="avatar-title rounded-circle bg-warning bg-soft text-success font-size-18">
                        <i className="mdi mdi-arrow-down-bold"></i>
                      </span>
                    ) : null}

                    {stockPickingType.pickingType === 2 ? (
                        <span className="avatar-title rounded-circle bg-warning bg-soft text-danger font-size-18">
                        <i className="mdi mdi-arrow-up-bold"></i>
                      </span>
                    ) : null}
                    {stockPickingType.pickingType === 3 ? (
                        <span className="avatar-title rounded-circle bg-warning bg-soft text-warning font-size-18">
                        <i className="mdi mdi-arrow-up-down-bold"></i>
                      </span>
                    ) : null}
                      
                    </div>
                    <Link to={`/inventory/stockpicking?stockPickingType=${stockPickingType.id}`} >
                      <h5 className="font-size-14 mb-0">
                        {stockPickingType.name}
                      </h5>
                    </Link>
                  </div>

                  <Row>
                    <div className="col-lg-6">
                      <div className="text-muted mt-3">
                        <h4>{stockPickingType.stockWareHouse.name}</h4>
                        <Link to={`/inventory/stockpicking?stockPickingType=${stockPickingType.id}&waiting=true`} >
                          <p>En espera ({stockPickingType.waitingQty}) </p>
                        </Link>
                        <Link to={`/inventory/stockpicking?stockPickingType=${stockPickingType.id}&isDue=true`} >
                          <p className="mb-0">Vencidas ({stockPickingType.dueQty})</p>
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-6 align-self-end">
                      <div className="float-right mt-3">
                      {stockPickingType.pickingType === 1 ? (
                       <Link to={`/inventory/stockpicking?stockPickingType=${stockPickingType.id}&assigned=true`} className="btn btn-primary">
                       {stockPickingType.assignedQty}  POR RECIBIR
                     </Link>
                    ) : null}

                    {stockPickingType.pickingType === 2 ? (
                        <Link to={`/inventory/stockpicking?stockPickingType=${stockPickingType.id}&assigned=true`} className="btn btn-primary">
                        {stockPickingType.assignedQty}  POR HACER
                      </Link>
                    ) : null}
                    {stockPickingType.pickingType === 3 ? (
                        <Link to={`/inventory/stockpicking?stockPickingType=${stockPickingType.id}&assigned=true`} className="btn btn-primary">
                        {stockPickingType.assignedQty} TRANSFERENCIAS
                      </Link>
                    ) : null}
                        
                      </div>
                    </div>
                  </Row>
                  </CardBody>
                  </Card>
              </Col>
            ))}
          </Row>
          </Container>
          </div>
          
          </React.Fragment>
  );
};

export default InventoryDashboard;
