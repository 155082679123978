import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

const StackedColumnChart = props => {
  const { data } = props

  const handleBarClick = s => {
    if (s) {
      window.open(
        `/invoices/accountinvoice?moveType=1&dateInvoice=${s}`,
        "_blank"
      )
    }
  }

  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
      events: {
        click(event, chartContext, config) {
          // console.log(config.config.series[config.seriesIndex])
          // console.log(config.config.series[config.seriesIndex].name)
          handleBarClick(config.config.xaxis.categories[config.dataPointIndex])

          //console.log(config.config.xaxis.categories[config.dataPointIndex])
        },
      },
    },

    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "50%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: data?.categories,
      labels: {
        show: true,
      },
    },
    colors: ["#ff6347", "#f1b44c", "#34c38f"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }
  return (
    <React.Fragment>
      {data ? (
        <ReactApexChart
          options={options}
          series={data?.series}
          type="bar"
          height="359"
          className="apex-charts"
        />
      ) : null}
    </React.Fragment>
  )
}

export default StackedColumnChart
