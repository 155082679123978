import React from "react"
import { Link } from "react-router-dom"
import useMenu from "../../hooks/useMenu"

export const CustomLink = props => {
  const { className, to, content, reset, id, exactpath, index, disabled } = props

  const { GoTo } = useMenu()

  const onClick = e => {
    GoTo(content, to, false, null, index)
  }

  let linkProps = {
    onClick : disabled ? null : onClick,
    className : className,
    to: disabled ? null : to,
  };

  if(id != null){
    linkProps["data-id"] = id;
  }

  if(exactpath != null){
    linkProps["data-exactpath"] = exactpath;
  }

  return disabled ? (
    <span
      {...linkProps}
    >
      {props.children}
    </span>
  )  : (
    
    <Link
      {...linkProps}
    >
      {props.children}
    </Link>
  )
}

export default CustomLink
