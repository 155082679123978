import CustomFilters from "../../../../components/Common/CustomFilters"

const AccountPaymentTermCustomFilters = props => {
  const { filter, setFilter, columns } = props

  const itemsFilter = []

  return (
    <CustomFilters
      filter={filter}
      setFilter={setFilter}
      columns={columns}
      itemsFilter={itemsFilter}
    />
  )
}

export default AccountPaymentTermCustomFilters
