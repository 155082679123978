import React from "react";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { Row, Col, FormGroup, Label } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import { AFIP_CODE_ENUM, AFIP_TRIBUTE_CODE_ENUM, CALCULATION_TYPE_ENUM, PRICELISTTYPE_ENUM, SCOPE_ENUM } from "./consts";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CustomSelect from "../../../../components/Common/CustomSelect";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
      <Row>
        <Col lg="4">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />
        
        </Col>
       
      </Row>
  );
};

export default BaseForm;
