import React from "react"
import { Row, Col } from "reactstrap"
import AccountInvoiceTabs from "./AccountInvoiceTabs"
import SmartButtons from "./SmartButtons"
import { useEffect } from "react"
import useQueryURL from "../../../../hooks/useQueryURL"
import { INITIAL_STATE_FILTER } from "./consts"
import { useState } from "react"
import BaseFormSale from "./BaseFormSale"
import BaseFormPurchase from "./BaseFormPurchase"
import Container from "react-modal-promise";

export const BaseForm = props => {
  const { form, setFormState, disabled } = props
  const { filter, queryURL } = useQueryURL(INITIAL_STATE_FILTER)
  const [internalType, setInternalType] = useState(0)

  const readOnlyStates = [1, 2, 3]

  useEffect(() => {
    if (filter) {
      setInternalType(filter["internalType"])
    }
  }, [filter])

  return (
    <React.Fragment>
      <Container />
      <Row>
        {form.fields.moveType === 1 && <BaseFormSale form={form} disabled={disabled} internalType={internalType} readOnlyStates={readOnlyStates} /> }
        {form.fields.moveType === 2 && <BaseFormPurchase form={form} disabled={disabled} internalType={internalType} readOnlyStates={readOnlyStates} /> }

        <Col md="4">
          <SmartButtons form={form} />
        </Col>
      </Row>
      <AccountInvoiceTabs
        form={form}
        setFormState={setFormState}
        readOnlyStates={readOnlyStates}
        disabled={
          disabled || readOnlyStates.includes(form.fields?.invoiceState) || form.fields?.origin != null
        }
      />
    </React.Fragment>
  )
}

export default BaseForm
