import React from "react"
import { Button, Col } from "reactstrap"
import { useKeyUp } from "../../../../hooks/useKeyUp"

const Pads = props => {
  const { setMode, setValue } = props

  useKeyUp(() => AddDigit(0), ["Digit0", "Numpad0"])
  useKeyUp(() => AddDigit(1), ["Digit1", "Numpad1"])
  useKeyUp(() => AddDigit(2), ["Digit2", "Numpad2"])
  useKeyUp(() => AddDigit(3), ["Digit3", "Numpad3"])
  useKeyUp(() => AddDigit(4), ["Digit4", "Numpad4"])
  useKeyUp(() => AddDigit(5), ["Digit5", "Numpad5"])
  useKeyUp(() => AddDigit(6), ["Digit6", "Numpad6"])
  useKeyUp(() => AddDigit(7), ["Digit7", "Numpad7"])
  useKeyUp(() => AddDigit(8), ["Digit8", "Numpad8"])
  useKeyUp(() => AddDigit(9), ["Digit9", "Numpad9"])
  useKeyUp(() => setValue(0), ["Backspace"])

  const AddDigit = char => {
    setValue(prev => prev + "" + char)
  }
  return (
    <div className="subpads">
      {/* actionpad */}
      <div className="actionpad">
        <button className="button set-partner" onClick={e => setMode(2)}>
          <i class="bx bx-smile font-size-16 align-middle me-2"></i>
          Cliente
        </button>
        <button className="button validation" onClick={e => setMode(1)}>
          <i class="bx bx-up-arrow-circle font-size-22 align-middle mb-2"></i>
          Pago
        </button>
      </div>

      {/* numpad */}
      <div className="numpad">
        <button className="btn" onClick={e => AddDigit(1)}>
          1
        </button>
        <button className="btn" onClick={e => AddDigit(2)}>
          2
        </button>
        <button className="btn" onClick={e => AddDigit(3)}>
          3
        </button>
        <button className="btn selected">Cant.</button>
        <button className="btn" onClick={e => AddDigit(4)}>
          4
        </button>
        <button className="btn" onClick={e => AddDigit(5)}>
          5
        </button>
        <button className="btn" onClick={e => AddDigit(6)}>
          6
        </button>
        <button className="btn">Desc.</button>

        <button className="btn" onClick={e => AddDigit(7)}>
          7
        </button>
        <button className="btn" onClick={e => AddDigit(8)}>
          8
        </button>
        <button className="btn" onClick={e => AddDigit(9)}>
          9
        </button>
        <button className="btn">Precio</button>

        <button className="btn">+/-</button>
        <button className="btn" onClick={e => AddDigit(0)}>
          0
        </button>
        <button className="btn">,</button>
        <button className="btn">
          <i className="fa fa-angle-double-left fa-fw"></i>
        </button>
      </div>
    </div>
  )
}

export default Pads
