import { MODULE } from "../../consts";

export const MODEL = 'stockmoveline';

export const INDEX_TITLE = 'Movimiento de productos';
export const CREATE_TITLE = 'Nuevo tipo de operación';

export const INITIAL_STATE = {
    quantity: 0,
    reserved: 0,
    product: null,
    location: null,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;


export const INITIAL_STATE_FILTER = {
  product: null,
  internal: false,
  in: false,
  out: false,
  done: false,
  assigned: false,
  customFilters: []
};
