import React from "react";
import { Row, Col } from "reactstrap";
import CustomSelect from "../../../../components/Common/CustomSelect";
import CustomDateTime from "../../../../components/Common/CustomDateTime";
import PurchaseOrderTabs from "./PurchaseOrderTabs";
import PurchaseOrderActions from "./PurchaseOrderActions";
import SmartButtons  from "./SmartButtons";
import CumstomField from "../../../../components/Common/CustomFields";
import { getLabelFromArray } from "../../../../helpers/array_utils";
import { DOCUMENTTYPE_ENUM } from "../../../Core/Partner/components/consts";
import { partnerModal } from "../../../Core/Partner/components/PartnerModal"
import Container from "react-modal-promise";

export const BaseForm = (props) => {
  const { form, setFormState, disabled } = props;

  const readOnlyStates = [5, 6]

  return (
    <React.Fragment>
      <Container />
      <Row>
        <Col md="4">

          <CustomSelect
             label={`Proveedor ${form.fields?.partner ? `[${getLabelFromArray(form.fields?.partner?.documentType,DOCUMENTTYPE_ENUM)} - ${form.fields?.partner?.document}]` : ''}`}
            {...form?.getSelect("partner", "partner","partnerType=2",partnerModal,{active: true, supplier: true})}
            required
            isDisabled={disabled || readOnlyStates.includes(form.fields?.state)}
          />

        <CumstomField
            {...form?.getInput("partnerReference")}
            label="Referencia de proveedor"
            type="text"
            disabled={disabled || readOnlyStates.includes(form.fields?.state)}
          />

        </Col>

        <Col md="4">
          <CustomDateTime
            label="Fecha de pedido"
            disabled={disabled || readOnlyStates.includes(form.fields?.state)}
            required
            {...form?.getInputDate("datetime-local", "dateOrder")}
          />

        {form.fields?.state == 4 || form.fields?.state == 5 || form.fields?.state == 6 ? (
            <CustomSelect
              label="Diario"
              {...form?.getSelect(
                "accountJournal",
                "accountJournal",
                "types=2&forInvoice=false"
              )}
              required
              isDisabled={disabled || readOnlyStates.includes(form.fields?.state)}
            />
          ) : null}
        </Col>
        <Col md="4">
          <SmartButtons form={form} />
        </Col>
      </Row>
      <PurchaseOrderTabs
        form={form}
        setFormState={setFormState}
        disabled={disabled || readOnlyStates.includes(form.fields?.state)}
        readOnlyStates={readOnlyStates}
      />
    </React.Fragment>
  );
};

export default BaseForm;
