import {
    BASE_DELETE_TRY,
    BASE_DELETE_ERROR,
    BASE_DELETE_OK,
  } from "./actionTypes";
  
  const initialState = {
    data: [],
    error: "",
    loading: false,
  };
  
  const result = (state = initialState, action) => {
    switch (action.type) {
      case BASE_DELETE_TRY:
        state = {
          ...state,
          loading: true,
        };
        break;
      case BASE_DELETE_OK:
        state = {
          ...state,
          loading: false,
          data: action.payload.data,
        };
        break;
      case BASE_DELETE_ERROR:
        state = { ...state, error: action.payload, loading: false };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default result;
  