import React from "react"

import { MODEL, INITIAL_STATE_FILTER } from "./consts"
import CustomDatatable from "../../../../components/Common/CustomDatatable/CustomDatatable"
import FormLayout from "../../../../components/Common/FormLayout"
import PartnerCustomFilters from "./PartnerCustomFilters"
import CustomLink from "../../../../components/Common/CustomLink"
import { getLabelFromArray } from "../../../../helpers/array_utils";
import {DOCUMENTTYPE_ENUM } from "./consts";

const IndexTable = props => {
  
  const { filter, actionButtons, setFilter, modal, setValue, module} = props

  const columns = [
    {
      name: "FullName",
      label: "Nombre",
      width: 20,
      type: "string"
    },
    {
      name: "FiscalPositionName",
      label: "Posición fiscal",
      width: 20,
    },
    {
      name: "Document",
      label: "Documento",
      width: 20,
      type: "number"
    },
    {
      name: "Email",
      label: "Email",
      width: 20,
      type: "string"
    },
    {
      name: "Phone",
      label: "Teléfono",
      width: 20,
      type: "string",
    },
  ]

  const rows = data => {
    const rows = []

    const r = data?.map((p, index) => {
      const fields = []

      var inputName = p.name.split(" ")

      fields.push(
        <CustomLink content={p.name} to={`/${module}/${MODEL}/${p.id}`} disabled={modal}>
          <div className="d-flex align-items-center">
            <span className="me-2">
              {p.logo == null ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {inputName[0].charAt(0)}
                    {inputName.length > 1 ? inputName[1].charAt(0) : null}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    onClick={() => {
                      setPreviewImage(p.logo)
                    }}
                    className="rounded-circle avatar-xs"
                    src={p.logo}
                    alt=""
                  />
                </div>
              )}
            </span>
            {p.fullName}
          </div>
        </CustomLink>
      )

      fields.push(<span>{p.fiscalPositionName}</span>)
      

      fields.push(<span>{`${getLabelFromArray(p.documentType,DOCUMENTTYPE_ENUM)}: ${p.document ?? ''}`}</span>)

      fields.push(<span>{p.email}</span>)

      fields.push(<a href={`tel:${p.phone}`}>{p.phone}</a>)
      rows.push({ fields: fields })
    })

    return rows
  }

  const customFilters = (
    <PartnerCustomFilters filter={filter} setFilter={setFilter} columns={columns} isModal={modal} />
  )

  return (
    <FormLayout actionButtons={actionButtons} modal={modal}>
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        filter={filter}
        customFilters={customFilters}
        modal={modal}
        setValue={setValue}
        defaultSort={{orderCriteria: "Name", orderDirection: 0}}
      />
    </FormLayout>
  )
}

export default IndexTable
