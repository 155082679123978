import React from "react";
import { Row, Col } from "reactstrap";
import CustomEnumSelect from "../../../../components/Common/CustomEnumSelect";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSelect from "../../../../components/Common/CustomSelect";
import CustomSwitch from "../../../../components/Common/CustomSwitch";
import {  STOCKWAREHOUSE_TYPE_ENUM } from "./consts";

export const BaseForm = ({ form, handleSubmit, disabled }) => {
  return (
    <Row>
      <Col lg="4">
        <CumstomField
          {...form?.getInput("name")}
          label="Nombre"
          type="text"
          disabled={disabled}
          required
          autoFocus={true}
        />

        <CumstomField
          {...form?.getInput("prefix")}
          label="Prefijo"
          type="text"
          disabled={disabled}
          required
        />

        <CustomEnumSelect
            label="Tipo"
            {...form?.getEnumSelect("type", STOCKWAREHOUSE_TYPE_ENUM)}
            isDisabled={disabled}
          />

        <CumstomField
          {...form?.getInput("address")}
          label="Dirección"
          type="text"
          disabled={disabled}
          required
        />

        <CustomSwitch
            label="Activo"
            disabled={disabled}
            {...form?.getSwitch("active")}
          />

      </Col>

      <Col lg="4">

      <CustomSelect
          label="Compañia"
          {...form?.getSelect("company", "company")}
          required
          isDisabled={disabled}
        />

      </Col>
    </Row>
  );
};

export default BaseForm;
