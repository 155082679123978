import { MODULE } from "../../consts";

export const MODEL = 'stockLocation';

export const INDEX_TITLE = 'Ubicaciones';
export const CREATE_TITLE = 'Nueva ubicación';

export const INITIAL_STATE = {
    name: "",
    prefix: "",
    type: 1,
    address: "",
    stockLocation: null,
    initialLocation: null,
    finalLocation: null,
    partner: null,
    usage: 3,
    active: true,
  };

export const BASE_URL = `/${MODULE}/${MODEL}`;

export const STOCKLOCATION_USAGETYPE_ENUM = [
  { value: 1, label: 'Ubicación de proveedor'},
  { value: 2, label: 'Ubicación de stock'},
  { value: 3, label: 'Ubicación interna'},
  { value: 4, label: 'Ubicación de cliente'},
  { value: 5, label: 'Ajuste de inventario'},
];
