import React from "react";
import {
  Row,
  TabPane,
} from "reactstrap";
import ProductPriceListTable from "../Tables/ProductPriceListTable";

const SalesTab = (props) => {
  const { form, disabled, setFormState } = props;


  return (
    <TabPane tabId="2" className="p-3">
      <Row>
        <ProductPriceListTable
          form={form}
          setFormState={setFormState}
          disabled={disabled}
        />
      </Row>
    </TabPane>
  );
};

export default SalesTab;
