import React from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import CumstomField from "../../../../components/Common/CustomFields";
import CustomSelect from "../../../../components/Common/CustomSelect";

import Tabs from "./Tabs";

export const BaseForm = ({ form, handleSubmit, disabled, setFormState }) => {

  
  // useEffect(() => {
  //   console.log(form.fields);

  // },[form.fields['category']]);

  return (
    
      <Row>
        <Col lg="4">
          <CumstomField
            {...form?.getInput("name")}
            label="Nombre"
            type="text"
            disabled={disabled}
            required
            autoFocus={true}
          />

        <CustomSelect
          label="Módulo"
          {...form?.getSelect("applicationModule", "applicationModule")}
          required
          isDisabled={disabled}
        />

        </Col>
        <Tabs form={form} disabled={disabled} setFormState={setFormState} />
      </Row>
  );
};

export default BaseForm;
