import { useEffect } from "react";

import {  MODEL, BASE_URL, INDEX_TITLE, INVOICE_STATUS_ENUM } from "./components/consts";
import CustomDatatable from "../../../components/Common/CustomDatatable/CustomDatatable";
import FormLayout from "../../../components/Common/FormLayout";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../store/pagetitle/actions";
import IndexActionButtons from "../../../components/Actions/Index/IndexActionButtons";
import {  MODULE } from "../consts";
import { INITIAL_STATE_FILTER } from "./components/consts";
import AccountInvoiceCustomFilters from "./components/AccountInvoiceCustomFilters";
import moment from "moment";
import {Badge} from "reactstrap";
import useQueryURL from "../../../hooks/useQueryURL";
import CustomLink from "../../../components/Common/CustomLink";

const Index = (props) => {

  const dispatch = useDispatch();
  const {filter, setFilter, queryURL} = useQueryURL(INITIAL_STATE_FILTER);
  const { type } = props;

  useEffect(() => {

    if(type==='sale'){
      setFilter({...filter,
        sale: true
      });
    }

  },[type]);

  useEffect(() => {
    dispatch(setPageTitle(INDEX_TITLE));
  },[]);

  const columns = [
      {
        name: "ID",
        label: "Número",
        width: 10,
        type: "string"
      },
      {
        name: "PartnerName",
        label: filter?.moveType== 2 ? "Proveedor" : "Cliente",
        width: 20,
        type: "string"
      },
      {
        name: "DateInvoice",
        label: "Fecha",
        width: 10,
        type: "date"
      },
      {
        name: "UserName",
        label: "Comercial",
        width: 15,
        type: "string"
      },
      {
        name: "Origin",
        label: "Referencia",
        width: 10,
        type: "string"
      },
      {
        name: "DueInvoice",
        label: "Vencimiento",
        width: 10,
        type: "date"
      },
      {
        name: "Total",
        label: "Total",
        width: 10,
        type: "number"
      },
      {
        name: "InvoiceState",
        label: "Estado",
        width: 20,
        type: "enum",
        customEnum: INVOICE_STATUS_ENUM
      },
    ];

  const rows = (data) => {

    const rows = [];
    
    const r= data?.map((p, index) => {
      const fields = [];
      
      fields.push(
        <CustomLink 
        content={p.name}
        to={`${BASE_URL}/${p.id}${queryURL}`}>{p.name}</CustomLink>
      );
      
      fields.push(
        <span>
          <CustomLink 
          content={p.partnerName}
          to={`/${MODULE}/partner/${p.partnerID}`}>{p.partnerName}</CustomLink>
            
        </span>
      );

      fields.push(
        <span>
           {moment(p.dateInvoice).format("DD/MM/YYYY")}</span>
      );

      fields.push(
        <span>
          <CustomLink 
          content={p.userName}
          to={`/settings/user/${p.userID}`}>{p.userName}</CustomLink>
            
        </span>
      );

      fields.push(
        <span>{p.origin}</span>
        );
      
     fields.push(
        <span>
           {moment(p.dueInvoice).format("DD/MM/YYYY")}</span>
      );

      fields.push(
      <span>$ {p.total}</span>
      );

      switch (p.invoiceState) {
          case 0:
            fields.push(
              <Badge className="me-1 rounded-pill bg-info font-size-11">Borrador</Badge>
            );
            
            break;
          case 1:
            fields.push(
              <Badge className="me-1 rounded-pill bg-danger font-size-11">No pagada</Badge>
            );
            break;
          case 2:
            fields.push(
              <Badge className="me-1 rounded-pill bg-success font-size-11">Pagada</Badge>
            );
            break;
          case 4:
            fields.push(
              <Badge className="me-1 rounded-pill bg-info font-size-11">Cancelado</Badge>
            );
            break;
          default:
            break;
        }
        

      rows.push({fields : fields});
    });

    return rows;
  };

  const customFilters = (
    <AccountInvoiceCustomFilters
      filter={filter}
      setFilter={setFilter}
      columns={columns}
    />
  );

  const actionButtons =  (
    <IndexActionButtons
      url={BASE_URL}
      queryURL={queryURL}
      disableCreate={(filter?.internalType === 3 && filter?.moveType===1) || (filter?.internalType === 1 && filter?.moveType===2)}
    />
);

  return (
    <FormLayout
      actionButtons={actionButtons}
    >
      <CustomDatatable
        model={MODEL}
        checks
        columns={columns}
        rows={rows}
        customFilters={customFilters}
        filter={filter}
        defaultSort={{orderCriteria: "ID", orderDirection: 1}}
      />
    </FormLayout>
  );
};

export default Index;
