import React, { useRef, useEffect } from 'react';
import { Badge } from 'reactstrap';

const CustomBadge = (props) => {

  const { color, ...rest } = props;
  const badgeRef = useRef(null);

  useEffect(() => {
    if (badgeRef.current) {
      badgeRef.current.style.setProperty("background-color", color, "important");
    }
  }, [color]);

  return <span className="badge me-1" {...rest} ref={badgeRef} />;
};

export default CustomBadge;
